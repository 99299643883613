import EditarMicroscopia from "layouts/pages/auxiliares/microscopia/editar";
import EditarDescricaoAdicional from "layouts/pages/auxiliares/descricaoAdicional/editar";
import EditarProcedimento from "./layouts/pages/auxiliares/procedimento/editar";
import EditarTabelaProcedimento from "./layouts/pages/auxiliares/tabelaProcedimento/editar";
import EditarAgenda from "./layouts/pages/auxiliares/agenda/editar";
import EditarAgendamento from "./layouts/pages/auxiliares/agendamento/editar";
import EditarAlmoxarifado from "./layouts/pages/auxiliares/almoxarifado/editar";
import EditarBancada from "./layouts/pages/auxiliares/bancada/editar";
import EditarCadastroRastreabilidade from "./layouts/pages/auxiliares/cadastroRastreabilidade/editar";
import EditarCategoriaProduto from "./layouts/pages/auxiliares/categoriaProduto/editar";
import EditarCobrancaExame from "./layouts/pages/auxiliares/cobrancaExame/editar";
import EditarCompras from "./layouts/pages/auxiliares/compras/editar";
import EditarContatoPaciente from "./layouts/pages/auxiliares/contatoPaciente/editar";
import EditarControleEstoque from "./layouts/pages/auxiliares/controleEstoque/editar";
import EditarConvenioPaciente from "./layouts/pages/auxiliares/convenioPaciente/editar";
import EditarCotacao from "./layouts/pages/auxiliares/cotacao/editar";
import EditarEnderecoPaciente from "./layouts/pages/auxiliares/enderecoPaciente/editar";
import EditarEntradaCaixa from "./layouts/pages/auxiliares/entradaCaixa/editar";
import EditarSaidaCaixa from "./layouts/pages/auxiliares/saidaCaixa/editar";
import EditarEntradaExame from "./layouts/pages/auxiliares/entradaExame/editar";
import EditarEntradaMaterialTecido from "./layouts/pages/auxiliares/entradaMaterialTecido/editar";
import EditarFilaEmail from "./layouts/pages/auxiliares/filaEmail/editar";
import EditarFilaSms from "./layouts/pages/auxiliares/filaSms/editar";
import EditarFornecedor from "./layouts/pages/auxiliares/fornecedor/editar";
import EditarHorarioAgenda from "./layouts/pages/auxiliares/horarioAgenda/editar";
import EditarInstituicao from "./layouts/pages/auxiliares/instituicao/editar";
import EditarLaudo from "./layouts/pages/auxiliares/laudo/editar";
import EditarLaudoFinalizado from "./layouts/pages/auxiliares/laudo/editarLaudoFinalizado";
import VisualizarLaudo from "./layouts/pages/auxiliares/laudo/visualizar";
import EditarLembreteProcedimento from "./layouts/pages/auxiliares/lembreteProcedimento/editar";
import EditarMaterialTecido from "./layouts/pages/auxiliares/materialTecido/editar";
import EditarMedicosEntrada from "./layouts/pages/auxiliares/medicosEntrada/editar";
import EditarOrigemExame from "./layouts/pages/auxiliares/origemExame/editar";
import EditarPaciente from "./layouts/pages/auxiliares/paciente/editar";
import EditarProcedimentoAgendamento from "./layouts/pages/auxiliares/procedimentoAgendamento/editar";
import EditarProduto from "./layouts/pages/auxiliares/produto/editar";
import EditarProfissionalSaude from "./layouts/pages/auxiliares/profissionalSaude/editar";
import EditarRastreabilidade from "./layouts/pages/auxiliares/rastreabilidade/editar";
import EditarValorProcedimento from "./layouts/pages/auxiliares/valorProcedimento/editar";
import EditarConvenio from "./layouts/pages/auxiliares/convenio/editar";
import EditarTemplateLaudos from "./layouts/pages/auxiliares/templateLaudos/editar";
import DadosRegistro from "./layouts/pages/auxiliares/entradaExame/dados_registro";
import DadosMacroscopia from "./layouts/pages/auxiliares/entradaExame/dados_macroscopia";
import VizualizarMacroscopia from "./layouts/pages/auxiliares/entradaExame/vizualizar_macroscopia";
import ItensMateriasKit from "./layouts/pages/auxiliares/kitMaterialProcedimento/itens_kit";
import EditarMateriasKit from "./layouts/pages/auxiliares/kitMaterialProcedimento/editar";
import EditarContasBancarias from "./layouts/pages/auxiliares/contasBancarias/editar";
import EditarBancoFrases from "./layouts/pages/auxiliares/bancoFrases/editar";
import EditarPalavraChave from "./layouts/pages/auxiliares/palavraChave/editar";
import EditarLaminario from "./layouts/pages/auxiliares/laminario/editar";
import CadastroLaudo from "./layouts/pages/auxiliares/laudo/cadastro";
import DadosPassagemMedica from "./layouts/pages/auxiliares/entradaExame/dados_passagem";
import EditarDadosClinicos from "./layouts/pages/auxiliares/dadosClinicos/editar";
import EditarDiagHisto from "./layouts/pages/auxiliares/diagHisto/editar";
import EditarDiagNotas from "./layouts/pages/auxiliares/diagNotas/editar";
import EditarMacroscopia from "./layouts/pages/auxiliares/macroscopia/editar";
import EditarColoracao from "./layouts/pages/auxiliares/coloracao/editar";
import EditarProcedimentoEntradaExame from "./layouts/pages/auxiliares/procedimentoEntradaExame/editar";
import EditarMaterialBiologicoEntradaExame from "./layouts/pages/auxiliares/materialBiologicoEntradaExame/editar";


const editRoutes = [
  {
    name: "editarprocedimento",
    key: "editarprocedimento",
    route: "/auxiliares/procedimento/editar/:id/:uuid",
    component: EditarProcedimento,
  },
  {
    name: "editartabelaprocedimento",
    key: "editartabelaprocedimento",
    route: "/auxiliares/tabelaProcedimento/editar/:id/:uuid",
    component: EditarTabelaProcedimento,
  },
  {
    name: "editaragenda",
    key: "editaragenda",
    route: "/auxiliares/agenda/editar/:id/:uuid",
    component: EditarAgenda,
  },
  {
    name: "editaragendamento",
    key: "editaragendamento",
    route: "/auxiliares/agendamento/editar/:id/:uuid",
    component: EditarAgendamento,
  },
  {
    name: "editaralmoxarifado",
    key: "editaralmoxarifado",
    route: "/auxiliares/almoxarifado/editar/:id/:uuid",
    component: EditarAlmoxarifado,
  },
  {
    name: "editarbancada",
    key: "editarbancada",
    route: "/auxiliares/bancada/editar/:id/:uuid",
    component: EditarBancada,
  },
  {
    name: "editarcadastrorastreabilidade",
    key: "editarcadastrorastreabilidade",
    route: "/auxiliares/cadastroRastreabilidade/editar/:id/:uuid",
    component: EditarCadastroRastreabilidade,
  },
  {
    name: "editarcategoriaproduto",
    key: "editarcategoriaproduto",
    route: "/auxiliares/categoriaProduto/editar/:id/:uuid",
    component: EditarCategoriaProduto,
  },
  {
    name: "editarcobrancaexame",
    key: "editarcobrancaexame",
    route: "/auxiliares/cobrancaExame/editar/:id/:uuid",
    component: EditarCobrancaExame,
  },
  {
    name: "editarcompras",
    key: "editarcompras",
    route: "/auxiliares/compras/editar/:id/:uuid",
    component: EditarCompras,
  },
  {
    name: "editarcontatopaciente",
    key: "editarcontatopaciente",
    route: "/auxiliares/contatoPaciente/editar/:id/:uuid",
    component: EditarContatoPaciente,
  },
  {
    name: "editarcontroleestoque",
    key: "editarcontroleestoque",
    route: "/auxiliares/controleEstoque/editar/:id/:uuid",
    component: EditarControleEstoque,
  },
  {
    name: "editarconvenio",
    key: "editarconvenio",
    route: "/auxiliares/convenio/editar/:id/:uuid",
    component: EditarConvenio,
  },
  {
    name: "editarconveniopaciente",
    key: "editarconveniopaciente",
    route: "/auxiliares/convenioPaciente/editar/:id/:uuid",
    component: EditarConvenioPaciente,
  },
  {
    name: "editarcotacao",
    key: "editarcotacao",
    route: "/auxiliares/cotacao/editar/:id/:uuid",
    component: EditarCotacao,
  },
  {
    name: "editarenderecopaciente",
    key: "editarenderecopaciente",
    route: "/auxiliares/enderecoPaciente/editar/:id/:uuid",
    component: EditarEnderecoPaciente,
  },
  {
    name: "editarentradacaixa",
    key: "editarentradacaixa",
    route: "/auxiliares/entradaCaixa/editar/:id/:uuid",
    component: EditarEntradaCaixa,
  },
  {
    name: "editarsaidacaixa",
    key: "editarsaidacaixa",
    route: "/auxiliares/saidaCaixa/editar/:id/:uuid",
    component: EditarSaidaCaixa,
  },
  {
    name: "editarentradaexame",
    key: "editarentradaexame",
    route: "/auxiliares/entradaExame/editar/:id/:uuid",
    component: EditarEntradaExame,
  },
  {
    name: "dadosregistro",
    key: "dadosregistro",
    route: "/auxiliares/entradaExame/dadosRegistro/:id/:uuid",
    component: DadosRegistro,
  },
  {
    name: "dadosmacroscopia",
    key: "dadosmacroscopia",
    route: "/auxiliares/entradaExame/dadosMacroscopia/:id/:uuid",
    component: DadosMacroscopia,
  },
  {
    name: "vizualizarmacroscopia",
    key: "vizualizarmacroscopia",
    route: "/auxiliares/entradaExame/vizualizarMacroscopia/:id/:uuid",
    component: VizualizarMacroscopia,
  },
  {
    name: "editarentradamaterialtecido",
    key: "editarentradamaterialtecido",
    route: "/auxiliares/entradaMaterialTecido/editar/:id/:uuid",
    component: EditarEntradaMaterialTecido,
  },
  {
    name: "editarfilaemail",
    key: "editarfilaemail",
    route: "/auxiliares/filaEmail/editar/:id/:uuid",
    component: EditarFilaEmail,
  },
  {
    name: "editarfilasms",
    key: "editarfilasms",
    route: "/auxiliares/filaSms/editar/:id/:uuid",
    component: EditarFilaSms,
  },
  {
    name: "editarfornecedor",
    key: "editarfornecedor",
    route: "/auxiliares/fornecedor/editar/:id/:uuid",
    component: EditarFornecedor,
  },
  {
    name: "editarhorarioagenda",
    key: "editarhorarioagenda",
    route: "/auxiliares/horarioAgenda/editar/:id/:uuid",
    component: EditarHorarioAgenda,
  },
  {
    name: "editarinstituicao",
    key: "editarinstituicao",
    route: "/auxiliares/instituicao/editar/:id/:uuid",
    component: EditarInstituicao,
  },
  {
    name: "editarlaudo",
    key: "editarlaudo",
    route: "/auxiliares/laudo/editar/:id/:uuid",
    component: EditarLaudo,
  },
  {
    name: "editarlaudoFinalizado",
    key: "editarlaudofinalizado",
    route: "/auxiliares/laudo/editarLaudoFinalizado/:id/:uuid",
    component: EditarLaudoFinalizado,
  },
  {
    name: "visualizarLaudo",
    key: "visualizarLaudo",
    route: "/auxiliares/laudo/visualizar/:id/:uuid",
    component: VisualizarLaudo,
  },
  {
    name: "editarlembreteprocedimento",
    key: "editarlembreteprocedimento",
    route: "/auxiliares/lembreteProcedimento/editar/:id/:uuid",
    component: EditarLembreteProcedimento,
  },
  {
    name: "editarmaterialtecido",
    key: "editarmaterialtecido",
    route: "/auxiliares/materialTecido/editar/:id/:uuid",
    component: EditarMaterialTecido,
  },
  {
    name: "editarmedicosentrada",
    key: "editarmedicosentrada",
    route: "/auxiliares/medicosEntrada/editar/:id/:uuid",
    component: EditarMedicosEntrada,
  },
  {
    name: "editarorigemexame",
    key: "editarorigemexame",
    route: "/auxiliares/origemExame/editar/:id/:uuid",
    component: EditarOrigemExame,
  },
  {
    name: "editarpaciente",
    key: "editarpaciente",
    route: "/auxiliares/paciente/editar/:id/:uuid",
    component: EditarPaciente,
  },
  {
    name: "editarprocedimentoagendamento",
    key: "editarprocedimentoagendamento",
    route: "/auxiliares/procedimentoAgendamento/editar/:id/:uuid",
    component: EditarProcedimentoAgendamento,
  },
  {
    name: "editarproduto",
    key: "editarproduto",
    route: "/auxiliares/produto/editar/:id/:uuid",
    component: EditarProduto,
  },
  {
    name: "editarprofissionalsaude",
    key: "editarprofissionalsaude",
    route: "/auxiliares/profissionalSaude/editar/:id/:uuid",
    component: EditarProfissionalSaude,
  },
  {
    name: "editarrastreabilidade",
    key: "editarrastreabilidade",
    route: "/auxiliares/rastreabilidade/editar/:id/:uuid",
    component: EditarRastreabilidade,
  },
  {
    name: "editarvalorprocedimento",
    key: "editarvalorprocedimento",
    route: "/auxiliares/valorProcedimento/editar/:id/:uuid",
    component: EditarValorProcedimento,
  },
  {
    name: "editartemplatelaudos",
    key: "editartemplatelaudos",
    route: "/auxiliares/template/editar/:id/:uuid",
    component: EditarTemplateLaudos,
  },
  {
    name: "itensmateriaskit",
    key: "itensmateriaskit",
    route: "/auxiliares/estoque/kitsProdutos/itens/:id/:uuid",
    component: ItensMateriasKit,
  },
  {
    name: "editarcontasbancarias",
    key: "editarcontasbancarias",
    route: "/auxiliares/contasBancarias/editar/:id/:uuid",
    component: EditarContasBancarias,
  },
  {
    name: "editarbancofrases",
    key: "editarbancofrases",
    route: "/auxiliares/bancoFrases/editar/:id/:uuid",
    component: EditarBancoFrases,
  },
  {
    name: "editarpalavrachave",
    key: "editarpalavrachave",
    route: "/auxiliares/palavraChave/editar/:id/:uuid",
    component: EditarPalavraChave,
  },
  {
    name: "editardadosclinicos",
    key: "editardadosclinicos",
    route: "/auxiliares/dadosClinicos/editar/:id/:uuid",
    component: EditarDadosClinicos,
  },
  {
    name: "editardiaghisto",
    key: "editardiaghisto",
    route: "/auxiliares/diagHisto/editar/:id/:uuid",
    component: EditarDiagHisto,
  },
  {
    name: "editardiagnotas",
    key: "editardiagnotas",
    route: "/auxiliares/diagNotas/editar/:id/:uuid",
    component: EditarDiagNotas,
  },
  {
    name: "editarmacroscopia",
    key: "editarmacroscopia",
    route: "/auxiliares/macroscopia/editar/:id/:uuid",
    component: EditarMacroscopia,
  },
  {
    name: "editarmicroscopia",
    key: "editarmicroscopia",
    route: "/auxiliares/microscopia/editar/:id/:uuid",
    component: EditarMicroscopia,
  },
  {
    name: "editarcoloracao",
    key: "editarcoloracao",
    route: "/auxiliares/coloracao/editar/:id/:uuid",
    component: EditarColoracao,
  },
  {
    name: "editardescricaoadicional",
    key: "editardescricaoadicional",
    route: "/auxiliares/descricaoAdicional/editar/:id/:uuid",
    component: EditarDescricaoAdicional,
  },
  {
    name: "editarmateriaskit",
    key: "editarmateriaskit",
    route: "/auxiliares/kitMaterialProcedimento/editar/:id/:uuid",
    component: EditarMateriasKit,
  },
  {
    name: "editarlaminario",
    key: "editarlaminario",
    route: "/auxiliares/laminario/editar/:id/:uuid",
    component: EditarLaminario,
  },
  {
    name: "dadospassagemmedica",
    key: "dadospassagemmedica",
    route: "/clinico/passagem/:id/:uuid",
    component: DadosPassagemMedica,
  },
  {
    name: "cadastrolaudo",
    key: "cadastrolaudo",
    route: "/clinico/laudo/cadastro/:id_entrada/:uuid",
    component: CadastroLaudo,
  },
  {
    name: "editarprocedimentoentradaexame",
    key: "editarprocedimentoentradaexame",
    route: "/auxiliares/procedimentoEntradaExame/editar/:id/:uuid",
    component: EditarProcedimentoEntradaExame,
  },
  {
    name: "editarmaterialbiologicoentradaexame",
    key: "editarmaterialbiologicoentradaexame",
    route: "/auxiliares/materialBiologicoEntradaExame/editar/:id/:uuid",
    component: EditarMaterialBiologicoEntradaExame,
  },
  {
    name: "editarmaterialtecido",
    key: "editarmaterialtecido",
    route: "/auxiliares/entradaMaterialTecido/editar/:id/:uuid",
    component: EditarEntradaMaterialTecido,
  },
];

export default editRoutes;
