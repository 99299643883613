import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import SuiBox from "components/SuiBox";
import axios from "axios";
import { useHistory, withRouter } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import Icon from "@mui/material/Icon";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BounceLoader } from "react-spinners";
import CalendarRoot from "./CalendarRoot";

function Agendamento({ ...rest }) {
    let eventGuid = 0;
    const todayStr = new Date().toISOString().replace(/T.*$/, "");

    const createEventId = () => {
        eventGuid += 1;
        return eventGuid;
    };

    const [eventos, setEventos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    

    const validClassNames = [
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ];

    const history = useHistory();

    const getEvents = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        try {
            const response = await axios.get(`${window.url}ws/agendamento`, config);
            const eventData = response.data.map((item) => {
                const formattedHour = item.hora.slice(0, 5);
                return {
                    id: item.id,
                    uuid: item.uuid,
                    start: `${item.data}`,
                    title: `${item.nome} às ${formattedHour}`,
                };
            });
            setEventos(eventData);
            setIsLoading(false);
        } catch (error) {
            console.error("Erro ao buscar eventos", error);
        }
    };

    useEffect(() => {
        getEvents();
    }, []);

    const events = rest.events
        ? rest.events.map((el) => ({
            ...el,
            className: validClassNames.find((item) => item === el.className)
                ? `event-${el.className}`
                : "event-info",
        }))
        : [];

    const handleEventClick = (clickInfo) => {
        const eventId = clickInfo.event.id;
        const eventUuid = clickInfo.event.extendedProps.uuid;
        history.push(`/auxiliares/agendamento/editar/${eventId}/${eventUuid}`);
    };

    const handleDateSelect = (selectInfo) => {
        window.location.href = `/auxiliares/agendamento/cadastro/?startDate=${selectInfo.startStr}`;
    };

    return isLoading ? (
        <DashboardLayout>
            <DashboardNavbar />
            <BounceLoader size={30} color="#ccc" />
        </DashboardLayout>
    ) : (
        <DashboardLayout>
            <DashboardNavbar />
            <Card sx={{ height: "100%" }}>
                <CalendarRoot p={2}>
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        eventClick={handleEventClick}
                        select={handleDateSelect}
                        headerToolbar={{
                            left: "prev,next today",
                            center: "title",
                            right: "dayGridMonth,timeGridWeek,timeGridDay",
                        }}
                        events={eventos}
                        height="100%"
                        initialView="dayGridMonth"
                        locale={ptBrLocale}
                        editable
                        selectable
                        selectMirror
                    />
                </CalendarRoot>
            </Card>
            <Footer />
        </DashboardLayout>
    );
}

export default withRouter(Agendamento);

