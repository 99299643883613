import React, { Component, Fragment, useEffect, useState } from "react";
import Select, { components } from "react-select";
import axios from "axios";
import { withRouter } from "react-router-dom";
import "../../globals";
import SuiSelect from "../SuiSelect";

function ContatoPreferencialSelect(props) {
  const [selectedOption, setSelectedOption] = useState();
  const [statusOptions, setStatusOptions] = useState([
    {
      value: 0,
      label: "Não",
    },
    {
      value: 1,
      label: "Sim",
    },
    {
      value: 3,
      label: "Não informado",
    },
  ]);
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
  });
  // eslint-disable-next-line no-shadow
  const NoOptionsMessage = (props) => (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">Nenhum registro para a busca</span>
    </components.NoOptionsMessage>
  );

  const { value, onChangeValue } = props;

  return (
    <div>
      <SuiSelect
        placeholder="Selecione"
        components={{ NoOptionsMessage }}
        value={value}
        onChange={onChangeValue}
        options={statusOptions}
        className="form-control"
      />
    </div>
  );
}

export default withRouter(ContatoPreferencialSelect);
