import { useEffect, useState } from "react";
import { toast } from "react-toastify";


// @mui material components
import Card from "@mui/material/Card";
import swal from "@sweetalert/with-react";


// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import axios from "axios";
import DataTable from "examples/Tables/DataTable";

import { useHistory, withRouter, useParams } from "react-router-dom";

// Soft UI Dashboard PRO React example components

import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BounceLoader } from "react-spinners";
import {
  BsFillTagsFill,
  BsGearFill,
  GiMedicalPackAlt,
  BiClipboard,
  FiTool,
  MdLocalHospital,
  GiStethoscope,
  GiDrop,
  GiDna2,
  IoCheckmarkDoneSharp,

} from "react-icons/all";
import SuiInput from "components/SuiInput";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SuiButton from "../../../../components/SuiButton";
import { dateEnBr, statusBase } from "../../../../utils";
import BoxStatus from "./components/BoxStatus";
import Paginacao from "../../../../components/paginacao";


function EntradaExame(props) {

  const [entradaExame, setEntradaExame] = useState([]);
  const { id } = useParams();
  const [identificador, setIdentificador] = useState(id);
  const [isLoading, setIsLoading] = useState(true);
  const [links, setLinks] = useState([]);
  const [page, setPage] = useState("1");
  const [atualizar, setAtualizar] = useState(false);
  const [searchTerm, setSearchTerm] = useState(id !== undefined ? id : "");
  const [pesquisa, setPesquisa] = useState([]);
  const [atualizaLista, setAtualizaLista] = useState(1);
  const history = useHistory();


  const handleSearch = (searchPage = 1) => {
    axios
      .get(`${window.url}ws/entradaExame/buscar/entradaExame`, {
        params: {
          buscar: searchTerm,
          page: searchPage,
          status: 1,
        },
      })
      .then((response) => {
        if (searchTerm !== "") {
          if (response.data.data.length === 0) {
            toast.info("Nenhum item encontrado!");
          } else {
            setPesquisa(response);
            setLinks(response.data.links);
            setPage(searchPage);
            if (response.data.current_page === 1) {
              setPage('1');
            }

          }
        } else {
          setPesquisa([]);
          setPage('1');
          setAtualizaLista(atualizaLista + 1);


        }
      })
      .catch(() => {
        toast.error("Ops! Houve um erro na pesquisa!");
      });
  };


  const handlePageChange = (newPage) => {
    if (searchTerm !== "") {
      handleSearch(newPage);
    } else {
      setPage(newPage);
    }
  };

  useEffect(async () => {
    if (identificador !== undefined) {
      setSearchTerm(identificador)
      handleSearch();
    }
  }, [identificador]);


  const saveEntradaExames = async (idExame) => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .patch(
        `${window.url}ws/entradaExame/${idExame}`,
        {
          status: 2,
        },
        config
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success("Exame enviado com sucesso!");
          setAtualizar(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  const saveVerificar = (idExame) => {

    const MySwal = withReactContent(Swal);
    const self = this;

    MySwal.fire({
      title: "Enviar para macroscopia",
      text: `Deseja enviar à macroscopia?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, enviar!",
      cancelButtonText: "Não, vou conferir!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        saveEntradaExames(idExame);
      }
    });


  };


  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    const status = props.match.params?.status ? props.match.params?.status : 9;

    setIsLoading(true);

    await axios
      .get(`${window.url}ws/entradaExame/1/list?page=${page}`, config)
      .then((res) => {
        setEntradaExame([]);
        const dados = pesquisa.data && pesquisa.data.data.length > 0 ? pesquisa.data.data : res.data.data;
        // eslint-disable-next-line array-callback-return
        dados.map((item) => {
          // eslint-disable-next-line no-shadow
          setEntradaExame((entradaExame) => [
            ...entradaExame,
            /* VARIÁVEIS DO BANCO DE DADOS */
            {
              id: item.id,
              identificador: item.identificador,
              data_entrada: item.data_entrada,
              razao_social: item.razao_social,
              nome_convenio: item.nome_convenio,
              nome: item.nome,
              data_nascimento: item.data_nascimento,
              previsao_entrega: item.previsao_entrega,
              data_laudo: item.data_laudo,
              data_liberado: item.data_liberado,
              prioridade: item.prioridade,
              deve_guia: item.deve_guia,
              status_procedimento: item.status_procedimento,
              carteirinha: item.carteirinha,
              /* FUNÇÃO DE EDIÇÃO */
              functions: (
                <SuiBox ml={-1.325}>
                  <Tooltip title="Editar" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={() => {
                        history.push(`/auxiliares/entradaExame/editar/${item.id}/${item.uuid}`);
                      }}
                    >
                      <Icon>edit</Icon>
                    </SuiButton>
                  </Tooltip>
                  <Tooltip title="Procedimento" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor={item.status_procedimento !== 0 ? "info" : "error"}
                      onClick={() => {
                        history.push(
                          `/auxiliares/entradaExame/procedimento/${item.id}/${item.uuid}`
                        );
                      }}
                      style={{ marginLeft: '3px' }}
                    >
                      <GiStethoscope style={item.status_procedimento !== 0 ? {} : { color: 'white' }} />
                    </SuiButton>
                  </Tooltip>
                  <Tooltip title="Enviar para macroscopia" placement="top">
                    <SuiButton
                      variant="gradient"
                      // buttonColor={item.status_procedimento !== 0 ? "success" : "warning"}
                      buttonColor="success"
                      onClick={() => {
                        saveVerificar(item.id);
                      }}
                      style={{ marginLeft: '3px' }}
                    >
                      <IoCheckmarkDoneSharp style={item.status_procedimento !== 0 ? {} : { color: 'white' }} />
                    </SuiButton>
                  </Tooltip>
                </SuiBox>
              ),
            },
          ]);
        });
        setIsLoading(false);
        if (pesquisa.length === 0 && identificador === undefined) {
          setLinks(res.data.links);
        }
      })
      .catch(() => { });
  };



  const table = {
    /* EDITAR CAMPOS */
    columns: [
      // { Header: "#", accessor: "id", width: "2%" },
      { Header: "Exame Nº", accessor: "identificador", width: "10%" },
      { Header: "Paciente", accessor: "nome", width: "10%" },
      {
        Header: "Carteirinha",
        accessor: "carteirinha",
        Cell: ({ value }) => value || "Não informado",
        width: "10%",
      },
      {
        Header: "Nascimento",
        accessor: "data_nascimento",
        Cell: ({ value }) => value ? dateEnBr(value) : "Não informado",
        width: "15%",
      },
      
      { Header: "Convênio", accessor: "nome_convenio", width: "10%" },
      {
        Header: "Entrada",
        accessor: "data_entrada",
        Cell: ({ value }) => value ? dateEnBr(value) : "Não informado",
        width: "10%",
      },
      {
        Header: "Previsão",
        accessor: "previsao_entrega",
        Cell: ({ value }) => value ? dateEnBr(value) : "Não informado",
        width: "10%",
      },
      
      { Header: "Opções", accessor: "functions", width: "1%" },
    ],

    rows: entradaExame,
  };

  useEffect(() => {
    get();
  }, [page, atualizar, atualizaLista]);



  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <SuiBox p={3} lineHeight={1}>
          <SuiTypography variant="h5" fontWeight="medium">
            Entrada exame
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            Lista de entradas de materiais biológicos
          </SuiTypography>
        </SuiBox>
        <SuiBox p={3.5} mb={1} width="20rem" ml="auto">
          {entradaExame.length > 0 && (
            <SuiInput
              placeholder="Busca & Enter"
              value={searchTerm}
              onChange={(event) => {
                setSearchTerm(event.target.value);
                if (event.target.value === "") {
                  setPesquisa([]);
                  setPage('1');
                  setAtualizaLista(atualizaLista + 1);
                }
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSearch();
                }
              }}
            />
          )}
        </SuiBox>
        {/* eslint-disable-next-line no-nested-ternary */}
        {entradaExame.length > 0 ? (
          <>
            <DataTable table={table} />
            <Paginacao links={links} page={page} setPage={handlePageChange} />
          </>
        ) : (
          <SuiBox p={3} lineHeight={1}>
            <small>Nenhum registro encontrado.</small>
          </SuiBox>
        )}
        <BoxStatus />
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(EntradaExame);
