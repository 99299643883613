import React, { useEffect, useState } from "react";
import {toast} from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import swal from "@sweetalert/with-react";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiEditor from "components/SuiEditor";
import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { BounceLoader } from "react-spinners";
import SaasSelect from "../../../../components/saas-select";
import TabelaSelect from "../../../../components/tabela-select";
import StatusSelect from "../../../../components/status-select";
import ProcedimentoSelect from "../../../../components/procedimento-select";

function EditarTemplateLaudos(props) {
  const [idSaas, setIdSaas] = useState();
  const [idProcedimento, setIdProcedimento] = useState();
  const [procedimento, setProcedimento] = useState();
  const [conteudo, setConteudo] = useState();
  // const [descritivo, setDescritivo] = useState();
  const [template, setTemplate] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const save = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .put(
        `${window.url}ws/templateLaudos/${props.match.params.id}`,
        {
          idSaas: sessionStorage.getItem("idSaas"),
          id_saas: idSaas,
          id_procedimento: idProcedimento,
          template,
          conteudo,
          // descritivo,
        },
        config
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          swal("Agilie", "Procedimento atualizado com sucesso.", "success");
          props.history.push("/auxiliares/template");
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/templateLaudos/${props.match.params.id}`, config)
      .then((res) => {
        const templateObj = res.data.template;
        setIdSaas(templateObj.id_saas);
        setIdProcedimento(templateObj.id_procedimento);
        setProcedimento(templateObj.nome_procedimento);
        setTemplate(templateObj.template);
        setConteudo(templateObj.conteudo);
        // setDescritivo(templateObj.descritivo);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  useEffect(() => {
    get();
  }, []);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
         <Grid container spacing={3}>
          <Grid item xs={12} lg={11}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Editar template de procedimento
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  Os dados alterados não geram efeito sobre entrada de exames anteriores
                </SuiTypography>
                <Divider />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Procedimento
                      </SuiTypography>
                    </SuiBox>
                    <ProcedimentoSelect
                      value={{ value: idProcedimento, label: procedimento }}
                      onChangeValue={(e) => {
                        setIdProcedimento(e.value);
                        setProcedimento(e.label);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Template
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={template || ""}
                      onChange={(e) => {
                        setTemplate(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Conteúdo
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="regular"
                      textColor="text"
                    >
                      Descreva o conteúdo
                    </SuiTypography>
                  </SuiBox>
                  <SuiEditor value={conteudo || ""} onChange={setConteudo} />
                </SuiBox>
                {/* <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Descritivo
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="regular"
                      textColor="text"
                    >
                      Você pode escrever uma observação aqui
                    </SuiTypography>
                  </SuiBox>
                  <SuiEditor value={descritivo || ""} onChange={setDescritivo} />
                </SuiBox> */}
                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  <SuiBox mr={1}>
                    <SuiButton
                      buttonColor="light"
                      onClick={() => {
                        history.push(`/auxiliares/template`);
                      }}
                    >
                      cancelar
                    </SuiButton>
                  </SuiBox>
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    onClick={() => {
                      save(); // ao clicar no botão irá chamar a função POST no banco de dados
                    }}
                  >
                    Salvar
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
               {/* <Grid item xs={12} lg={3}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Dicas
                </SuiTypography>
                <Divider />
              </SuiBox>
            </Card>
          </Grid> */}
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(EditarTemplateLaudos);
