import React, {useState} from "react";
import {toast} from "react-toastify";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {withRouter} from "react-router-dom";
import swal from "@sweetalert/with-react";
import SuiDatePicker from "../../../../components/SuiDatePicker";
import ContabancariaSelect from "../../../../components/contabancaria-select";
// import FilialSelect from "../../../../components/filial-select";
import FornecedorSelect from "../../../../components/fornecedor-select";
import StatusContasSelect from "../../../../components/status-contas-select";

function CadastroSaidaCaixa(props) {
    const [idFornecedor, setIdFornecedor] = useState();
    const [idContaBancaria, setIdContaBancaria] = useState();
    // const [idFilial, setIdFilial] = useState();
    const [dataVencimento, setDataVencimento] = useState();
    const [dataPagamento, setDataPagamento] = useState();
    const [valor, setValor] = useState();
    const [desconto, setDesconto] = useState();
    const [juros, setJuros] = useState();
    const [valorPraticado, setValorPraticado] = useState();
    const [status, setStatus] = useState();
    // const [principal, setPrincipal] = useState();
    // const handleSetPrincipal = () => setPrincipal(!principal);

    const save = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .post(
                `${window.url}ws/saidaCaixa`,
                {
                    /* MANTER O IDSAAS  */
                    idSaas: sessionStorage.getItem("idSaas"),
                    id_fornecedor: idFornecedor,
                    id_conta_bancaria: idContaBancaria,
                    // id_filial: idFilial,
                    data_vencimento: dataVencimento,
                    data_pagamento: dataPagamento,
                    valor,
                    desconto,
                    juros,
                    valor_praticado: valorPraticado,
                    status: status.value,
                },
                config
            )
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    swal("Agilie", "Saída caixa cadastrado com sucesso.", "success");
                    props.history.push("/auxiliares/saidaCaixa");
                }
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SuiBox mt={3} mb={4}>
                       <Grid container spacing={3}>
                    <Grid item xs={12} lg={11}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Nova Saída caixa
                                </SuiTypography>
                                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                                    Cadastro Saída caixa
                                </SuiTypography>
                                <Divider/>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Fornecedor
                                            </SuiTypography>
                                        </SuiBox>
                                        <FornecedorSelect
                                            onChangeValue={(e) => {
                                                setIdFornecedor(e.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Conta bancária
                                            </SuiTypography>
                                        </SuiBox>
                                        <ContabancariaSelect
                                            onChangeValue={(e) => {
                                                setIdContaBancaria(e.value);
                                            }}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Filial
                      </SuiTypography>
                    </SuiBox>
                    <FilialSelect 
                     onChange={(e) => {
                      setIdFilial(e.target.value);
                    }}
                    
                    />
                  </Grid> */}
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Data de vencimento
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            onChangeValue={(evt) => {
                                                setDataVencimento(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Data de pagamento
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            onChangeValue={(evt) => {
                                                setDataPagamento(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Valor
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setValor(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Desconto
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setDesconto(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Juros
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setJuros(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Valor praticado
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setValorPraticado(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Status
                                            </SuiTypography>
                                        </SuiBox>
                                        <StatusContasSelect
                                            value={status}
                                            onChangeValue={(e) => {
                                                if (e.value === 1) {
                                                    setStatus({value: e.value, label: "Em aberto"});
                                                } else if (e.value === 2) {
                                                    setStatus({value: e.value, label: "Paga"});
                                                } else if (e.value === 3) {
                                                    setStatus({value: e.value, label: "Em atraso"});
                                                } else if (e.value === 4) {
                                                    setStatus({value: e.value, label: "Cancelada"});
                                                } else {
                                                    setStatus({value: e.value, label: "Ativo"});
                                                }
                                            }}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <Checkbox checked={principal} onChange={handleSetPrincipal} />
                      <SuiTypography
                        variant="button"
                        fontWeight="bold"
                        onClick={handleSetPrincipal}
                        customClass="cursor-pointer user-select-none"
                      >
                        &nbsp;&nbsp; Principal &nbsp;
                      </SuiTypography>
                    </SuiBox>
                  </Grid> */}
                                    {/* <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <Checkbox checked={receberEmail} onChange={handleSetReceberEmail} />
                      <SuiTypography
                        variant="button"
                        fontWeight="bold"
                        onClick={handleSetReceberEmail}
                        customClass="cursor-pointer user-select-none"
                      >
                        &nbsp;&nbsp; ReceberEmail &nbsp;
                      </SuiTypography>
                    </SuiBox>
                  </Grid> */}
                                    {/* <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Origem Exame
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setVariavelLocal13(e.target.value);
                      }}
                    />
                  </Grid> */}
                                    {/* <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Nome da Entrada caixa
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                   
                      onChange={(e) => {
                        setIdFilial(e.target.value);
                      }}
                    />
                  </Grid> */}
                                    {/* <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Nome da Entrada caixa
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      
                      onChange={(e) => {
                        setDataVencimento(e.target.value);
                      }}
                    />
                  </Grid> */}
                                    {/* CHECKBOX */}
                                    {/* <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <Checkbox checked={receberEmail} onChange={handleSetReceberEmail} />
                      <SuiTypography
                        variant="button"
                        fontWeight="bold"
                        onClick={handleSetReceberEmail}
                        customClass="cursor-pointer user-select-none"
                      >
                        &nbsp;&nbsp; ReceberEmail &nbsp;
                      </SuiTypography>
                    </SuiBox>
                  </Grid> */}
                                    {/* SELECT */}
                                    {/* <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Select
                      </SuiTypography>
                    </SuiBox>
                    <SuiSelect
                      defaultValue={[{ value: 0, label: "Selecione uma opção..." }]}
                      onChange={(e) => {
                        setDataPagamento(e.value);
                      }}
                      options={[
                        { value: 1, label: "Opção 1" },
                        { value: 2, label: "Opção 1" },
                      ]}
                    />
                  </Grid> */}
                                </Grid>
                                {/* <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Observação
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="regular"
                      textColor="text"
                    >
                      Você pode escrever uma observação aqui
                    </SuiTypography>
                  </SuiBox>
                  <SuiEditor value={variavelLocal11 || ""} onChange={setVariavelLocal11} />
                </SuiBox> */}
                                {/* TEXTO */}
                                {/*  <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Observação
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="regular"
                      textColor="text"
                    >
                      Você pode escrever uma observação aqui
                    </SuiTypography>
                  </SuiBox>
                  <SuiEditor value={valor || ""} onChange={setValor} />
                </SuiBox> */}
                                {/* ANEXAR PDF OU WORD */}
                                {/*     <SuiBox>
                  <SuiBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                  >
                    <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Anexar tabela em PDF ou WORD
                      </SuiTypography>
                    </SuiBox>
                    <SuiDropzone options={{ addRemoveLinks: true }} />
                  </SuiBox>
                </SuiBox> */}
                                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                                    <SuiBox mr={1}>
                                        <SuiButton
                                            buttonColor="light"
                                            onClick={() => {
                                                props.history.goBack();
                                            }}
                                        >
                                            cancelar
                                        </SuiButton>
                                    </SuiBox>
                                    <SuiButton
                                        variant="gradient"
                                        buttonColor="info"
                                        onClick={() => {
                                            save(); // ao clicar no botão irá chamar a função POST no banco de dados
                                        }}
                                    >
                                        Salvar
                                    </SuiButton>
                                </SuiBox>
                            </SuiBox>
                        </Card>
                    </Grid>
                    {/* <Grid item xs={12} lg={3}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Dicas
                                </SuiTypography>
                                <Divider/>
                            </SuiBox>
                        </Card>
                    </Grid> */}
                </Grid>
            </SuiBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default withRouter(CadastroSaidaCaixa);
