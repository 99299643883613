import React, { useEffect, useState } from "react";
import {toast} from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import swal from "@sweetalert/with-react";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiEditor from "components/SuiEditor";
import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Redirect, withRouter } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { BounceLoader } from "react-spinners";
import CotacaoSelect from "../../../../components/cotacao-select";
import SuiDatePicker from "../../../../components/SuiDatePicker";
import FornecedorSelect from "../../../../components/fornecedor-select";
import ProdutoSelect from "../../../../components/produto-select";
import { dateEnBr } from "../../../../utils";

function EditarCompras(props) {
  const [idFornecedor, setIdFornecedor] = useState();
  const [fornecedor, setFornecedor] = useState();
  const [idProduto, setIdProduto] = useState();
  const [produto, setProduto] = useState();
  const [idCotacao, setIdCotacao] = useState();
  const [cotacao, setCotacao] = useState();
  const [quantidade, setQuantidade] = useState();
  const [dataEntrega, setDataEntrega] = useState(new Date());
  const [valorUnitario, setValorUnitario] = useState();
  const [numeroPedido, setNumeroPedido] = useState();
  const [valorTotal, setValorTotal] = useState();
  const [valorNota, setValorNota] = useState();
  const [chaveNfe, setChaveNfe] = useState();
  const [nNotaFiscal, setNNotaFiscal] = useState();
  const [dataEmissao, setDataEmissao] = useState(new Date());
  const [formaPagamento, setFormaPagamento] = useState();
  const [dataVencimento, setDataVencimento] = useState(new Date());
  const [parcelas, setParcelas] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const save = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .put(
        `${window.url}ws/compras/${props.match.params.id}`,
        {
          idSaas: sessionStorage.getItem("idSaas"),
          id_fornecedor: idFornecedor,
          id_produto: idProduto,
          id_cotacao: idCotacao,
          quantidade,
          data_entrega: dataEntrega,
          valor_unitario: valorUnitario,
          numero_pedido: numeroPedido,
          valor_total: valorTotal,
          valor_nota: valorNota,
          chave_nfe: chaveNfe,
          n_nota_fiscal: nNotaFiscal,
          data_emissao: dataEmissao,
          forma_pagamento: formaPagamento,
          data_vencimento: dataVencimento,
          parcelas,
        },
        config
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          swal("Agilie", "Compras atualizado com sucesso.", "success");
          props.history.push("/auxiliares/compras");
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/compras/${props.match.params.id}`, config)
      .then((res) => {
        const comprasObj = res.data.compras;
        setFornecedor(comprasObj.id_fornecedor);
        setFornecedor(comprasObj.razao_social);
        setIdProduto(comprasObj.id_produto);
        setProduto(comprasObj.nome_produto);
        setIdCotacao(comprasObj.id_cotacao);
        setCotacao(comprasObj.descricao);
        setQuantidade(comprasObj.quantidade);
        setDataEntrega(dateEnBr(comprasObj.data_entrega));
        setValorUnitario(comprasObj.valor_unitario);
        setNumeroPedido(comprasObj.numero_pedido);
        setValorTotal(comprasObj.valor_total);
        setValorNota(comprasObj.valor_nota);
        setChaveNfe(comprasObj.chave_nfe);
        setNNotaFiscal(comprasObj.n_nota_fiscal);
        setDataEmissao(dateEnBr(comprasObj.data_emissao));
        setFormaPagamento(comprasObj.forma_pagamento);
        setDataVencimento(dateEnBr(comprasObj.data_vencimento));
        setParcelas(comprasObj.parcelas);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  useEffect(() => {
    get();
  }, []);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
         <Grid container spacing={3}>
          <Grid item xs={12} lg={11}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Editar compra
                </SuiTypography>
                <Divider />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Cotação
                      </SuiTypography>
                    </SuiBox>
                    <CotacaoSelect
                      value={{ value: idCotacao, label: cotacao }}
                      onChangeValue={(e) => {
                        setIdCotacao(e.value);
                        setCotacao(e.label);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Número do pedido
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={numeroPedido || ""}
                      onChange={(e) => {
                        setNumeroPedido(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Data de entrega
                      </SuiTypography>
                    </SuiBox>
                    <SuiDatePicker
                      valueDefault={dataEntrega}
                      onChangeValue={(evt) => {
                        setDataEntrega(evt.toLocaleString("pt-BR"));
                      }}
                      input={{
                        placeholder: "Selecione uma data",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Chave NFE
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={chaveNfe || ""}
                      onChange={(e) => {
                        setChaveNfe(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Nº nota fiscal
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={nNotaFiscal || ""}
                      onChange={(e) => {
                        setNNotaFiscal(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Data de emissão
                      </SuiTypography>
                    </SuiBox>
                    <SuiDatePicker
                      valueDefault={dataEmissao}
                      onChangeValue={(evt) => {
                        setDataEmissao(evt.toLocaleString("pt-BR"));
                      }}
                      input={{
                        placeholder: "Selecione uma data",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Fornecedor
                      </SuiTypography>
                    </SuiBox>
                    <FornecedorSelect
                      value={{ value: idFornecedor, label: fornecedor }}
                      onChangeValue={(e) => {
                        setIdFornecedor(e.value);
                        setFornecedor(e.label);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Produto
                      </SuiTypography>
                    </SuiBox>
                    <ProdutoSelect
                      value={{ value: idProduto, label: produto }}
                      onChangeValue={(e) => {
                        setIdProduto(e.value);
                        setProduto(e.label);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Quantidade
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={quantidade || ""}
                      onChange={(e) => {
                        setQuantidade(e.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Valor unitário
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={valorUnitario || ""}
                      onChange={(e) => {
                        setValorUnitario(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Valor total
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={valorTotal || ""}
                      onChange={(e) => {
                        setValorTotal(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Valor total nota
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={valorNota || ""}
                      onChange={(e) => {
                        setValorNota(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Forma de pagamento
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={formaPagamento || ""}
                      onChange={(e) => {
                        setFormaPagamento(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Data de vencimento
                      </SuiTypography>
                    </SuiBox>
                    <SuiDatePicker
                      valueDefault={dataVencimento}
                      onChangeValue={(evt) => {
                        setDataVencimento(evt.toLocaleString("pt-BR"));
                      }}
                      input={{
                        placeholder: "Selecione uma data",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Parcelas
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={parcelas || ""}
                      onChange={(e) => {
                        setParcelas(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  <SuiBox mr={1}>
                    <SuiButton
                      buttonColor="light"
                      onClick={() => {
                        props.history.goBack();
                      }}
                    >
                      cancelar
                    </SuiButton>
                  </SuiBox>
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    onClick={() => {
                      save(); // ao clicar no botão irá chamar a função POST no banco de dados
                    }}
                  >
                    Salvar
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
               {/* <Grid item xs={12} lg={3}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Dicas
                </SuiTypography>
                <Divider />
              </SuiBox>
            </Card>
          </Grid> */}
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(EditarCompras);
