import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import axios from "axios";
import DataTable from "examples/Tables/DataTable";

import { useHistory, withRouter } from "react-router-dom";

// Soft UI Dashboard PRO React example components
import Icon from "@mui/material/Icon";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BounceLoader, ClipLoader } from "react-spinners";
import SuiButton from "../../../../components/SuiButton";

function FaturamentoRelatorios() {
  const [laudo, setLaudo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <SuiBox p={3} lineHeight={1}>
          <SuiTypography variant="h5" fontWeight="medium">
            Relatórios
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            Relatórios analíticos e sintéticos
          </SuiTypography>
        </SuiBox>
        <SuiBox p={3} lineHeight={1}>
          <small>Dados insuficientes para geração de relatórios.</small>
        </SuiBox>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(FaturamentoRelatorios);
