import { useEffect, useState } from "react";
import { toast } from "react-toastify";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import axios from "axios";
import DataTable from "examples/Tables/DataTable";

import { useHistory, withRouter } from "react-router-dom";

// Soft UI Dashboard PRO React example components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BounceLoader, ClipLoader } from "react-spinners";
import SuiInput from "components/SuiInput";
import SuiButton from "../../../../components/SuiButton";
import { dateEnBr } from "../../../../utils";
import Paginacao from "../../../../components/paginacao";

function Convenio() {
  const [convenio, setConvenio] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [links, setLinks] = useState([]);
  const [page, setPage] = useState("1");
  const [searchTerm, setSearchTerm] = useState("");
  const [pesquisa, setPesquisa] = useState([]);
  const [atualizaLista, setAtualizaLista] = useState(1);

  const history = useHistory();


  const handleSearch = (searchPage = 1) => {

    axios
      .get(`${window.url}ws/convenio/buscar/convenio`, {
        params: {
          buscar: searchTerm,
          page: searchPage,
        },
      })
      .then((response) => {
        if (searchTerm !== "") {
          if (response.data.data.length === 0) {
            toast.info("Nenhum item encontrado!");
          } else {
            setPesquisa(response);
            setLinks(response.data.links);
            setPage(searchPage);
            if (response.data.current_page === 1) {
              setPage('1');
            }
          }
        } else {
          setPesquisa([]);
          setPage('1');
          setAtualizaLista(atualizaLista + 1);

        }
      })
      .catch(() => {
        toast.error("Ops! Houve um erro na pesquisa!");
      });
  };

  const handlePageChange = (newPage) => {
    if (searchTerm !== "") {
      handleSearch(newPage);
    } else {
      setPage(newPage);
    }
  };

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    setIsLoading(true);

    await axios
      .get(`${window.url}ws/convenio?page=${page}`, config)
      .then((res) => {
        setConvenio([]);
        const dados = pesquisa.data && pesquisa.data.data.length > 0 ? pesquisa.data.data : res.data.data;
        // eslint-disable-next-line array-callback-return
        dados.map((item) => {
          // eslint-disable-next-line no-shadow
          setConvenio((convenio) => [
            ...convenio,
            /* VARIÁVEIS DO BANCO DE DADOS */
            {
              id: item.id,
              nome_convenio: item.nome_convenio,
              cnpj: item.cnpj,
              data_contrato_inicio: item.data_contrato_inicio,
              data_contrato_fim: item.data_contrato_fim,
              fone_1: item.fone_1,
              fone_2: item.fone_2,
              senha: item.senha,
              endereco: item.endereco,
              uf: item.uf,
              cidade: item.cidade,
              cep: item.cep,
              complemento: item.complemento,
              observacoes: item.observacoes,
              recebe_email: item.recebe_email,
              email: item.email,
              /* FUNÇÃO DE EDIÇÃO */
              functions: (
                <SuiBox ml={-1.325}>
                  {/* <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      history.push(`/auxiliares/convenio/editar/${item.id}/${item.uuid}`);
                    }}
                  >
                    <Icon>edit</Icon>
                  </SuiButton> */}
                  <Tooltip title="Editar" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={() => {
                        history.push(`/auxiliares/convenio/editar/${item.id}/${item.uuid}`);
                      }}
                    >
                      <Icon>edit</Icon>
                    </SuiButton>
                  </Tooltip>
                </SuiBox>
              ),
            },
          ]);
        });
        setIsLoading(false);
        if (pesquisa.length === 0) {
          setLinks(res.data.links);
        }
      })
      .catch(() => { });
  };

  const table = {
    /* EDITAR CAMPOS */
    columns: [
      // { Header: "#", accessor: "id", width: "2%" },
      { Header: "Nome Convênio", accessor: "nome_convenio", width: "15%" },
      {
        Header: "Data fim do contrato",
        accessor: "data_contrato_fim",
        Cell: ({ row }) =>
          row.values.data_contrato_fim === null ? (
            "Não informado"
          ) : (
            <span dangerouslySetInnerHTML={{ __html: dateEnBr(row.values.data_contrato_fim) }} />
          ),
        width: "10%",
      },
      { Header: "Fone 1", accessor: "fone_1", width: "10%" },
      { Header: "Cidade", accessor: "cidade", width: "10%" },
      { Header: "UF", accessor: "uf", width: "5%" },
      { Header: "Opções", accessor: "functions", width: "1%" },
    ],

    rows: convenio,
  };

  useEffect(() => {
    get();
  }, [page, atualizaLista]);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <SuiBox p={3} display="flex" justifyContent="space-between">
          <SuiBox>
            <SuiTypography variant="h5" fontWeight="medium">
              Convênio
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" textColor="text">
              Lista de convênio
            </SuiTypography>
          </SuiBox>
          <SuiBox p={1}>
            <SuiButton
              variant="gradient"
              buttonColor="info"
              onClick={() => {
                history.push("/auxiliares/convenio/Cadastro");
              }}
            >
              Cadastrar
            </SuiButton>
          </SuiBox>
        </SuiBox>
        <SuiBox p={3.5} mb={1} width="20rem" ml="auto">
          {convenio.length > 0 && (
            <SuiInput
              placeholder="Busca & Enter"
              value={searchTerm}
              onChange={(event) => {
                setSearchTerm(event.target.value);
                if (event.target.value === "") {
                  setPesquisa([]);
                  setPage('1');
                  setAtualizaLista(atualizaLista + 1);
                }
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSearch();
                }
              }}
            />
          )}
        </SuiBox>
        {/* eslint-disable-next-line no-nested-ternary */}
        {convenio.length > 0 ? (
          <>
            <DataTable table={table} />
            <Paginacao links={links} page={page} setPage={handlePageChange} />
          </>
        ) : (
          <SuiBox p={3} lineHeight={1}>
            <small>Nenhum registro encontrado.</small>
          </SuiBox>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(Convenio);
