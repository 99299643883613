import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import swal from "@sweetalert/with-react";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiEditor from "components/SuiEditor";
import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { cpf as checkCpf } from "cpf-cnpj-validator";
import { Redirect, withRouter } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { BounceLoader } from "react-spinners";
import StatusSelect from "../../../../components/status-select";
import UfBrasilSelect from "../../../../components/ufBrasil-select";

function EditarProfissionalSaude(props) {
  const [nomeProfissional, setNomeProfissional] = useState();
  const [siglaConselho, setSiglaConselho] = useState();
  const [numeroConselho, setNumeroConselho] = useState();
  const [ufConselho, setUfConselho] = useState();
  const [especialidade, setEspecialidade] = useState();
  const [telefone, setTelefone] = useState();
  const [email, setEmail] = useState();
  const [cpf, setCpf] = useState();
  const [cbos, setCbos] = useState();
  const [telefone2, setTelefone2] = useState();
  const [senha, setSenha] = useState();
  const [cep, setCep] = useState();
  const [logradouro, setLogradouro] = useState();
  const [uf, setUf] = useState('PR');
  const [cidade, setCidade] = useState();
  const [status, setStatus] = useState();
  const [numero, setNumero] = useState();
  const [complemento, setComplemento] = useState();
  const [observacao, setObservacao] = useState();
  const [recebeEmail, setRecebeEmail] = useState(false);
  const [responsavelLaudo, setResponsavelLaudo] = useState(false);
  const handleSetRecebeEmail = () => setRecebeEmail(!recebeEmail);
  const handleSetResponsavelLaudo = () => setResponsavelLaudo(!responsavelLaudo);
  const [isLoading, setIsLoading] = useState(true);

  const save = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    if (cpf && cpf.trim() !== "" && !checkCpf.isValid(cpf)) {
      toast.error("CPF inválido");
      return;
    }

    await axios
      .put(
        `${window.url}ws/profissionalSaude/${props.match.params.id}`,
        {
          idSaas: sessionStorage.getItem("idSaas"),
          nome_profissional: nomeProfissional,
          sigla_conselho: siglaConselho,
          numero_conselho: numeroConselho,
          uf_conselho: ufConselho,
          especialidade,
          telefone,
          email,
          cpf,
          cbos,
          telefone_2: telefone2,
          senha,
          cep,
          logradouro,
          uf,
          cidade,
          status: status.value,
          numero,
          complemento,
          observacao,
          recebe_email: recebeEmail,
          responsavel_laudo: responsavelLaudo,
        },
        config
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          swal("Agilie", "Profissional de saúde atualizado com sucesso.", "success");
          props.history.push("/auxiliares/profissionalSaude");
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/profissionalSaude/${props.match.params.id}`, config)
      .then((res) => {
        const profissionalSaudeObj = res.data.profissionalSaude;
        setNomeProfissional(profissionalSaudeObj.nome_profissional);
        setSiglaConselho(profissionalSaudeObj.sigla_conselho);
        setNumeroConselho(profissionalSaudeObj.numero_conselho);
        setUfConselho(profissionalSaudeObj.uf_conselho);
        setEspecialidade(profissionalSaudeObj.especialidade);
        setTelefone(profissionalSaudeObj.telefone);
        setEmail(profissionalSaudeObj.email);
        setCpf(profissionalSaudeObj.cpf);
        setCbos(profissionalSaudeObj.cbos);
        setTelefone2(profissionalSaudeObj.telefone_2);
        setSenha(profissionalSaudeObj.senha);
        setCep(profissionalSaudeObj.cep);
        setLogradouro(profissionalSaudeObj.logradouro);
        setComplemento(profissionalSaudeObj.complemento);
        setNumero(profissionalSaudeObj.numero);
        setUf(profissionalSaudeObj.uf);
        setCidade(profissionalSaudeObj.cidade);
        setStatus(!!profissionalSaudeObj.status);
        setStatus({
          value: profissionalSaudeObj.status,
          label: profissionalSaudeObj.status === 1 ? "Ativo" : "Inativo",
        });
        setObservacao(profissionalSaudeObj.observacao);
        setRecebeEmail(!!profissionalSaudeObj.recebe_email);
        setResponsavelLaudo(!!profissionalSaudeObj.responsavel_laudo);

        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  const buscarCep = async (numeroCep) => {
    await axios
      .get(`https://viacep.com.br/ws/${numeroCep}/json/`)
      .then((res) => {
        console.log(res.data.erro);
        setLogradouro(res.data.logradouro);
        setCidade(res.data.localidade);
        setComplemento(res.data.complemento);
        setUf(res.data.uf);
        if (res.data.erro === true) {
          toast.error("CEP não encontrado!");
        } else {
          toast.success("CEP encontrado!");
        }
      })
      .catch(() => {
        swal("Agilie", "Ops! Verifique a quantidade de números! Precisa ser 8 números! ", "error");
      });
  };

  useEffect(() => {
    get();
  }, []);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
         <Grid container spacing={3}>
          <Grid item xs={12} lg={11}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Editar profissional de saúde
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  Os dados alterados não geram efeito sobre entrada de exames anteriores
                </SuiTypography>
                <Divider />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={10}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Nome do Profissional de saúde
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={nomeProfissional || ""}
                      onChange={(e) => {
                        setNomeProfissional(e.target.value.toUpperCase());
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SuiBox mb={1} ml={0.5} lineHeight={4} display="inline-block">
                      <Checkbox checked={responsavelLaudo} onChange={handleSetResponsavelLaudo} />
                      <SuiTypography
                        variant="button"
                        fontWeight="bold"
                        onClick={handleSetResponsavelLaudo}
                        customClass="cursor-pointer user-select-none"
                      >
                        &nbsp;&nbsp; Reponsável Laudo &nbsp;
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Sigla do conselho
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={siglaConselho || ""}
                      onChange={(e) => {
                        setSiglaConselho(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Número do conselho
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={numeroConselho || ""}
                      onChange={(e) => {
                        setNumeroConselho(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        UF do conselho
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={ufConselho || ""}
                      onChange={(e) => {
                        setUfConselho(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        CBOS
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={cbos || ""}
                      onChange={(e) => {
                        setCbos(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        CPF
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={cpf || ""}
                      onChange={(e) => {
                        setCpf(e.target.value);
                      }}
                      onBlur={(e) => {
                        e.preventDefault();
                        const cpf = e.target.value;
                        if (cpf.trim() !== '') {
                          if (!checkCpf.isValid(cpf)) {
                            toast.error('CPF inválido');
                          }
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Especialidade
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={especialidade || ""}
                      onChange={(e) => {
                        setEspecialidade(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Telefone 1
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={telefone || ""}
                      onChange={(e) => {
                        setTelefone(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Telefone 2
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={telefone2 || ""}
                      onChange={(e) => {
                        setTelefone2(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Email
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={email || ""}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        CEP
                      </SuiTypography>
                      <SuiTypography component="label" variant="caption" fontWeight="regular">
                        (Somente números)
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={cep || ""}
                      onChange={(e) => {
                        setCep(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SuiBox mb={0} ml={0.5} lineHeight={4.5} display="inline-block">
                      <SuiButton
                        variant="gradient"
                        buttonColor="success"
                        onClick={() => {
                          buscarCep(cep);
                        }}
                      >
                        buscar cep
                      </SuiButton>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SuiBox mb={1} ml={0.5} lineHeight={4} display="inline-block">
                      <Checkbox checked={recebeEmail} onChange={handleSetRecebeEmail} />
                      <SuiTypography
                        variant="button"
                        fontWeight="bold"
                        onClick={handleSetRecebeEmail}
                        customClass="cursor-pointer user-select-none"
                      >
                        &nbsp;&nbsp; Recebe email &nbsp;
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Logradouro
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={logradouro || ""}
                      onChange={(e) => {
                        setLogradouro(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Cidade
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={cidade || ""}
                      onChange={(e) => {
                        setCidade(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        UF
                      </SuiTypography>
                    </SuiBox>
                    <UfBrasilSelect
                      value={{ value: uf, label: uf || "Selecione" }}
                      onChangeValue={(e) => {
                        setUf(e.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Status
                      </SuiTypography>
                    </SuiBox>
                    <StatusSelect
                      value={status}
                      onChangeValue={(e) => {
                        if (e.value === 1) {
                          setStatus({ value: e.value, label: "Ativo" });
                        } else {
                          setStatus({ value: e.value, label: "Inativo" });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Número
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={numero || ""}
                      onChange={(e) => {
                        setNumero(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Complemento
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={complemento || ""}
                      onChange={(e) => {
                        setComplemento(e.target.value);
                      }}
                    />
                  </Grid>

                  {/* <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <Checkbox checked={receberEmail} onChange={handleSetReceberEmail} />
                      <SuiTypography
                        variant="button"
                        fontWeight="bold"
                        onClick={handleSetReceberEmail}
                        customClass="cursor-pointer user-select-none"
                      >
                        &nbsp;&nbsp; ReceberEmail &nbsp;
                      </SuiTypography>
                    </SuiBox>
                  </Grid> */}
                  {/* <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Origem Exame
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={uf || ""}
                      onChange={(e) => {
                        setUf(e.target.value);
                      }}
                    />
                  </Grid> */}
                  {/* <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Nome da Entrada caixa
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={numeroConselho || ""}
                      onChange={(e) => {
                        setNumeroConselho(e.target.value);
                      }}
                    />
                  </Grid> */}
                  {/* <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Nome da Entrada caixa
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={ufConselho || ""}
                      onChange={(e) => {
                        setUfConselho(e.target.value);
                      }}
                    />
                  </Grid> */}
                  {/* CHECKBOX */}
                  {/* <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <Checkbox checked={receberEmail} onChange={handleSetReceberEmail} />
                      <SuiTypography
                        variant="button"
                        fontWeight="bold"
                        onClick={handleSetReceberEmail}
                        customClass="cursor-pointer user-select-none"
                      >
                        &nbsp;&nbsp; ReceberEmail &nbsp;
                      </SuiTypography>
                    </SuiBox>
                  </Grid> */}
                  {/* SELECT */}
                  {/* <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Select
                      </SuiTypography>
                    </SuiBox>
                    <SuiSelect
                      defaultValue={[{ value: 0, label: "Selecione uma opção..." }]}
                      onChange={(e) => {
                        setEspecialidade(e.value);
                      }}
                      options={[
                        { value: 1, label: "Opção 1" },
                        { value: 2, label: "Opção 1" },
                      ]}
                    />
                  </Grid> */}
                </Grid>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Observação
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="regular"
                      textColor="text"
                    >
                      Você pode escrever uma observação aqui
                    </SuiTypography>
                  </SuiBox>
                  <SuiEditor value={observacao || ""} onChange={setObservacao} />
                </SuiBox>

                {/* TEXTO */}

                {/* ANEXAR PDF OU WORD */}
                {/*     <SuiBox>
                  <SuiBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                  >
                    <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Anexar tabela em PDF ou WORD
                      </SuiTypography>
                    </SuiBox>
                    <SuiDropzone options={{ addRemoveLinks: true }} />
                  </SuiBox>
                </SuiBox> */}
                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  <SuiBox mr={1}>
                    <SuiButton
                      buttonColor="light"
                      onClick={() => {
                        props.history.goBack();
                      }}
                    >
                      cancelar
                    </SuiButton>
                  </SuiBox>
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    onClick={() => {
                      save(); // ao clicar no botão irá chamar a função POST no banco de dados
                    }}
                  >
                    Salvar
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
               {/* <Grid item xs={12} lg={3}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Dicas
                </SuiTypography>
                <Divider />
              </SuiBox>
            </Card>
          </Grid> */}
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(EditarProfissionalSaude);
