import React, {useState} from "react";
import {toast} from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiEditor from "components/SuiEditor";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {withRouter} from "react-router-dom";
import swal from "@sweetalert/with-react";
import PacienteSelect from "../../../../components/paciente-select";
import AgendaSelect from "../../../../components/agenda-select";
import ConvenioSelect from "../../../../components/convenio-select";
import ProfissionalSelect from "../../../../components/profissional-select";
import InstituicaoSelect from "../../../../components/instituicao-select";
import SuiDatePicker from "../../../../components/SuiDatePicker";

function CadastroAgendamento(props) {
    const [titulo, setTitulo] = useState();
    const [idPaciente, setIdPaciente] = useState();
    const [idAgenda, setIdAgenda] = useState();
    const [idConvenio, setIdConvenio] = useState();
    const [idMedicoSolicitante, setIdMedicoSolicitante] = useState();
    const [idInstituicao, setIdInstituicao] = useState();
    const [data, setData] = useState();
    const [hora, setHora] = useState();
    const [observacoes, setObservacoes] = useState();
    const [mensagens, setMensagens] = useState([]);

    const save = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .post(
                `${window.url}ws/agendamento`,
                {
                    /* MANTER O IDSAAS  */
                    idSaas: sessionStorage.getItem("idSaas"),
                    titulo,
                    data,
                    hora,
                    id_paciente: idPaciente,
                    id_agenda: idAgenda,
                    id_convenio: idConvenio,
                    id_medico_solicitante: idMedicoSolicitante,
                    id_instituicao: idInstituicao,
                    observacoes,
                },
                config
            )
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    swal("Agilie", "agendamento cadastrado com sucesso.", "success");
                    props.history.push("/auxiliares/agendamento");
                }
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SuiBox mt={3} mb={4}>
                       <Grid container spacing={3}>
                    <Grid item xs={12} lg={11}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Novo agendamento
                                </SuiTypography>
                                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                                    Cadastro de agendamento
                                </SuiTypography>
                                <Divider/>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Título
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setTitulo(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Paciente
                                            </SuiTypography>
                                        </SuiBox>
                                        <PacienteSelect
                                            onChangeValue={(e) => {
                                                setIdPaciente(e.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Agenda
                                            </SuiTypography>
                                        </SuiBox>
                                        <AgendaSelect
                                            onChangeValue={(e) => {
                                                setIdAgenda(e.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Convênio
                                            </SuiTypography>
                                        </SuiBox>
                                        <ConvenioSelect
                                            onChangeValue={(e) => {
                                                setIdConvenio(e.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Médico solicitante
                                            </SuiTypography>
                                        </SuiBox>
                                        <ProfissionalSelect
                                            onChangeValue={(e) => {
                                                setIdMedicoSolicitante(e.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Instituição
                                            </SuiTypography>
                                        </SuiBox>
                                        <InstituicaoSelect
                                            onChangeValue={(e) => {
                                                setIdInstituicao(e.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Data do agendamento
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            onChangeValue={(evt) => {
                                                setData(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Hora do agendamento - Exem.: 14:35
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setHora(e.target.value);
                                            }}
                                            placeholder="Digite a hora e minutos nesse formato: HH:mm"
                                        />
                                    </Grid>

                                </Grid>
                                <SuiBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                >
                                    <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                                            Observação
                                        </SuiTypography>
                                    </SuiBox>
                                    <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                                        <SuiTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="regular"
                                            textColor="text"
                                        >
                                            Você pode escrever uma observação aqui
                                        </SuiTypography>
                                    </SuiBox>
                                    <SuiEditor value={observacoes || ""} onChange={setObservacoes}/>
                                </SuiBox>
                                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                                    <SuiBox mr={1}>
                                        <SuiButton
                                            buttonColor="light"
                                            onClick={() => {
                                                props.history.goBack();
                                            }}
                                        >
                                            cancelar
                                        </SuiButton>
                                    </SuiBox>
                                    <SuiButton
                                        variant="gradient"
                                        buttonColor="info"
                                        onClick={() => {
                                            save(); // ao clicar no botão irá chamar a função POST no banco de dados
                                        }}
                                    >
                                        Salvar
                                    </SuiButton>
                                </SuiBox>
                            </SuiBox>
                        </Card>
                    </Grid>
                    {/* <Grid item xs={12} lg={3}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Dicas
                                </SuiTypography>
                                <Divider/>
                                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                                    A tela de agendamento serve como um controle de realização de coleta e análises
                                    externas. Por exemplo, o especialista precisa fazer uma análise de material em
                                    algum dia e hora específicos, em alguma clínica específica.
                                </SuiTypography>
                            </SuiBox>
                        </Card>
                    </Grid> */}
                </Grid>
            </SuiBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default withRouter(CadastroAgendamento);
