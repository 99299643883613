import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
// react-router-dom components
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import curved9 from "assets/images/curved-images/curved9.jpg";
import FilialSelect from "../../../../components/filial-select";
import {login} from "../../../../store/slices/authThunk";

export default function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [idFilial, setIdFilial] = useState();
  const [filial, setFilial] = useState();
  const dispatch = useDispatch();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  function handleSignIn() {
    dispatch(login({email, password, idFilial}));
  }

  const defaultFilial = () => {
    setIdFilial(1);
    setFilial('Byori - 001');
  };


  useEffect(() => {
    defaultFilial();
  }, []);

  return (
      <BasicLayout
          title="Bem vindo novamente!"
          description="O Ágile oferece soluções que simplificam a gestão, facilitam o fluxo de trabalho e velocidade nos processos de sua clínica e laboratório."
          image={curved9}
      >
        <Card>
          <SuiBox p={3} textAlign="center">
            <SuiTypography variant="h6">Login {process.env.REACT_APP_VERSION}</SuiTypography>
          </SuiBox>
          <SuiBox p={5} pt={0}>
            <SuiBox component="form" role="form">
              <SuiBox mb={2}>
                <SuiInput
                    defaultValue={email}
                    type="email"
                    placeholder="E-mail"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    autoComplete="off"
                />
              </SuiBox>
              <SuiBox mb={2}>
                <SuiInput
                    defaultValue={password}
                    type="password"
                    placeholder="Senha"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    autoComplete="off"
                />
              </SuiBox>
              <SuiBox mb={2}>
                <SuiTypography component="label" variant="caption" fontWeight="bold">
                  Filial
                </SuiTypography>
                <FilialSelect
                    value={{value: idFilial, label: filial}}
                    onChangeValue={(e) => {
                      setIdFilial(e.value);
                      setFilial(e.label);
                    }}
                />
              </SuiBox>
              <SuiBox display="flex" alignItems="center">
                <Switch checked={rememberMe} onChange={handleSetRememberMe}/>
                <SuiTypography
                    variant="button"
                    fontWeight="regular"
                    onClick={handleSetRememberMe}
                    customClass="cursor-pointer user-select-none"
                >
                  &nbsp;&nbsp;Ficar conectado
                </SuiTypography>
              </SuiBox>

              <SuiBox mt={4} mb={1}>
                <SuiButton
                    onClick={() => {
                      handleSignIn();
                    }}
                    variant="gradient"
                    buttonColor="info"
                    fullWidth
                >
                  Entrar
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </Card>
      </BasicLayout>
  );
}
