import React, { useEffect, useState, useRef } from "react";
import { components } from "react-select";
import axios from "axios";
import { withRouter } from "react-router-dom";
import "../../globals";
import SuiSelect from "../SuiSelect";
import { dateEnBr } from "../../utils";


function PacienteSelect(props) {
  const [selectedOption, setSelectedOption] = useState();
  const [statusOptions, setStatusOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const currentPageRef = useRef(1);
  const hasMoreOptionsRef = useRef(true);

  function fetchData(page, search) {
    setIsLoading(true);

    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .get(`${window.url}ws/paciente/select/full?page=${page}&search=${search}`, config)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data.length === 0) {
            hasMoreOptionsRef.current = false;
          }
          setStatusOptions((prevState) => [
            ...prevState,
            ...res.data.data.map((item) => ({
              value: item.id,
              label: `${item.nome} - ${
                item.data_nascimento === null
                  ? "Não informado"
                  : dateEnBr(item.data_nascimento)
              }`,

            })),
          ]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.status);
      })
      .then(() => {});
  }

  useEffect(() => {
    currentPageRef.current = 1;
    hasMoreOptionsRef.current = true;
    setStatusOptions([]);
  }, [searchTerm]);

  useEffect(() => {
    fetchData(currentPageRef.current, searchTerm);
  }, [searchTerm]);

  const handleInputChange = (query) => {
    setSearchTerm(query);
  };

  const handleMenuScrollToBottom = () => {
    if (isLoading || !hasMoreOptionsRef.current) {
      return;
    }
    currentPageRef.current += 1;
    fetchData(currentPageRef.current, searchTerm);
  };

  const NoOptionsMessage = (props) => (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">Nenhum registro para a busca</span>
    </components.NoOptionsMessage>
  );

  const { value, onChangeValue } = props;

  return (
    <div>
      <SuiSelect
        placeholder="Selecione"
        components={{ NoOptionsMessage }}
        value={value}
        onChange={onChangeValue}
        options={statusOptions}
        onInputChange={handleInputChange}
        onMenuScrollToBottom={handleMenuScrollToBottom}
        isLoading={isLoading}
        className="form-control"
      />
    </div>
  );
}

export default withRouter(PacienteSelect);
