// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MenuGrandeLink from "../../../components/menu-grande";

function FuncoesAdministradorHome() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={3}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={5}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MenuGrandeLink titulo="Fila SMS" rota="/auxiliares/filaSms" icone="assignment" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MenuGrandeLink
                    titulo="Fila e-mail"
                    rota="/auxiliares/filaEmail"
                    icone="assignment"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MenuGrandeLink
                    titulo="Lembretes procedimentos"
                    rota="/auxiliares/lembreteProcedimento"
                    icone="assignment"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MenuGrandeLink
                    titulo="Rastreabilidade"
                    rota="/auxiliares/rastreabilidade"
                    icone="assignment"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FuncoesAdministradorHome;
