import React, { useEffect, useState } from "react";
import {toast} from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import swal from "@sweetalert/with-react";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiEditor from "components/SuiEditor";
import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Redirect, withRouter } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { BounceLoader } from "react-spinners";
import TabelaSelect from "../../../../components/tabela-select";
import EstadoBrasilSelect from "../../../../components/estadosBrasil-select";
import UfBrasilSelect from "../../../../components/ufBrasil-select";

function EditarEnderecoPaciente(props) {
  const [idPaciente, setIdPaciente] = useState();
  const [paciente, setPaciente] = useState();
  const [apelido, setApelido] = useState();
  const [logradouro, setLogradouro] = useState();
  const [numero, setNumero] = useState();
  const [complemento, setComplemento] = useState();
  const [bairro, setBairro] = useState();
  const [cidade, setCidade] = useState();
  const [estado, setEstado] = useState();
  const [uf, setUf] = useState();
  const [cep, setCep] = useState();
  const [principal, setPrincipal] = useState(false);
  const [observacoes, setObservacoes] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const handleSetPrincipal = () => setPrincipal(!principal);

  const save = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .put(
        `${window.url}ws/enderecoPaciente/${props.match.params.id}`,
        {
          idSaas: sessionStorage.getItem("idSaas"),
          id_paciente: idPaciente,
          apelido,
          logradouro,
          numero,
          complemento,
          bairro,
          cidade,
          estado,
          uf,
          cep,
          principal,
          observacoes,
        },
        config
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          swal("Agilie", "Endereço do paciente atualizado com sucesso.", "success");
          props.history.goBack();
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/enderecoPaciente/${props.match.params.id}`, config)
      .then((res) => {
        const enderecoPacienteObj = res.data.enderecoPaciente;
        setIdPaciente(enderecoPacienteObj.id_paciente);
        setPaciente(enderecoPacienteObj.nome);
        setApelido(enderecoPacienteObj.apelido);
        setLogradouro(enderecoPacienteObj.logradouro);
        setNumero(enderecoPacienteObj.numero);
        setComplemento(enderecoPacienteObj.complemento);
        setBairro(enderecoPacienteObj.bairro);
        setCidade(enderecoPacienteObj.cidade);
        setEstado(enderecoPacienteObj.estado);
        setUf(enderecoPacienteObj.uf);
        setCep(enderecoPacienteObj.cep);
        setPrincipal(!!enderecoPacienteObj.principal);
        setObservacoes(enderecoPacienteObj.observacoes);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  const buscarCep = async (numeroCep) => {
    await axios
      .get(`https://viacep.com.br/ws/${numeroCep}/json/`)
      .then((res) => {
        console.log(res.data.erro);
        setLogradouro(res.data.logradouro);
        setComplemento(res.data.complemento);
        setBairro(res.data.bairro);
        setCidade(res.data.localidade);
        setUf(res.data.uf);
        if (res.data.erro === true) {
          toast.error("CEP não encontrado!");
        } else {
          toast.success("CEP encontrado!");
        }
      })
      .catch(() => {
        swal("Agilie", "Ops! Verifique a quantidade de números! Precisa ser 8 números! ", "error");
      });
  };

  useEffect(() => {
    get();
  }, []);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
         <Grid container spacing={3}>
          <Grid item xs={12} lg={11}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Editar endereço do paciente
                </SuiTypography>
                <Divider />
                <SuiTypography variant="h6" fontWeight="medium">
                  {paciente || ""}
                </SuiTypography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Nome do endereço
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={apelido || ""}
                      onChange={(e) => {
                        setApelido(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        CEP
                      </SuiTypography>
                      <SuiTypography component="label" variant="caption" fontWeight="regular">
                        (Somente números)
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={cep || ""}
                      onChange={(e) => {
                        setCep(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SuiBox mb={0} ml={0.5} lineHeight={4.5} display="inline-block">
                      <SuiButton
                        variant="gradient"
                        buttonColor="success"
                        onClick={() => {
                          buscarCep(cep);
                        }}
                      >
                        buscar cep
                      </SuiButton>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Logradouro
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={logradouro || ""}
                      onChange={(e) => {
                        setLogradouro(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Número
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={numero || ""}
                      onChange={(e) => {
                        setNumero(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Complemento
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={complemento || ""}
                      onChange={(e) => {
                        setComplemento(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Bairro
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={bairro || ""}
                      onChange={(e) => {
                        setBairro(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Cidade
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={cidade || ""}
                      onChange={(e) => {
                        setCidade(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Estado
                      </SuiTypography>
                    </SuiBox>
                    <EstadoBrasilSelect
                      value={{ value: estado, label: estado }}
                      onChangeValue={(e) => {
                        setEstado(e.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        UF
                      </SuiTypography>
                    </SuiBox>
                    <UfBrasilSelect
                      value={{ value: uf, label: uf }}
                      onChangeValue={(e) => {
                        setUf(e.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={4} display="inline-block">
                      <Checkbox checked={principal} onChange={handleSetPrincipal} />
                      <SuiTypography
                        variant="button"
                        fontWeight="bold" /* regular */
                        onClick={handleSetPrincipal}
                        customClass="cursor-pointer user-select-none"
                      >
                        &nbsp;&nbsp; Principal &nbsp;
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                </Grid>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Observações
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="regular"
                      textColor="text"
                    >
                      Observações do endereço
                    </SuiTypography>
                  </SuiBox>
                  <SuiEditor value={observacoes || ""} onChange={setObservacoes} />
                </SuiBox>
                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  <SuiBox mr={1}>
                    <SuiButton
                      buttonColor="light"
                      onClick={() => {
                        props.history.goBack();
                      }}
                    >
                      cancelar
                    </SuiButton>
                  </SuiBox>
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    onClick={() => {
                      save(); // ao clicar no botão irá chamar a função POST no banco de dados
                    }}
                  >
                    Salvar
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
               {/* <Grid item xs={12} lg={3}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Dicas
                </SuiTypography>
                <Divider />
              </SuiBox>
            </Card>
          </Grid> */}
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(EditarEnderecoPaciente);
