// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function Footer() {
    return (
        <SuiBox component="footer" py={6}>
            <Grid container justifyContent="center">
                <Grid item xs={10} lg={8}>
                    <SuiBox display="flex" justifyContent="center" flexWrap="wrap" mb={3}>
                        <SuiBox mr={{xs: 2, lg: 3, xl: 6}}>
                            <SuiTypography component="a" href="#" variant="body2" textColor="secondary">
                                Sobre o Agilie
                            </SuiTypography>
                        </SuiBox>
                        <SuiBox mr={{xs: 2, lg: 3, xl: 6}}>
                            <SuiTypography component="a" href="#" variant="body2" textColor="secondary">
                                Fortran Tecnologia
                            </SuiTypography>
                        </SuiBox>
                        <SuiBox mr={{xs: 2, lg: 3, xl: 6}}>
                            <SuiTypography component="a" href="#" variant="body2" textColor="secondary">
                                Suporte
                            </SuiTypography>
                        </SuiBox>
                    </SuiBox>
                </Grid>
                <Grid item xs={12} lg={8} className="text-center">
                    <SuiTypography variant="body2" textColor="secondary">
                        Copyright &copy; 2022 Fortran Tecnologia.
                    </SuiTypography>
                </Grid>
            </Grid>
        </SuiBox>
    );
}

export default Footer;
