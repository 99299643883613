import React, { useEffect, useState } from "react";
import {toast} from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import swal from "@sweetalert/with-react";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiEditor from "components/SuiEditor";
import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Redirect, withRouter } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import InstituicaoSelect from "../../../../components/instituicao-select";
import SuiDatePicker from "../../../../components/SuiDatePicker";

function DadosPassagemMedica(props) {
  const [id, setId] = useState();
  const [registro, setRegistro] = useState([]);
  const [observacao, setObservacao] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setId(props.match.params.id);
  }, []);

  const save = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .post(
        `${window.url}ws/entradaExame/dados/passagem`,
        {
          idSaas: sessionStorage.getItem("idSaas"),
          id_entrada_exame: id,
          observacao,
          status: 5,
        },
        config
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          swal("Agilie", "Entrada atualizada com sucesso.", "success");
          props.history.push("/auxiliares/entradaExame");
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/entradaExame/${props.match.params.id}`, config)
      .then((res) => {
        setId(res.data.entrada_exame.id);
        setRegistro(res.data.entrada_exame);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  useEffect(() => {
    get();
  }, []);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
         <Grid container spacing={3}>
          <Grid item xs={12} lg={11}>
            <Card className="overflow-visible">
              <SuiBox p={2}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Registro de passagem médica
                </SuiTypography>
                <Divider />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8}>
                    <SuiBox display="inline-block">
                      <SuiTypography variant="h6" fontWeight="medium">
                        Paciente
                      </SuiTypography>
                      <SuiTypography fontWeight="bold">{registro.nome}</SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox display="inline-block">
                      <SuiTypography variant="h6" fontWeight="medium">
                        Convênio
                      </SuiTypography>
                      <SuiTypography fontWeight="bold">{registro.nome_convenio}</SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <SuiBox mb={1} ml={0.5} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Observações
                      </SuiTypography>
                    </SuiBox>
                    <SuiEditor
                      value={observacao || ""}
                      onChange={setObservacao}
                      style={{ height: "180px" }}
                    />
                  </Grid>
                </Grid>
              </SuiBox>
              <SuiBox p={2} mt={5}>
                <SuiButton
                  variant="gradient"
                  buttonColor="secondary"
                  onClick={() => {
                    save();
                  }}
                >
                  Salvar e enviar
                </SuiButton>
              </SuiBox>
            </Card>
          </Grid>
               {/* <Grid item xs={12} lg={3}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Dicas
                </SuiTypography>
                <Divider />
              </SuiBox>
            </Card>
          </Grid> */}
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(DadosPassagemMedica);
