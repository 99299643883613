import React, {useState} from "react";
import {toast} from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiEditor from "components/SuiEditor";
import SuiButton from "components/SuiButton";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {withRouter} from "react-router-dom";
import swal from "@sweetalert/with-react";
import EntradaExameSelect from "../../../../components/entradaExame-select";
import ProcedimentoSelect from "../../../../components/procedimento-select";
import ValorProcedimentoSelect from "../../../../components/valorProcedimento-select";
import ContabancariaSelect from "../../../../components/contabancaria-select";
import SuiDatePicker from "../../../../components/SuiDatePicker";
import TipoPagamentoSelect from "../../../../components/tipoPagamento-select";


function CadastroEntradaCaixa(props) {
    const [idEntradaExame, setIdEntradaExame] = useState();
    const [idProcedimento, setIdProcedimento] = useState();
    const [idValorProcedimento, setIdValorProcedimento] = useState();
    const [idContaBancaria, setIdContaBancaria] = useState();
    const [ano, setAno] = useState();
    const [pago, setPago] = useState();
    const [aPagar, setApagar] = useState();
    const [dataPagamento, setDataPagamento] = useState();
    const [tipoPagamento, setTipoPagamento] = useState(6);
    const [observacao, setObservacao] = useState();

    const save = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .post(
                `${window.url}ws/entradaCaixa`,
                {
                    /* MANTER O IDSAAS  */
                    idSaas: sessionStorage.getItem("idSaas"),
                    id_entrada_exame: idEntradaExame,
                    id_procedimento: idProcedimento,
                    id_valor_procedimento: idValorProcedimento,
                    id_conta_bancaria: idContaBancaria,
                    ano,
                    pago,
                    a_pagar: aPagar,
                    data_pagamento: dataPagamento,
                    tipo_pagamento: tipoPagamento.value,
                    observacao,
                },
                config
            )
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    swal("Agilie", "Entrada caixa cadastrado com sucesso.", "success");
                    props.history.push("/auxiliares/entradaCaixa");
                }
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SuiBox mt={3} mb={4}>
                       <Grid container spacing={3}>
                    <Grid item xs={12} lg={11}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Nova Entrada caixa
                                </SuiTypography>
                                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                                    Cadastro Entrada caixa
                                </SuiTypography>
                                <Divider/>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Entrada exame
                                            </SuiTypography>
                                        </SuiBox>
                                        <EntradaExameSelect
                                            onChangeValue={(e) => {
                                                setIdEntradaExame(e.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Procedimento
                                            </SuiTypography>
                                        </SuiBox>
                                        <ProcedimentoSelect
                                            onChangeValue={(e) => {
                                                setIdProcedimento(e.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Valor do procedimento
                                            </SuiTypography>
                                        </SuiBox>
                                        <ValorProcedimentoSelect
                                            onChangeValue={(e) => {
                                                setIdValorProcedimento(e.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Conta bancária
                                            </SuiTypography>
                                        </SuiBox>
                                        <ContabancariaSelect
                                            onChangeValue={(e) => {
                                                setIdContaBancaria(e.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Ano
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setAno(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Pago
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setPago(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                A pagar
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setApagar(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Data de pagamento
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            onChangeValue={(evt) => {
                                                setDataPagamento(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} mt={1} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Tipo de pagamento
                                            </SuiTypography>
                                        </SuiBox>
                                        <TipoPagamentoSelect
                                            value={tipoPagamento}
                                            onChangeValue={(e) => {
                                                if (e.value === 1) {
                                                    setTipoPagamento({value: e.value, label: "PIX"});
                                                } else if (e.value === 2) {
                                                    setTipoPagamento({value: e.value, label: "TED"});
                                                } else if (e.value === 3) {
                                                    setTipoPagamento({value: e.value, label: "DOC"});
                                                } else if (e.value === 4) {
                                                    setTipoPagamento({value: e.value, label: "DINHEIRO"});
                                                } else if (e.value === 5) {
                                                    setTipoPagamento({value: e.value, label: "CONVÊNIO"});
                                                } else if (e.value === 6) {
                                                    setTipoPagamento({value: e.value, label: "Outros"});
                                                } else {
                                                    setTipoPagamento({value: e.value, label: "Outros"});
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                {/* TEXTO */}
                                <SuiBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                >
                                    <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                                            Observação
                                        </SuiTypography>
                                    </SuiBox>
                                    <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                                        <SuiTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="regular"
                                            textColor="text"
                                        >
                                            Você pode escrever uma observação aqui
                                        </SuiTypography>
                                    </SuiBox>
                                    <SuiEditor value={observacao || ""} onChange={setObservacao}/>
                                </SuiBox>
                                {/* ANEXAR UM ARQUIVO PDF OU WORD */}
                                {/* <SuiBox>
                  <SuiBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                  >
                    <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Anexar tabela em PDF ou WORD
                      </SuiTypography>
                    </SuiBox>
                    <SuiDropzone options={{ addRemoveLinks: true }} />
                  </SuiBox>
                </SuiBox> */}
                                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                                    <SuiBox mr={1}>
                                        <SuiButton
                                            buttonColor="light"
                                            onClick={() => {
                                                props.history.goBack();
                                            }}
                                        >
                                            cancelar
                                        </SuiButton>
                                    </SuiBox>
                                    <SuiButton
                                        variant="gradient"
                                        buttonColor="info"
                                        onClick={() => {
                                            save(); // ao clicar no botão irá chamar a função POST no banco de dados
                                        }}
                                    >
                                        Salvar
                                    </SuiButton>
                                </SuiBox>
                            </SuiBox>
                        </Card>
                    </Grid>
                    {/* <Grid item xs={12} lg={3}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Dicas
                                </SuiTypography>
                                <Divider/>
                            </SuiBox>
                        </Card>
                    </Grid> */}
                </Grid>
            </SuiBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default withRouter(CadastroEntradaCaixa);
