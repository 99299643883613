import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import Stack from '@mui/material/Stack';

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiEditor from "components/SuiEditor";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { BiEditAlt, BiPlusCircle, BiSearch } from "react-icons/all";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import Tooltip from "@mui/material/Tooltip";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { withRouter } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import swal from "@sweetalert/with-react";
import PacienteSelect from "../../../../components/paciente-select";
import InstituicaoSelect from "../../../../components/instituicao-select";
import ConvenioSelect from "../../../../components/convenio-select";
import ProfissionalSelect from "../../../../components/profissional-select";
import SuiDatePicker from "../../../../components/SuiDatePicker";
import { dateEnBr } from "../../../../utils";

function EditarEntradaExame(props) {
    const [idPaciente, setIdPaciente] = useState();
    const [paciente, setPaciente] = useState();
    const [idInstituicao, setIdInstituicao] = useState();
    const [instituicao, setInstituicao] = useState();
    const [idConvenio, setIdConvenio] = useState();
    const [convenio, setConvenio] = useState();
    const [idProfissionalSaude, setIdProfissionalSaude] = useState();
    const [profissionalSaude, setProfissionalSaude] = useState();
    const [idProfissionalSaudeAux, setIdProfissionalSaudeAux] = useState();
    const [profissionalSaudeAux, setProfissionalSaudeAux] = useState();
    const [carteirinha, setCarteirinha] = useState();
    const [idCarteirinha, setIdCarteirinha] = useState();
    const [carteirinhaEncontrada, setCarteirinhaEncontrada] = useState(false);
    // const [idProcedimento, setIdProcedimento] = useState();
    // const [procedimento, setProcedimento] = useState();
    const [idOrigem, setIdOrigem] = useState();
    // const [idMaterial, setIdMaterial] = useState();
    // const [material, setMaterial] = useState();
    const [identificadorGeral, setIdentificadorGeral] = useState();
    const [identificador, setIdentificador] = useState();
    const [dataEntrada, setDataEntrada] = useState(new Date());
    // const [dataEntradaExame, setDataEntradaExame] = useState(new Date());
    // const [previsaoEntrega, setPrevisaoEntrega] = useState(new Date());
    const [registroEnvio, setRegistroEnvio] = useState();
    const [prioridade, setPrioridade] = useState(false);
    const [dataColeta, setDataColeta] = useState(new Date());
    const [observacao, setObservacao] = useState();
    const [deveGuia, setDeveGuia] = useState(false);
    const [load, setLoad] = useState(true);
    const handleSetPrioridade = () => {
        setPrioridade(!prioridade);
    };
    const handleSetDeveGuia = () => setDeveGuia(!deveGuia);
    const [isLoading, setIsLoading] = useState(true);

    const save = async () => {

        // if (carteirinhaEncontrada === false) {
        //     toast.error('Carteirinha não encontrada!')
        //     return;
        // }
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .put(
                `${window.url}ws/entradaExame/${props.match.params.id}`,
                {
                    idSaas: sessionStorage.getItem("idSaas"),
                    id_paciente: idPaciente,
                    id_instituicao: idInstituicao,
                    id_convenio: idConvenio,
                    id_profissional: idProfissionalSaude,
                    id_profissional_aux: idProfissionalSaudeAux,
                    id_carteirinha: idCarteirinha,
                    identificador: identificadorGeral,
                    // previsao_entrega: previsaoEntrega,
                    data_entrada: dataEntrada,
                    registro_envio: registroEnvio,
                    prioridade,
                    data_coleta: dataColeta,
                    observacao,
                    deve_guia: deveGuia,
                },
                config
            )
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    swal("Agilie", "Entrada exame atualizada com sucesso.", "success");
                    props.history.goBack();
                }
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    const get = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .get(`${window.url}ws/entradaExame/${props.match.params.id}`, config)
            .then((res) => {
                const entradaExameObj = res.data.entrada_exame;
                setIdPaciente(entradaExameObj.id_paciente);
                setPaciente(entradaExameObj.nome);
                setIdInstituicao(entradaExameObj.id_instituicao);
                setInstituicao(entradaExameObj.razao_social);
                setIdConvenio(entradaExameObj.id_convenio);
                setConvenio(entradaExameObj.nome_convenio);
                setIdProfissionalSaude(entradaExameObj.id_profissional);
                setProfissionalSaude(entradaExameObj.nome_profissional);
                setIdProfissionalSaudeAux(entradaExameObj.id_profissional_aux);
                setProfissionalSaudeAux(entradaExameObj.nome_profissional_aux);
                setDataColeta(dateEnBr(entradaExameObj.data_coleta));
                setDataEntrada(dateEnBr(entradaExameObj.data_entrada));
                setPrioridade(Boolean(entradaExameObj.prioridade));
                setDeveGuia(Boolean(entradaExameObj.deve_guia));
                setObservacao(entradaExameObj.observacao);
                setCarteirinha(entradaExameObj.carteirinha);
                setIdCarteirinha(entradaExameObj.id_carteirinha);
                setIdentificador(entradaExameObj.identificador);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };


    const buscarCarteirinha = async (botao) => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .get(`${window.url}ws/convenioPaciente/carteirinha/${carteirinha}`, config)
            .then((res) => {
                const convenioPacienteObj = res.data.carteirinha;
                if (botao === true) {
                    setIdCarteirinha(convenioPacienteObj.id);
                    setIdPaciente(convenioPacienteObj.id_paciente);
                    setPaciente(convenioPacienteObj.nome);
                    setIdConvenio(convenioPacienteObj.id_convenio);
                    setConvenio(convenioPacienteObj.nome_convenio);
                    toast.success("Carteirinha encontrada!")
                    setCarteirinhaEncontrada(true);

                } else {
                    setIdCarteirinha(convenioPacienteObj.id);
                    setCarteirinhaEncontrada(true);
                }


            })
            .catch((error) => {
                if (error.response.status === 404) {
                    // const falha = error.response.data.message;
                    // toast.error(falha);
                    if (botao === true) {
                        toast.error("Carteirinha não encontrada!")
                        setCarteirinhaEncontrada(false);
                        setIdCarteirinha(null);
                        setIdPaciente(null);
                        setPaciente('');
                        setIdConvenio(null);
                        setConvenio('');
                    } else {
                        setCarteirinhaEncontrada(false);
                        setIdPaciente(null);
                        setPaciente('');
                        setIdConvenio(null);
                        setConvenio('');

                    }

                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    const buscarPacienteCarteirinha = async (idPaciente) => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .get(`${window.url}ws/convenioPaciente/pacienteCarteirinha/${idPaciente}`, config)
            .then((res) => {
                const pacienteCarteirinhaObj = res.data.pacienteCarteirinha;
                setIdCarteirinha(pacienteCarteirinhaObj.id)
                setCarteirinha(pacienteCarteirinhaObj.carteirinha);
                setIdConvenio(pacienteCarteirinhaObj.id_convenio);
                setConvenio(pacienteCarteirinhaObj.nome_convenio);
                setCarteirinhaEncontrada(true);
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    setIdCarteirinha(null)
                    setCarteirinha('');
                    setIdConvenio(null);
                    setConvenio('');
                    setCarteirinhaEncontrada(false);
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

  
    useEffect(() => {
        get();
  
    }, []);


    useEffect(() => {
        if (carteirinha !== undefined && !load) {
            buscarCarteirinha();
            setLoad(false);
        }
        

    }, [carteirinha]);


    return isLoading ? (
        <DashboardLayout>
            <DashboardNavbar />
            <BounceLoader size={30} color="#ccc" />
        </DashboardLayout>
    ) : (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox mt={3} mb={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={11} container alignItems="center">
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={12} md={3} lg={5.4}>
                                        <SuiTypography variant="h6" fontWeight="medium">
                                            Editar entrada de exames
                                        </SuiTypography>
                                        <SuiTypography variant="button" fontWeight="regular" textColor="text">
                                            Edição da entrada de exames
                                        </SuiTypography>

                                    </Grid>
                                    <Grid item xs={12} md={3} lg={3}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Carteirinha Nº
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={carteirinha ?? ""}
                                            onChange={(e) => {
                                                setCarteirinha(e.target.value);
                                            }}

                                        />
                                    </Grid>
                                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
                                        <SuiBox mt={1} mb={-4} ml={1}>
                                            <Tooltip title="Buscar carteirinha" placement="top">
                                                <SuiButton
                                                    variant="gradient"
                                                    buttonColor="info"
                                                    onClick={() => {
                                                        if (carteirinha === null || carteirinha === '') {
                                                            swal("Agilie", "Digite o número da carteirinha!", "info");
                                                        } else {
                                                            const botao = true;
                                                            buscarCarteirinha(botao);
                                                        }
                                                    }}
                                                >
                                                    <BiSearch />
                                                </SuiButton>
                                            </Tooltip>
                                        </SuiBox>
                                    </Stack>
                                    <Grid item xs={12} md={2.7} lg={2.7}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Exame Nº
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            readOnly
                                            value={identificador ?? ""}
                                        />
                                    </Grid>
                                </Grid>

                                <Divider />
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Paciente
                                            </SuiTypography>
                                        </SuiBox>
                                        <PacienteSelect
                                            value={{ value: idPaciente, label: paciente }}
                                            onChangeValue={(e) => {
                                                setIdPaciente(e.value);
                                                setPaciente(e.label);
                                                buscarPacienteCarteirinha(e.value); 
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Instituição
                                            </SuiTypography>
                                        </SuiBox>
                                        <InstituicaoSelect
                                            value={{ value: idInstituicao, label: instituicao }}
                                            onChangeValue={(e) => {
                                                setIdInstituicao(e.value);
                                                setInstituicao(e.label);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Convênio
                                            </SuiTypography>
                                        </SuiBox>
                                        <ConvenioSelect
                                            value={{ value: idConvenio, label: convenio }}
                                            onChangeValue={(e) => {
                                                setIdConvenio(e.value);
                                                setConvenio(e.label);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Médico e/ou profissional solicitante
                                            </SuiTypography>
                                        </SuiBox>
                                        <ProfissionalSelect
                                            value={{ value: idProfissionalSaude, label: profissionalSaude }}
                                            onChangeValue={(e) => {
                                                setIdProfissionalSaude(e.value);
                                                setProfissionalSaude(e.label);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Médico e/ou profissional auxiliar
                                            </SuiTypography>
                                        </SuiBox>
                                        <ProfissionalSelect
                                            value={{ value: idProfissionalSaudeAux, label: profissionalSaudeAux }}
                                            onChangeValue={(e) => {
                                                setIdProfissionalSaudeAux(e.value);
                                                setProfissionalSaudeAux(e.label);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Data de coleta
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            valueDefault={dataColeta || ""}
                                            onChangeValue={(evt) => {
                                                setDataColeta(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Data entrada do exame
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            valueDefault={dataEntrada || ""}
                                            onChangeValue={(evt) => {
                                                setDataEntrada(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <Checkbox
                                                checked={prioridade}
                                                onChange={() => {
                                                    handleSetPrioridade();
                                                }}
                                            />
                                            <SuiTypography
                                                variant="button"
                                                fontWeight="bold" /* regular */
                                                onClick={() => {
                                                    handleSetPrioridade();
                                                }}
                                                customClass="cursor-pointer user-select-none"
                                            >
                                                Prioridade
                                            </SuiTypography>
                                        </SuiBox>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <Checkbox checked={deveGuia} onChange={handleSetDeveGuia} />
                                            <SuiTypography
                                                variant="button"
                                                fontWeight="bold" /* regular */
                                                onClick={handleSetDeveGuia}
                                                customClass="cursor-pointer user-select-none"
                                            >
                                                Deve guia
                                            </SuiTypography>
                                        </SuiBox>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <SuiBox mb={1} ml={0.5} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Observações
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiEditor value={observacao || ""} onChange={setObservacao} />
                                    </Grid>
                                </Grid>
                                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                                    <SuiBox mr={1}>
                                        <SuiButton
                                            buttonColor="light"
                                            onClick={() => {
                                                props.history.goBack();
                                            }}
                                        >
                                            cancelar
                                        </SuiButton>
                                    </SuiBox>
                                    <SuiButton
                                        variant="gradient"
                                        buttonColor="info"
                                        onClick={() => {
                                            save(); // ao clicar no botão irá chamar a função POST no banco de dados
                                        }}
                                    >
                                        Salvar
                                    </SuiButton>
                                </SuiBox>
                            </SuiBox>
                        </Card>
                    </Grid>
                    {/* <Grid item xs={12} lg={3}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Dicas
                </SuiTypography>
                <Divider />
              </SuiBox>
            </Card>
          </Grid> */}
                </Grid>
            </SuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default withRouter(EditarEntradaExame);
