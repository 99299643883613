/**
 =========================================================
 * Soft UI Dashboard PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-flatpickr components
// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";

// Soft UI Dashboard PRO React components
import InputBase from "@mui/material/InputBase";
import InputMask from "react-input-mask";

function SuiDatePicker({input, onChangeValue, valueDefault, ...rest}) {
    // console.log(valueDefault);
    return (
        /*
        <Flatpickr
            {...rest}
            data-enable-time
            options={{
                showMonths: true,
                dateFormat: "d/m/Y",
                locale: Portuguese,
                enableTime: false,
                allowInput: true
            }}
            render={({defaultValue}, ref) => <SuiInputDate {...input} inputRef={ref}/>}
        />
         */
        valueDefault === undefined ? <InputMask mask="99/99/9999" onChange={(e) => {
            onChangeValue(e.target.value)
        }}>
            {(inputProps) => <InputBase
                {...inputProps}
                {...rest}
            />}
        </InputMask> : <InputMask mask="99/99/9999" onChange={(e) => {
            onChangeValue(e.target.value)
        }} value={valueDefault}>
            {(inputProps) => <InputBase
                {...inputProps}
                {...rest}
            />}
        </InputMask>
    );
}

// Setting default values for the props of SuiDatePicker
SuiDatePicker.defaultProps = {
    input: {},
};

// Typechecking props for the SuiDatePicker
SuiDatePicker.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    input: PropTypes.objectOf(PropTypes.any),
};

export default SuiDatePicker;
