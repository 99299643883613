/**
 =========================================================
 * Soft UI Dashboard PRO React - v3.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiBadgeDot from "components/SuiBadgeDot";
import PieChart from "examples/Charts/PieChart";

// Data
import channelChartData from "./data";

function GraficoCategoriasPagar() {
  return (
    <Card>
      <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SuiTypography variant="h6">Contas a pagar por categoria</SuiTypography>
        <Tooltip title="See traffic channels" placement="bottom" arrow>
          <SuiButton variant="outlined" color="secondary" size="small" circular iconOnly>
            <Icon>priority_high</Icon>
          </SuiButton>
        </Tooltip>
      </SuiBox>
      <SuiBox p={2} mt={3}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <PieChart chart={channelChartData} height="100%" />
          </Grid>
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default GraficoCategoriasPagar;
