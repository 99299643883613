import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import swal from "@sweetalert/with-react";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiEditor from "components/SuiEditor";
import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import Tooltip from "@mui/material/Tooltip";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Redirect, withRouter } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { BounceLoader } from "react-spinners";
import UsersGestaoSistemaSelect from "../../../../components/usersGestaoSistema-select";



function GestaoSistema(props) {
  const [idUser, setIdUser] = useState();
  const [user, setUser] = useState();
  const [laudoHistView, setLaudoHistView] = useState(false);
  const [laudoHistViewPdf, setLaudoHistViewPdf] = useState(false);
  const [laudoHistEditPaciente, setLaudoHistEditPaciente] = useState(false);
  const [laudoHistEdit, setLaudoHistEdit] = useState(false);
  const handleLaudoView = () => setLaudoHistView(!laudoHistView);
  const handleLaudoInsert = () => setLaudoHistViewPdf(!laudoHistViewPdf);
  const handleLaudoEdit = () => setLaudoHistEditPaciente(!laudoHistEditPaciente);
  const handleLaudoDelete = () => setLaudoHistEdit(!laudoHistEdit);


  const [isLoading, setIsLoading] = useState(true);

  const save = async (idUser) => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .put(
        `${window.url}ws/gestaoSistema/${idUser}`,
        {
          idSaas: sessionStorage.getItem("idSaas"),
          laudo_hist_view: laudoHistView,
          laudo_hist_view_pdf: laudoHistViewPdf,
          laudo_hist_edit_paciente: laudoHistEditPaciente,
          laudo_hist_edit: laudoHistEdit,
        },
        config
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          swal("Agilie", "Painel atualizado com sucesso.", "success");
          props.history.push("/auxiliares/gestaoSistema");
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  const get = async (idUser) => {
    setIsLoading(true);
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/gestaoSistema/${idUser}`, config)
      .then((res) => {
        const gestaoSistemaObj = res.data.gestaoSistema;
        setLaudoHistView(Boolean(gestaoSistemaObj.laudo_hist_view));
        setLaudoHistViewPdf(Boolean(gestaoSistemaObj.laudo_hist_view_pdf));
        setLaudoHistEditPaciente(Boolean(gestaoSistemaObj.laudo_hist_edit_paciente));
        setLaudoHistEdit(Boolean(gestaoSistemaObj.laudo_hist_edit));
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  useEffect(() => {
    if (idUser !== undefined) {
      get(idUser);
    }
  }, [idUser]);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12} md={9} lg={7}>
                    <SuiTypography variant="h6" fontWeight="medium">
                      Gestão do sistema
                    </SuiTypography>
                    <SuiTypography variant="button" fontWeight="regular" textColor="text">
                      Painel de controle
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={12} md={3} lg={5}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Usuário
                      </SuiTypography>
                    </SuiBox>
                    <UsersGestaoSistemaSelect
                      value={{
                        value: idUser,
                        label: user || "Selecione"
                      }}
                      onChangeValue={(e) => {
                        setIdUser(e.value);
                        setUser(e.label);
                      }}
                    />
                  </Grid>
                </Grid>
              </SuiBox>
            </Card>
          </Grid>
          {!isLoading ? (
            <Grid item xs={12} lg={12}>
              <Card className="overflow-visible">
                <SuiBox p={5} lineHeight={3}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={1}>
                            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SuiTypography component="label" variant="caption" fontWeight="bold">
                                Laudo histórico
                              </SuiTypography>
                            </SuiBox>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Checkbox checked={laudoHistView} onChange={handleLaudoView} />
                            <SuiTypography
                              variant="button"
                              fontWeight="bold"
                              onClick={handleLaudoView}
                              customClass="cursor-pointer user-select-none"
                            >
                              &nbsp;&nbsp; Visualizar laudo &nbsp;
                            </SuiTypography>
                          </Grid>

                          <Grid item xs={12} md={2}>
                            <Checkbox checked={laudoHistViewPdf} onChange={handleLaudoInsert} />
                            <SuiTypography
                              variant="button"
                              fontWeight="bold"
                              onClick={handleLaudoInsert}
                              customClass="cursor-pointer user-select-none"
                            >
                              &nbsp;&nbsp; Visualizar PDF do laudo &nbsp;
                            </SuiTypography>
                          </Grid>

                          <Grid item xs={12} md={2}>
                            <Checkbox checked={laudoHistEditPaciente} onChange={handleLaudoEdit} />
                            <SuiTypography
                              variant="button"
                              fontWeight="bold"
                              onClick={handleLaudoEdit}
                              customClass="cursor-pointer user-select-none"
                            >
                              &nbsp;&nbsp; Editar paciente &nbsp;
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <Checkbox checked={laudoHistEdit} onChange={handleLaudoDelete} />
                            <SuiTypography
                              variant="button"
                              fontWeight="bold"
                              onClick={handleLaudoDelete}
                              customClass="cursor-pointer user-select-none"
                            >
                              &nbsp;&nbsp; Editar laudo &nbsp;
                            </SuiTypography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider />
                    </Grid>
                  </Grid>
                </SuiBox>
                <SuiBox display="flex" justifyContent="flex-end" mt={7} mb={3} mr={3}>
                  <SuiBox mr={1}>
                    <SuiButton
                      buttonColor="light"
                      onClick={() => {
                        props.history.push('/dashboards/home');
                      }}
                    >
                      cancelar
                    </SuiButton>
                  </SuiBox>
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    onClick={() => {
                      if (idUser !== undefined) {
                        save(idUser);
                      } else {
                        toast.error("Selecione um usuário antes de salvar!");
                      }
                    }}
                  >
                    Salvar
                  </SuiButton>
                </SuiBox>
              </Card>
            </Grid>
          ) : (
            <Grid item xs={12} lg={12}>
              <Card className="overflow-visible">
                <SuiBox p={2} lineHeight={1}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} lg={12}>
                      <SuiTypography variant="h6" fontWeight="bold" textColor="text" textAlign="center" mt={1}>
                        Selecione um usuário para visualizar o painel
                      </SuiTypography>
                    </Grid>
                  </Grid>
                </SuiBox>
              </Card>
            </Grid>

          )}
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(GestaoSistema);
