import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import Tooltip from "@mui/material/Tooltip";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Icon from "@mui/material/Icon";
import moment from "moment";
import SuiButton from "../../../../../components/SuiButton";
import DefaultLineChart from "../../../../../examples/Charts/LineCharts/DefaultLineChart";
import SuiBadgeDot from "../../../../../components/SuiBadgeDot";

import defaultLineChartData from "./defaultLineChartData";
import HorizontalBarChart from "../../../../../examples/Charts/BarCharts/HorizontalBarChart";
import GraficoCategoriasPagar from "./contas_categorias";
import MiniStatisticsCard from "../../../../../examples/Cards/StatisticsCards/MiniStatisticsCard";
import { calcBusinessDays } from "../../../../../utils";

function FinanceiroDashboardSintetico() {
  const horizontalBarChartData = {
    labels: ["Unimed", "Clinipam", "Particular", "Amil", "SUS", "Outros"],
    datasets: [
      {
        label: "Faturamento por convênio",
        color: "dark",
        data: [15, 20, 12, 60, 20, 15],
      },
    ],
  };
  const hoje = new Date();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <MiniStatisticsCard
              title={{
                text: `Faturamento (atual) até ${hoje.getDate()}/${
                  hoje.getMonth() + 1
                }/${hoje.getFullYear()}`,
                fontWeight: "medium",
              }}
              count="90.5k"
              percentage={{ color: "success", text: "+5%" }}
              icon={{ color: "dark", component: "image" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <MiniStatisticsCard
              title={{
                text: `Faturamento previsto próximos ${calcBusinessDays(
                  "2022-05-05",
                  "2022-05-31"
                )} dias úteis`,
                fontWeight: "medium",
              }}
              count="300k"
              percentage={{ color: "success", text: "+5%" }}
              icon={{ color: "dark", component: "image" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <MiniStatisticsCard
              title={{
                text: `Média de despesas fixas`,
                fontWeight: "medium",
              }}
              count="112k"
              percentage={{ color: "success", text: "+5%" }}
              icon={{ color: "dark", component: "image" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <MiniStatisticsCard
              title={{
                text: `Média de despesas variáveis`,
                fontWeight: "medium",
              }}
              count="85k"
              percentage={{ color: "success", text: "+5%" }}
              icon={{ color: "dark", component: "image" }}
            />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={7}>
            <DefaultLineChart
              title="Fluxo de caixa"
              description={
                <SuiBox display="flex" justifyContent="space-between">
                  <SuiBox display="flex">
                    <SuiBadgeDot color="info" size="sm" badgeContent="Contas a pagar" />
                    <SuiBadgeDot color="dark" size="sm" badgeContent="Contas a receber" />
                  </SuiBox>
                  <SuiBox mt={-5.25} mr={-1}>
                    <Tooltip title="See which ads perform better" placement="left" arrow>
                      <SuiButton
                        variant="outlined"
                        color="secondary"
                        size="small"
                        circular
                        iconOnly
                      >
                        <Icon>priority_high</Icon>
                      </SuiButton>
                    </Tooltip>
                  </SuiBox>
                </SuiBox>
              }
              chart={defaultLineChartData}
            />
          </Grid>
          <Grid item xs={12} xl={5}>
            <HorizontalBarChart title="Faturamento por convênio" chart={horizontalBarChartData} />
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={4}>
            <GraficoCategoriasPagar />
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FinanceiroDashboardSintetico;
