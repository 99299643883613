import React, { useState } from "react";
import { cnpj as cnpjValidator } from "cpf-cnpj-validator";
import { toast } from "react-toastify";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiEditor from "components/SuiEditor";
import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Redirect, withRouter } from "react-router-dom";
import swal from "@sweetalert/with-react";
import IntituicaoTipo from "../../../../components/intituicaoTipo-select";
import UfBrasilSelect from "../../../../components/ufBrasil-select";


function CadastroInstituicao(props) {
  const [tipo, setTipo] = useState(4);
  const [razaoSocial, setRazaoSocial] = useState();
  const [nomeFantasia, setNomeFantasia] = useState();
  const [cnpj, setCnpj] = useState();
  const [telefone1, setTelefone1] = useState();
  const [telefone2, setTelefone2] = useState();
  const [email, setEmail] = useState();
  const [endereco, setEndereco] = useState();
  const [uf, setUf] = useState('PR');
  const [cidade, setCidade] = useState();
  const [cep, setCep] = useState();
  const [numero, setNumero] = useState();
  const [complemento, setComplemento] = useState();


  const save = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    if (cnpj && cnpj.trim() !== "" && !cnpjValidator.isValid(cnpj)) {
      toast.error("CNPJ inválido");
      return;
    }

    await axios
      .post(
        `${window.url}ws/instituicao`,
        {
          /* MANTER O IDSAAS  */
          idSaas: sessionStorage.getItem("idSaas"),
          tipo: tipo.value,
          razao_social: razaoSocial,
          nome_fantasia: nomeFantasia,
          cnpj,
          telefone_1: telefone1,
          telefone_2: telefone2,
          email,
          endereco,
          uf,
          cidade,
          cep,
          numero,
          complemento,
        },
        config
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          swal("Agilie", "Instituição cadastrado com sucesso.", "success");
          if (localStorage.getItem("goBackStatus") === "1") {
            localStorage.setItem("id_instituicao", res.data.id);
            localStorage.setItem("instituicao", res.data.razao_social);
            localStorage.removeItem("goBackStatus");
            props.history.goBack();
          } else {
            props.history.push("/auxiliares/instituicao");
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  const buscarCep = async (numeroCep) => {
    await axios
      .get(`https://viacep.com.br/ws/${numeroCep}/json/`)
      .then((res) => {
        console.log(res.data.erro);
        setEndereco(res.data.logradouro);
        setCidade(res.data.localidade);
        setComplemento(res.data.complemento);
        setUf(res.data.uf);
        if (res.data.erro === true) {
          toast.error("CEP não encontrado!");
        } else {
          toast.success("CEP encontrado!");
        }
      })
      .catch(() => {
        swal("Agilie", "Ops! Verifique a quantidade de números! Precisa ser 8 números! ", "error");
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={11}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Nova Instituição
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  Cadastro Instituição
                </SuiTypography>
                <Divider />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Tipo de instituição
                      </SuiTypography>
                    </SuiBox>
                    <IntituicaoTipo
                      value={tipo}
                      onChangeValue={(e) => {
                        if (e.value === 1) {
                          setTipo({ value: e.value, label: "Clínica" });
                        } else if (e.value === 2) {
                          setTipo({ value: e.value, label: "Consultório" });
                        } else if (e.value === 3) {
                          setTipo({ value: e.value, label: "Hospital" });
                        } else {
                          setTipo({ value: e.value, label: "Outros" });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Razão social
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setRazaoSocial(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Nome fantasia
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setNomeFantasia(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        CNPJ
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setCnpj(e.target.value);
                      }}
                      onBlur={(e) => {
                        e.preventDefault();
                        if (cnpj && cnpj.trim() !== "" && !cnpjValidator.isValid(cnpj)) {
                          toast.error("CNPJ inválido");
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Email
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Telefone 1
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setTelefone1(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Telefone 2
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setTelefone2(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        CEP
                      </SuiTypography>
                      <SuiTypography component="label" variant="caption" fontWeight="regular">
                        (Somente números)
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setCep(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SuiBox mb={0} ml={0.5} lineHeight={4.5} display="inline-block">
                      <SuiButton
                        variant="gradient"
                        buttonColor="success"
                        onClick={() => {
                          buscarCep(cep);
                        }}
                      >
                        buscar cep
                      </SuiButton>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Endereço
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={endereco || ""}
                      onChange={(e) => {
                        setEndereco(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Cidade
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={cidade || ""}
                      onChange={(e) => {
                        setCidade(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        UF
                      </SuiTypography>
                    </SuiBox>
                    <UfBrasilSelect
                      value={{ value: uf, label: uf }}
                      onChangeValue={(e) => {
                        setUf(e.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Número
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setNumero(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Complemento
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={complemento || ""}
                      onChange={(e) => {
                        setComplemento(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>

                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  <SuiBox mr={1}>
                    <SuiButton
                      buttonColor="light"
                      onClick={() => {
                        if (localStorage.getItem("goBackStatus") === "1") {
                          localStorage.removeItem("goBackStatus");
                          props.history.goBack();
                        } else {
                          props.history.goBack();
                        }
                      }}
                    >
                      cancelar
                    </SuiButton>
                  </SuiBox>
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    onClick={() => {
                      save(); // ao clicar no botão irá chamar a função POST no banco de dados
                    }}
                  >
                    Salvar
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
               {/* <Grid item xs={12} lg={3}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Dicas
                </SuiTypography>
                <Divider />
              </SuiBox>
            </Card>
          </Grid> */}
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(CadastroInstituicao);
