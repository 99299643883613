import React, {useEffect, useState} from "react";
import {toast} from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import swal from "@sweetalert/with-react";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {withRouter} from "react-router-dom";
import {BounceLoader} from "react-spinners";
import FornecedorSelect from "../../../../components/fornecedor-select";
import ProdutoSelect from "../../../../components/produto-select";
import SuiDatePicker from "../../../../components/SuiDatePicker";
import {dateEnBr} from "../../../../utils";

function EditarAlmoxarifado(props) {
    const [idFornecedor, setIdFornecedor] = useState();
    const [fornecedor, setFornecedor] = useState();
    const [idProduto, setIdProduto] = useState();
    const [produto, setProduto] = useState();
    const [quantidade, setQuantidade] = useState();
    const [dataRecebimento, setDataRecebimento] = useState(new Date());
    const [dataPagamento, setDataPagamento] = useState(new Date());
    const [valorUnitario, setValorUnitario] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const save = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .put(
                `${window.url}ws/almoxarifado/${props.match.params.id}`,
                {
                    id_fornecedor: idFornecedor,
                    id_produto: idProduto,
                    quantidade,
                    data_recebimento: dataRecebimento,
                    data_pagamento: dataPagamento,
                    valor_unitario: valorUnitario,
                    idSaas: sessionStorage.getItem("idSaas"),
                },
                config
            )
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    swal("Agilie", "almoxarifado atualizado com sucesso.", "success");
                    props.history.push("/auxiliares/almoxarifado");
                }
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    const get = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .get(`${window.url}ws/almoxarifado/${props.match.params.id}`, config)
            .then((res) => {
                const almoxarifadoObj = res.data.almoxarifado;
                setIdFornecedor(almoxarifadoObj.id_fornecedor);
                setFornecedor(almoxarifadoObj.razao_social);
                setIdProduto(almoxarifadoObj.id_produto);
                setProduto(almoxarifadoObj.nome_produto);
                setQuantidade(almoxarifadoObj.quantidade);
                setDataRecebimento(dateEnBr(dataRecebimento));
                setDataPagamento(dateEnBr(dataPagamento));
                setValorUnitario(almoxarifadoObj.valor_unitario);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    useEffect(() => {
        get();
    }, []);

    return isLoading ? (
        <DashboardLayout>
            <DashboardNavbar/>
            <BounceLoader size={30} color="#ccc"/>
        </DashboardLayout>
    ) : (
        <DashboardLayout>
            <DashboardNavbar/>
            <SuiBox mt={3} mb={4}>
                       <Grid container spacing={3}>
                    <Grid item xs={12} lg={11}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Editar almoxarifado
                                </SuiTypography>
                                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                                    Os dados alterados não geram efeito sobre entrada de exames anteriores
                                </SuiTypography>
                                <Divider/>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Fornecedor
                                            </SuiTypography>
                                        </SuiBox>
                                        <FornecedorSelect
                                            value={{value: idFornecedor, label: fornecedor}}
                                            onChangeValue={(e) => {
                                                setIdFornecedor(e.value);
                                                setFornecedor(e.label);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Produto
                                            </SuiTypography>
                                        </SuiBox>
                                        <ProdutoSelect
                                            value={{value: idProduto, label: produto}}
                                            onChangeValue={(e) => {
                                                setIdProduto(e.value);
                                                setProduto(e.label);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Data de recebimento
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            valueDefault={dataRecebimento}
                                            onChangeValue={(evt) => {
                                                setDataRecebimento(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Data de pagamento
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            valueDefault={dataPagamento}
                                            onChangeValue={(evt) => {
                                                setDataPagamento(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Quantidade do almoxarifado
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={quantidade || ""}
                                            onChange={(e) => {
                                                setQuantidade(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Valor unitário
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={valorUnitario || ""}
                                            onChange={(e) => {
                                                setValorUnitario(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                                    <SuiBox mr={1}>
                                        <SuiButton
                                            buttonColor="light"
                                            onClick={() => {
                                                props.history.goBack();
                                            }}
                                        >
                                            cancelar
                                        </SuiButton>
                                    </SuiBox>
                                    <SuiButton
                                        variant="gradient"
                                        buttonColor="info"
                                        onClick={() => {
                                            save(); // ao clicar no botão irá chamar a função POST no banco de dados
                                        }}
                                    >
                                        Salvar
                                    </SuiButton>
                                </SuiBox>
                            </SuiBox>
                        </Card>
                    </Grid>
                    {/* <Grid item xs={12} lg={3}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Dicas
                                </SuiTypography>
                                <Divider/>
                            </SuiBox>
                        </Card>
                    </Grid> */}
                </Grid>
            </SuiBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default withRouter(EditarAlmoxarifado);
