import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import swal from "@sweetalert/with-react";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiEditor from "components/SuiEditor";
import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Redirect, withRouter } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { BounceLoader } from "react-spinners";
import MaterialTecidoSelect from "../../../../components/materialTecido-select";
import ColoracaoSelect from "../../../../components/coloracao-select";
import DescricaoAdicionalSelect from "../../../../components/descricaoAdicional-select";


function EditarMaterialBiologicoEntradaExame(props) {
  const [idEntradaExame, setIdEntradaExame] = useState();
  const [idMaterial, setIdMaterial] = useState();
  const [material, setMaterial] = useState();
  const [idColoracao, setIdColoracao] = useState();
  const [idDescricao, setIdDescricao] = useState();
  const [coloracao, setColoracao] = useState('');
  const [numerobi, setNumeroBi] = useState();
  const [fixando, setFixando] = useState(false);
  const [descalcificando, setDescalcificando] = useState(false);
  const [amostragem, setAmostragem] = useState(false);
  const [descricao, setDescricao] = useState();
  const [isLoading, setIsLoading] = useState(true);


  const handleSetFixando = () => setFixando(!fixando);
  const handleSetDescalcificando = () => setDescalcificando(!descalcificando);


  const save = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .put(
        `${window.url}ws/materialBioEntradaExame/${props.match.params.id}`,
        {
          idSaas: sessionStorage.getItem("idSaas"),
          id_entrada_exame: idEntradaExame,
          id_material_tecido: idMaterial,
          material_biologico: material,
          id_coloracao: idColoracao,
          coloracao,
          fixando,
          descalcificando,
          descricao,
          amostragem,
          numero_bi: numerobi,
        },
        config
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success("Material biológico atualizado com sucesso");
          props.history.goBack();
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/materialBioEntradaExame/${props.match.params.id}`, config)
      .then((res) => {
        const materialBioEntradaExameObj = res.data.materialBioEntradaExame;
        setIdEntradaExame(materialBioEntradaExameObj.id_entrada_exame);
        setIdMaterial(materialBioEntradaExameObj.id_material_tecido);
        setMaterial(materialBioEntradaExameObj.material_biologico);
        setNumeroBi(materialBioEntradaExameObj.numero_bi);
        setIdColoracao(materialBioEntradaExameObj.id_coloracao);
        setColoracao(materialBioEntradaExameObj.coloracao);
        setFixando(Boolean(materialBioEntradaExameObj.fixando));
        setDescalcificando(Boolean(materialBioEntradaExameObj.descalcificando));
        setDescricao(materialBioEntradaExameObj.descricao);
        setAmostragem(materialBioEntradaExameObj.amostragem);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  const handleChangeLaudoDescricao = (content, delta, source, editor) => {
    setDescricao(content);
  };


  const handleChangeMaterialBiologico = (content, delta, source, editor) => {
    setMaterial(content);
  };


  const handleChangeColoracao = (itemSelecionado) => {
    setColoracao(prev => {
      let next = prev ? `${prev}, ${itemSelecionado.label}` : itemSelecionado.label;
      // Foi necessário remover as tags HTML <p> e </p> para que cada item ficasse um ao lado do outro
      next = next.replace(/<\/?p>/g, '');
      return next;
    });
  }

  const handleColoracaoComplemento = (content, delta, source, editor) => {
    setColoracao(content);
  };



  useEffect(() => {
    get();
  }, []);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={11}>
            <Card className="overflow-visible">
              <SuiBox p={2}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Material biológico da entrada de exames
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  Cadastro de material biológico
                </SuiTypography>
                <Divider />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12} container spacing={2}>
                    <Grid item xs={12} md={8} lg={8} xl={8}>
                      <SuiBox mb={0} ml={0.5} lineHeight={1} display="inline-block">
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Material biológico (Template)
                        </SuiTypography>
                      </SuiBox>
                      <MaterialTecidoSelect
                        onChangeValue={(e) => {
                          setIdMaterial(e.value);
                          setMaterial(e.label);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                      <SuiBox display="inline-block">
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Número Blocos
                        </SuiTypography>
                      </SuiBox>
                      <SuiInput
                        value={numerobi || ""}
                        onChange={(e) => {
                          setNumeroBi(e.target.value);
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={12} md={12}>
                      <SuiBox mb={1} ml={0.5} display="inline-block">
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Material biológico
                        </SuiTypography>
                      </SuiBox>
                      <SuiEditor
                        value={material || ""}
                        onChange={handleChangeMaterialBiologico}
                        style={{ height: "120px" }}

                      />
                    </Grid> */}
                    <Grid Grid item xs={12} md={12}>
                      <SuiBox mb={0} ml={0.5} lineHeight={1} display="inline-block">
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Material biológico
                        </SuiTypography>
                      </SuiBox>
                      <SuiInput
                        value={material || ""}
                        onChange={(e) => {
                          setMaterial(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={8} style={{ marginTop: '10px' }}>
                    <SuiBox mb={0} ml={0.5} lineHeight={1} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Coloração (Template)
                      </SuiTypography>
                    </SuiBox>
                    <ColoracaoSelect
                      onChangeValue={(e) => {
                        setIdColoracao(e.value);
                        handleChangeColoracao(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} style={{ marginTop: '10px' }}>
                    <SuiBox display="flex" alignItems="center" >
                      <SuiBox mb={1} ml={1.5} lineHeight={4.5}>
                        <Checkbox checked={fixando} onChange={handleSetFixando} />
                        <SuiTypography
                          variant="button"
                          fontWeight="bold"
                          onClick={handleSetFixando}
                          customClass="cursor-pointer user-select-none"
                        >
                          Fixando
                        </SuiTypography>
                      </SuiBox>

                      <SuiBox mb={1} ml={1.5} lineHeight={4.5}>
                        <Checkbox checked={descalcificando} onChange={handleSetDescalcificando} />
                        <SuiTypography
                          variant="button"
                          fontWeight="bold"
                          onClick={handleSetDescalcificando}
                          customClass="cursor-pointer user-select-none"
                        >
                          Descalcificando
                        </SuiTypography>
                      </SuiBox>
                    </SuiBox>
                  </Grid>
                  {/* <Grid item xs={12} md={12}>
                    <SuiBox mb={1} ml={0.5} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Coloração
                      </SuiTypography>
                    </SuiBox>
                    <SuiEditor
                      value={coloracao}
                      onChange={handleColoracaoComplemento}
                      style={{ height: "120px" }}

                    />
                  </Grid> */}
                  <Grid item xs={12} md={12} style={{ marginTop: '-15px' }}>
                    <SuiBox mb={0} ml={0.5} lineHeight={1} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Coloração
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={coloracao || ""}
                      onChange={(e) => {
                        setColoracao(e.target.value);
                      }}

                    />
                  </Grid>
                  <Grid item xs={12} md={12} style={{ marginTop: '10px' }}>
                    <SuiBox mb={0} ml={0.5} lineHeight={1} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Amostragem
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={amostragem || ""}
                      onChange={(e) => {
                        setAmostragem(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} lg={8} xl={8} style={{ marginTop: '10px' }}>
                    <SuiBox mb={0} ml={0.5} lineHeight={1} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Descrição adicional (Template)
                      </SuiTypography>
                    </SuiBox>
                    <DescricaoAdicionalSelect
                      onChangeValue={(e) => {
                        setIdDescricao(e.value);
                        setDescricao(e.texto);

                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <SuiBox mb={1} ml={0.5} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Descrição adicional
                      </SuiTypography>
                    </SuiBox>
                    <SuiEditor
                      value={descricao || ""}
                      onChange={handleChangeLaudoDescricao}
                      style={{ height: "120px" }}

                    />
                  </Grid>
                  {/* <Grid item xs={12} md={12} marginTop={5}>
                    <SuiBox mb={1} ml={0.5} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Amostragem
                      </SuiTypography>
                    </SuiBox>
                    <SuiEditor
                      value={amostragem || ""}
                      onChange={setAmostragem}
                      style={{ height: "100px" }}
                    />
                  </Grid> */}
                </Grid>
              </SuiBox>
              <SuiBox display="flex" justifyContent="flex-end" p={2} mt={5}>
                <SuiBox mr={1}>
                  <SuiButton
                    buttonColor="light"
                    onClick={() => {
                      props.history.goBack();
                    }}
                  >
                    cancelar
                  </SuiButton>
                </SuiBox>
                <SuiButton
                  variant="gradient"
                  buttonColor="info"
                  onClick={() => {
                    save(); // ao clicar no botão irá chamar a função POST no banco de dados
                  }}
                >
                  Salvar
                </SuiButton>
              </SuiBox>
            </Card>
          </Grid>
          {/* <Grid item xs={12} lg={3}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Dicas
                </SuiTypography>
                <Divider />
              </SuiBox>
            </Card>
          </Grid> */}
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(EditarMaterialBiologicoEntradaExame);
