import EnderecoPaciente from "./layouts/pages/auxiliares/enderecoPaciente";
import ConvenioPaciente from "./layouts/pages/auxiliares/convenioPaciente";
import ContatoPaciente from "./layouts/pages/auxiliares/contatoPaciente";
import CadastroContatoPaciente from "./layouts/pages/auxiliares/contatoPaciente/cadastro";
import CadastroEnderecoPaciente from "./layouts/pages/auxiliares/enderecoPaciente/cadastro";
import CadastroConvenioPaciente from "./layouts/pages/auxiliares/convenioPaciente/cadastro";
import MedicosEntrada from "./layouts/pages/auxiliares/medicosEntrada";
import ProcedimentoAgendamento from "./layouts/pages/auxiliares/procedimentoAgendamento";
import FinanceiroDashboard from "./layouts/dashboards/financeiro";
import EntradaExame from "./layouts/pages/auxiliares/entradaExame";
import CadastroLaudo from "./layouts/pages/auxiliares/laudo/cadastro";
import EntradaExamePreparo from "./layouts/pages/auxiliares/entradaExame/index_preparo";
import EntradaExameLaudo from "./layouts/pages/auxiliares/entradaExame/index_laudo";
import EnviarParaPreparoExterno from "./layouts/pages/auxiliares/entradaExame/enviar_preparo";
import RetornoDaLaminacao from "./layouts/pages/auxiliares/entradaExame/retornar_preparo";
import EntradaExameRegistro from "./layouts/pages/auxiliares/entradaExame/index_registro";
import EntradaExameMacroscopia from "./layouts/pages/auxiliares/entradaExame/index_macroscopia";
import EntradaExameDiagnostico from "./layouts/pages/auxiliares/entradaExame/index_diagnostico";
import EntradaExamePassagem from "./layouts/pages/auxiliares/entradaExame/index_passagem";
import EntradaExameDigitacao from "./layouts/pages/auxiliares/entradaExame/index_digitacao";
import EntradaExameCorrecao from "./layouts/pages/auxiliares/entradaExame/index_correcao";
import EntradaExameFinalizados from "./layouts/pages/auxiliares/entradaExame/index_finalizados";
import ProcedimentoEntradaExame from "./layouts/pages/auxiliares/procedimentoEntradaExame";
import CadastroProcedimentoEntradaExame from "./layouts/pages/auxiliares/procedimentoEntradaExame/cadastro";
import MaterialBiologicoEntradaExame from "./layouts/pages/auxiliares/materialBiologicoEntradaExame";
import CadastroMaterialBiologicoEntradaExame from "./layouts/pages/auxiliares/materialBiologicoEntradaExame/cadastro";
import EntradaMaterialTecido from "./layouts/pages/auxiliares/entradaMaterialTecido";
import CadastroEntradaMaterialTecido from "./layouts/pages/auxiliares/entradaMaterialTecido/cadastro";
import Laudo from "./layouts/pages/auxiliares/laudo";

const intRoutes = [
  {
    name: "enderecopaciente",
    key: "enderecopaciente",
    route: "/auxiliares/paciente/enderecos/:id/:uuid",
    component: EnderecoPaciente,
  },
  {
    name: "cadastroenderecopaciente",
    key: "cadastroenderecopaciente",
    route: "/auxiliares/paciente/enderecos/cadastro/:id/:uuid",
    component: CadastroEnderecoPaciente,
  },
  {
    name: "entradaexame",
    key: "entradaexame",
    route: "/auxiliares/entradaExame/lista/recepcao",
    component: EntradaExame,
  },
  {
    name: "entradaexame",
    key: "entradaexame",
    route: "/auxiliares/entradaExame/:id",
    component: EntradaExame,
  },
  {
    name: "entradaexameregistro",
    key: "entradaexameregistro",
    route: "/auxiliares/entradaExame/lista/registro",
    component: EntradaExameRegistro,
  },
  {
    name: "entradaexamemacroscopia",
    key: "entradaexamemacroscopia",
    route: "/auxiliares/entradaExame/lista/macroscopia",
    component: EntradaExameMacroscopia,
  },
  {
    name: "entradaexamemacroscopia",
    key: "entradaexamemacroscopia",
    route: "/auxiliares/entradaExame/lista/macroscopia/:id",
    component: EntradaExameMacroscopia,
  },
  {
    name: "entradaexamepreparo",
    key: "entradaexamepreparo",
    route: "/auxiliares/entradaExame/lista/preparo",
    component: EntradaExamePreparo,
  },
  {
    name: "entradaexamepreparo",
    key: "entradaexamepreparo",
    route: "/auxiliares/entradaExame/lista/preparo/:id",
    component: EntradaExamePreparo,
  },
  {
    name: "entradaexamepassagem",
    key: "entradaexamepassagem",
    route: "/auxiliares/entradaExame/lista/passagem",
    component: EntradaExamePassagem,
  },
  {
    name: "entradaexamediagnostico",
    key: "entradaexamediagnostico",
    route: "/auxiliares/entradaExame/lista/diagnostico",
    component: EntradaExameDiagnostico,
  },
  {
    name: "entradaexamediagnostico",
    key: "entradaexamediagnostico",
    route: "/auxiliares/entradaExame/lista/diagnostico/:id",
    component: EntradaExameDiagnostico,
  },
  {
    name: "entradaexamedigitacao",
    key: "entradaexamedigitacao",
    route: "/auxiliares/entradaExame/lista/digitacao",
    component: EntradaExameDigitacao,
  },
  {
    name: "entradaexamedigitacao",
    key: "entradaexamedigitacao",
    route: "/auxiliares/entradaExame/lista/digitacao/:id",
    component: EntradaExameDigitacao,
  },
  {
    name: "entradaexamecorrecao",
    key: "entradaexamecorrecao",
    route: "/auxiliares/entradaExame/lista/correcao",
    component: EntradaExameCorrecao,
  },
  {
    name: "entradaexamecorrecao",
    key: "entradaexamecorrecao",
    route: "/auxiliares/entradaExame/lista/correcao/:id",
    component: EntradaExameCorrecao,
  },
  {
    name: "laudo",
    key: "laudo",
    route: "/auxiliares/laudo/:id",
    component: Laudo,
  },
  {
    name: "entradaexamelaudo",
    key: "entradaexamelaudo",
    route: "/auxiliares/entradaExame/lista/finalizados",
    component: EntradaExameFinalizados,
  },
  {
    name: "entradaexamelaudo",
    key: "entradaexamelaudo",
    route: "/auxiliares/entradaExame/lista/laudo",
    component: EntradaExameLaudo,
  },
  {
    name: "cadastrolaudo",
    key: "cadastrolaudo",
    route: "/auxiliares/laudo/cadastro/:id_entrada/:uuid",
    component: CadastroLaudo,
  },
  {
    name: "conveniopaciente",
    key: "conveniopaciente",
    route: "/auxiliares/paciente/convenios/:id/:uuid",
    component: ConvenioPaciente,
  },
  {
    name: "cadastroconveniopaciente",
    key: "cadastroconveniopaciente",
    route: "/auxiliares/paciente/convenios/cadastro/:id/:uuid",
    component: CadastroConvenioPaciente,
  },
  {
    name: "contatopaciente",
    key: "contatopaciente",
    route: "/auxiliares/paciente/contatos/:id/:uuid",
    component: ContatoPaciente,
  },
  {
    name: "cadastrocontatopaciente",
    key: "cadastrocontatopaciente",
    route: "/auxiliares/paciente/contatos/cadastro/:id/:uuid",
    component: CadastroContatoPaciente,
  },
  {
    name: "cadastroconveniopaciente",
    key: "cadastroconveniopaciente",
    route: "/auxiliares/paciente/convenioPaciente/:id",
    component: CadastroConvenioPaciente,
  },
  {
    name: "cadastrocontatopaciente",
    key: "cadastrocontatopaciente",
    route: "/auxiliares/paciente/contatoPaciente/:id",
    component: CadastroContatoPaciente,
  },
  {
    name: "medicosentrada",
    key: "medicosentrada",
    route: "/auxiliares/medicos-entrada/:id/:uuid",
    component: MedicosEntrada,
  },
  {
    name: "procedimentoagendamento",
    key: "procedimentoagendamento",
    route: "/auxiliares/procedimentos-entrada/:id/:uuid",
    component: ProcedimentoAgendamento,
  },
  {
    name: "financeirodashboard",
    key: "financeirodashboard",
    route: "/dashboard/fluxocaixa",
    component: FinanceiroDashboard,
  },
  {
    name: "enviarparapreparoexterno",
    key: "enviarparapreparoexterno",
    route: "/clinico/enviar/laminacao/:id/:uuid",
    component: EnviarParaPreparoExterno,
  },
  {
    name: "retornodalaminacao",
    key: "retornodalaminacao",
    route: "/clinico/retorno/laminacao/:id/:uuid",
    component: RetornoDaLaminacao,
  },
  {
    name: "procedimentoentradaexame",
    key: "procedimentoentradaexame",
    route: "/auxiliares/entradaExame/procedimento/:id/:uuid",
    component: ProcedimentoEntradaExame,
  },
  {
    name: "cadastroprocedimentoentradaexame",
    key: "cadastroprocedimentoentradaexame",
    route: "/auxiliares/entradaExame/procedimento/cadastro/:id/:uuid",
    component: CadastroProcedimentoEntradaExame,
  },
  {
    name: "materialbiologicoentradaexame",
    key: "materialbiologicoentradaexame",
    route: "/auxiliares/entradaExame/material/:id/:uuid",
    component: MaterialBiologicoEntradaExame,
  },
  {
    name: "cadastromaterialbiologicoentradaexame",
    key: "cadastromaterialbiologicoentradaexame",
    route: "/auxiliares/entradaExame/material/cadastro/:id/:uuid",
    component: CadastroMaterialBiologicoEntradaExame,
  },
  {
    name: "entradamaterialtecido",
    key: "entradamaterialtecido",
    route: "/auxiliares/entradaMaterialTecido/material/:id/:uuid",
    component: EntradaMaterialTecido,
  },
  {
    name: "cadastromaterialtecido",
    key: "cadastromaterialtecido",
    route: "/auxiliares/entradaMaterialTecido/material/cadastro/:id/:uuid",
    component: CadastroEntradaMaterialTecido,
  },
];

export default intRoutes;
