import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import { v4 as uuidv4 } from "uuid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import axios from "axios";
import DataTable from "examples/Tables/DataTable";

import { useHistory, withRouter } from "react-router-dom";

// Soft UI Dashboard PRO React example components
import Icon from "@mui/material/Icon";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BounceLoader, ClipLoader } from "react-spinners";
import {
  BiCalendar,
  BsFileEarmarkMedical,
  BsFillTagsFill,
  FaMicroscope,
  ImLab,
} from "react-icons/all";
import SuiButton from "../../../../components/SuiButton";
import BoxStatus from "./components/BoxStatus";
import { dateEnBr, statusBase } from "../../../../utils";
import Paginacao from "../../../../components/paginacao";

function EntradaExameRegistro(props) {
  const [entradaExame, setEntradaExame] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [links, setLinks] = useState([]);
  const [page, setPage] = useState("1");

  const history = useHistory();

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    setIsLoading(true);

    const status = props.match.params?.status ? props.match.params?.status : 9;

    await axios
      .get(`${window.url}ws/entradaExame/1/list?page=${page}`, config)
      .then((res) => {
        setEntradaExame([]);
        // eslint-disable-next-line array-callback-return
        res.data.data.map((item) => {
          // eslint-disable-next-line no-shadow
          setEntradaExame((entradaExame) => [
            ...entradaExame,
            /* VARIÁVEIS DO BANCO DE DADOS */
            {
              id: item.id,
              identificador: item.identificador,
              data_entrada: item.data_entrada,
              razao_social: item.razao_social,
              nome_convenio: item.nome_convenio,
              nome: item.nome,
              data_nascimento: item.data_nascimento,
              previsao_entrega: item.previsao_entrega,
              data_laudo: item.data_laudo,
              data_liberado: item.data_liberado,
              prioridade: item.prioridade,
              deve_guia: item.deve_guia,
              /* FUNÇÃO DE EDIÇÃO */
              functions: (
                <SuiBox ml={-1.325}>
                  <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      history.push(
                        `/auxiliares/entradaExame/dadosRegistro/${item.id}/${item.uuid}`
                      );
                    }}
                  >
                    <BsFillTagsFill />
                  </SuiButton>
                </SuiBox>
              ),
            },
          ]);
        });
        setIsLoading(false);
        setLinks(res.data.links);
      })
      .catch(() => { });
  };

  const table = {
    /* EDITAR CAMPOS */
    columns: [
       // { Header: "#", accessor: "id", width: "2%" },
      { Header: "Paciente", accessor: "nome", width: "10%" },
      {
        Header: "Data nascimento",
        accessor: "data_nascimento",
        Cell: ({ row }) =>
          row.values.data_nascimento === null ? (
            "Não informado"
          ) : (
            <span dangerouslySetInnerHTML={{ __html: dateEnBr(row.values.data_nascimento) }} />
          ),
        width: "12%",
      },
      {
        Header: "Data de entrada",
        accessor: "data_entrada",
        Cell: ({ row }) => (
          <span dangerouslySetInnerHTML={{ __html: dateEnBr(row.values.data_entrada) }} />
        ),
        width: "12%",
      },
      // {
      //   Header: "Prioridade",
      //   accessor: "prioridade",
      //   Cell: ({ row }) => statusBase(row.values.prioridade),
      // },
      // {
      //   Header: "Deve guia",
      //   accessor: "deve_guia",
      //   Cell: ({ row }) => statusBase(row.values.deve_guia),
      // },
      { Header: "Opções", accessor: "functions", width: "1%" },
    ],

    rows: entradaExame,
  };

  useEffect(() => {
    get();
  }, [page]);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <SuiBox p={3} lineHeight={1}>
          <SuiTypography variant="h5" fontWeight="medium">
            Entrada exame (registro)
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            Lista de materiais biológicos (registro)
          </SuiTypography>
        </SuiBox>
        <BoxStatus />
        {/* eslint-disable-next-line no-nested-ternary */}
        {entradaExame.length > 0 ? (
          <>
            <DataTable table={table} canSearch />
            <Paginacao links={links} page={page} setPage={setPage} />
          </>
        ) : (
          <SuiBox p={3} lineHeight={1}>
            <small>Nenhum registro encontrado.</small>
          </SuiBox>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(EntradaExameRegistro);
