import React, {useEffect, useState} from "react";
import {toast} from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import swal from "@sweetalert/with-react";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiEditor from "components/SuiEditor";
import SuiButton from "components/SuiButton";


import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {withRouter} from "react-router-dom";
import {BounceLoader} from "react-spinners";
import PacienteSelect from "../../../../components/paciente-select";
import AgendaSelect from "../../../../components/agenda-select";
import ConvenioSelect from "../../../../components/convenio-select";
import ProfissionalSelect from "../../../../components/profissional-select";
import InstituicaoSelect from "../../../../components/instituicao-select";
import SuiDatePicker from "../../../../components/SuiDatePicker";
import {dateEnBr} from "../../../../utils";

function EditarAgendamento(props) {
    const [titulo, setTitulo] = useState();
    const [idPaciente, setIdPaciente] = useState();
    const [paciente, setPaciente] = useState();
    const [idAgenda, setIdAgenda] = useState();
    const [agenda, setAgenda] = useState();
    const [idConvenio, setIdConvenio] = useState();
    const [convenio, setConvenio] = useState();
    const [idMedicoSolicitante, setIdMedicoSolicitante] = useState();
    const [medicoSolicitante, setMedicoSolicitante] = useState();
    const [idInstituicao, setIdInstituicao] = useState();
    const [instituicao, setInstituicao] = useState();
    const [data, setData] = useState();
    const [hora, setHora] = useState();
    const [observacoes, setObservacoes] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const save = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .put(
                `${window.url}ws/agendamento/${props.match.params.id}`,
                {
                    idSaas: sessionStorage.getItem("idSaas"),
                    id_paciente: idPaciente,
                    id_agenda: idAgenda,
                    id_convenio: idConvenio,
                    id_medico_solicitante: idMedicoSolicitante,
                    id_instituicao: idInstituicao,
                    titulo,
                    data,
                    hora,
                    observacoes,
                },
                config
            )
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    swal("Agilie", "agendamento atualizado com sucesso.", "success");
                    props.history.push("/auxiliares/agendamento");
                }
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    const get = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .get(`${window.url}ws/agendamento/${props.match.params.id}`, config)
            .then((res) => {
                const agendamentoObj = res.data.agendamento;
                setTitulo(agendamentoObj.titulo);
                setIdPaciente(agendamentoObj.id_paciente);
                setPaciente(agendamentoObj.nome);
                setIdAgenda(agendamentoObj.id_agenda);
                setAgenda(agendamentoObj.nome_agenda);
                setIdConvenio(agendamentoObj.id_convenio);
                setConvenio(agendamentoObj.nome_convenio);
                setIdMedicoSolicitante(agendamentoObj.id_medico_solicitante);
                setMedicoSolicitante(agendamentoObj.nome_profissional);
                setIdInstituicao(agendamentoObj.id_instituicao);
                setInstituicao(agendamentoObj.nome_fantasia);
                setData(dateEnBr(agendamentoObj.data));
                setHora(agendamentoObj.hora);
                setObservacoes(agendamentoObj.observacoes);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    useEffect(() => {
        get();
    }, []);

    return isLoading ? (
        <DashboardLayout>
            <DashboardNavbar/>
            <BounceLoader size={30} color="#ccc"/>
        </DashboardLayout>
    ) : (
        <DashboardLayout>
            <DashboardNavbar/>
            <SuiBox mt={3} mb={4}>
                       <Grid container spacing={3}>
                    <Grid item xs={12} lg={11}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Editar agendamento
                                </SuiTypography>
                                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                                    Os dados alterados não geram efeito sobre entrada de exames anteriores
                                </SuiTypography>
                                <Divider/>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Título
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={titulo || ""}
                                            onChange={(e) => {
                                                setTitulo(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Paciente
                                            </SuiTypography>
                                        </SuiBox>
                                        <PacienteSelect
                                            value={{value: idPaciente, label: paciente}}
                                            onChangeValue={(e) => {
                                                setIdPaciente(e.value);
                                                setPaciente(e.label);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Agenda
                                            </SuiTypography>
                                        </SuiBox>
                                        <AgendaSelect
                                            value={{value: idAgenda, label: agenda}}
                                            onChangeValue={(e) => {
                                                setIdAgenda(e.value);
                                                setAgenda(e.label);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Convênio
                                            </SuiTypography>
                                        </SuiBox>
                                        <ConvenioSelect
                                            value={{value: idConvenio, label: convenio}}
                                            onChangeValue={(e) => {
                                                setIdConvenio(e.value);
                                                setConvenio(e.label);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Médico solicitante
                                            </SuiTypography>
                                        </SuiBox>
                                        <ProfissionalSelect
                                            value={{value: idMedicoSolicitante, label: medicoSolicitante}}
                                            onChangeValue={(e) => {
                                                setIdMedicoSolicitante(e.value);
                                                setMedicoSolicitante(e.label);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Instituição
                                            </SuiTypography>
                                        </SuiBox>
                                        <InstituicaoSelect
                                            value={{value: idInstituicao, label: instituicao}}
                                            onChangeValue={(e) => {
                                                setIdInstituicao(e.value);
                                                setInstituicao(e.label);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Data do agendamento
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            valueDefault={data}
                                            onChangeValue={(evt) => {
                                                setData(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Hora do agendamento
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={hora || ""}
                                            onChange={(e) => {
                                                setHora(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <SuiBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                >
                                    <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                                            Observação
                                        </SuiTypography>
                                    </SuiBox>
                                    <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                                        <SuiTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="regular"
                                            textColor="text"
                                        >
                                            Você pode escrever uma observação aqui
                                        </SuiTypography>
                                    </SuiBox>
                                    <SuiEditor value={observacoes || ""} onChange={setObservacoes}/>
                                </SuiBox>
                                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                                    <SuiBox mr={1}>
                                        <SuiButton
                                            buttonColor="light"
                                            onClick={() => {
                                                props.history.goBack();
                                            }}
                                        >
                                            cancelar
                                        </SuiButton>
                                    </SuiBox>
                                    <SuiButton
                                        variant="gradient"
                                        buttonColor="info"
                                        onClick={() => {
                                            save(); // ao clicar no botão irá chamar a função POST no banco de dados
                                        }}
                                    >
                                        Salvar
                                    </SuiButton>
                                </SuiBox>
                            </SuiBox>
                        </Card>
                    </Grid>
                    {/* <Grid item xs={12} lg={3}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Dicas
                                </SuiTypography>
                                <Divider/>
                            </SuiBox>
                        </Card>
                    </Grid> */}
                </Grid>
            </SuiBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default withRouter(EditarAgendamento);
