import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import axios from "axios";
import DataTable from "examples/Tables/DataTable";

import { useHistory, withRouter } from "react-router-dom";

// Soft UI Dashboard PRO React example components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BounceLoader } from "react-spinners";
import Swal from "sweetalert2";
import swal from "@sweetalert/with-react";
import withReactContent from "sweetalert2-react-content";
import Grid from "@mui/material/Grid";
import SuiButton from "../../../../components/SuiButton";
import Paginacao from "../../../../components/paginacao";

function EnderecoPaciente(props) {
  const [enderecoPaciente, setEnderecoPaciente] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [idPaciente, setIdPaciente] = useState(true);
  const uuid = props.match.params.id;
  const [links, setLinks] = useState([]);
  const [page, setPage] = useState("1");
  const history = useHistory();
  const [idRemove, setIdRemove] = useState(null);


  const destroy = async (id) => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };
    await axios
      .delete(`${window.url}ws/enderecoPaciente/${id}`, config)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success("Endereço do paciente excluído com sucesso");
          setPage("1");
          setIdRemove(id);
        }
      })
      .catch(() => {
        swal("Agilie", "Ops! Houve um erro!", "error");
      });
  };

  const excluir = async (id) => {
    const MySwal = withReactContent(Swal);
    const self = this;

    MySwal.fire({
      title: "Excluir cadastro",
      text: `Tem certeza que deseja excluir?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        destroy(id);
      }
    });
  };

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    setIsLoading(true);

    await axios
      .get(`${window.url}ws/enderecoPaciente/${props.match.params.id}/list?page=${page}`, config)
      .then((res) => {
        setEnderecoPaciente([]);
        setIsLoading(false);
        setIdPaciente(props.match.params.id);
        // eslint-disable-next-line array-callback-return
        res.data.data.map((item) => {
          // eslint-disable-next-line no-shadow
          setEnderecoPaciente((enderecoPaciente) => [
            ...enderecoPaciente,
            /* VARIÁVEIS DO BANCO DE DADOS */
            {
              id: item.id,
              id_paciente: item.id_paciente,
              nome: item.nome,
              apelido: item.apelido,
              logradouro: item.logradouro,
              numero: item.numero,
              complemento: item.complemento,
              bairro: item.bairro,
              cidade: item.cidade,
              estado: item.estado,
              cep: item.cep,
              principal: item.principal,
              observacoes: item.observacoes,
              /* FUNÇÃO DE EDIÇÃO */
              functions: (
                <SuiBox ml={-1.325}>
                  {/* <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      history.push(`/auxiliares/enderecoPaciente/editar/${item.id}/${item.uuid}`);
                    }}
                  >
                    <Icon>edit</Icon>
                  </SuiButton> */}
                  <Tooltip title="Editar" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={() => {
                        history.push(`/auxiliares/enderecoPaciente/editar/${item.id}/${item.uuid}`);
                      }}
                    >
                      <Icon>edit</Icon>
                    </SuiButton>
                  </Tooltip>

                  {/* <SuiButton
                    variant="gradient"
                    color="error"
                    onClick={() => {
                      excluir(item.id);
                    }}
                  >
                    <Icon>delete</Icon>
                  </SuiButton> */}
                         <Tooltip title="Excluir" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={() => {
                        excluir(item.id);
                      }}
                      style={{ marginLeft: '3px' }}
                    >
                      <Icon>delete</Icon>
                    </SuiButton>
                  </Tooltip>                    
                    

                </SuiBox>
              ),
            },
          ]);
        });
        setIsLoading(false);
        setLinks(res.data.links);
      })
      .catch(() => { });
  };


  const table = {
    /* EDITAR CAMPOS */
    columns: [
      // { Header: "#", accessor: "id", width: "2%" },
      { Header: "Logradouro", accessor: "logradouro", width: "25%" },
      { Header: "Número", accessor: "numero", width: "10%" },
      { Header: "Cidade", accessor: "cidade", width: "15%" },
      { Header: "Estado", accessor: "estado", width: "15%" },
      { Header: "Opções", accessor: "functions", width: "1%" },
    ],

    rows: enderecoPaciente,
  };

  useEffect(() => {
    get();
  }, [page, idRemove]);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <SuiBox p={3} lineHeight={1}>
          <SuiTypography variant="h5" fontWeight="medium">
            Endereço do paciente
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            Lista de endereço do paciente
          </SuiTypography>
          <SuiBox
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            mt={1}
            lineHeight={1}
            width="100%"
            position="absolute"
            top="1rem"
            right="1rem"
          >
            <SuiButton
              variant="gradient"
              buttonColor="light"
              size="medium"
              onClick={() => {
                history.push(`/auxiliares/paciente`);
              }}
            >
              <SuiTypography variant="h6" fontWeight="medium">
                Voltar
              </SuiTypography>
            </SuiButton>
            <SuiBox ml={1}>
              <SuiButton
                buttonColor="info"
                size="medium"
                onClick={() => {
                  history.push(`/auxiliares/paciente/enderecos/cadastro/${idPaciente}/${uuid}`);
                }}
              >
                <SuiTypography variant="h6" fontWeight="medium">
                  Novo
                </SuiTypography>
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>
        {/* eslint-disable-next-line no-nested-ternary */}
        {enderecoPaciente.length > 0 ? (
          <>
            <DataTable table={table} canSearch />
            <Paginacao links={links} page={page} setPage={setPage} />
          </>
        ) : (
          <SuiBox p={3} lineHeight={1}>
            <small>Nenhum registro encontrado.</small>
          </SuiBox>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(EnderecoPaciente);