import React, { useState } from "react";
import moment from "moment";
import { toast } from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Stack from '@mui/material/Stack';

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiEditor from "components/SuiEditor";
import SuiButton from "components/SuiButton";
import { BiPlusCircle, BiSearch } from "react-icons/all";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import Tooltip from "@mui/material/Tooltip";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { withRouter } from "react-router-dom";
import swal from "@sweetalert/with-react";
import { cpf as checkCpf } from "cpf-cnpj-validator";
import SuiDatePicker from "../../../../components/SuiDatePicker";
import GeneroSelect from "../../../../components/genero-select";
import ConvenioSelect from "../../../../components/convenio-select";



function CadastroPaciente(props) {

    const [idConvenio, setIdConvenio] = useState();
    const [carteirinha, setCarteirinha] = useState();
    const [dataValidade, setDataValidade] = useState();
    const [principal, setPrincipal] = useState();
    const [nome, setNome] = useState();
    const [nomeMae, setNomeMae] = useState();
    const [dataNascimento, setDataNascimento] = useState();
    const [rg, setRg] = useState();
    const [cpf, setCpf] = useState();
    const [docEstrangeiro, setDocEstrangeiro] = useState();
    const [genero, setGenero] = useState(3);
    const [portadorDeficiencia, setPortadorDeficiencia] = useState(false);
    const [portadorDeficienciaQuais, setPortadorDeficienciaQuais] = useState();
    const [necessidadesEspeciais, setNecessidadesEspeciais] = useState(false);
    const [necessidadesEspeciaisQuais, setNecessidadesEspeciaisQuais] = useState();
    const [idade, setIdade] = useState();
    const [telefone, setTelefone] = useState();
    const [celular, setCelular] = useState();
    const [email, setEmail] = useState();
    const [observacao, setObservacao] = useState();
    const handleSetPortadorDeficiencia = () => setPortadorDeficiencia(!portadorDeficiencia);
    const handleSetNecessidadesEspeciais = () => setNecessidadesEspeciais(!necessidadesEspeciais);


    const buscarCPF = async (cpf) => {
        try {
            const response = await axios.get(`${window.url}ws/paciente/consulta/${cpf}`, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`
                }
            });

            if (response.status === 200 || response.status === 201) {
                swal({
                    title: "CPF já cadastrado",
                    text: "Este CPF já está cadastrado no sistema. Deseja visualizar o cadastro?",
                    icon: "warning",
                    buttons: {
                        cancel: "Não",
                        confirm: "Sim"
                    },
                    dangerMode: true
                }).then((res) => {
                    if (res) {
                        props.history.push(`/auxiliares/paciente/editar/${response.data.consultaCpf.id}/${response.data.consultaCpf.id}`);
                    }
                });
                return true;
            }
        } catch (error) {
            // console.error('Erro ao buscar CPF:', error);
            // toast.error("Erro ao verificar o CPF.");
        }
        return false;
    };

    const save = async () => {

        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        if (cpf && cpf.trim() !== "" && !checkCpf.isValid(cpf)) {
            toast.error("CPF inválido");
            return;
        }

        await axios
            .post(
                `${window.url}ws/paciente`,
                {
                    /* MANTER O IDSAAS  */
                    idSaas: sessionStorage.getItem("idSaas"),
                    nome,
                    nome_mae: nomeMae,
                    data_nascimento: dataNascimento,
                    rg,
                    cpf,
                    doc_estrangeiro: docEstrangeiro,
                    genero: genero.value,
                    portador_deficiencia: portadorDeficiencia,
                    portador_deficiencia_quais: portadorDeficienciaQuais,
                    necessidades_especiais: necessidadesEspeciais,
                    necessidades_especiais_quais: necessidadesEspeciaisQuais,
                    idade,
                    telefone,
                    celular,
                    email,
                    observacao,
                    /* CARTEIRINHA */
                    id_convenio: idConvenio,
                    carteirinha,
                    data_validade: dataValidade,
                    principal: true,
                },
                config
            )
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    swal("Agilie", "Paciente cadastrado com sucesso.", "success");
                    if (localStorage.getItem("goBackStatus") === "1") {
                        localStorage.setItem("id_paciente", res.data.paciente.id);
                        localStorage.setItem("paciente", res.data.paciente.nome);
                        if (res.data.convenio_paciente.carteirinha !== undefined && res.data.convenio_paciente.id !== undefined) {
                            localStorage.setItem("carteirinha", res.data.convenio_paciente.carteirinha);
                            localStorage.setItem("id_carteirinha", res.data.convenio_paciente.id);

                        }
                        if (res.data.convenio_paciente.nome_convenio !== undefined && res.data.convenio_paciente.id_convenio !== undefined) {
                            localStorage.setItem("convenio", res.data.convenio_paciente.nome_convenio);
                            localStorage.setItem("id_convenio", res.data.convenio_paciente.id_convenio);

                        }
                        localStorage.removeItem("goBackStatus");
                        props.history.goBack();
                    } else {
                        props.history.push("/auxiliares/paciente");
                    }
                }
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };


    const convenioPush = () => {
        localStorage.setItem("convenioPacienteStatus", "1");
        props.history.push("/auxiliares/convenio/cadastro");
    };

    const calcularIdade = (dataNascimento) => {
        if (dataNascimento && moment(dataNascimento, "DD/MM/YYYY").isValid()) {
            const dataNasc = moment(dataNascimento, "DD/MM/YYYY");
            const hoje = moment();
            const diffAnos = hoje.diff(dataNasc, "years");
            return diffAnos.toString();
        }
        return "";
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox mt={3} mb={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={11}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Novo Paciente
                                </SuiTypography>
                                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                                    Cadastro Paciente
                                </SuiTypography>
                                <Divider />
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={5}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Convênio
                                            </SuiTypography>
                                        </SuiBox>
                                        <ConvenioSelect
                                            onChangeValue={(e) => {
                                                setIdConvenio(e.value);
                                            }}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} md={1} style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                                        <SuiBox mt={1} mb={1}>
                                            <Tooltip title="Cadastrar convênio" placement="top">
                                                <SuiButton
                                                    variant="gradient"
                                                    buttonColor="info"
                                                    onClick={() => {
                                                        convenioPush();
                                                    }}
                                                >
                                                    <BiPlusCircle />
                                                </SuiButton>
                                            </Tooltip>
                                        </SuiBox>
                                    </Grid> */}
                                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
                                        <SuiBox mt={1} mb={-5} ml={1}>
                                            <Tooltip title="Cadastrar convênio" placement="top">
                                                <SuiButton
                                                    variant="gradient"
                                                    buttonColor="info"
                                                    onClick={() => {
                                                        convenioPush();
                                                    }}
                                                >
                                                    <BiPlusCircle />
                                                </SuiButton>
                                            </Tooltip>
                                        </SuiBox>
                                    </Stack>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Carteirinha Nº
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setCarteirinha(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                            >
                                                Data de validade
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            onChangeValue={(evt) => {
                                                setDataValidade(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Nome
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setNome(e.target.value.toUpperCase());
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Nome da mãe
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setNomeMae(e.target.value.toUpperCase());
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Data de nascimento (DD/MM/AAAA)
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker onChangeValue={(e) => {
                                            setDataNascimento(e.toString());
                                            setIdade(calcularIdade(e.toString())); // atualizando a idade automaticamente
                                        }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                RG
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setRg(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                CPF
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setCpf(e.target.value);
                                            }}
                                            onBlur={async (e) => {
                                                e.preventDefault();
                                                const cpf = e.target.value.trim();
                                                if (cpf !== '') {
                                                    if (!checkCpf.isValid(cpf)) {
                                                        toast.error('CPF inválido');
                                                    } else {
                                                        await buscarCPF(cpf);
                                                    }
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Documento Estrangeiro
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setDocEstrangeiro(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Gênero
                                            </SuiTypography>
                                        </SuiBox>
                                        <GeneroSelect
                                            value={genero}
                                            onChangeValue={(e) => {
                                                if (e.value === 1) {
                                                    setGenero({ value: e.value, label: "Feminino" });
                                                } else if (e.value === 2) {
                                                    setGenero({ value: e.value, label: "Masculino" });
                                                } else {
                                                    setGenero({ value: e.value, label: "Não informado" });
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Idade
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={idade || ""}
                                            onChange={(e) => {
                                                setIdade(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Telefone
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setTelefone(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Celular
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setCelular(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Email
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <SuiBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                >
                                    <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                                            Observação
                                        </SuiTypography>
                                    </SuiBox>
                                    <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                                        <SuiTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="regular"
                                            textColor="text"
                                        >
                                            Você pode escrever uma observação aqui
                                        </SuiTypography>
                                    </SuiBox>
                                    <SuiEditor value={observacao || ""} onChange={setObservacao} />
                                </SuiBox>
                                {/* TEXTO */}
                                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                                    <SuiBox mr={1}>
                                        <SuiButton
                                            buttonColor="light"
                                            onClick={() => {
                                                if (localStorage.getItem("goBackStatus") === "1") {
                                                    localStorage.removeItem("goBackStatus");
                                                    props.history.goBack();
                                                } else {
                                                    props.history.goBack();
                                                }
                                            }}
                                        >
                                            cancelar
                                        </SuiButton>
                                    </SuiBox>
                                    <SuiButton
                                        variant="gradient"
                                        buttonColor="info"
                                        onClick={() => {
                                            save(); // ao clicar no botão irá chamar a função POST no banco de dados
                                        }}
                                    >
                                        Salvar
                                    </SuiButton>
                                </SuiBox>
                            </SuiBox>
                        </Card>
                    </Grid>
                    {/* <Grid item xs={12} lg={3}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Dicas
                                </SuiTypography>
                                <Divider />
                                <SuiTypography variant="body2" color="textSecondary" mt={2}>
                                    
                                </SuiTypography> 
                            </SuiBox>
                        </Card>
                    </Grid> */}
                </Grid>
            </SuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default withRouter(CadastroPaciente);
