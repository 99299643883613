import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Backdrop from '@mui/material/Backdrop';
import Tooltip from "@mui/material/Tooltip";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Icon from "@mui/material/Icon";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { BounceLoader } from "react-spinners";
import { toast } from "react-toastify";
import DataTable from "examples/Tables/DataTable";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiBox from "components/SuiBox";
import SuiButton from "../../../../components/SuiButton";
import Paginacao from "../../../../components/paginacao";

function Painel({ idEntradaExame }) {
    const [laudoPainel, setLaudoPainel] = useState(['']);
    const [openForm, setOpenForm] = useState(false);

    const [links, setLinks] = useState([]);
    const [page, setPage] = useState("1");
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    const [atualizar, setAtualizar] = useState('');
    const action = `${window.url}ws/laudoPainel`;

    const [cadastrar, setCadastrar] = useState(false);
    const [editar, setEditar] = useState(false);
    const [editarID, setEditarID] = useState('');

    const [anticorpos, setAnticorpos] = useState('');
    const [clone, setClone] = useState('');
    const [resultado, setResultado] = useState('');
    const [observacao, setObservacao] = useState('');

    
    const handleFormOpen = () => {
        setOpenForm(true);
    }

    const handleFormClose = () => {
        setOpenForm(false);
    }



    const handleDelete = async (id) => {

        await fetch(`${action}/${id}`, { method: 'DELETE' });
        setPage("1");
        if(id === atualizar){
            setAtualizar('0');
        }else{ setAtualizar(id);}
        toast.success("Cadastro excluído com sucesso!");
    }

    const excluir = async (id) => {
        const MySwal = withReactContent(Swal);
        const self = this;

        MySwal.fire({
            title: "Excluir item",
            text: `Tem certeza que deseja excluir?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim, excluir!",
            cancelButtonText: "Não!",
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(id);
            }
        });
    };


    const handleUpdate = async (id) => {
    
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .put(
                `${window.url}ws/laudoPainel/${id}`,
                {

                    id_entrada_exame: idEntradaExame,
                    anticorpos,
                    clone,
                    resultado,
                    observacao,

                },
                config
            )
            .then((res) => {
                if(id === atualizar){
                    setAtualizar('0');
                }else{ setAtualizar(id);}
                setOpenForm(false);
                setEditar(false);
                setAtualizar(id);
                toast.success("Cadastro atualizado com sucesso!");

            })
            .catch((error) => {
                console.log(error.response.data.errors[0]);
                const erros = (error.response.data.errors[0]);
                toast.error("Ops! Houve um erro");
                toast.error(erros);
            
            });
    };

    const handleEdit = async (id) => {
        setEditarID(id);
        setEditar(true);
        setIsLoadingEdit(true);
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .get(`${window.url}ws/laudoPainel/${id}`, config)
            .then((res) => {
                const laudoPainelObj = res.data.laudoPainel;
                setAnticorpos(laudoPainelObj.anticorpos);
                setClone(laudoPainelObj.clone);
                setResultado(laudoPainelObj.resultado);
                setObservacao(laudoPainelObj.observacao);
                setIsLoadingEdit(false);
            })
            .catch((error) => {

            });



        handleFormOpen();
    };


    const cadastro = () => {
        setAnticorpos('');
        setClone('');
        setResultado('');
        setObservacao('');
        setCadastrar(true);
        handleFormOpen();
    }


    const save = async () => {

        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .post(
                `${window.url}ws/laudoPainel`,
                {
                    id_entrada_exame: idEntradaExame,
                    anticorpos,
                    clone,
                    resultado,
                    observacao,

                },
                config
            )
            .then((res) => {
                setOpenForm(false);
                setCadastrar(false);
                setAtualizar(res.data.id);
                toast.success("Cadastro realizado com sucesso!");

            })
            .catch((err) => {
                toast.error("Ops! Houve um erro");
                console.error(err);
            });
    }


    const get = useCallback(() => {
        const config = {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          };
        axios
            .get(`${window.url}ws/laudoPainel/${idEntradaExame}/list?page=${page}`, config)
            .then((res) => {
                setLaudoPainel(res.data.data.map((item) => ({
                    id: item.id,
                    anticorpos: item.anticorpos,
                    clone: item.clone,
                    resultado: item.resultado,
                    observacao: item.observacao,
                    functions: (
                        <SuiBox display="flex" alignItems="center">
                            <SuiTypography sx={{ cursor: "pointer", lineHeight: 0 }}>
                                <Tooltip title="Editar" placement="top">
                                    <Icon
                                        variant="gradient"
                                        color="secondary"
                                        onClick={() => handleEdit(item.id)}
                                    >edit</Icon>
                                </Tooltip>
                            </SuiTypography>
                            <SuiBox mx={2}>
                                <SuiTypography sx={{ cursor: "pointer", lineHeight: 0 }}>
                                    <Tooltip title="Excluir" placement="left">
                                        <Icon
                                            variant="gradient"
                                            color="secondary"
                                            onClick={() => excluir(item.id)}
                                        >delete</Icon>
                                    </Tooltip>
                                </SuiTypography>
                            </SuiBox>
                        </SuiBox>
                    ),
                })));
                setIsLoading(false);
                setLinks(res.data.links);
            })
            .catch(() => { });
    }, [page, atualizar]); // Add the dependencies

    useEffect(() => {
        get();
      }, [get, atualizar]);

    const table = useMemo(() => ({
        columns: [
            { Header: "ID", accessor: "id", width: "2%" },
            { Header: "Anticorpos", accessor: "anticorpos", width: "5%" },
            { Header: "Clone", accessor: "clone", width: "5%" },
            { Header: "Resultado", accessor: "resultado", width: "5%" },
            { Header: "Observação", accessor: "observacao", width: "5%" },
            { Header: "Opções", accessor: "functions", width: "1%" },
        ],
        rows: laudoPainel,
    }), [laudoPainel]);

    const renderContent = useCallback(() => {
        if (laudoPainel.length > 0) {
            return (
                <>
                    <DataTable table={table} canSearch />
                    <Paginacao links={links} page={page} setPage={setPage} />
                </>
            );
        }
        return (
            <SuiBox
                p={10}
                lineHeight={10}
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                width="100%"
                height="100%"
            >
                <small>Nenhum cadastro encontrado.</small>
            </SuiBox>
        );
    }, [laudoPainel, table, links, page]);

   
    return (
        <>
             <Backdrop open={isLoadingEdit} style={{ zIndex: 1500, color: '#ccc' }}>
                <BounceLoader size={30} color="#ccc" />
            </Backdrop>

            <Dialog open={openForm} onClose={handleFormClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">

                <DialogTitle id="form-dialog-title">
                    <SuiTypography variant="h5" fontWeight="medium" component="div" ml={1}>
                        Painel
                    </SuiTypography>
                    <SuiTypography variant="button" fontWeight="regular" textColor="text" component="div" ml={1}>
                        {cadastrar && "Cadastro de um novo painel"}
                        {editar && "Editar cadastro de painel"}
                    </SuiTypography>
                </DialogTitle>

                <DialogContent style={{ height: '100%' }}>
                    <SuiBox mt={1} mb={1}>
                        <SuiBox p={1}>
                            <Grid item xs={12} md={12} >
                                <SuiBox mb={0} ml={0.5} lineHeight={1} display="inline-block">
                                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                                        Anticorpos
                                    </SuiTypography>
                                </SuiBox>
                                <SuiInput
                                    value={anticorpos || ""}
                                    onChange={(e) => {
                                        setAnticorpos(e.target.value);
                                    }}


                                />
                            </Grid>
                            <Grid item xs={12} md={12} >
                                <SuiBox mb={0} ml={0.5} lineHeight={1} display="inline-block">
                                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                                        Clone
                                    </SuiTypography>
                                </SuiBox>
                                <SuiInput
                                    value={clone || ""}
                                    onChange={(e) => {
                                        setClone(e.target.value);
                                    }}


                                />
                            </Grid>
                            <Grid item xs={12} md={12} >
                                <SuiBox mb={0} ml={0.5} lineHeight={1} display="inline-block">
                                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                                        Resultado
                                    </SuiTypography>
                                </SuiBox>
                                <SuiInput
                                    value={resultado || ""}
                                    onChange={(e) => {
                                        setResultado(e.target.value);
                                    }}


                                />

                            </Grid>
                            <Grid item xs={12} md={12} >
                                <SuiBox mb={0} ml={0.5} lineHeight={1} display="inline-block">
                                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                                        Observação
                                    </SuiTypography>
                                </SuiBox>
                                <SuiInput
                                    value={observacao || ""}
                                    onChange={(e) => {
                                        setObservacao(e.target.value);
                                    }}


                                />
                            </Grid>
                        </SuiBox>

                    </SuiBox>
                </DialogContent>

                <DialogActions>
                    <SuiBox display="flex" justifyContent="flex-end" p={2} mt={1}>
                        <SuiBox mr={1}>
                            <SuiButton
                                buttonColor="light"
                                onClick={() => {
                                    handleFormClose();
                                    setCadastrar(false);
                                    setEditar(false);
                                }}
                            >
                                cancelar
                            </SuiButton>
                        </SuiBox>
                        <SuiButton
                            variant="gradient"
                            buttonColor="info"
                            onClick={() => {
                                if (cadastrar) {
                                    save();
                                } else if (editar) {
                                    handleUpdate(editarID);
                                   
                                }
                            }}
                        >
                            Salvar
                        </SuiButton>
                    </SuiBox>
                </DialogActions>
            </Dialog>

            <Grid container spacing={2}>
                <Grid item xs={12} lg={12} container alignItems="flex-end" direction="row-reverse">
                    <SuiBox ml={1}>
                        <SuiButton
                            buttonColor="info"
                            size="medium"
                            onClick={cadastro}
                        >
                            <SuiTypography variant="h6" fontWeight="medium">
                                Novo
                            </SuiTypography>
                        </SuiButton>
                    </SuiBox>
                </Grid>
                <Grid item xs={12} lg={12}>
                    {isLoading ? (
                        <BounceLoader size={30} color="#ccc" />
                    ) : (
                        renderContent()
                    )}
                </Grid>
            </Grid>
        </>
    );
}

Painel.propTypes = {
    idEntradaExame: PropTypes.number.isRequired,
};

export default React.memo(Painel);