import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, withRouter } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";

// Soft UI Dashboard PRO React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import Tooltip from "@mui/material/Tooltip";
import {
  BsFillTagsFill,
  BsGearFill,
  GiMedicalPackAlt,
  BiClipboard,
  FiTool,
  MdLocalHospital,
  GiStethoscope,
  GiDrop,
  GiDna2,
  IoCheckmarkDoneSharp,
  FiEdit,
  MdPlaylistAdd,
  MdPersonAdd,
  BiSearch,

} from "react-icons/all";

// Custom styles for DashboardNavbar
import styles from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import { useSoftUIController } from "context";

import SuiBadge from "../../../components/SuiBadge";
import { signOut } from "../../../store/slices/authThunk";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const classes = styles({ transparentNavbar, absolute, light, isMini });
  const route = useLocation().pathname.split("/").slice(1);
  const [filial, setFilial] = useState("Byori");

  const { token, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      dispatch({
        type: "TRANSPARENT_NAVBAR",
        value: (fixedNavbar && window.scrollY === 0) || !fixedNavbar,
      });
    }

    /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => dispatch({ type: "MINI_SIDENAV", value: !miniSidenav });
  const handleConfiguratorOpen = () =>
    dispatch({ type: "OPEN_CONFIGURATOR", value: !openConfigurator });
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const history = useHistory();

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ marginTop: "1rem" }}
    >
      <NotificationItem
        color="secondary"
        image={
          <Icon fontSize="small" className="material-icon-round text-white">
            payment
          </Icon>
        }
        title={["", "Nenhuma nova mensagem"]}
        date="Agora pouco"
        onClick={handleCloseMenu}
      />
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      className={classes.navbar}
    >
      <Toolbar className={classes.navbar_container}>
        <SuiBox customClass={classes.navbar_row} color="inherit" mb={{ xs: 1, md: 0 }}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          <Icon
            className={classes.navbar_desktop_menu}
            fontSize="medium"
            onClick={handleMiniSidenav}
          >
            {miniSidenav ? "menu_open" : "menu"}
          </Icon>
        </SuiBox>
        {isMini ? null : (
          <>
            <SuiBox customClass={classes.navbar_row}>
              <SuiBox display="flex" justifyContent="flex-first" p={1} mt={1}>
                <SuiBox mr={2}>
                  {/* <Tooltip title="Entrada de exames" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={() => {
                        history.push(
                          `/auxiliares/entradaExame`
                        );
                      }}
                      style={{ marginLeft: '3px' }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FiEdit style={{ color: 'white', marginRight: '5px' }} />
                        <span style={{ color: 'white' }}>Exame</span>
                      </div>
                    </SuiButton>
                  </Tooltip> */}

                  <Tooltip title="Entrada de exames" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor={sessionStorage.getItem('theme')} 
                      onClick={() => {
                        history.push(
                          `/auxiliares/entradaExame`
                        );
                      }}
                      style={{ marginLeft: '3px', width: '75px', height: '45px' }}
                    >
                      <FiEdit />

                    </SuiButton>
                  </Tooltip>

                  <Tooltip title="Cadastrar exame" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor={sessionStorage.getItem('theme')} 
                      onClick={() => {
                        history.push("/auxiliares/entradaExame/Cadastros");
                      }}
                      style={{ marginLeft: '3px', width: '75px', height: '45px' }}
                    >
                      <MdPlaylistAdd />
                    </SuiButton>
                  </Tooltip>

                  <Tooltip title="Cadastrar paciente" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor={sessionStorage.getItem('theme')} 
                      onClick={() => {
                        history.push("/auxiliares/paciente/Cadastro");
                      }}
                      style={{ marginLeft: '3px', width: '75px', height: '45px' }}
                    >
                      <MdPersonAdd />
                    </SuiButton>
                  </Tooltip>

                  <Tooltip title="Rastreabilidade" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor={sessionStorage.getItem('theme')} 
                      onClick={() => {
                        history.push("/clinico/rastreabilidade");
                      }}
                      style={{ marginLeft: '3px', width: '75px', height: '45px' }}
                    >
                      <BiSearch />
                    </SuiButton>
                  </Tooltip>

                </SuiBox>

              </SuiBox>
              <SuiBox
                color={light ? "white" : "inherit"}
                customClass={classes.navbar_section_desktop}
              >
                <IconButton
                  className={classes.navbar_icon_button}
                  size="small"
                  onClick={() => {
                    signOut();
                    history.push("/authentication/sign-in");
                  }}
                >
                  <Icon className={light ? "text-white" : "text-dark"}>account_circle</Icon>
                  <SuiTypography
                    variant="button"
                    fontWeight="medium"
                    textColor={light ? "white" : "dark"}
                  >
                    Sair
                  </SuiTypography>
                </IconButton>
                <IconButton
                  size="small"
                  color="inherit"
                  className={classes.navbar_mobile_menu}
                  onClick={handleMiniSidenav}
                >
                  <Icon className={light ? "text-white" : "text-dark"}>
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
                <IconButton
                  size="small"
                  color="inherit"
                  className={classes.navbar_icon_button}
                  onClick={handleConfiguratorOpen}
                >
                  <Icon>settings</Icon>
                </IconButton>
                <IconButton
                  size="small"
                  color="inherit"
                  className={classes.navbar_icon_button}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>
                </IconButton>
                {renderMenu()}
              </SuiBox>
            </SuiBox>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default withRouter(DashboardNavbar);
