import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import swal from "@sweetalert/with-react";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiEditor from "components/SuiEditor";
import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";
import { BiPlusCircle } from "react-icons/all";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Redirect, withRouter } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { BounceLoader } from "react-spinners";
import SuiDatePicker from "../../../../components/SuiDatePicker";
import { dateEnBr } from "../../../../utils";
import ConvenioSelect from "../../../../components/convenio-select";

function EditarConvenioPaciente(props) {
  const [idConvenio, setIdConvenio] = useState();
  const [convenio, setConvenio] = useState();
  const [carteirinha, setCarteirinha] = useState();
  const [dataValidade, setDataValidade] = useState();
  const [principal, setPrincipal] = useState(false);
  const [observacoes, setObservacoes] = useState();
  const handleSetPrincipal = () => setPrincipal(!principal);
  const [isLoading, setIsLoading] = useState(true);

  const save = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .put(
        `${window.url}ws/convenioPaciente/${props.match.params.id}`,
        {
          idSaas: sessionStorage.getItem("idSaas"),
          id_convenio: idConvenio,
          carteirinha,
          data_validade: dataValidade,
          principal,
          observacoes,
        },
        config
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          swal("Agilie", "Convênio Paciente atualizado com sucesso.", "success");
          props.history.goBack();
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();

        } else if (error.response.status === 422) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Atenção! Ao menos um convênio deve ser definido como principal; escolha um na lista para que os demais não sejam considerados principais.", "error");
          props.history.goBack();
        }

        else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  const convenioPush = () => {
    localStorage.setItem("convenioPacienteStatus", "1");
    props.history.push("/auxiliares/convenio/cadastro");
  };

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/convenioPaciente/${props.match.params.id}`, config)
      .then((res) => {
        const convenioPacienteObj = res.data.convenio_paciente;
        setIdConvenio(convenioPacienteObj.id_convenio);
        setConvenio(convenioPacienteObj.nome_convenio);
        setCarteirinha(convenioPacienteObj.carteirinha);
        setDataValidade(dateEnBr(convenioPacienteObj.data_validade));
        setPrincipal(!!convenioPacienteObj.principal);
        setObservacoes(convenioPacienteObj.observacoes);
      })
      .catch((error) => {
        if (error.status === 422 || error.status === 422) {
          swal("Agilie", "Falha na validação dos campos", "error");
          props.history.push("/auxiliares/convenioPaciente");
        }
      });
    setIsLoading(false);
  };

  useEffect(() => {
    get();
  }, []);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={11}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Editar Convênio Paciente
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  Os dados alterados não geram efeito sobre entrada de exames anteriores
                </SuiTypography>
                <Divider />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Convênio
                      </SuiTypography>
                    </SuiBox>
                    <ConvenioSelect
                      value={{ value: idConvenio, label: convenio }}
                      onChangeValue={(e) => {
                        setIdConvenio(e.value);
                        setConvenio(e.label);
                      }}
                    />
                    <SuiBox mt={1} mb={1}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <SuiButton
                            variant="gradient"
                            buttonColor="secondary"
                            onClick={() => {
                              convenioPush();
                            }}
                          >
                            <BiPlusCircle />
                          </SuiButton>
                        </Grid>
                      </Grid>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Carteirinha Nº
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={carteirinha || ""}
                      onChange={(e) => {
                        setCarteirinha(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Data de validade
                      </SuiTypography>
                    </SuiBox>
                    <SuiDatePicker
                      valueDefault={dataValidade}
                      onChangeValue={(evt) => {
                        setDataValidade(evt.toLocaleString("pt-BR"));
                      }}
                      input={{
                        placeholder: "Selecione uma data",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <Checkbox checked={principal} onChange={handleSetPrincipal} />
                      <SuiTypography
                        variant="button"
                        fontWeight="bold"
                        onClick={handleSetPrincipal}
                        customClass="cursor-pointer user-select-none"
                      >
                        &nbsp;&nbsp; Principal &nbsp;
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                </Grid>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Observação
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="regular"
                      textColor="text"
                    >
                      Você pode escrever uma observação aqui
                    </SuiTypography>
                  </SuiBox>
                  <SuiEditor value={observacoes || ""} onChange={setObservacoes} />
                </SuiBox>
                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  <SuiBox mr={1}>
                    <SuiButton
                      buttonColor="light"
                      onClick={() => {
                        props.history.goBack();
                      }}
                    >
                      cancelar
                    </SuiButton>
                  </SuiBox>
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    onClick={() => {
                      save(); // ao clicar no botão irá chamar a função POST no banco de dados
                    }}
                  >
                    Salvar
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
          {/* <Grid item xs={12} lg={3}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Dicas
                </SuiTypography>
                <Divider />
              </SuiBox>
            </Card>
          </Grid> */}
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(EditarConvenioPaciente);
