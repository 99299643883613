import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";


// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import swal from "@sweetalert/with-react";
import { v4 as uuidv4 } from "uuid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import axios from "axios";
import DataTable from "examples/Tables/DataTable";

import { useHistory, withRouter, useParams } from "react-router-dom";

// Soft UI Dashboard PRO React example components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BounceLoader, ClipLoader } from "react-spinners";
import SuiInput from "components/SuiInput";
import { IoEyeOutline } from "react-icons/all";
import SuiButton from "../../../../components/SuiButton";
import { dateEnBr } from "../../../../utils";
import Paginacao from "../../../../components/paginacao";



function Laudo() {
  const [laudo, setLaudo] = useState([]);
  const { id } = useParams();
  const [identificador, setIdentificador] = useState(id);
  const [isLoading, setIsLoading] = useState(true);
  const [links, setLinks] = useState([]);
  const [page, setPage] = useState("1");
  const [searchTerm, setSearchTerm] = useState(id !== undefined ? id : "");
  const [pesquisa, setPesquisa] = useState([]);
  const [atualizaLista, setAtualizaLista] = useState(1);
  const history = useHistory();
  const idFilial = sessionStorage.getItem('idFilial');
  const [laudoHistView, setLaudoHistView] = useState(localStorage.getItem('laudoHistView') === 'true');
  const [laudoHistViewPdf, setLaudoHistViewPdf] = useState(localStorage.getItem('laudoHistViewPdf') === 'true');
  const [laudoHistEditPaciente, setLaudoHistEditPaciente] = useState(localStorage.getItem('laudoHistEditPaciente') === 'true');
  const [laudoHistEdit, setLaudoHistEdit] = useState(localStorage.getItem('laudoHistEdit') === 'true');
  const [componentUpdate, setComponentUpdate] = useState(false);

  const idUser = useSelector((state) => state.auth.userData.id);


  const permissao = async (idUser) => {
    setIsLoading(true);
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/gestaoSistema/${idUser}`, config)
      .then((res) => {
        const gestaoSistemaObj = res.data.gestaoSistema;
        setLaudoHistView(Boolean(gestaoSistemaObj.laudo_hist_view));
        setLaudoHistViewPdf(Boolean(gestaoSistemaObj.laudo_hist_view_pdf));
        setLaudoHistEditPaciente(Boolean(gestaoSistemaObj.laudo_hist_edit_paciente));
        setLaudoHistEdit(Boolean(gestaoSistemaObj.laudo_hist_edit));
        // setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  useEffect(async () => {
    if (idUser !== undefined) {
      permissao(idUser);
    }

  }, []);



  useEffect(() => {
    localStorage.setItem('laudoHistView', laudoHistView);
    localStorage.setItem('laudoHistViewPdf', laudoHistViewPdf);
    localStorage.setItem('laudoHistEditPaciente', laudoHistEditPaciente);
    localStorage.setItem('laudoHistEdit', laudoHistEdit);
    setComponentUpdate(!componentUpdate);
  }, [laudoHistView, laudoHistViewPdf, laudoHistEditPaciente, laudoHistEdit]);




  const handleSearch = (searchPage = 1) => {

    axios
      .get(`${window.url}ws/laudo/buscar/laudo`, {
        params: {
          buscar: searchTerm,
          page: searchPage,
          status: 7,
        },
      })
      .then((response) => {
        if (searchTerm !== "") {
          if (response.data.data.length === 0) {
            toast.info("Nenhum item encontrado!");
          } else {
            setPesquisa(response);
            setLinks(response.data.links);
            setPage(searchPage);
            if (response.data.current_page === 1) {
              setPage('1');
            }
          }
        } else {
          setPesquisa([]);
          setPage('1');
          setAtualizaLista(atualizaLista + 1);

        }
      })
      .catch(() => {
        toast.error("Ops! Houve um erro na pesquisa!");
      });
  };

  const handlePageChange = (newPage) => {
    if (searchTerm !== "") {
      handleSearch(newPage);
    } else {
      setPage(newPage);
    }
  };

  useEffect(async () => {
    if (identificador !== undefined) {
      setSearchTerm(identificador)
      handleSearch();
    }
  }, [identificador]);



  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    setIsLoading(true);

    await axios
      .get(`${window.url}ws/laudo?page=${page}`, config)
      .then((res) => {
        setLaudo([]);
        const dados = pesquisa.data && pesquisa.data.data.length > 0 ? pesquisa.data.data : res.data.data;
        // eslint-disable-next-line array-callback-return
        dados.map((item) => {
          // eslint-disable-next-line no-shadow
          setLaudo((laudo) => [
            ...laudo,
            /* VARIÁVEIS DO BANCO DE DADOS */
            {
              id: item.id,
              ano: item.ano,
              identificador: item.identificador,
              nome_convenio: item.nome_convenio,
              id_entrada_exame: item.id_entrada_exame,
              id_paciente: item.id_paciente,
              id_profissional: item.id_profissional,
              nome_profissional: item.nome_profissional,
              nome: item.nome,
              id_entrada_material: item.id_entrada_material,
              modelo_diagnostico: item.modelo_diagnostico,
              diagnostico_codigo: item.diagnostico_codigo,
              diagnostico_descritivo: item.diagnostico_descritivo,
              diagnostico_laudo: item.diagnostico_laudo,
              data_liberado: item.data_liberado,
              data_coleta: item.data_coleta,
              data_site: item.data_site,
              data_email: item.data_email,
              email: item.email,
              carteirinha: item.carteirinha,
              /* FUNÇÃO DE EDIÇÃO */
              functions: (
                <SuiBox ml={-1.325}>
                  {laudoHistView && (
                    <Tooltip title="Visualizar laudo" placement="top">
                      <SuiButton
                        variant="gradient"
                        buttonColor="info"
                        onClick={() => {
                          history.push(`/auxiliares/laudo/visualizar/${item.id}/${item.uuid}`);
                        }}
                        style={{ marginLeft: '3px' }}
                      >
                        <IoEyeOutline />
                      </SuiButton>
                    </Tooltip>)}
                  {laudoHistViewPdf && (
                    <Tooltip title="Visualizar PDF do laudo" placement="top">
                      <SuiButton
                        variant="gradient"
                        buttonColor="info"
                        onClick={() => {
                          window.open(
                            `${window.url}ws/PDF/laudo/${idFilial}/${item.id}/${uuidv4()}`,
                            "LAUDOVIEW",
                            "top=10,left=10"
                          );
                        }}
                        style={{ marginLeft: '3px' }}
                      >
                        <IoEyeOutline />
                      </SuiButton>
                    </Tooltip>)}
                  {laudoHistEditPaciente && (
                    <Tooltip title="Editar paciente" placement="top">
                      <SuiButton
                        variant="gradient"
                        buttonColor="info"
                        onClick={() => {
                          localStorage.setItem("goBackStatus", "1");
                          history.push(`/auxiliares/paciente/editar/${item.id_paciente}/${item.uuid}`);
                        }}
                        style={{ marginLeft: '3px' }}
                      >
                        <Icon>edit</Icon>
                      </SuiButton>

                    </Tooltip>)}

                  {laudoHistEdit && (
                    <Tooltip title="Editar laudo" placement="top">
                      <SuiButton
                        variant="gradient"
                        buttonColor="warning"
                        onClick={() => {
                          history.push(`/auxiliares/laudo/editarLaudoFinalizado/${item.id}/${item.uuid}`);
                        }}
                        style={{ marginLeft: '3px' }}
                      >
                        <Icon>edit</Icon>
                      </SuiButton>

                    </Tooltip>

                  )}

                </SuiBox>
              ),
            },
          ]);
        });
        setIsLoading(false);
        if (pesquisa.length === 0 && identificador === undefined) {
          setLinks(res.data.links);
        }
      })
      .catch(() => { });
  };

  const table = {
    /* EDITAR CAMPOS */
    columns: [
      // { Header: "#", accessor: "id", width: "2%" },
      { Header: "Exame Nº", accessor: "identificador", width: "8%" },
      { Header: "Paciente", accessor: "nome", width: "15%" },
      {
        Header: "Carteirinha",
        accessor: "carteirinha",
        Cell: ({ value }) => value || "Não informado",
        width: "10%",
      },
      {
        Header: "Profissional",
        accessor: "nome_profissional",
        Cell: ({ value }) => value || "Não informado",
        width: "15%",
      },
      {
        Header: "Coleta",
        accessor: "data_coleta",
        Cell: ({ value }) => value ? dateEnBr(value) : "Não informado",
        width: "10%",
      },
      { Header: "Convênio", accessor: "nome_convenio", width: "10%" },
      {
        Header: "Liberado",
        accessor: "data_liberado",
        Cell: ({ value }) => value ? dateEnBr(value) : "Não informado",
        width: "10%",
      },

      { Header: "Opções", accessor: "functions", width: "1%" },


    ],


    rows: laudo,
  };

  useEffect(() => {
    get();
  }, [page, atualizaLista, componentUpdate]);


  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <SuiBox p={3} lineHeight={1}>
          <SuiTypography variant="h5" fontWeight="medium">
            Laudo
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            Histórico de Laudos
          </SuiTypography>
        </SuiBox>
        <SuiBox p={3.5} mb={1} width="20rem" ml="auto">
          {laudo.length > 0 && (
            <SuiInput
              placeholder="Busca & Enter"
              value={searchTerm}
              onChange={(event) => {
                setSearchTerm(event.target.value);
                if (event.target.value === "") {
                  setPesquisa([]);
                  setPage('1');
                  setAtualizaLista(atualizaLista + 1);
                }
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSearch();
                }
              }}
            />
          )}
        </SuiBox>
        {/* eslint-disable-next-line no-nested-ternary */}
        {laudo.length > 0 ? (
          <>
            <DataTable table={table} />
            <Paginacao links={links} page={page} setPage={handlePageChange} />
          </>
        ) : (
          <SuiBox p={3} lineHeight={1}>
            <small>Nenhum registro encontrado.</small>
          </SuiBox>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(Laudo);
