export const getToken = () => sessionStorage.getItem("token");

export const removeToken = () => {
    sessionStorage.removeItem("token");
};

export const setToken = (val) => {
    sessionStorage.setItem("token", val);
};

export const setFilial = (val) => {
    sessionStorage.setItem("idFilial", val);
};

export const setTheme = (val) => {
    sessionStorage.setItem("theme", val);
};