import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiEditor from "components/SuiEditor";
import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";
import { BiEditAlt, BiPlusCircle } from "react-icons/all";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import Tooltip from "@mui/material/Tooltip";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BounceLoader } from "react-spinners";
import { Redirect, withRouter } from "react-router-dom";
import swal from "@sweetalert/with-react";
import ProcedimentoSelect from "../../../../components/procedimento-select";

function CadastroProcedimentoEntradaExame(props) {
  const [idEntradaExame, setIdEntradaExame] = useState();
  const [idProcedimento, setIdProcedimento] = useState();
  const [procedimento, setProcedimento] = useState();
  const [quantidade, setQuantidade] = useState();
  const [identificador, setIdentificador] = useState('');
  const [nomePaciente, setNomePaciente] = useState('');
  const idExame = props.match.params.id;

  const getEntradaExame = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/entradaExame/${props.match.params.id}`, config)
      .then((res) => {
        setIdentificador(res.data.entrada_exame.identificador);
        setNomePaciente(res.data.entrada_exame.nome);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
    // setIsLoading(false);
  };


  const procedimentoPush = () => {
    localStorage.setItem("goBackStatus", "1");
    props.history.push("/auxiliares/procedimento/cadastro");
  };

  useEffect(() => {
    setIdEntradaExame(idExame);
  }, []);

  const save = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .post(
        `${window.url}ws/procedimentoEntradaExame`,
        {
          /* MANTER O IDSAAS  */
          idSaas: sessionStorage.getItem("idSaas"),
          id_entrada_exame: idEntradaExame,
          id_procedimento: idProcedimento,
          procedimento,
          quantidade,
        },
        config
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success("Procedimento cadastrado com sucesso");
          props.history.goBack();

        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  useEffect(async () => {
    await getEntradaExame();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={11}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Procedimento da entrada de exames
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  Cadastro de procedimento da entrada de exames
                </SuiTypography>
                {/* <Divider /> */}
                <Grid item xs={12} md={12}>
                  <SuiBox mb={0} ml={0.5} mt={1} lineHeight={0} display="inline-block">
                    <SuiTypography variant="h6" fontWeight="medium">
                      {identificador}
                    </SuiTypography>
                  </SuiBox>
                </Grid>
                <Grid item xs={12} md={12}>
                  <SuiBox mb={0} ml={0.5} mt={1} lineHeight={0} display="inline-block">
                    <SuiTypography variant="h6" fontWeight="medium">
                      {nomePaciente}
                    </SuiTypography>
                  </SuiBox>
                </Grid>
                <Divider />
                {/* <SuiTypography variant="h6" fontWeight="medium">
                  Exame ID: {idEntradaExame}
                </SuiTypography> */}
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Código do Procedimento
                      </SuiTypography>
                    </SuiBox>
                    <ProcedimentoSelect
                      onChangeValue={(e) => {
                        setIdProcedimento(e.value);
                      }}
                    />
                  </Grid>
                  <SuiBox mt={6} mb={1}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={1}>
                        <Tooltip title="Cadastrar procedimento" placement="top">
                          <SuiButton
                            variant="gradient"
                            buttonColor="info"
                            onClick={() => {
                              procedimentoPush();
                            }}
                            style={{ marginLeft: '6px' }}
                          >
                            <BiPlusCircle />
                          </SuiButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </SuiBox>
                  <Grid item xs={12} md={5}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Quantidade
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setQuantidade(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  <SuiBox mr={1}>
                    <SuiButton
                      buttonColor="light"
                      onClick={() => {
                        props.history.goBack();
                      }}
                    >
                      cancelar
                    </SuiButton>
                  </SuiBox>
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    onClick={() => {
                      save(); // ao clicar no botão irá chamar a função POST no banco de dados
                    }}
                  >
                    Salvar
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
          {/* <Grid item xs={12} lg={3}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Dicas
                </SuiTypography>
                <Divider />
              </SuiBox>
            </Card>
          </Grid> */}
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(CadastroProcedimentoEntradaExame);
