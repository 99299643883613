import React, { useState } from "react";
import { cnpj as cnpjValidator } from "cpf-cnpj-validator";
import { toast } from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiEditor from "components/SuiEditor";
import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";
import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Redirect, withRouter } from "react-router-dom";
import swal from "@sweetalert/with-react";
import SuiDatePicker from "../../../../components/SuiDatePicker";
import UfBrasilSelect from "../../../../components/ufBrasil-select";

function CadastroConveio(props) {
  const [nomeConvenio, setNomeConvenio] = useState();
  const [cnpj, setCnpj] = useState();
  const [dataContratoInicio, setDataContratoInicio] = useState();
  const [dataContratoFim, setDataContratoFim] = useState();
  const [fone1, setFone1] = useState();
  const [fone2, setFone2] = useState();
  const [senha, setSenha] = useState();
  const [endereco, setEndereco] = useState();
  const [uf, setUf] = useState('PR');
  const [cidade, setCidade] = useState();
  const [cep, setCep] = useState();
  const [numero, setNumero] = useState();
  const [complemento, setComplemento] = useState();
  const [observacoes, setObservacoes] = useState();
  const [receberEmail, setReceberEmail] = useState(false);
  const [email, setEmail] = useState();
  const handleSetReceberEmail = () => setReceberEmail(!receberEmail);

  const save = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    if (cnpj && cnpj.trim() !== "" && !cnpjValidator.isValid(cnpj)) {
      toast.error("CNPJ inválido");
      return;
    }

    await axios
      .post(
        `${window.url}ws/convenio`,
        {
          /* MANTER O IDSAAS  */
          idSaas: sessionStorage.getItem("idSaas"),
          nome_convenio: nomeConvenio,
          cnpj,
          data_contrato_inicio: dataContratoInicio,
          data_contrato_fim: dataContratoFim,
          fone_1: fone1,
          fone_2: fone2,
          senha,
          endereco,
          uf,
          cidade,
          cep,
          complemento,
          numero,
          observacoes,
          recebe_email: receberEmail,
          email,
        },
        config
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          swal("Agilie", "Convênio cadastrado com sucesso.", "success");
          if (localStorage.getItem("goBackStatus") === "1") {
            localStorage.setItem("id_convenio", res.data.id);
            localStorage.setItem("convenio", res.data.nome_convenio);
            localStorage.removeItem("goBackStatus");
            props.history.goBack();
          } else if (localStorage.getItem("convenioPacienteStatus") === "1") {
            localStorage.removeItem("convenioPacienteStatus");
            props.history.goBack();
          } else {
            props.history.push("/auxiliares/convenio");
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  const buscarCep = async (numeroCep) => {
    await axios
      .get(`https://viacep.com.br/ws/${numeroCep}/json/`)
      .then((res) => {
        console.log(res.data.erro);
        setEndereco(res.data.logradouro);
        setComplemento(res.data.complemento);
        setCidade(res.data.localidade);
        setUf(res.data.uf);
        if (res.data.erro === true) {
          toast.error("CEP não encontrado!");
        } else {
          toast.success("CEP encontrado!");
        }
      })
      .catch(() => {
        swal("Agilie", "Ops! Verifique a quantidade de números! Precisa ser 8 números! ", "error");
      });
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
         <Grid container spacing={3}>
          <Grid item xs={12} lg={11}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Novo Convênio
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  Cadastro Convênio
                </SuiTypography>
                <Divider />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Nome do convênio
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setNomeConvenio(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        CNPJ do Convênio
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setCnpj(e.target.value);
                      }}
                      onBlur={(e) => {
                        e.preventDefault();
                        if (cnpj && cnpj.trim() !== "" && !cnpjValidator.isValid(cnpj)) {
                          toast.error("CNPJ inválido");
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Data de início do contrato
                      </SuiTypography>
                    </SuiBox>
                    <SuiDatePicker
                      onChangeValue={(evt) => {
                        setDataContratoInicio(evt.toLocaleString("pt-BR"));
                      }}
                      input={{
                        placeholder: "Selecione uma data",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Data de fim do contrato
                      </SuiTypography>
                    </SuiBox>
                    <SuiDatePicker
                      onChangeValue={(evt) => {
                        setDataContratoFim(evt.toLocaleString("pt-BR"));
                      }}
                      input={{
                        placeholder: "Selecione uma data",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Senha
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setSenha(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Fone 1
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setFone1(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Fone 2
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setFone2(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        CEP
                      </SuiTypography>
                      <SuiTypography component="label" variant="caption" fontWeight="regular">
                        (Somente números)
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setCep(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SuiBox mb={0} ml={0.5} lineHeight={4.5} display="inline-block">
                      <SuiButton
                        variant="gradient"
                        buttonColor="success"
                        onClick={() => {
                          buscarCep(cep);
                        }}
                      >
                        buscar cep
                      </SuiButton>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Endereço
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={endereco || ""}
                      onChange={(e) => {
                        setEndereco(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Cidade
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={cidade || ""}
                      onChange={(e) => {
                        setCidade(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Número
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setNumero(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        UF
                      </SuiTypography>
                    </SuiBox>
                    <UfBrasilSelect
                      value={{ value: uf, label: uf }}
                      onChangeValue={(e) => {
                        setUf(e.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Complemento
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={complemento || ""}
                      onChange={(e) => {
                        setComplemento(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Email
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={4} display="inline-block">
                      <Checkbox checked={receberEmail} onChange={handleSetReceberEmail} />
                      <SuiTypography
                        variant="button"
                        fontWeight="bold"
                        onClick={handleSetReceberEmail}
                        customClass="cursor-pointer user-select-none"
                      >
                        &nbsp;&nbsp; Receber Email &nbsp;
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                </Grid>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Observações
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="regular"
                      textColor="text"
                    >
                      Você pode escrever uma observação aqui
                    </SuiTypography>
                  </SuiBox>
                  <SuiEditor value={observacoes || ""} onChange={setObservacoes} />
                </SuiBox>

                {/* <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Descrição da funcao
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="regular"
                      textColor="text"
                    >
                      Você pode descrever o Convênio para ajudar no processo de agendamento
                    </SuiTypography>
                  </SuiBox>
                  <SuiEditor value={descricao || ""} onChange={setDescricao} />
                </SuiBox> */}

                {/*  <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Tabela
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    defaultValue={[{ value: 1, label: "CBHPM" }]}
                    onChange={(e) => {
                      setTabela(e.value);
                    }}
                    options={[
                      { value: 1, label: "CBHPM" },
                      { value: 2, label: "AMB" },
                    ]}
                  />
                </SuiBox> */}

                {/*     <SuiBox>
                  <SuiBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                  >
                    <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Anexar tabela em PDF ou WORD
                      </SuiTypography>
                    </SuiBox>
                    <SuiDropzone options={{ addRemoveLinks: true }} />
                  </SuiBox>
                </SuiBox> */}
                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  <SuiBox mr={1}>
                    <SuiButton
                      buttonColor="light"
                      onClick={() => {
                        if (localStorage.getItem("goBackStatus") === "1") {
                          localStorage.removeItem("goBackStatus");
                          props.history.goBack();
                        } else if (localStorage.getItem("convenioPacienteStatus") === "1") {
                          localStorage.removeItem("convenioPacienteStatus");
                          props.history.goBack();
                        } else {
                          props.history.goBack();
                        }
                      }}
                    >
                      cancelar
                    </SuiButton>
                  </SuiBox>
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    onClick={() => {
                      save(); // ao clicar no botão irá chamar a função POST no banco de dados
                    }}
                  >
                    Salvar
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
               {/* <Grid item xs={12} lg={3}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Dicas
                </SuiTypography>
                <Divider />
              </SuiBox>
            </Card>
          </Grid> */}
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(CadastroConveio);
