import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import App from "App";
import "./globals";

// Soft UI Context Provider
import {SoftUIControllerProvider} from "context";
import {DevSupport} from "@react-buddy/ide-toolbox";
import store from "./store";
import {getToken} from "./utils/HelperFunctions";
import {fetchUserData} from "./store/slices/authThunk";
import {ComponentPreviews, useInitial} from "./dev";

if (getToken()) {
    store.dispatch(fetchUserData());
}

ReactDOM.render(
    <BrowserRouter>
        <SoftUIControllerProvider>
            <ToastContainer autoClose={3000}/>
            <Provider store={store}>
                <DevSupport ComponentPreviews={ComponentPreviews}
                            useInitialHook={useInitial}
                >
                    <App/>
                </DevSupport>
            </Provider>
        </SoftUIControllerProvider>
    </BrowserRouter>,
    document.getElementById("root")
);
