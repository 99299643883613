import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import swal from "@sweetalert/with-react";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiEditor from "components/SuiEditor";
import SuiButton from "components/SuiButton";
import Checkbox from "@mui/material/Checkbox";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { withRouter } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import MaterialTecidoSelect from "../../../../components/materialTecido-select";
import ColoracaoSelect from "../../../../components/coloracao-select";
import SuiInput from "../../../../components/SuiInput";


function DadosMacroscopia(props) {
  const [id, setId] = useState();
  const [registro, setRegistro] = useState([]);


  const [descricao, setDescricao] = useState();
  const [idMaterial, setIdMaterial] = useState();
  const [material, setMaterial] = useState();
  const [idColoracao, setIdColoracao] = useState();
  const [coloracao, setColoracao] = useState();
  const [identificacaoGeral, setIdentificadorGeral] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [fixando, setFixando] = useState(false);
  const [descalcificando, setDescalcificando] = useState(false);
  const [amostragem, setAmostragem] = useState(false);


  const [numerobi, setNumeroBi] = useState();

  const handleSetFixando = () => setFixando(!fixando);
  const handleSetDescalcificando = () => setDescalcificando(!descalcificando);


  useEffect(() => {
    setId(props.match.params.id);
  }, []);

  const save = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .post(
        `${window.url}ws/entradaExame/dados/registro`,
        {
          identificador: identificacaoGeral,
          id_entrada_exame: id,
          id_material_tecido: idMaterial,
          id_coloracao: idColoracao,
          fixando,
          descalcificando,
          descricao,
          amostragem,
          numero_bi: numerobi,
          status:99,
        },
        config
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          swal("Agilie", "Entrada atualizada com sucesso.", "success");
          props.history.goBack();
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  const saveEntradaExames = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .patch(
        `${window.url}ws/entradaExame/${props.match.params.id}`,
        {
          id_coloracao: idColoracao,
          fixando,
          descalcificando,
          descricao,
          amostragem,
          numero_bi: numerobi,
          status:2,
        },
        config
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          swal("Agilie", "Entrada salva com sucesso.", "success");
          props.history.goBack();
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  const saveVerificar = (fixando, descalcificando) => {
    if (fixando === true || fixando === 1) {
      swal("Fixando selecionado", "Não pode Salvar e enviar, apenas salvar.", "info");
    } else if (descalcificando === true || descalcificando === 1) {
      swal("Descalcificando selecionado", "Não pode Salvar e enviar, apenas salvar.", "info");
    } else {
      save();
    }
  };

  const saveApenas = () => {
    saveEntradaExames();
  };

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/entradaExame/${props.match.params.id}`, config)
      .then((res) => {
        setIdentificadorGeral(res.data.entrada_exame.identificador);
        setFixando(res.data.entrada_exame.fixando);
        setDescalcificando(res.data.entrada_exame.descalcificando);
        setAmostragem(res.data.entrada_exame.amostragem);
        setDescricao(res.data.entrada_exame.descricao);
        setNumeroBi(res.data.entrada_exame.numero_bi);
        setId(res.data.entrada_exame.id);
        setRegistro(res.data.entrada_exame);
        setIdMaterial(res.data.entrada_exame.id_material);
        setMaterial(res.data.entrada_exame.nome_material);
        setIdColoracao(res.data.entrada_exame.id_coloracao);
        setColoracao(res.data.entrada_exame.codigo_coloracao);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  useEffect(() => {
    get();
  }, []);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
       <Grid container spacing={2}>
          <Grid item xs={12} lg={11}>
            <Card className="overflow-visible">
              <SuiBox p={2}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Macroscopia
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  Registro de dados de macroscopia
                </SuiTypography>
                <Divider />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <SuiBox lineHeight={0} display="inline-block">
                      <SuiTypography variant="h6" fontWeight="medium">
                        Identificação:
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox mb={0} ml={0.5} mt={0} lineHeight={0} display="inline-block">
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="regular"
                        textColor="text"
                      >
                        {identificacaoGeral}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <SuiBox lineHeight={0} display="inline-block">
                      <SuiTypography variant="h6" fontWeight="medium">
                        Nome do paciente:
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox mb={0} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="regular"
                        textColor="text"
                      >
                        {registro.nome}
                      </SuiTypography>
                    </SuiBox>
                    <Divider />
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <SuiBox display="inline-block">
                      <SuiTypography fontWeight="bold">{registro.nome_convenio}</SuiTypography>
                    </SuiBox>
                  </Grid> */}
                  <Grid item xs={12} md={12} lg={12} container spacing={2}>
                    <Grid item xs={8} md={6} lg={4} xl={6}>
                      <SuiBox mb={0} ml={0.5} lineHeight={1} display="inline-block">
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Material biológico
                        </SuiTypography>
                      </SuiBox>
                      <MaterialTecidoSelect
                        value={{ value: idMaterial, label: material }}
                        onChangeValue={(e) => {
                          setIdMaterial(e.value);
                          setMaterial(e.label);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} xl={6}>
                      <SuiBox display="inline-block">
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Número Blocos
                        </SuiTypography>
                      </SuiBox>
                      <SuiInput
                        value={numerobi || ""}
                        onChange={(e) => {
                          setNumeroBi(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={8} md={6} lg={4} xl={6}>
                      <SuiBox mb={0} ml={0.5} lineHeight={1} display="inline-block">
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Coloração
                        </SuiTypography>
                      </SuiBox>
                      <ColoracaoSelect
                        value={{ value: idColoracao , label: coloracao || 'Selecione'}}
                        onChangeValue={(e) => {
                          setIdColoracao(e.value);
                          setColoracao(e.label);
                        }}
                      />
                    </Grid>

                  <Grid item xs={12} md={2}>
                    <SuiBox mb={0} ml={2} lineHeight={4.5}>
                      <Checkbox checked={fixando} onChange={handleSetFixando} />
                      <SuiTypography
                        variant="button"
                        fontWeight="bold" /* regular */
                        onClick={handleSetFixando}
                        customClass="cursor-pointer user-select-none"
                      >
                        Fixando
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <SuiBox mb={1} ml={0.5} lineHeight={4.5}>
                      <Checkbox checked={descalcificando} onChange={handleSetDescalcificando} />
                      <SuiTypography
                        variant="button"
                        fontWeight="bold" /* regular */
                        onClick={handleSetDescalcificando}
                        customClass="cursor-pointer user-select-none"
                      >
                        Descalcificando
                      </SuiTypography>
                    </SuiBox>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <SuiBox mb={1} ml={0.5} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Amostragem
                      </SuiTypography>
                    </SuiBox>
                    <SuiEditor
                      value={amostragem || ""}
                      onChange={setAmostragem}
                      style={{ height: "100px" }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} marginTop={5}>
                    <SuiBox mb={1} ml={0.5} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Observação - Descrição adicional
                      </SuiTypography>
                    </SuiBox>
                    <SuiEditor
                      value={descricao || ""}
                      onChange={setDescricao}
                      style={{ height: "120px" }}
                    />
                  </Grid>
                </Grid>
              </SuiBox>
              <SuiBox display="flex" justifyContent="flex-first" p={2} mt={5}>
                <SuiBox p={1}>
                  <SuiButton
                    buttonColor="light"
                    onClick={() => {
                      props.history.goBack();
                    }}
                  >
                    cancelar
                  </SuiButton>
                </SuiBox>
                <SuiBox p={1}>
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    onClick={() => {
                      saveApenas();
                    }}
                  >
                    Apenas salvar
                  </SuiButton>
                </SuiBox>
                <SuiBox p={1}>
                  <SuiButton
                    variant="gradient"
                    buttonColor="success"
                    onClick={() => {
                      saveVerificar(fixando, descalcificando);
                    }}
                  >
                    Salvar e enviar
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
               {/* <Grid item xs={12} lg={3}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Dicas
                </SuiTypography>
                <Divider />
              </SuiBox>
            </Card>
          </Grid> */}
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(DadosMacroscopia);
