import React, {useEffect, useState} from "react";
import {toast} from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import swal from "@sweetalert/with-react";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {withRouter} from "react-router-dom";
import {BounceLoader} from "react-spinners";
import ProcedimentoSelect from "../../../../components/procedimento-select";
import ConvenioSelect from "../../../../components/convenio-select";
import PacienteSelect from "../../../../components/paciente-select";
import OrigemExameSelect from "../../../../components/origemExame-select";
import ProfissionalSelect from "../../../../components/profissional-select";
import SuiDatePicker from "../../../../components/SuiDatePicker";

function EditarCobrancaExame(props) {
    const [idProcedimento, setIdProcedimento] = useState();
    const [procedimento, setProcedimento] = useState();
    const [idConvenio, setIdConvenio] = useState();
    const [convenio, setConvenio] = useState();
    const [idPaciente, setIdPaciente] = useState();
    const [paciente, setPaciente] = useState();
    const [idOrigemExame, setIdOrigemExame] = useState();
    const [origemExame, setOrigemExame] = useState();
    const [idProfissional, setIdProfissional] = useState();
    const [profissional, setProfissional] = useState();
    const [registrosEncontrados, setRegistrosEncontrados] = useState();
    const [dataIncial, setDataInicial] = useState();
    const [dataFinal, setDataFinal] = useState();
    const [valor, setValor] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const save = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .put(
                `${window.url}ws/cobrancaExame/${props.match.params.id}`,
                {
                    idSaas: sessionStorage.getItem("idSaas"),
                    id_procedimento: idProcedimento,
                    id_convenio: idConvenio,
                    id_paciente: idPaciente,
                    id_origem_exame: idOrigemExame,
                    id_profissional: idProfissional,
                    registros_encontrados: registrosEncontrados,
                    data_inicial: dataIncial,
                    data_final: dataFinal,
                    valor,
                },
                config
            )
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    swal("Agilie", "Cobrança Exame atualizada com sucesso.", "success");
                    props.history.push("/auxiliares/cobrancaExame");
                }
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    const get = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .get(`${window.url}ws/cobrancaExame/${props.match.params.id}`, config)
            .then((res) => {
                const cobrancaExameObj = res.data.cobrancaExame;
                setIdProcedimento(cobrancaExameObj.id_procedimento);
                setProcedimento(cobrancaExameObj.nome_procedimento);
                setIdConvenio(cobrancaExameObj.id_convenio);
                setConvenio(cobrancaExameObj.nome_convenio);
                setIdPaciente(cobrancaExameObj.id_paciente);
                setPaciente(cobrancaExameObj.nome);
                setIdOrigemExame(cobrancaExameObj.id_origem_exame);
                setOrigemExame(cobrancaExameObj.nome_origem);
                setIdProfissional(cobrancaExameObj.id_profissional);
                setProfissional(cobrancaExameObj.nome_profissional);
                setRegistrosEncontrados(cobrancaExameObj.registros_encontrados);
                setDataInicial(cobrancaExameObj.data_inicial);
                setDataFinal(cobrancaExameObj.data_final);
                setValor(cobrancaExameObj.valor);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    useEffect(() => {
        get();
    }, []);

    return isLoading ? (
        <DashboardLayout>
            <DashboardNavbar/>
            <BounceLoader size={30} color="#ccc"/>
        </DashboardLayout>
    ) : (
        <DashboardLayout>
            <DashboardNavbar/>
            <SuiBox mt={3} mb={4}>
                       <Grid container spacing={3}>
                    <Grid item xs={12} lg={11}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Editar Cobrança Exame
                                </SuiTypography>
                                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                                    Os dados alterados não geram efeito sobre entrada de exames anteriores
                                </SuiTypography>
                                <Divider/>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Procedimento
                                            </SuiTypography>
                                        </SuiBox>
                                        <ProcedimentoSelect
                                            value={{value: idProcedimento, label: procedimento}}
                                            onChangeValue={(e) => {
                                                setIdProcedimento(e.value);
                                                setProcedimento(e.label);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Convênio
                                            </SuiTypography>
                                        </SuiBox>
                                        <ConvenioSelect
                                            value={{value: idConvenio, label: convenio}}
                                            onChangeValue={(e) => {
                                                setIdConvenio(e.value);
                                                setConvenio(e.label);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Paciente
                                            </SuiTypography>
                                        </SuiBox>
                                        <PacienteSelect
                                            value={{value: idPaciente, label: paciente}}
                                            onChangeValue={(e) => {
                                                setIdPaciente(e.value);
                                                setPaciente(e.label);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Origem exame
                                            </SuiTypography>
                                        </SuiBox>
                                        <OrigemExameSelect
                                            value={{value: idOrigemExame, label: origemExame}}
                                            onChangeValue={(e) => {
                                                setIdOrigemExame(e.value);
                                                setOrigemExame(e.label);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Profissional
                                            </SuiTypography>
                                        </SuiBox>
                                        <ProfissionalSelect
                                            value={{value: idProfissional, label: profissional}}
                                            onChangeValue={(e) => {
                                                setIdProfissional(e.value);
                                                setProfissional(e.label);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Valor de Cobrança Exame
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={valor || ""}
                                            onChange={(e) => {
                                                setValor(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Data inicial
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            valueDefault={dataIncial}
                                            onChangeValue={(evt) => {
                                                setDataInicial(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Data final
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            valueDefault={dataFinal}
                                            onChangeValue={(evt) => {
                                                setDataFinal(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                                    <SuiBox mr={1}>
                                        <SuiButton
                                            buttonColor="light"
                                            onClick={() => {
                                                props.history.goBack();
                                            }}
                                        >
                                            cancelar
                                        </SuiButton>
                                    </SuiBox>
                                    <SuiButton
                                        variant="gradient"
                                        buttonColor="info"
                                        onClick={() => {
                                            save(); // ao clicar no botão irá chamar a função POST no banco de dados
                                        }}
                                    >
                                        Salvar
                                    </SuiButton>
                                </SuiBox>
                            </SuiBox>
                        </Card>
                    </Grid>
                    {/* <Grid item xs={12} lg={3}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Dicas
                                </SuiTypography>
                                <Divider/>
                            </SuiBox>
                        </Card>
                    </Grid> */}
                </Grid>
            </SuiBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default withRouter(EditarCobrancaExame);
