// eslint-disable-next-line import/prefer-default-export
import moment from "moment";

export function functionGenero(data) {
  switch (data) {
    case 1:
      return "Feminino";
    case 2:
      return "Masculino";
    default:
      return "Não informado";
  }
}

export function contatoPreferencial(data) {
  switch (data) {
    case 0:
      return "Não";
    case 1:
      return "Sim";
    default:
      return "Não informado";
  }
}

export function prioridade(data) {
  switch (data) {
    case 0:
      return "Não";
    case 1:
      return "Sim";
    default:
      return "Não informado";
  }
}

export function fixando(data) {
  switch (data) {
    case 0:
      return "Não";
    case 1:
      return "Sim";
    default:
      return "Não";
  }
}

export function retorno(data) {
  switch (data) {
    case 0:
      return "Não";
    case 1:
      return "Sim";
    default:
      return "Não";
  }
}

export function descalcificando(data) {
  switch (data) {
    case 0:
      return "Não";
    case 1:
      return "Sim";
    default:
      return "Não";
  }
}

export function deveGuia(data) {
  switch (data) {
    case 0:
      return "Não";
    case 1:
      return "Sim";
    default:
      return "Não informado";
  }
}

export function etapaExame(data) {
  switch (data) {
    case 1:
      return "Entrada de exame";
    case 2:
      return "Macroscopia";
    case 99:
      return "Histotécnico";
    case 4:
      return "Diagnóstico";
    case 6:
      return "Digitação";
    case 7:
      return "Correção e Liberação";
    case 8:
      return "Histórico de laudos";
    default:
      return "Etapa desconhecida";
  }
}

export function functionPeriodoAtendimento(data) {
  switch (data) {
    case 1:
      return "Manhã";
    case 2:
      return "Tarde";
    case 3:
      return "Noite";
    default:
      return "Qualquer horário";
  }
}

export function portadorDeficiencia(data) {
  switch (data) {
    case 0:
      return "Não";
    case 1:
      return "Sim";
    default:
      return "Não informado";
  }
}

export function principal(data) {
  switch (data) {
    case 0:
      return "Não";
    case 1:
      return "Sim";
    default:
      return "Não informado";
  }
}

export function tipoPagamentos(data) {
  switch (data) {
    case 1:
      return "PIX";
    case 2:
      return "TED";
    case 3:
      return "DOC";
    case 4:
      return "DINHEIRO";
    case 5:
      return "CONVÊNIO";
    case 6:
      return "Outros";
    default:
      return "Outros";
  }
}

export function statusConta(data) {
  switch (data) {
    case 1:
      return "Em aberto";
    case 2:
      return "Paga";
    case 3:
      return "Em atraso";
    case 4:
      return "Cancelada";
    default:
      return "Ativo";
  }
}

export function status(data) {
  switch (data) {
    case 0:
      return "Inativo";
    default:
      return "Ativo";
  }
}

export function tipoInstituicao(data) {
  switch (data) {
    case 1:
      return "Clínica";
    case 2:
      return "Consultório";
    case 3:
      return "Hospital";
    default:
      return "Outros";
  }
}

export function dateTimeEnBrComplet(data) {
  if (data === undefined) return " - ";

  let check = moment(data);
  check = check.isValid() ? check.format("DD/MM/YYYY HH:mm:ss") : " - ";
  return check;
}

export function dateTimeEnBr(data) {
  if (data === undefined) return " - ";

  let check = moment(data);
  check = check.isValid() ? check.format("DD/MM/YYYY HH:mm:ss") : " - ";
  return check;
}

export function statusBase(number) {
  const variable = parseInt(number, 10);
  switch (variable) {
    case 0:
      return "Não";
    case 1:
      return "Sim";
    default:
      return "Não informado";
  }
}

export const calcBusinessDays = (startDate, endDate) => {
  const day = moment(startDate);
  let businessDays = 0;
  while (day.isSameOrBefore(endDate, "day")) {
    if (day.day() !== 0 && day.day() !== 6) {
      // eslint-disable-next-line no-plusplus
      businessDays++;
    }
    day.add(1, "d");
  }
  return businessDays;
};

export function agora() {
  return moment(new Date()).format("DD/MM/YYYY HH:mm");
}

export function dateEnBr(data) {
  return moment(data).format("DD/MM/YYYY");
}

export function removeHTML(html) {
  if (html?.length > 0) {
    return html.replace(/<[^>]+>/g, "");
  }
  return "...";
}
