import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import axios from "axios";
import DataTable from "examples/Tables/DataTable";

import { useHistory, withRouter } from "react-router-dom";

// Soft UI Dashboard PRO React example components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BounceLoader } from "react-spinners";
import Swal from "sweetalert2";
import swal from "@sweetalert/with-react";
import withReactContent from "sweetalert2-react-content";
import Grid from "@mui/material/Grid";
import SuiButton from "../../../../components/SuiButton";
import Paginacao from "../../../../components/paginacao";
import { fixando, descalcificando } from "../../../../utils";

function MaterialBiologicoEntradaExame(props) {
  const [materialBioEntradaExame, setMaterialBioEntradaExame] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [idEntradaExame, setIdEntradaExame] = useState(true);
  const [identificador, setIdentificador] = useState('');
  const [nomePaciente, setNomePaciente] = useState('');
  const [links, setLinks] = useState([]);
  const [page, setPage] = useState("1");
  const history = useHistory();
  const [idRemove, setIdRemove] = useState(null);
  const [dados, setDados] = useState([]);
  const uuid = props.match.params.id;

  const getEntradaExame = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/entradaExame/${props.match.params.id}`, config)
      .then((res) => {
        setIdentificador(res.data.entrada_exame.identificador);
        setNomePaciente(res.data.entrada_exame.nome);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
    // setIsLoading(false);
  };


  const destroy = async (id) => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };
    await axios
      .delete(`${window.url}ws/materialBioEntradaExame/${id}`, config)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success("Procedimento excluído com sucesso");
          setPage("1");
          setIdRemove(id);
        }
      })
      .catch(() => {
        swal("Agilie", "Ops! Houve um erro!", "error");
      });
  };

  const excluir = async (id, itemCount) => {
    if (itemCount <= 1) {
      swal("Atenção", "É obrigatório ter pelo menos um item!", "warning");
      return;
    }
    const MySwal = withReactContent(Swal);
    const self = this;

    MySwal.fire({
      title: "Excluir cadastro",
      text: `Tem certeza que deseja excluir?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        destroy(id);
      }
    });
  };

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    setIsLoading(true);

    await axios
      .get(`${window.url}ws/materialBioEntradaExame/${props.match.params.id}/list?page=${page}`, config)
      .then((res) => {
        const itemCount = res.data.total;
        setDados(res.data.data);
        setMaterialBioEntradaExame([]);
        setIsLoading(false);
        setIdEntradaExame(props.match.params.id);
        // eslint-disable-next-line array-callback-return
        res.data.data.map((item) => {
          // eslint-disable-next-line no-shadow
          setMaterialBioEntradaExame((materialBioEntradaExame) => [
            ...materialBioEntradaExame,
            /* VARIÁVEIS DO BANCO DE DADOS */
            {
              id: item.id,
              id_entrada_exame: item.id_entrada_exame,
              id_material_tecido: item.id_material_tecido,
              nome_material: item.nome_material,
              material_biologico: item.material_biologico,
              id_coloracao: item.id_coloracao,
              id_item: item.id_item,
              coloracao: item.coloracao,
              codigo_coloracao: item.codigo_coloracao,
              numero_bi: item.numero_bi,
              fixando: fixando(item.fixando),
              descalcificando: descalcificando(item.descalcificando),
              amostragem: item.amostragem,
              descricao: item.descricao,
              identificador: item.identificador,
              /* FUNÇÃO DE EDIÇÃO */
              functions: (
                <SuiBox ml={-1.325}>
                  {/* <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      history.push(`/auxiliares/materialBiologicoEntradaExame/editar/${item.id}/${item.uuid}`);
                    }}
                  >
                    <Icon>edit</Icon>
                  </SuiButton> */}
                  <Tooltip title="Editar" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={() => {
                        history.push(`/auxiliares/materialBiologicoEntradaExame/editar/${item.id}/${item.uuid}`);
                      }}

                    >
                      <Icon>edit</Icon>
                    </SuiButton>
                  </Tooltip>
                  <Tooltip title="Excluir" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={() => {
                        excluir(item.id, itemCount);
                        
                      }}
                      style={{ marginLeft: '3px' }}
                    >
                      <Icon>delete</Icon>
                    </SuiButton>
                  </Tooltip>
                </SuiBox>
              ),
            },
          ]);
        });
        setIsLoading(false);
        setLinks(res.data.links);
      })
      .catch(() => { });
  };


  const table = {
    /* EDITAR CAMPOS */
    columns: [
      { Header: "id", accessor: "id_item", width: "3%" },
      // {
      //   Header: "Material",
      //   accessor: "material_biologico",
      //   Cell: ({ row }) => <span dangerouslySetInnerHTML={{ __html: row.values.material_biologico }} />,
      //   width: "10%",
      // },
      {
        Header: "Material",
        accessor: "material_biologico",
        Cell: ({ row }) => {
          const materialBiologico = row.values.material_biologico || '';
          return (
            <span>
              {materialBiologico.length > 35 ? `${materialBiologico.slice(0, 35)}...` : materialBiologico}
            </span>
          );
        },
        width: "10%",
      },
      // {
      //   Header: "Coloracao",
      //   accessor: "coloracao",
      //   Cell: ({ row }) => {
      //     const coloracao = row.values.coloracao || '';
      //     return (
      //       <span 
      //         dangerouslySetInnerHTML={{
      //           __html: coloracao.length > 50 ? `${coloracao.slice(0, 35)}...` : coloracao
      //         }}
      //       />
      //     );
      //   },
      //   width: "15%",
      // },
      {
        Header: "Coloracao",
        accessor: "coloracao",
        Cell: ({ row }) => {
          const coloracao = row.values.coloracao || '';
          return (
            <span>
              {coloracao.length > 35 ? `${coloracao.slice(0, 35)}...` : coloracao}
            </span>
          );
        },
        width: "15%",
      },

      { Header: "NºBlocos", accessor: "numero_bi", width: "3%" },
      { Header: "Fixando", accessor: "fixando", width: "5%" },
      { Header: "Descalcificando", accessor: "descalcificando", width: "5%" },
      // {
      //   Header: "Amostragem",
      //   accessor: "amostragem",
      //   Cell: ({ row }) => <span dangerouslySetInnerHTML={{ __html: row.values.amostragem }} />,
      //   width: "10%",
      // },
      {
        Header: "Amostragem",
        accessor: "amostragem",
        Cell: ({ row }) => {
          const amostragem = row.values.amostragem || '';
          return (
            <span>
              {amostragem.length > 35 ? `${amostragem.slice(0, 35)}...` : amostragem}
            </span>
          );
        },
        width: "10%",
      },
      { Header: "Opções", accessor: "functions", width: "1%" },
    ],

    rows: materialBioEntradaExame,
  };

 

  useEffect(() => {
    get();
  }, [page, idRemove]);

  useEffect(async () => {
    await getEntradaExame();
  }, []);
  
  const save = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };
  
    try {
      const requests = dados.map(async (item) => {
        const data = {
          material: {
            id_material_biologico_ee: item.id,
            id_entrada_exame: item.id_entrada_exame,
            id_item: item.id_item,
            id_material_tecido: item.id_material_tecido, 
            material_biologico: item.material_biologico,
            id_coloracao: item.id_coloracao,
            coloracao: item.coloracao,
            identificador: item.identificador,
            descricao: item.descricao,
            fixando: item.fixando,
            descalcificando: item.descalcificando,
            status: 99,
            amostragem: item.amostragem,
            numero_bi: item.numero_bi,
            complemento_material: item.complemento_material,
          },
        };
  
        const response = await axios.post(
          `${window.url}ws/entradaExame/dados/registro`,
          data,
          config
        );
  
        if (response.status === 200 || response.status === 201) {
          toast.success(`Material biológico ID: ${item.id} enviado com sucesso`);
          
        } else {
          toast.error(`Erro ao enviar o material biológico ID: ${item.id}`);
        }
        return response; 
      });
  
      await Promise.all(requests);
      props.history.goBack();
    } catch (error) {
      toast.error("Erro ao enviar o material biológico");
    }
  };



  const saveVerificar = () => {
    if (dados.length === 0) {
      swal("Nenhum cadastro para enviar", "Não existe nenhum item para ser enviado! ", "info");
    } else {
      const hasFixando = dados.some((item) => item.fixando === true || item.fixando === 1);
      const hasDescalcificando = dados.some((item) => item.descalcificando === true || item.descalcificando === 1);
      if (hasFixando) {
        swal("Fixando selecionado", "Não é possível enviar para o histotécnico.", "info");
      } else if (hasDescalcificando) {
        swal("Descalcificando selecionado", "Não é possível enviar para o histotécnico.", "info");
      } else {

        const MySwal = withReactContent(Swal);
        const self = this;
    
        MySwal.fire({
          title: "Enviar para histotécnico",
          text: `Tem certeza que deseja enviar para o histotécnico?`,
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Sim, tenho!",
          cancelButtonText: "Não!",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            save();
          }
        });
        
        
      }
    }
  };
  

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <SuiBox p={3} lineHeight={1}>
          <SuiTypography variant="h5" fontWeight="medium">
            Material biológico da entrada de exames
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            Lista de materiais biológico
          </SuiTypography>
          <Grid item xs={12} md={12}>
            <SuiBox mb={0} ml={0.5} mt={1} lineHeight={0} display="inline-block">
              <SuiTypography variant="h6" fontWeight="medium">
                {identificador}
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid item xs={12} md={12}>
            <SuiBox mb={0} ml={0.5} mt={1} lineHeight={0} display="inline-block">
              <SuiTypography variant="h6" fontWeight="medium">
                {nomePaciente}
              </SuiTypography>
            </SuiBox>
          </Grid>
          <SuiBox
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            mt={1}
            lineHeight={1}
            width="100%"
            position="absolute"
            top="1rem"
            right="1rem"
          >
            <SuiButton
              variant="gradient"
              buttonColor="light"
              size="medium"
              onClick={() => {
                history.goBack();
              }}
            >
              <SuiTypography variant="h6" fontWeight="medium">
                Voltar
              </SuiTypography>
            </SuiButton>
            <SuiBox ml={1}>
              <SuiButton
                buttonColor="info"
                size="medium"
                onClick={() => {
                  history.push(`/auxiliares/entradaExame/material/cadastro/${idEntradaExame}/${uuid}`);
                }}
              >
                <SuiTypography variant="h6" fontWeight="medium">
                  Novo
                </SuiTypography>
              </SuiButton>
              
            </SuiBox>
            <SuiBox ml={1}>
              <SuiButton
                buttonColor="success"
                size="medium"
                onClick={() => {
                  saveVerificar();
                }}
              >
                <SuiTypography variant="h6" fontWeight="medium">
                  Enviar
                </SuiTypography>
              </SuiButton>
              
            </SuiBox>
          </SuiBox>
        </SuiBox>
        {/* eslint-disable-next-line no-nested-ternary */}
        {materialBioEntradaExame.length > 0 ? (
          <>
            <DataTable table={table} canSearch />
            <Paginacao links={links} page={page} setPage={setPage} />
          </>
        ) : (
          <SuiBox p={3} lineHeight={1}>
            <small>Nenhum registro encontrado.</small>
          </SuiBox>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(MaterialBiologicoEntradaExame);