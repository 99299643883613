import React, { useState, useEffect, useRef, useCallback } from "react";
import mime from 'mime-types';
import axios from "axios";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Backdrop from "@mui/material/Backdrop";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { IoEyeOutline } from "react-icons/io5";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiBox from "components/SuiBox";
import DataTable from "examples/Tables/DataTable";
import { toast } from "react-toastify";
import Dropzone from "dropzone";
import PropTypes from "prop-types";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import swal from "@sweetalert/with-react";
import { BounceLoader } from "react-spinners";
import SuiButton from "../../../../components/SuiButton";
import Paginacao from "../../../../components/paginacao";
import styles from "./styles";

function SuiDropzone({ options, idEntradaExame }) {
  const [uploaded, setUploaded] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [imagens, setImagens] = useState([]);
  const dropzoneRef = useRef();
  const classes = styles();
  const action = `${window.url}ws/laudoImagens`;
  const [links, setLinks] = useState([]);
  const [page, setPage] = useState("1");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [atualizar, setAtualizar] = useState(false);
  const [atualizarDelete, setAtualizarDelete] = useState(0);
  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [nomeImagem, setNomeImagem] = useState("");
  const [itemCount, setItemCount] = useState(0);
  const [editarID, setEditarID] = useState("");


  const handleFormOpen = () => {
    setOpenForm(true);
  };


  const handleFormClose = () => {
    setOpenForm(false);
  };


  const handleDelete = useCallback(async (id) => {
    
    try {
      const response = await axios.delete(`${action}/${id}`);
      if (response.status === 200) {
        setItemCount((prevCount) => prevCount - 1);
        setAtualizarDelete(id);
        window.scrollBy(0, -500);
        toast.success("Imagem deletada com sucesso!");

      }
    } catch (error) {
      console.error(error);
      toast.error("Ops! Houve um erro ao deletar a imagem.");
    }
  }, [action]);


  const excluir = useCallback(async (id) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: "Excluir imagem",
      text: `Tem certeza que deseja excluir?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
      }
    });
  }, [handleDelete]);


  const handleView = useCallback(async (id) => {
    try {
      const response = await axios.get(`${action}/visualizar/${id}`);
      setImageUrl(response.data.image);
      setOpen(true);
    } catch (error) {
      console.error(error);
      toast.error("Ops! Houve um erro ao visualizar a imagem.");
    }
  }, [action]);


  const handleUpdate = async (id) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      };
      await axios.put(
        `${window.url}ws/laudoImagens/${id}`,
        {
          nome_imagem: nomeImagem,
        },
        config
      );
      setAtualizar(!atualizar);
      setOpenForm(false);
      window.scrollBy(0, -500);
      toast.success("Cadastro atualizado com sucesso!");
    } catch (error) {
      console.error(error);
      toast.error("Ops! Houve um erro ao atualizar o nome da imagem.");
    }
  };


  const handleEdit = useCallback(async (id) => {
    try {
      setEditarID(id);
      setIsLoadingEdit(true);
      const response = await axios.get(`${action}/show/${id}`);
      setNomeImagem(response.data.laudoImagens.nome_imagem);
      setIsLoadingEdit(false);
      handleFormOpen();
    } catch (error) {
      console.error(error);
      toast.error("Ops! Houve um erro ao editar a imagem.");
    }
  }, [action]);

 
  const handleClose = () => {
    setOpen(false);
  };


  const getImagens = useCallback(async () => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      };

      setIsLoading(true);

      const res = await axios.get(
        `${window.url}ws/laudoImagens/${idEntradaExame}/list?page=${page}`,
        config
      );

      const itensCount = res.data.total;
      setItemCount(itensCount);

      const novasImagens = res.data.data.map((item) => ({
        id: item.id,
        nome_imagem: item.nome_imagem,
        created_at: item.created_at,
        functions: (
          <SuiBox display="flex" alignItems="center">
            <SuiTypography sx={{ cursor: "pointer", lineHeight: 0 }}>
              <Tooltip title="Visualizar imagem" placement="top">
                <Icon
                  variant="gradient"
                  color="secondary"
                  onClick={() => handleView(item.id)}
                >
                  visibility
                </Icon>
              </Tooltip>
            </SuiTypography>
            <SuiBox mx={2}>
              <SuiTypography sx={{ cursor: "pointer", lineHeight: 0 }}>
                <Tooltip title="Editar nome da imagem" placement="left">
                  <Icon
                    variant="gradient"
                    color="secondary"
                    onClick={() => handleEdit(item.id)}
                  >
                    edit
                  </Icon>
                </Tooltip>
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <SuiTypography sx={{ cursor: "pointer", lineHeight: 0 }}>
                <Tooltip title="Deletar imagem" placement="left">
                  <Icon
                    variant="gradient"
                    color="secondary"
                    onClick={() => excluir(item.id)}
                  >
                    delete
                  </Icon>
                </Tooltip>
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        ),
      }));

      setImagens(novasImagens);
      setIsLoading(false);
      setLinks(res.data.links);
      window.scrollBy(0, 100);
    } catch (error) {
      console.error(error);
      toast.error("Ops! Houve um erro ao obter as imagens.");
    }
  }, [idEntradaExame, page, excluir, handleView]);


  const createDropzone = useCallback(() => {
    const dropzoneOptions = {
      ...options,
      url: action,
      params: { id_entrada_exame: idEntradaExame },
      autoProcessQueue: false,
    };

    const dropzone = new Dropzone(dropzoneRef.current, dropzoneOptions);

    dropzone.on("addedfile", async (file) => {
      if (itemCount >= 3) {
        dropzone.removeFile(file);
        swal("Limite atingido", "Máximo três(3) imagens", "info");
        return;
      }

      const allowedImageTypes = ['image/bmp', 'image/jpeg', 'image/jpg', 'image/png'];
      const mimeType = mime.lookup(file.name);

      if (!mimeType || !mimeType.startsWith('image/')) {
        dropzone.removeFile(file);
        swal("Agilie", "Arquivo inválido! Envie apenas imagens.", "error");
        return;
      }

      if (!allowedImageTypes.includes(mimeType)) {
        dropzone.removeFile(file);
        swal("Agilie", "Extensão de imagem inválida! Apenas .bmp, .jpg, .jpeg e .png são permitidos.", "error");
        return;
      }

      const img = file.previewElement.querySelector("img");

      img.onload = async () => {
        if (file.size > 2000000) {
          dropzone.removeFile(file);
          swal("Agilie", "A imagem excede o tamanho permitido. Envie uma imagem de no máximo 2MB.", "error");
          return;
        }

        if (dropzone.getQueuedFiles().length > 0) {
          dropzone.on("sending", (file, xhr, formData) => {
            formData.append("nome_imagem", file.name);
          });

          dropzone.processQueue();
        }
      };
    });

    dropzone.on("success", () => {
      dropzoneRef.current.dropzone.removeAllFiles();
      setUploaded(true);
      toast.success("Imagem enviada com sucesso!");
      setAtualizar(!atualizar);
    });

    dropzone.on("error", (file, errorMessage, xhr) => {
      dropzoneRef.current.dropzone.removeAllFiles();
      setAtualizar(!atualizar);
      if (xhr && xhr.response) {
        const errorResponse = JSON.parse(xhr.response);
        const { message, errors } = errorResponse;

        if (message) {
          toast.error(message);
        }

        if (errors && Array.isArray(errors)) {
          for (let i = 0; i < errors.length; i += 1) {
            toast.error(errors[i]);
          }
        }
      } else {
        window.scrollBy(0, -500);
        setAtualizar(!atualizar);
        toast.error(`Erro ao enviar imagem, tente novamente`);
      }
    });

    return dropzone;
  }, [options, idEntradaExame, action, getImagens, itemCount]);


  const removeDropzone = useCallback(() => {
    if (Dropzone.instances.length > 0) {
      Dropzone.instances.forEach((dz) => dz.destroy());
    }
  }, []);


  useEffect(() => {
    Dropzone.autoDiscover = false;
    const dropzone = createDropzone();
    return () => {
      removeDropzone();
      dropzone.off("success");
    };
  }, [options, idEntradaExame, createDropzone, removeDropzone]);


  useEffect(() => {
    getImagens();
  }, [page, atualizar,atualizarDelete]);



  const table = {
    columns: [
      { Header: "ID", accessor: "id", width: "2%" },
      { Header: "Nome da imagem", accessor: "nome_imagem", width: "5%" },
      { Header: "Data de criação", accessor: "created_at", width: "15%" },
      { Header: "Opções", accessor: "functions", width: "1%" },
    ],
    rows: imagens,
  };


  function renderContent() {
    if (imagens.length > 0) {
      return (
        <Grid item xs={12} lg={12}>
          <DataTable table={table} canSearch />
          <Paginacao links={links} page={page} setPage={setPage} />
        </Grid>
      );
    }
    return (
      <SuiBox
        p={10}
        lineHeight={10}
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        width="100%"
        height="100%"
      >
        <small>Nenhuma imagem encontrada.</small>
      </SuiBox>
    );
  }


  return (
    <>
      <Backdrop open={isLoadingEdit} style={{ zIndex: 1500, color: "#ccc" }}>
        <BounceLoader size={30} color="#ccc" />
      </Backdrop>

      <Dialog
        open={openForm}
        onClose={handleFormClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          <SuiTypography variant="h5" fontWeight="medium" component="div" ml={1}>
            Imagem
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" textColor="text" component="div" ml={1}>
            Editar nome da imagem
          </SuiTypography>
        </DialogTitle>

        <DialogContent style={{ height: "100%" }}>
          <SuiBox mt={1} mb={1}>
            <SuiBox p={1}>
              <Grid item xs={12} md={12} >
                <SuiBox mb={0} ml={0.5} lineHeight={1} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    Nome da imagem
                  </SuiTypography>
                </SuiBox>
                <SuiInput
                  value={nomeImagem || ""}
                  onChange={(e) => {
                    setNomeImagem(e.target.value);
                  }}
                />
              </Grid>
            </SuiBox>
          </SuiBox>
        </DialogContent>

        <DialogActions>
          <SuiBox display="flex" justifyContent="flex-end" p={2} mt={1}>
            <SuiBox mr={1}>
              <SuiButton
                buttonColor="light"
                onClick={() => {
                  handleFormClose();
                }}
              >
                cancelar
              </SuiButton>
            </SuiBox>
            <SuiButton
              variant="gradient"
              buttonColor="info"
              onClick={() => {
                handleUpdate(editarID);
              }}
            >
              Salvar
            </SuiButton>
          </SuiBox>
        </DialogActions>
      </Dialog>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <SuiBox p={2} lineHeight={1}>
            <form
              action={action}
              className={classes.dropzone}
              id="dropzone"
              ref={dropzoneRef}
            >
              <div className="dz-message">
                <IoEyeOutline size="40px" />
                <h4>Clique <span>AQUI</span> para fazer upload de imagens de até 2MB de tamanho.</h4>
                <small>Envie imagens apenas com as extensões: .bmp, .png, .jpg, .jpeg.</small>
              </div>
            </form>
          </SuiBox>
        </Grid>
        {isLoading ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: "20vh" }}
          >
            <BounceLoader color="#3A3727" loading={isLoading} size={60} />
          </Grid>
        ) : (
          renderContent()
        )}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            maxHeight: "80%",
            maxWidth: "90%",
          },
        }}
      >
        <DialogContent style={{ padding: 0 }}>
          <img
            src={imageUrl}
            alt="Preview"
            style={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

SuiDropzone.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object.isRequired,
  idEntradaExame: PropTypes.number.isRequired,
};
export default SuiDropzone;
