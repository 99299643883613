import React, { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import swal from "@sweetalert/with-react";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiEditor from "components/SuiEditor";
import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Redirect, withRouter } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { BounceLoader } from "react-spinners";

function EditarFilaEmail(props) {
  const [email, setEmail] = useState();
  const [status, setStatus] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const save = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .put(
        `${window.url}ws/filaEmail/${props.match.params.id}`,
        {
          idSaas: sessionStorage.getItem("idSaas"),
          email,
          status,
        },
        config
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          swal("Agilie", "Fila email atualizado com sucesso.", "success");
          props.history.push("/auxiliares/filaEmail");
        }
      })
      .catch((error) => {
        if (error.status === 422 || error.status === 422) {
          swal("Agilie", "Falha na validação dos campos", "error");
          props.history.push("/auxiliares/filaEmail");
        }
      });
  };

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/filaEmail/${props.match.params.id}`, config)
      .then((res) => {
        const filaEmailObj = res.data.filaEmail;
        setEmail(filaEmailObj.email);
        setStatus(filaEmailObj.status);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.status === 422 || error.status === 422) {
          swal("Agilie", "Falha na validação dos campos", "error");
          props.history.push("/auxiliares/filaEmail");
        }
      });
  };

  useEffect(() => {
    get();
  }, []);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
         <Grid container spacing={3}>
          <Grid item xs={12} lg={11}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Editar Fila email
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  Os dados alterados não geram efeito sobre entrada de exames anteriores
                </SuiTypography>
                <Divider />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Email
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={email || ""}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={8}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Status
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={status || ""}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                    />
                  </Grid> */}
                  {/* <Grid item xs={12} md={8}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Nome da Entrada caixa
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={variavelLocal3 || ""}
                      onChange={(e) => {
                        setVariavelLocal3(e.target.value);
                      }}
                    />
                  </Grid> */}
                  {/* <Grid item xs={12} md={8}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Nome da Entrada caixa
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={variavelLocal4 || ""}
                      onChange={(e) => {
                        setVariavelLocal4(e.target.value);
                      }}
                    />
                  </Grid> */}
                  {/* CHECKBOX */}
                  {/* <Grid item xs={12} md={8}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <Checkbox checked={principal} onChange={handleSetPrincipal} />
                      <SuiTypography
                        variant="button"
                        fontWeight="bold"
                        onClick={handleSetPrincipal}
                        customClass="cursor-pointer user-select-none"
                      >
                        &nbsp;&nbsp; Principal &nbsp;
                      </SuiTypography>
                    </SuiBox>
                  </Grid> */}
                  {/* SELECT */}
                  <Grid item xs={12} md={8}>
                    <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Status
                      </SuiTypography>
                    </SuiBox>
                    <SuiSelect
                      defaultValue={[{ value: 0, label: "Selecione uma opção..." }]}
                      onChange={(e) => {
                        setStatus(e.value);
                      }}
                      options={[
                        { value: 1, label: "Opção 1" },
                        { value: 2, label: "Opção 1" },
                      ]}
                    />
                  </Grid>
                </Grid>
                {/* TEXTO */}
                {/*  <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Observação
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="regular"
                      textColor="text"
                    >
                      Você pode escrever uma observação aqui
                    </SuiTypography>
                  </SuiBox>
                  <SuiEditor value={variavelLocal6 || ""} onChange={setVariavelLocal6} />
                </SuiBox> */}
                {/* ANEXAR PDF OU WORD */}
                {/*     <SuiBox>
                  <SuiBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                  >
                    <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Anexar tabela em PDF ou WORD
                      </SuiTypography>
                    </SuiBox>
                    <SuiDropzone options={{ addRemoveLinks: true }} />
                  </SuiBox>
                </SuiBox> */}
                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  <SuiBox mr={1}>
                    <SuiButton
                      buttonColor="light"
                      onClick={() => {
                        props.history.goBack();
                      }}
                    >
                      cancelar
                    </SuiButton>
                  </SuiBox>
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    onClick={() => {
                      save(); // ao clicar no botão irá chamar a função POST no banco de dados
                    }}
                  >
                    Salvar
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
               {/* <Grid item xs={12} lg={3}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Dicas
                </SuiTypography>
                <Divider />
              </SuiBox>
            </Card>
          </Grid> */}
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(EditarFilaEmail);
