import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import axios from "axios";
import DataTable from "examples/Tables/DataTable";

import { useHistory, withRouter } from "react-router-dom";

// Soft UI Dashboard PRO React example components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BounceLoader, ClipLoader } from "react-spinners";
import { Grid } from "@mui/material";
import SuiButton from "../../../../components/SuiButton";
import { contatoPreferencial, dateEnBr, functionPeriodoAtendimento } from "../../../../utils";
import Paginacao from "../../../../components/paginacao";

function ContatoPaciente(props) {
  const [contatoPaciente, setContatoPaciente] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [links, setLinks] = useState([]);
  const [page, setPage] = useState("1");
  const [idPaciente, setIdPaciente] = useState();

  const history = useHistory();

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    setIsLoading(true);

    await axios
      .get(`${window.url}ws/contatoPaciente/${props.match.params.id}/list?page=${page}`, config)
      .then((res) => {
        setContatoPaciente([]);
        // eslint-disable-next-line array-callback-return
        setIdPaciente(props.match.params.id);
        // eslint-disable-next-line array-callback-return
        res.data.data.map((item) => {
          // eslint-disable-next-line no-shadow
          setContatoPaciente((contatoPaciente) => [
            ...contatoPaciente,
            /* VARIÁVEIS DO BANCO DE DADOS */
            {
              id: item.id,
              nome: item.nome,
              tipo: item.tipo,
              descricao: item.descricao,
              periodo_atendimento: functionPeriodoAtendimento(item.periodo_atendimento),
              preferencial: contatoPreferencial(item.preferencial),
              /* FUNÇÃO DE EDIÇÃO */
              functions: (
                <SuiBox ml={-1.325}>
                  {/* <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      history.push(`/auxiliares/contatoPaciente/editar/${item.id}/${item.uuid}`);
                    }}
                  >
                    <Icon>edit</Icon>
                  </SuiButton> */}
                  <Tooltip title="Editar" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={() => {
                        history.push(`/auxiliares/contatoPaciente/editar/${item.id}/${item.uuid}`);
                      }}
                    >
                      <Icon>edit</Icon>
                    </SuiButton>
                  </Tooltip>

                </SuiBox>
              ),
            },
          ]);
        });
        setIsLoading(false);
        setLinks(res.data.links);
      })
      .catch(() => { });
  };

  const table = {
    /* EDITAR CAMPOS */
    columns: [

      // { Header: "#", accessor: "id", width: "2%" },
      { Header: "Nome", accessor: "nome", width: "20%" },
      { Header: "Tipo", accessor: "tipo", width: "15%" },
      {
        Header: "Descrição",
        accessor: "descricao",
        Cell: ({ row }) => <span dangerouslySetInnerHTML={{ __html: row.values.descricao }} />,
      },
      { Header: "Período de atendimento", accessor: "periodo_atendimento", width: "15%" },
      { Header: "Preferêncial", accessor: "preferencial", width: "15%" },
      { Header: "Opções", accessor: "functions", width: "1%" },
    ],

    rows: contatoPaciente,
  };

  useEffect(() => {
    get();
  }, [page]);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <SuiBox p={3} lineHeight={1}>
          <SuiTypography variant="h5" fontWeight="medium">
            Contato Paciente
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            Lista de tipo
          </SuiTypography>
        </SuiBox>
        <Grid item xs={12} md={6}>
          <SuiBox p={3} lineHeight={0}>
            <SuiButton
              ml={10}
              variant="gradient"
              color="info"
              onClick={() => {
                history.push(`/auxiliares/paciente/contatos/cadastro/${idPaciente}/${props.uuid}`);
              }}
            >
              <SuiTypography variant="h6" fontWeight="medium">
                Novo
              </SuiTypography>
            </SuiButton>
          </SuiBox>
        </Grid>
        {/* eslint-disable-next-line no-nested-ternary */}
        {contatoPaciente.length > 0 ? (
          <>
            <DataTable table={table} canSearch />
            <Paginacao links={links} page={page} setPage={setPage} />
          </>
        ) : (
          <SuiBox p={3} lineHeight={1}>
            <small>Nenhum registro encontrado.</small>
          </SuiBox>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(ContatoPaciente);
