import React, {useState} from "react";
import {toast} from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {withRouter} from "react-router-dom";
import swal from "@sweetalert/with-react";
import ProcedimentoSelect from "../../../../components/procedimento-select";
import ConvenioSelect from "../../../../components/convenio-select";
import PacienteSelect from "../../../../components/paciente-select";
import OrigemExameSelect from "../../../../components/origemExame-select";
import ProfissionalSelect from "../../../../components/profissional-select";
import SuiDatePicker from "../../../../components/SuiDatePicker";

function CadastroCobrancaExame(props) {
    const [idProcedimento, setIdProcedimento] = useState();
    const [idConvenio, setIdConvenio] = useState();
    const [idPaciente, setIdPaciente] = useState();
    const [idOrigemExame, setIdOrigemExame] = useState();
    const [idProfissional, setIdProfissional] = useState();
    const [registrosEncontrados, setRegistrosEncontrados] = useState();
    const [dataIncial, setDataInicial] = useState();
    const [dataFinal, setDataFinal] = useState();
    const [valor, setValor] = useState();

    const save = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .post(
                `${window.url}ws/cobrancaExame`,
                {
                    /* MANTER O IDSAAS  */
                    idSaas: sessionStorage.getItem("idSaas"),
                    id_procedimento: idProcedimento,
                    id_convenio: idConvenio,
                    id_paciente: idPaciente,
                    id_origem_exame: idOrigemExame,
                    id_profissional: idProfissional,
                    registros_encontrados: registrosEncontrados,
                    data_inicial: dataIncial,
                    data_final: dataFinal,
                    valor,
                },
                config
            )
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    swal("Agilie", "Cobrança de exame cadastrado com sucesso.", "success");
                    props.history.push("/auxiliares/cobrancaExame");
                }
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SuiBox mt={3} mb={4}>
                       <Grid container spacing={3}>
                    <Grid item xs={12} lg={11}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Cobrança Exame
                                </SuiTypography>
                                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                                    Cadastro de cobrança de exame
                                </SuiTypography>
                                <Divider/>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Procedimento
                                            </SuiTypography>
                                        </SuiBox>
                                        <ProcedimentoSelect
                                            onChangeValue={(e) => {
                                                setIdProcedimento(e.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Convênio
                                            </SuiTypography>
                                        </SuiBox>
                                        <ConvenioSelect
                                            onChangeValue={(e) => {
                                                setIdConvenio(e.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Paciente
                                            </SuiTypography>
                                        </SuiBox>
                                        <PacienteSelect
                                            onChangeValue={(e) => {
                                                setIdPaciente(e.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Origem exame
                                            </SuiTypography>
                                        </SuiBox>
                                        <OrigemExameSelect
                                            onChangeValue={(e) => {
                                                setIdOrigemExame(e.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Profissional
                                            </SuiTypography>
                                        </SuiBox>
                                        <ProfissionalSelect
                                            onChangeValue={(e) => {
                                                setIdProfissional(e.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Valor de Cobrança Exame
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setValor(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Data inicial
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            onChangeValue={(evt) => {
                                                setDataInicial(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Data final
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            onChangeValue={(evt) => {
                                                setDataFinal(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                                    <SuiBox mr={1}>
                                        <SuiButton
                                            buttonColor="light"
                                            onClick={() => {
                                                props.history.goBack();
                                            }}
                                        >
                                            cancelar
                                        </SuiButton>
                                    </SuiBox>
                                    <SuiButton
                                        variant="gradient"
                                        buttonColor="info"
                                        onClick={() => {
                                            save(); // ao clicar no botão irá chamar a função POST no banco de dados
                                        }}
                                    >
                                        Salvar
                                    </SuiButton>
                                </SuiBox>
                            </SuiBox>
                        </Card>
                    </Grid>
                    {/* <Grid item xs={12} lg={3}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Dicas
                                </SuiTypography>
                                <Divider/>
                            </SuiBox>
                        </Card>
                    </Grid> */}
                </Grid>
            </SuiBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default withRouter(CadastroCobrancaExame);
