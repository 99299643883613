/**
 =========================================================
 * Soft UI Dashboard PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useMemo, useState} from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import {useAsyncDebounce, useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
import SuiPagination from "components/SuiPagination";

// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

function DataTable({
                       entriesPerPage,
                       canSearch,
                       showTotalEntries,
                       table,
                       pagination,
                       isSorted,
                       noEndBorder,
                   }) {
    const {defaultValue, entries} = entriesPerPage;
    const columns = useMemo(() => table.columns, [table]);
    const data = useMemo(() => table.rows, [table]);

    const tableInstance = useTable(
        {columns, data, initialState: {pageIndex: 0}},
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        pageOptions,
        canPreviousPage,
        canNextPage,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        state: {pageIndex, pageSize, globalFilter},
    } = tableInstance;

    // Set the default value for the entries per page when component mounts
    useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

    // Set the entries per page value based on the select value
    const setEntriesPerPage = ({value}) => setPageSize(value);

    // Render the paginations
    const renderPagination = pageOptions.map((option) => (
        <SuiPagination
            item
            key={option}
            onClick={() => gotoPage(Number(option))}
            active={pageIndex === option}
        >
            {option + 1}
        </SuiPagination>
    ));

    // Handler for the input to set the pagination index
    const handleInputPagination = ({target: {value}}) =>
        value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

    // Customized page options starting from 1
    const customizedPageOptions = pageOptions.map((option) => option + 1);

    // Setting value for the pagination input
    const handleInputPaginationValue = ({target: value}) => gotoPage(Number(value.value - 1));

    // Search input value state
    const [search, setSearch] = useState(globalFilter);

    // Search input state handle
    const onSearchChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 100);

    // A function that sets the sorted value for the table
    const setSortedValue = (column) => {
        let sortedValue;

        if (isSorted && column.isSorted) {
            sortedValue = column.isSortedDesc ? "desc" : "asce";
        } else if (isSorted) {
            sortedValue = "none";
        } else {
            sortedValue = false;
        }

        return sortedValue;
    };

    // Setting the entries starting point
    const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

    // Setting the entries ending point
    let entriesEnd;

    if (pageIndex === 0) {
        entriesEnd = pageSize;
    } else if (pageIndex === pageOptions.length - 1) {
        entriesEnd = rows.length;
    } else {
        entriesEnd = pageSize * (pageIndex + 1);
    }

    return (
        <TableContainer className="shadow-none">
            {entriesPerPage || canSearch ? (
                <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
                    {/* {entriesPerPage && (
                        <SuiBox display="flex" alignItems="center">
                            <SuiSelect
                                defaultValue={{value: defaultValue, label: defaultValue}}
                                options={entries.map((entry) => ({value: entry, label: entry}))}
                                onChange={setEntriesPerPage}
                                size="small"
                            />
                            <SuiTypography variant="caption" textColor="secondary">
                                &nbsp;&nbsp;registros por página
                            </SuiTypography>
                        </SuiBox>
                    )} */}
                    {canSearch && (
                        <SuiBox width="12rem" ml="auto">
                            <SuiInput
                                placeholder="Busca..."
                                value={search}
                                onChange={({currentTarget}) => {
                                    setSearch(search);
                                    onSearchChange(currentTarget.value);
                                }}
                            />
                        </SuiBox>
                    )}
                </SuiBox>
            ) : null}
            <Table {...getTableProps()}>
                <SuiBox component="thead">
                    {headerGroups.map((headerGroup) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <DataTableHeadCell
                                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                                    width={column.width ? column.width : "auto"}
                                    align={column.align ? column.align : "left"}
                                    sorted={setSortedValue(column)}
                                >
                                    {column.render("Header")}
                                </DataTableHeadCell>
                            ))}
                        </TableRow>
                    ))}
                </SuiBox>
                <TableBody {...getTableBodyProps()}>
                    {page.map((row, key) => {
                        prepareRow(row);
                        return (
                            <TableRow {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <DataTableBodyCell
                                        noBorder={noEndBorder && rows.length - 1 === key}
                                        align={cell.column.align ? cell.column.align : "left"}
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render("Cell")}
                                    </DataTableBodyCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>

            <SuiBox
                display="flex"
                flexDirection={{xs: "column", sm: "row"}}
                justifyContent="space-between"
                alignItems={{xs: "flex-start", sm: "center"}}
                p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
            >
                {showTotalEntries && (
                    <SuiBox mb={{xs: 3, sm: 0}}>
                        <SuiTypography variant="button" textColor="secondary" fontWeight="regular">
                            Mostrando {entriesStart} até {entriesEnd} de {rows.length} registro(s)
                        </SuiTypography>
                    </SuiBox>
                )}

            </SuiBox>
        </TableContainer>
    );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
    entriesPerPage: {defaultValue: 5, entries: [5]},
    canSearch: false,
    showTotalEntries: true,
    pagination: {variant: "gradient", color: "info"},
    isSorted: true,
    noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
    entriesPerPage: PropTypes.oneOfType([
        PropTypes.shape({
            defaultValue: PropTypes.number,
            entries: PropTypes.arrayOf(PropTypes.number),
        }),
        PropTypes.bool,
    ]),
    canSearch: PropTypes.bool,
    showTotalEntries: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    table: PropTypes.objectOf(PropTypes.array).isRequired,
    pagination: PropTypes.shape({
        variant: PropTypes.oneOf(["contained", "gradient"]),
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]),
    }),
    isSorted: PropTypes.bool,
    noEndBorder: PropTypes.bool,
};

export default DataTable;
