import React, {useEffect, useState} from "react";
import {cnpj as cnpjValidator} from "cpf-cnpj-validator";
import {toast} from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import swal from "@sweetalert/with-react";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiEditor from "components/SuiEditor";
import SuiButton from "components/SuiButton";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {withRouter} from "react-router-dom";
import {BounceLoader} from "react-spinners";
import SuiDatePicker from "../../../../components/SuiDatePicker";
import {dateEnBr} from "../../../../utils";
import UfBrasilSelect from "../../../../components/ufBrasil-select";

function EditarConvenio(props) {
    const [nomeConvenio, setNomeConvenio] = useState();
    const [cnpj, setCnpj] = useState();
    const [dataContratoInicio, setDataContratoInicio] = useState();
    const [dataContratoFim, setDataContratoFim] = useState();
    const [fone1, setFone1] = useState();
    const [fone2, setFone2] = useState();
    const [senha, setSenha] = useState();
    const [endereco, setEndereco] = useState();
    const [uf, setUf] = useState();
    const [cidade, setCidade] = useState();
    const [cep, setCep] = useState();
    const [numero, setNumero] = useState();
    const [complemento, setComplemento] = useState();
    const [observacoes, setObservacoes] = useState();
    const [receberEmail, setReceberEmail] = useState(false);
    const [email, setEmail] = useState();
    const handleSetReceberEmail = () => setReceberEmail(!receberEmail);
    const [isLoading, setIsLoading] = useState(true);

    const save = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        if (cnpj && cnpj.trim() !== "" && !cnpjValidator.isValid(cnpj)) {
            toast.error("CNPJ inválido");
            return;
        }

        await axios
            .put(
                `${window.url}ws/convenio/${props.match.params.id}`,
                {
                    idSaas: sessionStorage.getItem("idSaas"),
                    nome_convenio: nomeConvenio,
                    cnpj,
                    data_contrato_inicio: dataContratoInicio,
                    data_contrato_fim: dataContratoFim,
                    fone_1: fone1,
                    fone_2: fone2,
                    senha,
                    endereco,
                    uf,
                    cidade,
                    cep,
                    numero,
                    complemento,
                    observacoes,
                    recebe_email: receberEmail,
                    email,
                },
                config
            )
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    swal("Agilie", "Convênio atualizado com sucesso.", "success");
                    props.history.push("/auxiliares/convenio");
                }
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    const get = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .get(`${window.url}ws/convenio/${props.match.params.id}`, config)
            .then((res) => {
                const convenioObj = res.data.convenio;
                setNomeConvenio(convenioObj.nome_convenio);
                setCnpj(convenioObj.cnpj);
                setDataContratoInicio(
                    convenioObj.data_contrato_inicio === null
                        ? null
                        : dateEnBr(convenioObj.data_contrato_inicio)
                );
                setDataContratoFim(
                    convenioObj.data_contrato_fim === null
                        ? null
                        : dateEnBr(convenioObj.data_contrato_fim)
                );
                setFone1(convenioObj.fone_1);
                setFone2(convenioObj.fone_2);
                setSenha(convenioObj.senha);
                setEndereco(convenioObj.endereco);
                setUf(convenioObj.uf);
                setCidade(convenioObj.cidade);
                setCep(convenioObj.cep);
                setNumero(convenioObj.numero);
                setComplemento(convenioObj.complemento);
                setObservacoes(convenioObj.observacoes);
                setReceberEmail(Boolean(convenioObj.recebe_email));
                setEmail(convenioObj.email);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    const buscarCep = async (numeroCep) => {
        await axios
            .get(`https://viacep.com.br/ws/${numeroCep}/json/`)
            .then((res) => {
                console.log(res.data.erro);
                setEndereco(res.data.logradouro);
                setComplemento(res.data.complemento);
                setCidade(res.data.localidade);
                setUf(res.data.uf);
                if (res.data.erro === true) {
                    toast.error("CEP não encontrado!");
                } else {
                    toast.success("CEP encontrado!");
                }
            })
            .catch(() => {
                swal("Agilie", "Ops! Verifique a quantidade de números! Precisa ser 8 números! ", "error");
            });
    };

    useEffect(() => {
        get();
    }, []);

    return isLoading ? (
        <DashboardLayout>
            <DashboardNavbar/>
            <BounceLoader size={30} color="#ccc"/>
        </DashboardLayout>
    ) : (
        <DashboardLayout>
            <DashboardNavbar/>
            <SuiBox mt={3} mb={4}>
                       <Grid container spacing={3}>
                    <Grid item xs={12} lg={11}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Editar Convênio
                                </SuiTypography>
                                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                                    Os dados alterados não geram efeito sobre entrada de exames anteriores
                                </SuiTypography>
                                <Divider/>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={8}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Nome do convênio
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={nomeConvenio || ""}
                                            onChange={(e) => {
                                                setNomeConvenio(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                CNPJ do Convênio
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={cnpj || ""}
                                            onChange={(e) => {
                                                setCnpj(e.target.value);
                                            }}
                                            onBlur={(e) => {
                                                e.preventDefault();
                                                if (cnpj && cnpj.trim() !== "" && !cnpjValidator.isValid(cnpj)) {
                                                    toast.error("CNPJ inválido");
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Data de início do contrato
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            valueDefault={dataContratoInicio || ""}
                                            onChangeValue={(evt) => {
                                                setDataContratoInicio(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Data de fim do contrato
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            valueDefault={dataContratoFim || ""}
                                            onChangeValue={(evt) => {
                                                setDataContratoFim(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Senha
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={senha || ""}
                                            onChange={(e) => {
                                                setSenha(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Fone 1
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={fone1 || ""}
                                            onChange={(e) => {
                                                setFone1(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Fone 2
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={fone2 || ""}
                                            onChange={(e) => {
                                                setFone2(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                CEP
                                            </SuiTypography>
                                            <SuiTypography component="label" variant="caption" fontWeight="regular">
                                                (Somente números)
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={cep || ""}
                                            onChange={(e) => {
                                                setCep(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <SuiBox mb={0} ml={0.5} lineHeight={4.5} display="inline-block">
                                            <SuiButton
                                                variant="gradient"
                                                buttonColor="success"
                                                onClick={() => {
                                                    buscarCep(cep);
                                                }}
                                            >
                                                buscar cep
                                            </SuiButton>
                                        </SuiBox>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Endereço
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={endereco || ""}
                                            onChange={(e) => {
                                                setEndereco(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Cidade
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={cidade || ""}
                                            onChange={(e) => {
                                                setCidade(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Número
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={numero || ""}
                                            onChange={(e) => {
                                                setNumero(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                UF
                                            </SuiTypography>
                                        </SuiBox>
                                        <UfBrasilSelect
                                            value={{value: uf, label: uf || 'Selecione'}}
                                            onChangeValue={(e) => {
                                                setUf(e.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Complemento
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={complemento || ""}
                                            onChange={(e) => {
                                                setComplemento(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Email
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={email || ""}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={4} display="inline-block">
                                            <Checkbox checked={receberEmail} onChange={handleSetReceberEmail}/>
                                            <SuiTypography
                                                variant="button"
                                                fontWeight="bold"
                                                onClick={handleSetReceberEmail}
                                                customClass="cursor-pointer user-select-none"
                                            >
                                                &nbsp;&nbsp; Receber Email &nbsp;
                                            </SuiTypography>
                                        </SuiBox>
                                    </Grid>
                                </Grid>
                                <SuiBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                >
                                    <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                                            Observação
                                        </SuiTypography>
                                    </SuiBox>
                                    <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                                        <SuiTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="regular"
                                            textColor="text"
                                        >
                                            Você pode escrever uma observação aqui
                                        </SuiTypography>
                                    </SuiBox>
                                    <SuiEditor value={observacoes || ""} onChange={setObservacoes}/>
                                </SuiBox>

                                {/* <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Descrição da funcao
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="regular"
                      textColor="text"
                    >
                      Você pode descrever o Convênio para ajudar no processo de agendamento
                    </SuiTypography>
                  </SuiBox>
                  <SuiEditor value={descricao || ""} onChange={setDescricao} />
                </SuiBox> */}

                                {/*  <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Tabela
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    defaultValue={[{ value: 1, label: "CBHPM" }]}
                    onChange={(e) => {
                      setTabela(e.value);
                    }}
                    options={[
                      { value: 1, label: "CBHPM" },
                      { value: 2, label: "AMB" },
                    ]}
                  />
                </SuiBox> */}

                                {/*     <SuiBox>
                  <SuiBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                  >
                    <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Anexar tabela em PDF ou WORD
                      </SuiTypography>
                    </SuiBox>
                    <SuiDropzone options={{ addRemoveLinks: true }} />
                  </SuiBox>
                </SuiBox> */}
                                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                                    <SuiBox mr={1}>
                                        <SuiButton
                                            buttonColor="light"
                                            onClick={() => {
                                                props.history.goBack();
                                            }}
                                        >
                                            cancelar
                                        </SuiButton>
                                    </SuiBox>
                                    <SuiButton
                                        variant="gradient"
                                        buttonColor="info"
                                        onClick={() => {
                                            save(); // ao clicar no botão irá chamar a função POST no banco de dados
                                        }}
                                    >
                                        Salvar
                                    </SuiButton>
                                </SuiBox>
                            </SuiBox>
                        </Card>
                    </Grid>
                    {/* <Grid item xs={12} lg={3}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Dicas
                                </SuiTypography>
                                <Divider/>
                            </SuiBox>
                        </Card>
                    </Grid> */}
                </Grid>
            </SuiBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default withRouter(EditarConvenio);
