import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiEditor from "components/SuiEditor";
import SuiButton from "components/SuiButton";
import { BiPlusCircle } from "react-icons/all";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import Tooltip from "@mui/material/Tooltip";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BounceLoader } from "react-spinners";
import { withRouter } from "react-router-dom";
import swal from "@sweetalert/with-react";
import ConvenioSelect from "../../../../components/convenio-select";
import SuiDatePicker from "../../../../components/SuiDatePicker";

function CadastroConvenioPaciente(props) {
    const [idConvenio, setIdConvenio] = useState();
    const [carteirinha, setCarteirinha] = useState();
    const [dataValidade, setDataValidade] = useState();
    const [principal, setPrincipal] = useState(true);
    const [observacoes, setObservacoes] = useState();
    const [idPaciente, setIdPaciente] = useState();
    const [paciente, setPaciente] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const handleSetPrincipal = () => setPrincipal(!principal);

    const save = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .post(
                `${window.url}ws/convenioPaciente`,
                {
                    /* MANTER O IDSAAS  */
                    idSaas: sessionStorage.getItem("idSaas"),
                    id_paciente: idPaciente,
                    id_convenio: idConvenio,
                    carteirinha,
                    data_validade: dataValidade,
                    principal,
                    observacoes,
                },
                config
            )
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    swal("Agilie", "Convênio Paciente cadastrado com sucesso.", "success");
                    if (localStorage.getItem("goBackStatus") === "1") {
                        localStorage.setItem("id_carteirinha", res.data.id);
                        localStorage.setItem("carteirinha", res.data.carteirinha);
                        localStorage.setItem("id_convenio", res.data.id_convenio);
                        localStorage.setItem("convenio", res.data.nome_convenio);
                        localStorage.removeItem("goBackStatus");
                        props.history.goBack();
                    } else {
                        props.history.push(`/auxiliares/paciente/convenios/${idPaciente}/${props.uuid}`);
                    }
                    
                }
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    const convenioPush = () => {
        localStorage.setItem("convenioPacienteStatus", "1");
        props.history.push("/auxiliares/convenio/cadastro");
    };

    const get = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .get(`${window.url}ws/paciente/${props.match.params.id}`, config)
            .then((res) => {
                const pacienteObj = res.data.paciente;
                setIdPaciente(pacienteObj.id);
                setPaciente(pacienteObj.nome);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };
    useEffect(() => {
        get();
    }, []);

    return isLoading ? (
        <DashboardLayout>
            <DashboardNavbar />
            <BounceLoader size={30} color="#ccc" />
        </DashboardLayout>
    ) : (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox mt={3} mb={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={11}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Convênio paciente
                                </SuiTypography>
                                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                                    Cadastro de convênio paciente
                                </SuiTypography>
                                <Divider />
                                <SuiTypography variant="h6" fontWeight="medium">
                                    {paciente}
                                </SuiTypography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Convênio
                                            </SuiTypography>
                                        </SuiBox>
                                        <ConvenioSelect
                                            onChangeValue={(e) => {
                                                setIdConvenio(e.value);
                                            }}
                                        />
                                        <SuiBox mt={1} mb={1}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={6}>
                                                    <Tooltip title="Cadastrar convênio" placement="top">
                                                        <SuiButton
                                                            variant="gradient"
                                                            buttonColor="info"
                                                            onClick={() => {
                                                                convenioPush();
                                                            }}
                                                        >
                                                            <BiPlusCircle />
                                                        </SuiButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </SuiBox>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Carteirinha Nº
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            onChange={(e) => {
                                                setCarteirinha(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography
                                                component="label"
                                                variant="caption"
                                                fontWeight="bold"
                                            >
                                                Data de validade
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            onChangeValue={(evt) => {
                                                setDataValidade(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={4} display="inline-block">
                                            <Checkbox checked={principal} onChange={handleSetPrincipal} />
                                            <SuiTypography
                                                variant="button"
                                                fontWeight="bold" /* regular */
                                                onClick={handleSetPrincipal}
                                                customClass="cursor-pointer user-select-none"
                                            >
                                                &nbsp;&nbsp; Principal &nbsp;
                                            </SuiTypography>
                                        </SuiBox>
                                    </Grid>
                                </Grid>
                                <SuiBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                >
                                    <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                                            Observação
                                        </SuiTypography>
                                    </SuiBox>
                                    <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                                        <SuiTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="regular"
                                            textColor="text"
                                        >
                                            Você pode escrever uma observação aqui
                                        </SuiTypography>
                                    </SuiBox>
                                    <SuiEditor value={observacoes || ""} onChange={setObservacoes} />
                                </SuiBox>
                                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                                    <SuiBox mr={1}>
                                        <SuiButton
                                            buttonColor="light"
                                            onClick={() => {
                                                props.history.goBack();
                                            }}
                                        >
                                            cancelar
                                        </SuiButton>
                                    </SuiBox>
                                    <SuiButton
                                        variant="gradient"
                                        buttonColor="info"
                                        onClick={() => {
                                            save(); // ao clicar no botão irá chamar a função POST no banco de dados
                                        }}
                                    >
                                        Salvar
                                    </SuiButton>
                                </SuiBox>
                            </SuiBox>
                        </Card>
                    </Grid>
                    {/* <Grid item xs={12} lg={3}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Dicas
                                </SuiTypography>
                                <Divider/>
                            </SuiBox>
                        </Card>
                    </Grid> */}
                </Grid>
            </SuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default withRouter(CadastroConvenioPaciente);
