import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import Tooltip from "@mui/material/Tooltip";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Icon from "@mui/material/Icon";
import SuiButton from "../../../components/SuiButton";
import DefaultLineChart from "../../../examples/Charts/LineCharts/DefaultLineChart";
import SuiBadgeDot from "../../../components/SuiBadgeDot";

import defaultLineChartData from "./defaultLineChartData";

function FinanceiroDashboard() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={3}>
        <SuiBox mb={3}>
          <Grid container>
            <Grid item xs={12} xl={8}>
              <DefaultLineChart
                title="Fluxo de caixa"
                description={
                  <SuiBox display="flex" justifyContent="space-between">
                    <SuiBox display="flex">
                      <SuiBadgeDot color="info" size="sm" badgeContent="Contas a pagar" />
                      <SuiBadgeDot color="dark" size="sm" badgeContent="Contas a receber" />
                    </SuiBox>
                    <SuiBox mt={-5.25} mr={-1}>
                      <Tooltip title="See which ads perform better" placement="left" arrow>
                        <SuiButton
                          variant="outlined"
                          color="secondary"
                          size="small"
                          circular
                          iconOnly
                        >
                          <Icon>priority_high</Icon>
                        </SuiButton>
                      </Tooltip>
                    </SuiBox>
                  </SuiBox>
                }
                chart={defaultLineChartData}
              />
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FinanceiroDashboard;
