import React, {useEffect, useState} from "react";
import moment from "moment";
import {toast} from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import swal from "@sweetalert/with-react";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiEditor from "components/SuiEditor";
import SuiButton from "components/SuiButton";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {withRouter} from "react-router-dom";
import {BounceLoader} from "react-spinners";
import {cpf as checkCpf} from "cpf-cnpj-validator";
import SuiDatePicker from "../../../../components/SuiDatePicker";
import GeneroSelect from "../../../../components/genero-select";
import {dateEnBr, functionGenero} from "../../../../utils";

function EditarPaciente(props) {
    const [nome, setNome] = useState();
    const [nomeMae, setNomeMae] = useState();
    const [dataNascimento, setDataNascimento] = useState();
    const [rg, setRg] = useState();
    const [cpf, setCpf] = useState();
    const [docEstrangeiro, setDocEstrangeiro] = useState();
    const [genero, setGenero] = useState();
    const [portadorDeficiencia, setPortadorDeficiencia] = useState(false);
    const [portadorDeficienciaQuais, setPortadorDeficienciaQuais] = useState();
    const [necessidadesEspeciais, setNecessidadesEspeciais] = useState(false);
    const [necessidadesEspeciaisQuais, setNecessidadesEspeciaisQuais] = useState();
    const [idade, setIdade] = useState();
    const [telefone, setTelefone] = useState();
    const [celular, setCelular] = useState();
    const [email, setEmail] = useState();
    const [observacao, setObservacao] = useState();
    const handleSetPortadorDeficiencia = () => setPortadorDeficiencia(!portadorDeficiencia);
    const handleSetNecessidadesEspeciais = () => setNecessidadesEspeciais(!necessidadesEspeciais);
    const [isLoading, setIsLoading] = useState(true);

    const save = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        if (cpf && cpf.trim() !== "" && !checkCpf.isValid(cpf)) {
            toast.error("CPF inválido");
            return;
        }

        await axios
            .put(
                `${window.url}ws/paciente/${props.match.params.id}`,
                {
                    idSaas: sessionStorage.getItem("idSaas"),
                    nome,
                    nome_mae: nomeMae,
                    data_nascimento: dataNascimento,
                    rg,
                    cpf,
                    doc_estrangeiro: docEstrangeiro,
                    genero: genero.value,
                    portador_deficiencia: portadorDeficiencia,
                    portador_deficiencia_quais: portadorDeficienciaQuais,
                    necessidades_especiais: necessidadesEspeciais,
                    necessidades_especiais_quais: necessidadesEspeciaisQuais,
                    idade,
                    telefone,
                    celular,
                    email,
                    observacao,
                },
                config
            )
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    swal("Agilie", "Paciente atualizado com sucesso.", "success");
                    if (localStorage.getItem("goBackStatus") === "1") {
                        localStorage.setItem("paciente", res.data.nome);
                        localStorage.removeItem("goBackStatus");
                        props.history.goBack();
                    } else {
                        props.history.push("/auxiliares/paciente");
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    const get = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .get(`${window.url}ws/paciente/${props.match.params.id}`, config)
            .then((res) => {
                const pacienteObj = res.data.paciente;
                setNome(pacienteObj.nome);
                setNomeMae(pacienteObj.nome_mae);
                setDataNascimento(
                    pacienteObj.data_nascimento === null
                        ? null
                        : dateEnBr(pacienteObj.data_nascimento)
                );
                setRg(pacienteObj.rg);
                setCpf(pacienteObj.cpf);
                setDocEstrangeiro(pacienteObj.doc_estrangeiro);
                setGenero({
                    value: pacienteObj.genero,
                    label: functionGenero(pacienteObj.genero),
                });
                setPortadorDeficiencia(pacienteObj.portador_deficiencia);
                setPortadorDeficienciaQuais(pacienteObj.portador_deficiencia_quais);
                setNecessidadesEspeciais(pacienteObj.necessidades_especiais);
                setNecessidadesEspeciaisQuais(pacienteObj.necessidades_especiais_quais);
                setIdade(pacienteObj.idade);
                setTelefone(pacienteObj.telefone);
                setCelular(pacienteObj.celular);
                setEmail(pacienteObj.email);
                setObservacao(pacienteObj.observacao);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    useEffect(() => {
        get();
    }, []);

    const calcularIdade = (dataNascimento) => {
        if (dataNascimento && moment(dataNascimento, "DD/MM/YYYY").isValid()) {
            const dataNasc = moment(dataNascimento, "DD/MM/YYYY");
            const hoje = moment();
            const diffAnos = hoje.diff(dataNasc, "years");
            return diffAnos.toString();
        }
        return "";
    }

    return isLoading ? (
        <DashboardLayout>
            <DashboardNavbar/>
            <BounceLoader size={30} color="#ccc"/>
        </DashboardLayout>
    ) : (
        <DashboardLayout>
            <DashboardNavbar/>
            <SuiBox mt={3} mb={4}>
                       <Grid container spacing={3}>
                    <Grid item xs={12} lg={11}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Editar Paciente
                                </SuiTypography>
                                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                                    Os dados alterados não geram efeito sobre entrada de exames anteriores
                                </SuiTypography>
                                <Divider/>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Nome
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={nome || ""}
                                            onChange={(e) => {
                                                setNome(e.target.value.toUpperCase());
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Nome da mãe
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={nomeMae || ""}
                                            onChange={(e) => {
                                                setNomeMae(e.target.value.toUpperCase());
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Data de nascimento
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            valueDefault={dataNascimento || ""}
                                            onChangeValue={(evt) => {
                                                setDataNascimento(evt.toLocaleString("pt-BR"));
                                                setIdade(calcularIdade(evt.toLocaleString("pt-BR"))); // atualizando a idade automaticamente
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                RG
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={rg || ""}
                                            onChange={(e) => {
                                                setRg(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                CPF
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={cpf || ""}
                                            onChange={(e) => {
                                                setCpf(e.target.value);
                                            }}
                                            onBlur={(e) => {
                                                e.preventDefault();
                                                const cpf = e.target.value;
                                                if (cpf.trim() !== '') {
                                                    if (!checkCpf.isValid(cpf)) {
                                                        toast.error('CPF inválido');
                                                    }
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Documento Estrangeiro
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={docEstrangeiro || ""}
                                            onChange={(e) => {
                                                setDocEstrangeiro(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Gênero
                                            </SuiTypography>
                                        </SuiBox>
                                        <GeneroSelect
                                            value={genero}
                                            onChangeValue={(e) => {
                                                if (e.value === 1) {
                                                    setGenero({value: e.value, label: "Feminino"});
                                                } else if (e.value === 2) {
                                                    setGenero({value: e.value, label: "Masculino"});
                                                } else {
                                                    setGenero({value: e.value, label: "Não informado"});
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Idade
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={idade || ""}
                                            onChange={(e) => {
                                                setIdade(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Telefone
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={telefone || ""}
                                            onChange={(e) => {
                                                setTelefone(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Celular
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={celular || ""}
                                            onChange={(e) => {
                                                setCelular(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Email
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={email || ""}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <SuiBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="100%"
                                >
                                    <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                                            Observação
                                        </SuiTypography>
                                    </SuiBox>
                                    <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                                        <SuiTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="regular"
                                            textColor="text"
                                        >
                                            Você pode escrever uma observação aqui
                                        </SuiTypography>
                                    </SuiBox>
                                    <SuiEditor value={observacao || ""} onChange={setObservacao}/>
                                </SuiBox>
                                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                                    <SuiBox mr={1}>
                                        <SuiButton
                                            buttonColor="light"
                                            onClick={() => {
                                                props.history.goBack();
                                                localStorage.removeItem("goBackStatus");
                                            }}
                                        >
                                            cancelar
                                        </SuiButton>
                                    </SuiBox>
                                    <SuiButton
                                        variant="gradient"
                                        buttonColor="info"
                                        onClick={() => {
                                            save(); // ao clicar no botão irá chamar a função POST no banco de dados
                                        }}
                                    >
                                        Salvar
                                    </SuiButton>
                                </SuiBox>
                            </SuiBox>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Dicas
                                </SuiTypography>
                                <Divider/>
                                {/* <SuiTypography variant="body2" color="textSecondary" mt={2}>
                
                </SuiTypography> */}
                            </SuiBox>
                        </Card>
                    </Grid>
                </Grid>
            </SuiBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default withRouter(EditarPaciente);
