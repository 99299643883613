// react-countup components
import CountUp from "react-countup";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function MenuGrandeLink({ titulo, rota, icone }) {
  return (
    <Card>
      <SuiBox p={3} textAlign="center" lineHeight={1.25}>
        <SuiTypography variant="h4" fontWeight="bold" textGradient>
          {titulo}
        </SuiTypography>
      </SuiBox>
    </Card>
  );
}

export default MenuGrandeLink;
