// Soft UI Dashboard PRO React layouts
import SmartHome from "layouts/dashboards/smart-home";
import Shop from "examples/Icons/Shop";
import {
  BiBuildings,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarEvent,
  BiHomeHeart,
  BiUser,
  BiUserCheck,
  IoAddCircleSharp,
  IoArrowForwardCircleOutline,
  IoBarChartOutline,
  IoBook,
  IoBookmark,
  IoCashOutline,
  IoDocumentText,
  IoFolderOpen,
  IoListCircleOutline,
  IoSearchCircle,
  IoSearchCircleOutline,
  IoSwapHorizontalOutline,
} from "react-icons/all";

import Procedimento from "./layouts/pages/auxiliares/procedimento";
import CadastroProcedimento from "./layouts/pages/auxiliares/procedimento/cadastro";
import TabelaProcedimento from "./layouts/pages/auxiliares/tabelaProcedimento";
import CadastroTabelaProcedimento from "./layouts/pages/auxiliares/tabelaProcedimento/cadastro";
import Agenda from "./layouts/pages/auxiliares/agenda";
import CadastroAgenda from "./layouts/pages/auxiliares/agenda/cadastro";
import Agendamento from "./layouts/pages/auxiliares/agendamento";
import CadastroAgendamento from "./layouts/pages/auxiliares/agendamento/cadastro";
import Almoxarifado from "./layouts/pages/auxiliares/almoxarifado";
import CadastroAlmoxarifado from "./layouts/pages/auxiliares/almoxarifado/cadastro";
import CategoriaProduto from "./layouts/pages/auxiliares/categoriaProduto";
import CadastroCategoriaProduto from "./layouts/pages/auxiliares/categoriaProduto/cadastro";
import CobrancaExame from "./layouts/pages/auxiliares/cobrancaExame";
import CadastroCobrancaExame from "./layouts/pages/auxiliares/cobrancaExame/cadastro";
import Compras from "./layouts/pages/auxiliares/compras";
import CadastroCompras from "./layouts/pages/auxiliares/compras/cadastro";
import ControleEstoque from "./layouts/pages/auxiliares/controleEstoque";
import CadastroControleEstoque from "./layouts/pages/auxiliares/controleEstoque/cadastro";
import Convenio from "./layouts/pages/auxiliares/convenio";
import CadastroConvenio from "./layouts/pages/auxiliares/convenio/cadastro";
import Cotacao from "./layouts/pages/auxiliares/cotacao";
import CadastroCotacao from "./layouts/pages/auxiliares/cotacao/cadastro";
import EntradaCaixa from "./layouts/pages/auxiliares/entradaCaixa";
import CadastroEntradaCaixa from "./layouts/pages/auxiliares/entradaCaixa/cadastro";
import SaidaCaixa from "./layouts/pages/auxiliares/saidaCaixa";
import CadastroSaidaCaixa from "./layouts/pages/auxiliares/saidaCaixa/cadastro";
import EntradaExame from "./layouts/pages/auxiliares/entradaExame";
import CadastroEntradaExames from "./layouts/pages/auxiliares/entradaExame/cadastros";
import Fornecedor from "./layouts/pages/auxiliares/fornecedor";
import CadastroFornecedor from "./layouts/pages/auxiliares/fornecedor/cadastro";
import HorarioAgenda from "./layouts/pages/auxiliares/horarioAgenda";
import CadastroHorarioAgenda from "./layouts/pages/auxiliares/horarioAgenda/cadastro";
import Instituicao from "./layouts/pages/auxiliares/instituicao";
import CadastroInstituicao from "./layouts/pages/auxiliares/instituicao/cadastro";
import Laudo from "./layouts/pages/auxiliares/laudo";
import MaterialTecido from "./layouts/pages/auxiliares/materialTecido";
import CadastroMaterialTecido from "./layouts/pages/auxiliares/materialTecido/cadastro";
import Paciente from "./layouts/pages/auxiliares/paciente";
import CadastroPaciente from "./layouts/pages/auxiliares/paciente/cadastro";
import Produto from "./layouts/pages/auxiliares/produto";
import CadastroProduto from "./layouts/pages/auxiliares/produto/cadastro";
import ProfissionalSaude from "./layouts/pages/auxiliares/profissionalSaude";
import CadastroProfissionalSaude from "./layouts/pages/auxiliares/profissionalSaude/cadastro";
import ValorProcedimento from "./layouts/pages/auxiliares/valorProcedimento";
import CadastroValorProcedimento from "./layouts/pages/auxiliares/valorProcedimento/cadastro";
import FuncoesAdministradorHome from "./layouts/dashboards/administrador";
import CadastroTemplateLaudos from "./layouts/pages/auxiliares/templateLaudos/cadastro";
import TemplateLaudos from "./layouts/pages/auxiliares/templateLaudos";
import Rastreabilidade from "./layouts/pages/auxiliares/rastreabilidade";
import GuiasSADT from "./layouts/pages/auxiliares/guiasSADT";
import Glosa from "./layouts/pages/auxiliares/glosa";
import Laminario from "./layouts/pages/auxiliares/laminario";
import CadastroLaminario from "./layouts/pages/auxiliares/laminario/cadastro";
import PalavraChave from "./layouts/pages/auxiliares/palavraChave";
import CadastroPalavraChave from "./layouts/pages/auxiliares/palavraChave/cadastro";
import BancoFrases from "./layouts/pages/auxiliares/bancoFrases";
import FinanceiroDashboardSintetico from "./layouts/pages/auxiliares/financeiro/dashboardSintetico";
import CadastroFrasesPadrao from "./layouts/pages/auxiliares/bancoFrases/cadastro";
import FaturamentoRelatorios from "./layouts/pages/auxiliares/faturamento";
import KitsMateriais from "./layouts/pages/auxiliares/kitMaterialProcedimento";
import CadastroKitMateriais from "./layouts/pages/auxiliares/kitMaterialProcedimento/cadastro";
import CadastroContasBancarias from "./layouts/pages/auxiliares/contasBancarias/cadastro";
import ContasBancarias from "./layouts/pages/auxiliares/contasBancarias";
import DadosClinicos from "./layouts/pages/auxiliares/dadosClinicos";
import CadastroDadosClinicos from "./layouts/pages/auxiliares/dadosClinicos/cadastro";
import Macroscopia from "./layouts/pages/auxiliares/macroscopia";
import Coloracao from "./layouts/pages/auxiliares/coloracao";
import CadastroColoracao from "./layouts/pages/auxiliares/coloracao/cadastro";
import CadastroMacroscopia from "./layouts/pages/auxiliares/macroscopia/cadastro";
import Microscopia from "./layouts/pages/auxiliares/microscopia";
import CadastroMicroscopia from "./layouts/pages/auxiliares/microscopia/cadastro";
import DescricaoAdicional from "./layouts/pages/auxiliares/descricaoAdicional";
import CadastroDescricaoAdicional from "./layouts/pages/auxiliares/descricaoAdicional/cadastro";
import DiagHisto from "./layouts/pages/auxiliares/diagHisto";
import CadastroDiagHisto from "./layouts/pages/auxiliares/diagHisto/cadastro";
import DiagNotas from "./layouts/pages/auxiliares/diagNotas";
import CadastroDiagNotas from "./layouts/pages/auxiliares/diagNotas/cadastro";
import ImpressaoEtiquetas from "./layouts/pages/auxiliares/impressao/etiquetas";
import RelatorioHistotecnico from "./layouts/pages/auxiliares/relatorio/histotecnico";
import gestaoSistema from "./layouts/pages/auxiliares/gestaoSistema";

const routes = [
    {
        type: "collapse",
        name: "Dashboards",
        key: "dashboards",
        icon: <Shop size="18px"/>,
        collapse: [
            {
                name: "Visão administrativa",
                key: "smart-home",
                route: "/dashboards/home",
                component: SmartHome,
            },
        ],
    },
    {
        type: "title",
        title: "AGENDA",
        key: "title-agenda",
    },
    {
        type: "collapse",
        name: "Paciente",
        key: "paciente",
        icon: <BiUser size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "paciente-lista",
                route: "/auxiliares/paciente",
                component: Paciente,
            },
            {
                name: "Cadastro",
                key: "novo-paciente",
                route: "/auxiliares/paciente/cadastro",
                component: CadastroPaciente,
            },
        ],
    },
    {
        type: "collapse",
        name: "Agendamento",
        key: "agendamento",
        icon: <BiCalendarEvent size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "agendamento-lista",
                route: "/auxiliares/agendamento",
                component: Agendamento,
            },
            {
                name: "Cadastro",
                key: "novo-agendamento",
                route: "/auxiliares/agendamento/cadastro",
                component: CadastroAgendamento,
            },
        ],
    },
    {
        type: "collapse",
        name: "Instituições",
        key: "instituicao",
        icon: <BiBuildings size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "instituicao-lista",
                route: "/auxiliares/instituicao",
                component: Instituicao,
            },
            {
                name: "Cadastro",
                key: "novo-instituicao",
                route: "/auxiliares/instituicao/cadastro",
                component: CadastroInstituicao,
            },
        ],
    },
    {
        type: "collapse",
        name: "Profissional de Saúde",
        key: "profissionalSaude",
        icon: <BiUserCheck size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "profissionalSaude-lista",
                route: "/auxiliares/profissionalSaude",
                component: ProfissionalSaude,
            },
            {
                name: "Cadastro",
                key: "novo-profissionalSaude",
                route: "/auxiliares/profissionalSaude/cadastro",
                component: CadastroProfissionalSaude,
            },
        ],
    },
    {
        type: "collapse",
        name: "Convênio",
        key: "convenio",
        icon: <BiHomeHeart size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "convenio-lista",
                route: "/auxiliares/convenio",
                component: Convenio,
            },
            {
                name: "Cadastro",
                key: "novo-convenio",
                route: "/auxiliares/convenio/cadastro",
                component: CadastroConvenio,
            },
        ],
    },
    {
        type: "title",
        title: "AUX. AGENDA",
        key: "aux-title-agenda",
    },
    {
        type: "collapse",
        name: "Agenda",
        key: "agenda",
        icon: <BiCalendarCheck size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "agenda-lista",
                route: "/auxiliares/agenda",
                component: Agenda,
            },
            {
                name: "Cadastro",
                key: "nova-agenda",
                route: "/auxiliares/agenda/cadastro",
                component: CadastroAgenda,
            },
        ],
    },
    {
        type: "collapse",
        name: "Horário Agenda",
        key: "horarioAgenda",
        icon: <BiCalendar size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "horarioAgenda-lista",
                route: "/auxiliares/horarioAgenda",
                component: HorarioAgenda,
            },
            {
                name: "Cadastro",
                key: "novo-horarioAgenda",
                route: "/auxiliares/horarioAgenda/cadastro",
                component: CadastroHorarioAgenda,
            },
        ],
    },

    {
        type: "title",
        title: "CLÍNICO",
        key: "title-clinico",
    },
    {
        type: "collapse",
        name: "Laudo",
        key: "laudo",
        icon: <IoDocumentText size="18px"/>,
        collapse: [
            {
                name: "Histórico",
                key: "laudo-lista",
                route: "/auxiliares/laudo",
                component: Laudo,
            },
        ],
    },
    {
        type: "collapse",
        name: "Entrada exame (mat. biológico)",
        key: "entradaExame",
        icon: <IoAddCircleSharp size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "entradaExame-lista",
                route: "/auxiliares/entradaExame",
                component: EntradaExame,
            },
            {
                name: "Novo cadastro",
                key: "novo-entradaExame",
                route: "/auxiliares/entradaExame/cadastros",
                component: CadastroEntradaExames,
            },
            {
                name: "Impressão etiquetas",
                key: "impressao-etiqueta",
                route: "/auxiliares/impressao/etiquetas",
                component: ImpressaoEtiquetas,
            },
            {
                name: "Relatório histotécnico",
                key: "relatorio-envio-histotecnio",
                route: "/auxiliares/relatorio/histotecnico",
                component: RelatorioHistotecnico,
            },
        ],
    },
    {
        type: "collapse",
        name: "Rastreabilidade",
        key: "rastreabilidade",
        icon: <IoSearchCircle size="18px"/>,
        collapse: [
            {
                name: "Gestão",
                key: "rastreabilidade-lista",
                route: "/clinico/rastreabilidade",
                component: Rastreabilidade,
            },
        ],
    },
    {
        type: "title",
        title: "AUX. CLÍNICO",
        key: "title-pages-clinico",
    },
    {
        type: "collapse",
        name: "Template de laudos",
        key: "templateLaudos",
        icon: <IoFolderOpen size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "templateLaudos-lista",
                route: "/auxiliares/template",
                component: TemplateLaudos,
            },
            {
                name: "Cadastro",
                key: "novo-template",
                route: "/auxiliares/template/cadastro",
                component: CadastroTemplateLaudos,
            },
        ],
    },
    {
        type: "collapse",
        name: "Dados Clínicos (templates)",
        key: "dadosClinicos",
        icon: <IoDocumentText size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "dadosClinicos-lista",
                route: "/auxiliares/dadosClinicos",
                component: DadosClinicos,
            },
            {
                name: "Cadastro",
                key: "novo-dadosClinicos",
                route: "/auxiliares/dadosClinicos/cadastro",
                component: CadastroDadosClinicos,
            },
        ],
    },
    {
        type: "collapse",
        name: "Macroscopia (templates)",
        key: "macroscopia",
        icon: <IoDocumentText size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "macroscopia-lista",
                route: "/auxiliares/macroscopia",
                component: Macroscopia,
            },
            {
                name: "Cadastro",
                key: "novo-macroscopia",
                route: "/auxiliares/macroscopia/cadastro",
                component: CadastroMacroscopia,
            },
        ],
    },
    {
        type: "collapse",
        name: "Microscopia (templates)",
        key: "microscopia",
        icon: <IoDocumentText size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "microscopia-lista",
                route: "/auxiliares/microscopia",
                component: Microscopia,
            },
            {
                name: "Cadastro",
                key: "novo-aux-macro",
                route: "/auxiliares/microscopia/cadastro",
                component: CadastroMicroscopia,
            },
        ],
    },
    {
        type: "collapse",
        name: "Diag. Histo. (templates)",
        key: "diagHisto",
        icon: <IoDocumentText size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "diagHisto-lista",
                route: "/auxiliares/diagHisto",
                component: DiagHisto,
            },
            {
                name: "Cadastro",
                key: "novo-diag",
                route: "/auxiliares/diagHisto/cadastro",
                component: CadastroDiagHisto,
            },
        ],
    },
    {
        type: "collapse",
        name: "Diag. Notas (templates)",
        key: "diagNotas",
        icon: <IoDocumentText size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "diagNotas-lista",
                route: "/auxiliares/diagNotas",
                component: DiagNotas,
            },
            {
                name: "Cadastro",
                key: "novo-diag-nota",
                route: "/auxiliares/diagNotas/cadastro",
                component: CadastroDiagNotas,
            },
        ],
    },
    {
        type: "collapse",
        name: "Coloracao (templates)",
        key: "coloracao",
        icon: <IoDocumentText size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "coloracao-lista",
                route: "/auxiliares/coloracao",
                component: Coloracao,
            },
            {
                name: "Cadastro",
                key: "novo-coloracao",
                route: "/auxiliares/coloracao/cadastro",
                component: CadastroColoracao,
            },
        ],
    },
    {
        type: "collapse",
        name: "Descrição adicional (templates)",
        key: "descricaoadicional",
        icon: <IoDocumentText size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "descricaoadicional-lista",
                route: "/auxiliares/descricaoAdicional",
                component: DescricaoAdicional,
            },
            {
                name: "Cadastro",
                key: "cadastrodescricaoadicional",
                route: "/auxiliares/descricaoAdicional/cadastro",
                component: CadastroDescricaoAdicional,
            },
        ],
    },
    {
        type: "collapse",
        name: "Banco de frases",
        key: "bancoFrases",
        icon: <IoBook size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "bancoFrasesLista",
                route: "/auxiliares/bancoFrases",
                component: BancoFrases,
            },
            {
                name: "Cadastro",
                key: "bancoFrasesCadastro",
                route: "/auxiliares/bancoFrases/cadastro",
                component: CadastroFrasesPadrao,
            },
        ],
    },
    {
        type: "collapse",
        name: "Palavras chave",
        key: "palavraChave",
        icon: <IoBookmark size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "palavraChave-lista",
                route: "/auxiliares/palavraChave",
                component: PalavraChave,
            },
            {
                name: "Cadastro",
                key: "CadastroPalavraChave",
                route: "/auxiliares/palavraChave/cadastro",
                component: CadastroPalavraChave,
            },
        ],
    },
    {
        type: "collapse",
        name: "Material Biológico (templates)",
        key: "materialTecido",
        icon: <IoArrowForwardCircleOutline size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "materialTecido-lista",
                route: "/auxiliares/materialTecido",
                component: MaterialTecido,
            },
            {
                name: "Cadastro",
                key: "novo-materialTecido",
                route: "/auxiliares/materialTecido/cadastro",
                component: CadastroMaterialTecido,
            },
        ],
    },
    {
        type: "title",
        title: "FINANCEIRO",
        key: "title-financeiro",
    },
    {
        type: "collapse",
        name: "Dashboard",
        key: "financeiro-dashboard",
        icon: <IoBarChartOutline size="18px"/>,
        collapse: [
            {
                name: "Dashboard sintético",
                key: "financeiro-dashboard-sintetico",
                route: "/financeiro/dashboard/sintetico",
                component: FinanceiroDashboardSintetico,
            },
        ],
    },
    {
        type: "collapse",
        name: "Entrada Caixa",
        key: "entradaCaixa",
        icon: <IoArrowForwardCircleOutline size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "entradaCaixa-lista",
                route: "/auxiliares/entradaCaixa",
                component: EntradaCaixa,
            },
            {
                name: "Cadastro",
                key: "novo-entradaCaixa",
                route: "/auxiliares/entradaCaixa/cadastro",
                component: CadastroEntradaCaixa,
            },
        ],
    },

    {
        type: "collapse",
        name: "Saída Caixa",
        key: "saidaCaixa",
        icon: <IoArrowForwardCircleOutline size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "saidaCaixa-lista",
                route: "/auxiliares/saidaCaixa",
                component: SaidaCaixa,
            },
            {
                name: "Cadastro",
                key: "novo-saidaCaixa",
                route: "/auxiliares/saidaCaixa/cadastro",
                component: CadastroSaidaCaixa,
            },
        ],
    },


    {
        type: "title",
        title: "AUX. FINANCEIRO",
        key: "title-financeiro-aux",
    },
    {
        type: "collapse",
        name: "Contas bancárias",
        key: "contasBancarias",
        icon: <IoSwapHorizontalOutline size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "contasBancarias-lista",
                route: "/auxiliares/contasBancarias",
                component: ContasBancarias,
            },
            {
                name: "Cadastro",
                key: "novo-conta-bancaria",
                route: "/auxiliares/contasBancarias/cadastro",
                component: CadastroContasBancarias,
            },
        ],
    },

    {
        type: "title",
        title: "FATURAMENTO",
        key: "title-faturamento",
    },
    {
        type: "collapse",
        name: "Guias SADT XML",
        key: "guias-xml",
        icon: <IoArrowForwardCircleOutline size="18px"/>,
        collapse: [
            {
                name: "Lista de guias",
                key: "listaguias",
                route: "/faturamento/lista/guias",
                component: GuiasSADT,
            },
        ],
    },
    {
        type: "collapse",
        name: "Glosa(s)",
        key: "glosas",
        icon: <IoArrowForwardCircleOutline size="18px"/>,
        collapse: [
            {
                name: "Lista de glosas",
                key: "listaglosas",
                route: "/faturamento/lista/glosas",
                component: Glosa,
            },
        ],
    },
    {
        type: "collapse",
        name: "Relatórios",
        key: "faturamento-relatorios",
        icon: <IoArrowForwardCircleOutline size="18px"/>,
        collapse: [
            {
                name: "Relatórios gerais",
                key: "faturamento-relatorios-gerais",
                route: "/faturamento/relatorios",
                component: FaturamentoRelatorios,
            },
        ],
    },
    {
        type: "title",
        title: "AUX. FATURAMENTO",
        key: "title-pages",
    },
    {
        type: "collapse",
        name: "Procedimentos",
        key: "procedimentos",
        icon: <IoSwapHorizontalOutline size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "procedimento-lista",
                route: "/auxiliares/procedimento",
                component: Procedimento,
            },
            {
                name: "Cadastro",
                key: "novo-procedimento",
                route: "/auxiliares/procedimento/cadastro",
                component: CadastroProcedimento,
            },
        ],
    },
    {
        type: "collapse",
        name: "Tabelas",
        key: "tabelas",
        icon: <IoListCircleOutline size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "tabelas-lista",
                route: "/auxiliares/tabelaProcedimento",
                component: TabelaProcedimento,
            },
            {
                name: "Cadastro",
                key: "nova-tabela",
                route: "/auxiliares/tabelaProcedimento/cadastro",
                component: CadastroTabelaProcedimento,
            },
        ],
    },
    {
        type: "collapse",
        name: "Valor do Procedimento",
        key: "valorProcedimento",
        icon: <IoCashOutline size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "valorProcedimento-lista",
                route: "/auxiliares/valorProcedimento",
                component: ValorProcedimento,
            },
            {
                name: "Cadastro",
                key: "novo-valorProcedimento",
                route: "/auxiliares/valorProcedimento/cadastro",
                component: CadastroValorProcedimento,
            },
        ],
    },
    {
        type: "title",
        title: "ESTOQUE",
        key: "title-estoque",
    },
    {
        type: "collapse",
        name: "Almoxarifado",
        key: "almoxarifado",
        icon: <IoArrowForwardCircleOutline size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "almoxarifado-lista",
                route: "/auxiliares/almoxarifado",
                component: Almoxarifado,
            },
            {
                name: "Cadastro",
                key: "novo-almoxarifado",
                route: "/auxiliares/almoxarifado/cadastro",
                component: CadastroAlmoxarifado,
            },
        ],
    },
    {
        type: "collapse",
        name: "Compras",
        key: "compras",
        icon: <IoArrowForwardCircleOutline size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "compras-lista",
                route: "/auxiliares/compras",
                component: Compras,
            },
            {
                name: "Cadastro",
                key: "novo-compras",
                route: "/auxiliares/compras/cadastro",
                component: CadastroCompras,
            },
        ],
    },
    {
        type: "collapse",
        name: "Controle Estoque",
        key: "controleEstoque",
        icon: <IoArrowForwardCircleOutline size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "controleEstoque-lista",
                route: "/auxiliares/controleEstoque",
                component: ControleEstoque,
            },
            {
                name: "Cadastro",
                key: "novo-controleEstoque",
                route: "/auxiliares/controleEstoque/cadastro",
                component: CadastroControleEstoque,
            },
        ],
    },
    {
        type: "collapse",
        name: "Cotação",
        key: "cotacao",
        icon: <IoArrowForwardCircleOutline size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "cotacao-lista",
                route: "/auxiliares/cotacao",
                component: Cotacao,
            },
            {
                name: "Cadastro",
                key: "novo-cotacao",
                route: "/auxiliares/cotacao/cadastro",
                component: CadastroCotacao,
            },
        ],
    },
    {
        type: "title",
        title: "AUX. ESTOQUE",
        key: "title-produto",
    },
    {
        type: "collapse",
        name: "Fornecedores",
        key: "fornecedor",
        icon: <IoArrowForwardCircleOutline size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "fornecedor-lista",
                route: "/auxiliares/fornecedor",
                component: Fornecedor,
            },
            {
                name: "Cadastro",
                key: "novo-fornecedor",
                route: "/auxiliares/fornecedor/cadastro",
                component: CadastroFornecedor,
            },
        ],
    },
    {
        type: "collapse",
        name: "Categorias (produtos)",
        key: "categoriaProduto",
        icon: <IoArrowForwardCircleOutline size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "categoriaProduto-lista",
                route: "/auxiliares/categoriaProduto",
                component: CategoriaProduto,
            },
            {
                name: "Cadastro",
                key: "novo-categoriaProduto",
                route: "/auxiliares/categoriaProduto/cadastro",
                component: CadastroCategoriaProduto,
            },
        ],
    },
    {
        type: "collapse",
        name: "Produtos",
        key: "produto",
        icon: <IoArrowForwardCircleOutline size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "produto-lista",
                route: "/auxiliares/produto",
                component: Produto,
            },
            {
                name: "Cadastro",
                key: "novo-produto",
                route: "/auxiliares/produto/cadastro",
                component: CadastroProduto,
            },
        ],
    },
    {
        type: "collapse",
        name: "Kits produtos",
        key: "KitsMateriais",
        icon: <IoArrowForwardCircleOutline size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "KitsMateriais-lista",
                route: "/auxiliares/kitMaterialProcedimento",
                component: KitsMateriais,
            },
            {
                name: "Cadastro",
                key: "novo-kit",
                route: "/auxiliares/kitMaterialProcedimento/cadastro",
                component: CadastroKitMateriais,
            },
        ],
    },
    {
        type: "title",
        title: "ADMINISTRADOR",
        key: "title-admin",
    },
    {
        type: "collapse",
        name: "Gestão do sistema",
        key: "gestaosistema",
        icon: <IoArrowForwardCircleOutline size="18px"/>,
        collapse: [
            {
                name: "Painel",
                key: "filaSms",
                route: "/auxiliares/gestaoSistema",
                component: gestaoSistema,
            },
        ],
    },
    {
        type: "collapse",
        name: "Funções gerais",
        key: "funcoesGerais",
        icon: <IoArrowForwardCircleOutline size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "filaSms",
                route: "/administrador/funcoes",
                component: FuncoesAdministradorHome,
            },
        ],
    },
    {
        type: "collapse",
        name: "Laminário/Blocoteca",
        key: "laminario",
        icon: <IoSearchCircleOutline size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "laminario-lista",
                route: "/auxiliares/laminario",
                component: Laminario,
            },
            {
                name: "Cadastro",
                key: "novo-laminario",
                route: "/auxiliares/laminario/cadastro",
                component: CadastroLaminario,
            },
        ],
    },
    {
        type: "title",
        title: "LANÇ. MANUAIS",
        key: "title-manuais",
    },
    {
        type: "collapse",
        name: "Cobranca Exame",
        key: "cobrancaExame",
        icon: <IoArrowForwardCircleOutline size="18px"/>,
        collapse: [
            {
                name: "Lista",
                key: "cobrancaExame-lista",
                route: "/auxiliares/cobrancaExame",
                component: CobrancaExame,
            },
            {
                name: "Cadastro",
                key: "novo-cobrancaExame",
                route: "/auxiliares/cobrancaExame/cadastro",
                component: CadastroCobrancaExame,
            },
        ],
    },
];

export default routes;
