import { useEffect, useState } from "react";
import { toast } from "react-toastify";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import axios from "axios";
import DataTable from "examples/Tables/DataTable";

import { useHistory, withRouter } from "react-router-dom";

// Soft UI Dashboard PRO React example components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import swal from "@sweetalert/with-react";
import withReactContent from "sweetalert2-react-content";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BounceLoader, ClipLoader } from "react-spinners";
import SuiInput from "components/SuiInput";
import SuiButton from "../../../../components/SuiButton";
import { dateEnBr, portadorDeficiencia } from "../../../../utils";
import Paginacao from "../../../../components/paginacao";

function Paciente() {
  const [paciente, setPaciente] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [links, setLinks] = useState([]);
  const [idRemove, setIdRemove] = useState();
  const history = useHistory();
  const [page, setPage] = useState("1");
  const [searchTerm, setSearchTerm] = useState("");
  const [pesquisa, setPesquisa] = useState([]);
  const [atualizaLista, setAtualizaLista] = useState(1);

  const excluirItem = async (id) => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };
    await axios
      .delete(`${window.url}ws/paciente/${id}`, config)
      .then((res) => {
        if (res.status === 200 || res.status === 204) {
          swal("Agilie", "Item removido com sucesso!.", "success");
          setIdRemove(id);
        }
      })
      .catch(() => {
        swal("Agilie", "Ops! Houve um erro!", "error");
      });
  };

  const excluir = async (id) => {
    const MySwal = withReactContent(Swal);
    const self = this;

    MySwal.fire({
      title: "Excluir cadastro",
      text: `Tem certeza que deseja excluir?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        excluirItem(id);
      }
    });
  };


  const handleSearch = (searchPage = 1) => {

    axios
      .get(`${window.url}ws/paciente/buscar/paciente`, {
        params: {
          buscar: searchTerm,
          page: searchPage,
        },
      })
      .then((response) => {
        if (searchTerm !== "") {
          if (response.data.data.length === 0) {
            toast.info("Nenhum item encontrado!");
          } else {
            setPesquisa(response);
            setLinks(response.data.links);
            setPage(searchPage);
            if (response.data.current_page === 1) {
              setPage('1');
            }
          }
        } else {
          setPesquisa([]);
          setPage('1');
          setAtualizaLista(atualizaLista + 1);

        }
      })
      .catch(() => {
        toast.error("Ops! Houve um erro na pesquisa!");
      });
  };

  const handlePageChange = (newPage) => {
    if (searchTerm !== "") {
      handleSearch(newPage);
    } else {
      setPage(newPage);
    }
  };

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    setIsLoading(true);

    await axios
      .get(`${window.url}ws/paciente?page=${page}`, config)
      .then((res) => {
        setPaciente([]);
        const dados = pesquisa.data && pesquisa.data.data.length > 0 ? pesquisa.data.data : res.data.data;
        // eslint-disable-next-line array-callback-return
        dados.map((item) => {
          // eslint-disable-next-line no-shadow
          setPaciente((paciente) => [
            ...paciente,
            {
              id: item.id,
              nome: item.nome,
              nome_mae: item.nome_mae,
              data_nascimento: item.data_nascimento,
              rg: item.rg,
              cpf: item.cpf,
              doc_estrangeiro: item.doc_estrangeiro,
              genero: item.genero,
              idade: item.idade,
              telefone: item.telefone,
              celular: item.celular,
              email: item.email,
              observacao: item.observacao,
              carteirinha: item.carteirinha,
              /* FUNÇÃO DE EDIÇÃO */
              functions: (
                <SuiBox ml={-1.325}>
                  <Tooltip title="Editar" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={() => {
                        history.push(`/auxiliares/paciente/editar/${item.id}/${item.uuid}`);
                      }}
                    >
                      <Icon>edit</Icon>
                    </SuiButton>
                  </Tooltip>
                  {/* <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      history.push(`/auxiliares/paciente/editar/${item.id}/${item.uuid}`);
                    }}
                  >
                    <Icon>edit</Icon>
                  </SuiButton> */}

                  {/* <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      excluir(item.id);
                    }}
                  >
                    <Icon>delete</Icon>
                  </SuiButton> */}

                  <Tooltip title="Endereço" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={() => {
                        history.push(`/auxiliares/paciente/enderecos/${item.id}/${item.uuid}`);
                      }}
                      style={{ marginLeft: '3px' }}
                    >
                      <Icon>home</Icon>
                    </SuiButton>
                  </Tooltip>
                  {/* <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      history.push(`/auxiliares/paciente/enderecos/${item.id}/${item.uuid}`);
                    }}
                  >
                    <Icon>home</Icon>
                  </SuiButton> */}
                  <Tooltip title="Convênio & Carteirinha" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={() => {
                        history.push(`/auxiliares/paciente/convenios/${item.id}/${item.uuid}`);

                      }}
                      style={{ marginLeft: '3px' }}
                    >
                      <Icon>dvr</Icon>
                    </SuiButton>
                  </Tooltip>
                  {/* <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      history.push(`/auxiliares/paciente/convenios/${item.id}/${item.uuid}`);
                    }}
                  >
                    <Icon>dvr</Icon>
                  </SuiButton> */}
                  <Tooltip title="Contato" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={() => {
                        history.push(`/auxiliares/paciente/contatos/${item.id}/${item.uuid}`);
                      }}
                      style={{ marginLeft: '3px' }}
                    >
                      <Icon>book-bookmark</Icon>
                    </SuiButton>
                  </Tooltip>
                  {/* <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      history.push(`/auxiliares/paciente/contatos/${item.id}/${item.uuid}`);
                    }}
                  >
                    <Icon>book-bookmark</Icon>
                  </SuiButton> */}
                </SuiBox>
              ),
            },
          ]);
        });
        setIsLoading(false);
        if (pesquisa.length === 0) {
          setLinks(res.data.links);
        }
      })
      .catch(() => { });
  };


  useEffect(() => {
    if (idRemove !== null) {
      setPaciente(paciente.filter((lista) => lista.id !== idRemove));
    }
  }, [idRemove]);

  const table = {
    /* EDITAR CAMPOS */
    columns: [
      // { Header: "#", accessor: "id", width: "2%" },
      { Header: "Nome", accessor: "nome", width: "15%" },
      {
        Header: "Carteirinha",
        accessor: "carteirinha",
        Cell: ({ value }) => value || "Não informado",
        width: "15%",
      },
      {
        Header: "Nascimento",
        accessor: "data_nascimento",
        Cell: ({ value }) => value ? dateEnBr(value) : "Não informado",
        width: "15%",
      },
      {
        Header: "Telefone",
        accessor: "telefone",
        Cell: ({ value }) => value || "Não informado",
        width: "15%",
      },
      {
        Header: "Celular",
        accessor: "celular",
        Cell: ({ value }) => value || "Não informado",
        width: "15%",
      },
      { Header: "Opções", accessor: "functions", width: "1%" },
    ],

    rows: paciente,
  };

  useEffect(() => {
    get();
  }, [page, atualizaLista]);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <SuiBox p={3} display="flex" justifyContent="space-between">
          <SuiBox>
            <SuiTypography variant="h5" fontWeight="medium">
              Paciente
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" textColor="text">
              Lista de Paciente
            </SuiTypography>
          </SuiBox>
          <SuiBox p={1}>
            <SuiButton
              variant="gradient"
              buttonColor="info"
              onClick={() => {
                history.push("/auxiliares/paciente/Cadastro");
              }}
            >
              Cadastrar
            </SuiButton>
          </SuiBox>
        </SuiBox>
        <SuiBox p={3.5} mb={1} width="20rem" ml="auto">
          {paciente.length > 0 && (
            <SuiInput
              placeholder="Busca & Enter"
              value={searchTerm}
              onChange={(event) => {
                setSearchTerm(event.target.value);
                if (event.target.value === "") {
                  setPesquisa([]);
                  setPage('1');
                  setAtualizaLista(atualizaLista + 1);
                }
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSearch();
                }
              }}
            />
          )}
        </SuiBox>
        {/* eslint-disable-next-line no-nested-ternary */}
        {paciente.length > 0 ? (
          <>
            <DataTable table={table} mb={2} />
            <Paginacao links={links} page={page} setPage={handlePageChange} />
          </>
        ) : (
          <SuiBox p={3} lineHeight={1}>
            <small>Nenhum registro encontrado.</small>
          </SuiBox>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(Paciente);