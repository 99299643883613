import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import axios from "axios";
import DataTable from "examples/Tables/DataTable";

import { useHistory, withRouter } from "react-router-dom";

// Soft UI Dashboard PRO React example components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BounceLoader } from "react-spinners";
import Swal from "sweetalert2";
import swal from "@sweetalert/with-react";
import withReactContent from "sweetalert2-react-content";
import Grid from "@mui/material/Grid";
import SuiButton from "../../../../components/SuiButton";
import Paginacao from "../../../../components/paginacao";

function ProcedimentoEntradaExame(props) {
  const [procedimentoEntradaExame, setProcedimentoEntradaExame] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [idEntradaExame, setIdEntradaExame] = useState(true);
  const [links, setLinks] = useState([]);
  const [page, setPage] = useState("1");
  const [identificador, setIdentificador] = useState('');
  const [nomePaciente, setNomePaciente] = useState('');
  const history = useHistory();
  const [idRemove, setIdRemove] = useState(null);
  // const [itemCount, setItemCount] = useState();
  const uuid = props.match.params.id;


  const getEntradaExame = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/entradaExame/${props.match.params.id}`, config)
      .then((res) => {
        setIdentificador(res.data.entrada_exame.identificador);
        setNomePaciente(res.data.entrada_exame.nome);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
    // setIsLoading(false);
  };

  const destroy = async (id) => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };
    await axios
      .delete(`${window.url}ws/procedimentoEntradaExame/${id}`, config)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success("Procedimento excluído com sucesso");
          console.log("Entrou no destroy")
          setPage("1");
          setIdRemove(id);
        }
      })
      .catch(() => {
        swal("Agilie", "Ops! Houve um erro!", "error");
      });
  };

  const excluir = async (id, itemCount) => {
    if (itemCount <= 1) {
      swal("Atenção", "É obrigatório ter pelo menos um item!", "warning");
      return;
    }
    const MySwal = withReactContent(Swal);
    const self = this;

    MySwal.fire({
      title: "Excluir cadastro",
      text: `Tem certeza que deseja excluir?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        destroy(id);
      }
    });
  };


  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };


    setIsLoading(true);

    await axios
      .get(`${window.url}ws/procedimentoEntradaExame/${props.match.params.id}/list?page=${page}`, config)
      .then((res) => {
        const itemCount = res.data.total;
        setProcedimentoEntradaExame([]);
        setIdEntradaExame(props.match.params.id);
        // eslint-disable-next-line array-callback-return
        res.data.data.map((item) => {
          // eslint-disable-next-line no-shadow
          setProcedimentoEntradaExame((procedimentoEntradaExame) => [
            ...procedimentoEntradaExame,
            /* VARIÁVEIS DO BANCO DE DADOS */
            {

              id: item.id,
              id_entrada_exame: item.id_entrada_exame,
              id_procedimento: item.id_procedimento,
              codigo: item.codigo,
              nome_procedimento: item.nome_procedimento,
              quantidade: item.quantidade,
              /* FUNÇÃO DE EDIÇÃO */
              functions: (
                <SuiBox ml={-1.325}>
                  {/* <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      history.push(`/auxiliares/procedimentoEntradaExame/editar/${item.id}/${item.uuid}`);
                    }}
                  >
                    <Icon>edit</Icon>
                  </SuiButton> */}
                  <Tooltip title="Editar" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={() => {
                        history.push(`/auxiliares/procedimentoEntradaExame/editar/${item.id}/${item.uuid}`);
                      }}
                    >
                      <Icon>edit</Icon>
                    </SuiButton>
                  </Tooltip>

                  {/* <SuiButton
                    variant="gradient"
                    color="error"
                    onClick={() => {
                      excluir(item.id, itemCount);
                    }}
                  >
                    <Icon>delete</Icon>
                  </SuiButton> */}
                         <Tooltip title="Excluir" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={() => {
                        excluir(item.id, itemCount);
                      }}
                      style={{ marginLeft: '3px' }}
                    >
                      <Icon>delete</Icon>
                    </SuiButton>
                  </Tooltip>

                </SuiBox>
              ),
            },
          ]);
        });
        setIsLoading(false);
        setLinks(res.data.links);
      })
      .catch(() => { });
  };


  const table = {
    /* EDITAR CAMPOS */
    columns: [
      // { Header: "id ", accessor: "id", width: "4%" },
      // { Header: "Exame", accessor: "id_entrada_exame", width: "4%" },
      { Header: "Código do procedimento", accessor: "codigo", width: "25%" },
      { Header: "Nome do procedimento", accessor: "nome_procedimento", width: "25%" },
      { Header: "Quantidade", accessor: "quantidade", width: "10%" },
      { Header: "Opções", accessor: "functions", width: "1%" },
    ],

    rows: procedimentoEntradaExame,
  };


  // useEffect(() => {
  //   console.log("Entrou")
  //   setItemCount(procedimentoEntradaExame.length);
  // }, [procedimentoEntradaExame]);

  useEffect(() => {
    get();
  }, [page, idRemove]);

  useEffect(async () => {
    await getEntradaExame();
  }, []);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <SuiBox p={3} lineHeight={1}>
          <SuiTypography variant="h5" fontWeight="medium">
            Procedimento da entrada de exames
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            Lista de procedimentos
          </SuiTypography>
          <Grid item xs={12} md={12}>
            <SuiBox mb={0} ml={0.5} mt={1} lineHeight={0} display="inline-block">
              <SuiTypography variant="h6" fontWeight="medium">
                {identificador}
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid item xs={12} md={12}>
            <SuiBox mb={0} ml={0.5} mt={1} lineHeight={0} display="inline-block">
              <SuiTypography variant="h6" fontWeight="medium">
                {nomePaciente}
              </SuiTypography>
            </SuiBox>
          </Grid>
          <SuiBox
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            mt={1}
            lineHeight={1}
            width="100%"
            position="absolute"
            top="1rem"
            right="1rem"
          >
            <SuiButton
              variant="gradient"
              buttonColor="light"
              size="medium"
              onClick={() => {
                history.goBack();
              }}
            >
              <SuiTypography variant="h6" fontWeight="medium">
                Voltar
              </SuiTypography>
            </SuiButton>
            <SuiBox ml={1}>
              <SuiButton
                buttonColor="info"
                size="medium"
                onClick={() => {
                  history.push(`/auxiliares/entradaExame/procedimento/cadastro/${idEntradaExame}/${uuid}`);
                }}
              >
                <SuiTypography variant="h6" fontWeight="medium">
                  Novo
                </SuiTypography>
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>
        {/* eslint-disable-next-line no-nested-ternary */}
        {procedimentoEntradaExame.length > 0 ? (
          <>
            <DataTable table={table} canSearch />
            <Paginacao links={links} page={page} setPage={setPage} />
          </>
        ) : (
          <SuiBox p={3} lineHeight={1}>
            <small>Nenhum registro encontrado.</small>
          </SuiBox>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(ProcedimentoEntradaExame);