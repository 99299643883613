import React, { useEffect, useState } from "react";
import axios from "axios";
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { toast } from "react-toastify";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiEditor from "components/SuiEditor";
import SuiButton from "components/SuiButton";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { withRouter } from "react-router-dom";
import swal from "@sweetalert/with-react";
import { BounceLoader } from "react-spinners";
import { BsEraserFill, BsRecordCircle, BsStopCircle } from "react-icons/all";
import { dateEnBr } from "../../../../utils";
import SuiDropzone from "./imagem";
import Painel from "./painel";




function TabPanel01({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function TabPanel02({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}



function VisualizarLaudo(props) {



  const [value01, setValue01] = useState(0);
  const [value02, setValue02] = useState(0);
  const [idEntradaExame, setIdEntradaExame] = useState();
  const [idBancada, setIdBancada] = useState();
  const [profissionalSaude, setProfissionalSaude] = useState('');
  // const [profissionalSaudeAux, setProfissionalSaudeAux] = useState('');
  const [identificador, setIdentificador] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [carregar, setCarregar] = useState(false);

  const [casoStatus, setCasoStatus] = useState();
  const [laudoSelecionado, setLaudoSelecionado] = useState();

  const [diagnosticoDescritivo, setDiagnosticoDescritivo] = useState("");
  const [dadosClinicosLaudo, setDadosClinicosLaudo] = useState("");
  const [dadosTecnicosLaudo, setDadosTecnicosLaudo] = useState("");
  const [macroscopiaLaudo, setMacroscopiaLaudo] = useState("");
  const [microscopiaLaudo, setMicroscopiaLaudo] = useState("");
  const [diagHistoLaudo, setDiagHistoLaudo] = useState("");
  const [conclusaoLaudo, setConclusaoLaudo] = useState("");
  const [diagNotasLaudo, setDiagNotasLaudo] = useState("");


  const [diagnosticoDescritivoStatus, setDiagnosticoDescritivoStatus] = useState(false);
  const [dadosClinicosLaudoStatus, setDadosClinicosLaudoStatus] = useState(false);
  const [dadosTecnicosLaudoStatus, setDadosTecnicosLaudoStatus] = useState(false);
  const [macroscopiaLaudoStatus, setMacroscopiaLaudoStatus] = useState(false);
  const [microscopiaLaudoStatus, setMicroscopiaLaudoStatus] = useState(false);
  const [diagHistoLaudoStatus, setDiagHistoLaudoStatus] = useState(false);
  const [conclusaoStatus, setConclusaoStatus] = useState(false);
  const [diagNotasLaudoStatus, setDiagNotasLaudoStatus] = useState(false);
  const [painelLaudoStatus, setPainelLaudoStatus] = useState(false);
  const [imagemLaudoStatus, setImagemLaudoStatus] = useState(false);

  const [diagnosticoDescritivoIndex, setDiagnosticoDescritivoIndex] = useState();
  const [dadosClinicosLaudoIndex, setDadosClinicosLaudoIndex] = useState();
  const [dadosTecnicosLaudoIndex, setDadosTecnicosLaudoIndex] = useState();
  const [macroscopiaLaudoIndex, setMacroscopiaLaudoIndex] = useState();
  const [microscopiaLaudoIndex, setMicroscopiaLaudoIndex] = useState();
  const [diagHistoLaudoIndex, setDiagHistoLaudoIndex] = useState();
  const [conclusaoIndex, setConclusaoIndex] = useState();
  const [diagNotasLaudoIndex, setDiagNotasLaudoIndex] = useState();
  const [painelLaudoIndex, setPainelLaudoIndex] = useState();
  const [imagemLaudoIndex, setImagemLaudoIndex] = useState();

  const [isListening, setIsListening] = useState(false);
  const [historico, setHistorico] = useState([]);
  const [materialBiologico, setMaterialBiologico] = useState([]);
  const [procedimento, setProcedimento] = useState([]);
  const [entradaExame, setEntradaExame] = useState([]);


  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    return <span>Seu navegador não suporta o laudo ditado.</span>;
  }



  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/laudo/${props.match.params.id}`, config)
      .then((res) => {
        setEntradaExame(res.data.laudo);
        const laudoObj = res.data.laudo;
        setIdEntradaExame(laudoObj.id_entrada_exame);
        setIdentificador(laudoObj.identificador);
        setIdBancada(laudoObj.id_bancada);
        setProfissionalSaude(laudoObj.nome_profissional);
        setDiagnosticoDescritivo(laudoObj.diagnostico_descritivo);
        setDadosClinicosLaudo(laudoObj.laudo_dados_clinicos);
        setDadosTecnicosLaudo(laudoObj.laudo_dados_tecnicos);
        setMacroscopiaLaudo(laudoObj.laudo_macroscopia);
        setMicroscopiaLaudo(laudoObj.laudo_microscopia);
        setDiagHistoLaudo(laudoObj.laudo_diag_histo);
        setConclusaoLaudo(laudoObj.laudo_conclusao);
        setDiagNotasLaudo(laudoObj.laudo_diag_notas);
        // setIsLoading(false);
        setCarregar(true);

      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };


  useEffect(async () => {
    await get();
  }, []);


  const getHistoricoAmostra = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/historicoMaterial/entrada/${idEntradaExame}`, config)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setHistorico(res.data);
          setMaterialBiologico(res.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
    // setIsLoading(false);
  };


  const getProcedimento = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/procedimentoEntradaExame/${idEntradaExame}/list`, config)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setProcedimento(res.data.data);

        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
    // setIsLoading(false);
  };




  useEffect(() => {

    if (idBancada !== undefined) {

      if (idBancada === 1 || idBancada === 2 || idBancada === 8 || idBancada === 9) {
        setDadosClinicosLaudoStatus(true);
        setDadosClinicosLaudoIndex(0);
        setMacroscopiaLaudoStatus(true);
        setMacroscopiaLaudoIndex(1);
        setMicroscopiaLaudoStatus(true);
        setMicroscopiaLaudoIndex(2);
        setDiagHistoLaudoStatus(true);
        setDiagHistoLaudoIndex(3);
        setDiagNotasLaudoStatus(true);
        setDiagNotasLaudoIndex(4);
        setCasoStatus(1);
        setIsLoading(false);

      } else if (idBancada === 7) {
        setDadosTecnicosLaudoStatus(true);
        setDadosTecnicosLaudoIndex(0);
        setConclusaoStatus(true);
        setConclusaoIndex(1);
        setDiagNotasLaudoStatus(true);
        setDiagNotasLaudoIndex(2);
        setPainelLaudoStatus(true);
        setPainelLaudoIndex(3);
        setImagemLaudoStatus(true);
        setImagemLaudoIndex(4);
        setCasoStatus(2);
        setIsLoading(false);
      } else {
        setDiagnosticoDescritivoStatus(true);
        setDiagnosticoDescritivoIndex(0);
        setCasoStatus(3);
        setIsLoading(false);
      }
    }
  }, [idBancada]);


  const save = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .put(
        `${window.url}ws/laudo/${props.match.params.id}`,
        {
          id_entrada_exame: idEntradaExame,
          laudo_dados_clinicos: dadosClinicosLaudo,
          laudo_dados_tecnicos: dadosTecnicosLaudo,
          laudo_macroscopia: macroscopiaLaudo,
          laudo_microscopia: microscopiaLaudo,
          laudo_diag_histo: diagHistoLaudo,
          laudo_conclusao: conclusaoLaudo,
          laudo_diag_notas: diagNotasLaudo,
          diagnostico_descritivo: diagnosticoDescritivo,

        },
        config
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          swal("Agilie", "Laudo atualizado com sucesso.", "success");
          props.history.goBack();
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };



  const handleChanges01 = (event, newValue01) => {
    setValue01(newValue01);
  };

  const handleChanges02 = (event, newValue02) => {
    setValue02(newValue02);
  };

  const handleListing = () => {
    setIsListening(true);
    SpeechRecognition.startListening({
      continuous: true,
      language: "pt-br",
    });
  };

  const stopHandle = () => {
    setIsListening(false);
    SpeechRecognition.stopListening();

    switch (laudoSelecionado) {
      case 'diagnosticoDescritivoIndex':
        if (diagnosticoDescritivo === '<p>Nenhum modelo selecionado</p>') {
          setDiagnosticoDescritivo(transcript);
        } else {
          setDiagnosticoDescritivo(prevState => `${prevState} ${transcript.trim()}`);
        }
        break;
      case 'dadosClinicosLaudoIndex':

        if (dadosClinicosLaudo === '<p>Nenhum modelo selecionado</p>') {
          setDadosClinicosLaudo(transcript);
        } else {
          setDadosClinicosLaudo(prevState => `${prevState} ${transcript.trim()}`);
        }

        break;
      case 'dadosTecnicosLaudoIndex':

        if (dadosTecnicosLaudo === '<p>Nenhum modelo selecionado</p>') {
          setDadosTecnicosLaudo(transcript);
        } else {
          setDadosTecnicosLaudo(prevState => `${prevState} ${transcript.trim()}`);
        }

        break;
      case 'macroscopiaLaudoIndex':
        if (macroscopiaLaudo === '<p>Nenhum modelo selecionado</p>') {
          setMacroscopiaLaudo(transcript);
        } else {
          setMacroscopiaLaudo(prevState => `${prevState} ${transcript.trim()}`);
        }
        break;
      case 'microscopiaLaudoIndex':
        if (microscopiaLaudo === '<p>Nenhum modelo selecionado</p>') {
          setMicroscopiaLaudo(transcript);
        } else {
          setMicroscopiaLaudo(prevState => `${prevState} ${transcript.trim()}`);
        }
        break;
      case 'diagHistoLaudoIndex':
        if (diagHistoLaudo === '<p>Nenhum modelo selecionado</p>') {
          setDiagHistoLaudo(transcript);
        } else {
          setDiagHistoLaudo(prevState => `${prevState} ${transcript.trim()}`);
        }
        break;
      case 'conclusaoIndex':
        if (conclusaoLaudo === '<p>Nenhum modelo selecionado</p>') {
          setConclusaoLaudo(transcript);
        } else {
          setConclusaoLaudo(prevState => `${prevState} ${transcript.trim()}`);
        }
        break;
      case 'diagNotasLaudoIndex':
        if (diagNotasLaudo === '<p>Nenhum modelo selecionado</p>') {
          setDiagNotasLaudo(transcript);
        } else {
          setDiagNotasLaudo(prevState => `${prevState} ${transcript.trim()}`);
        }
        break;
      default:
        if (diagnosticoDescritivo === '<p>Nenhum modelo selecionado</p>') {
          setDiagnosticoDescritivo(transcript);
        } else {
          setDiagnosticoDescritivo(prevState => `${prevState} ${transcript.trim()}`);
        }
        break;
    }
  };

  useEffect(() => {

    if (value02 === diagnosticoDescritivoIndex) {
      setLaudoSelecionado('diagnosticoDescritivoIndex')
    } else if (value02 === dadosClinicosLaudoIndex) {
      setLaudoSelecionado('dadosClinicosLaudoIndex')
    } else if (value02 === dadosTecnicosLaudoIndex) {
      setLaudoSelecionado('dadosTecnicosLaudoIndex')
    } else if (value02 === macroscopiaLaudoIndex) {
      setLaudoSelecionado('macroscopiaLaudoIndex')
    } else if (value02 === microscopiaLaudoIndex) {
      setLaudoSelecionado('microscopiaLaudoIndex')
    } else if (value02 === diagHistoLaudoIndex) {
      setLaudoSelecionado('diagHistoLaudoIndex')
    } else if (value02 === conclusaoIndex) {
      setLaudoSelecionado('conclusaoIndex')
    } else if (value02 === diagNotasLaudoIndex) {
      setLaudoSelecionado('diagNotasLaudoIndex')
    }

  }, [value02, casoStatus]);

  const handleReset = () => {
    stopHandle();
    resetTranscript();
  };

  const handleChange = (content, delta, source, editor) => {
    setDiagnosticoDescritivo(content);
  };

  const handleChangeLaudoDadosClinicos = (content, delta, source, editor) => {
    setDadosClinicosLaudo(content);
  };

  const handleChangeLaudoDadosTecnicos = (content, delta, source, editor) => {
    setDadosTecnicosLaudo(content);
  };

  const handleChangeLaudoMacroscopia = (content, delta, source, editor) => {
    setMacroscopiaLaudo(content);
  };

  const handleChangeLaudoMicroscopia = (content, delta, source, editor) => {
    setMicroscopiaLaudo(content);
  };

  const handleChangeLaudoDiagHisto = (content, delta, source, editor) => {
    setDiagHistoLaudo(content);
  };

  const handleChangeLaudoConclusao = (content, delta, source, editor) => {
    setConclusaoLaudo(content);
  };

  const handleChangeLaudoDiagNotas = (content, delta, source, editor) => {
    setDiagNotasLaudo(content);
  };

  useEffect(async () => {
    await getHistoricoAmostra();
    await getProcedimento();
  }, [carregar && idEntradaExame]);


  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={2} mb={4}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={12}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12} md={9} lg={4}>
                    <SuiTypography variant="h6" fontWeight="medium">
                      Visualizar Laudo
                    </SuiTypography>
                    <SuiTypography variant="button" fontWeight="regular" textColor="text">
                      Visualização do laudo do paciente
                    </SuiTypography>
                    <Grid item xs={12} md={12}>
                      <SuiBox mb={0} ml={0.5} mt={1} lineHeight={0} display="inline-block">
                        <SuiTypography variant="h6" fontWeight="medium">
                          {identificador}
                        </SuiTypography>
                      </SuiBox>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12} md={12}>
                    <SuiBox lineHeight={1} display="inline-block" ml={0.5}>
                      <SuiTypography variant="h6" fontWeight="medium">
                        Profissional de Saúde:
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox mb={0} ml={0.5} mt={0} lineHeight={0} display="inline-block">
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="regular"
                        textColor="text"
                      >
                        {profissionalSaude}
                      </SuiTypography>
                    </SuiBox>
                  </Grid> */}
                  {profissionalSaude !== null ? (
                    <Grid item xs={12} md={12}>
                      <SuiBox lineHeight={0} display="inline-block" ml={0.5}>
                        <SuiTypography variant="h6" fontWeight="medium">
                          Profissional de Saúde:
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox mb={0} ml={0.5} mt={0} lineHeight={0} display="inline-block">
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="regular"
                          textColor="text"
                        >
                          {profissionalSaude}
                        </SuiTypography>
                      </SuiBox>
                    </Grid>
                  ) : null}
                </Grid>
              </SuiBox>
            </Card>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Card>
              <SuiBox pt={3} px={3}>
                <Tabs 
                value={value01} 
                onChange={handleChanges01}
                sx={{
                  '.MuiTabs-indicator': {
                    backgroundImage: `linear-gradient(to right, ${sessionStorage.getItem('theme') === 'info' ? '#21C1FD' : '#85E32E'}, ${sessionStorage.getItem('theme') === 'info' ? '#21C1FD' : '#85E32E'})`,
                    boxShadow: '0 0 2px rgba(0,0,0,0.5)',
                    marginRight: '6px', 
                  },
                  '.MuiTab-root': {
                    color: 'rgba(0, 0, 0, 0.87)',
                    '&:not(.Mui-selected)': {
                      backgroundColor: '#f4f4f4',
                      boxShadow: '0 0 2px rgba(0,0,0,0.5)',
                      marginLeft: '6px', 
                      marginRight: '6px', 
                    },
                  },
                  '.MuiTab-root.Mui-selected': {
                    fontWeight: 'bold',
                    marginLeft: '6px', 
                    marginRight: '6px', 
                    boxShadow: '0 0 5px rgba(0,0,0,0.3)', 

                  }
                }}
                >
                  <Tab label="Entrada exame" />
                  <Tab label="Procedimento" />
                  <Tab label="Material biológico" />
                  <Tab label="Observações histotécnico" />
                </Tabs>
                <TabPanel01 value={value01} index={0}>
                  <Grid item xs={12} lg={12}>
                    <SuiBox p={1} lineHeight={1}>
                      <table style={{ borderSpacing: '0 10px', width: '100%' }}>
                        <thead>
                          <tr>
                            <th style={{ padding: '10px', fontSize: '13px', width: '10%', textAlign: 'left' }}>Entrada exame</th>
                            <th style={{ padding: '10px', fontSize: '13px', width: '15%', textAlign: 'left' }}>Paciente</th>
                            <th style={{ padding: '10px', fontSize: '13px', width: '15%', textAlign: 'left' }}>Data de nascimento</th>
                            <th style={{ padding: '10px', fontSize: '13px', width: '10%', textAlign: 'left' }}>Idade</th>
                            <th style={{ padding: '10px', fontSize: '13px', width: '15%', textAlign: 'left' }}>Convênio</th>
                            <th style={{ padding: '10px', fontSize: '13px', width: '15%', textAlign: 'left' }}>Instituição</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: 'none' }}>{dateEnBr(entradaExame.data_entrada)}</td>
                            <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: 'none' }}>{entradaExame.nome}</td>
                            <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: 'none' }}>{entradaExame.data_nascimento !== null ? dateEnBr(entradaExame.data_nascimento) : 'Não informado'}</td>
                            <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: 'none' }}>{entradaExame.idade !== null ? entradaExame.idade : 'Não informado'}</td>
                            <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: 'none' }}>{entradaExame.nome_convenio}</td>
                            <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: 'none' }}>{entradaExame.razao_social}</td>
                          </tr>
                        </tbody>
                      </table>
                    </SuiBox>
                  </Grid>
                </TabPanel01>
                <TabPanel01 value={value01} index={1}>
                  <Grid item xs={12} lg={12}>
                    <SuiBox p={1} lineHeight={1}>
                      {Array.isArray(procedimento) && procedimento.length > 0 ? (
                        <table style={{ borderSpacing: '0 10px', width: '100%' }}>
                          <thead>
                            <tr>
                              <th style={{ padding: '10px', fontSize: '13px', width: '25%', textAlign: 'left' }}>ID</th>
                              <th style={{ padding: '10px', fontSize: '13px', width: '25%', textAlign: 'left' }}>Código</th>
                              <th style={{ padding: '10px', fontSize: '13px', width: '25%', textAlign: 'left' }}>Nome do procedimento</th>
                              <th style={{ padding: '10px', fontSize: '13px', width: '25%', textAlign: 'center' }}>Quantidade</th>
                            </tr>
                          </thead>
                          <tbody>
                            {procedimento.map((item, index) => (
                              <tr key={item.id}>
                                <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: index !== procedimento.length - 1 ? '1px solid #ccc' : 'none' }}>{item.id}</td>
                                <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: index !== procedimento.length - 1 ? '1px solid #ccc' : 'none' }}>{item.codigo}</td>
                                <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: index !== procedimento.length - 1 ? '1px solid #ccc' : 'none' }}>{item.nome_procedimento}</td>
                                <td style={{ padding: '10px', fontSize: '13px', textAlign: 'center', borderBottom: index !== procedimento.length - 1 ? '1px solid #ccc' : 'none' }}>{item.quantidade}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <>
                          {procedimento && (
                            <table style={{ borderSpacing: '0 10px', width: '100%' }}>
                              <thead>
                                <tr>
                                  <th style={{ padding: '10px', fontSize: '13px', width: '25%', textAlign: 'left' }}>ID</th>
                                  <th style={{ padding: '10px', fontSize: '13px', width: '25%', textAlign: 'left' }}>Código</th>
                                  <th style={{ padding: '10px', fontSize: '13px', width: '25%', textAlign: 'left' }}>Nome do procedimento</th>
                                  <th style={{ padding: '10px', fontSize: '13px', width: '25%', textAlign: 'center' }}>Quantidade</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: 'none' }}>{procedimento.id}</td>
                                  <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: 'none' }}>{procedimento.codigo}</td>
                                  <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: 'none' }}>{procedimento.nome_procedimento}</td>
                                  <td style={{ padding: '10px', fontSize: '13px', textAlign: 'center', borderBottom: 'none' }}>{procedimento.quantidade}</td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </>
                      )}
                    </SuiBox>
                  </Grid>

                </TabPanel01>
                <TabPanel01 value={value01} index={2}>
                  <Grid item xs={12} lg={12}>
                    <SuiBox p={1} lineHeight={1}>
                      {Array.isArray(materialBiologico) && materialBiologico.length > 0 ? (
                        <table style={{ borderSpacing: '0 10px', width: '100%' }}>
                          <thead>
                            <tr>
                              <th style={{ padding: '10px', fontSize: '13px', width: '5%', textAlign: 'left' }}>ID</th>
                              <th style={{ padding: '10px', fontSize: '13px', width: '10%', textAlign: 'left' }}>Material</th>
                              <th style={{ padding: '10px', fontSize: '13px', width: '10%', textAlign: 'left' }}>Coloração</th>
                              <th style={{ padding: '10px', fontSize: '13px', width: '10%', textAlign: 'left' }}>Nº Blocos</th>
                              <th style={{ padding: '10px', fontSize: '13px', width: '10%', textAlign: 'left' }}>Amostragem</th>
                              <th style={{ padding: '10px', fontSize: '13px', width: '60%', textAlign: 'left' }}>Descrição</th>
                            </tr>
                          </thead>
                          <tbody>
                            {materialBiologico.map((item, index) => (
                              <tr key={item.id}>
                                <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: index !== materialBiologico.length - 1 ? '1px solid #ccc' : 'none' }}>{item.id_item}</td>
                                <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: index !== materialBiologico.length - 1 ? '1px solid #ccc' : 'none' }}>{item.material_biologico && item.material_biologico.replace(/<\/?p>/g, '')}</td>
                                <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: index !== materialBiologico.length - 1 ? '1px solid #ccc' : 'none' }}>{item.coloracao && item.coloracao.replace(/<\/?p>/g, '')}</td>
                                <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: index !== materialBiologico.length - 1 ? '1px solid #ccc' : 'none' }}>{item.numero_bi}</td>
                                <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: index !== materialBiologico.length - 1 ? '1px solid #ccc' : 'none' }}>{item.amostragem && item.amostragem.replace(/<\/?p>/g, '')}</td>
                                <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: index !== materialBiologico.length - 1 ? '1px solid #ccc' : 'none' }}>{item.descricao && item.descricao.replace(/<\/?p>/g, '')}</td>

                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <>
                          {materialBiologico && (
                            <table style={{ borderSpacing: '0 10px', width: '100%' }}>
                              <thead>
                                <tr>
                                  <th style={{ padding: '10px', fontSize: '13px', width: '5%', textAlign: 'left' }}>ID</th>
                                  <th style={{ padding: '10px', fontSize: '13px', width: '10%', textAlign: 'left' }}>Material</th>
                                  <th style={{ padding: '10px', fontSize: '13px', width: '10%', textAlign: 'left' }}>Coloração</th>
                                  <th style={{ padding: '10px', fontSize: '13px', width: '10%', textAlign: 'left' }}>Nº Blocos</th>
                                  <th style={{ padding: '10px', fontSize: '13px', width: '10%', textAlign: 'left' }}>Amostragem</th>
                                  <th style={{ padding: '10px', fontSize: '13px', width: '60%', textAlign: 'left' }}>Descrição</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: 'none' }}>{materialBiologico.id_item}</td>
                                  <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: 'none' }}>{materialBiologico.material_biologico && materialBiologico.material_biologico.replace(/<\/?p>/g, '')}</td>
                                  <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: 'none' }}>{materialBiologico.coloracao && materialBiologico.coloracao.replace(/<\/?p>/g, '')}</td>
                                  <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: 'none' }}>{materialBiologico.numero_bi}</td>
                                  <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: 'none' }}>{materialBiologico.amostragem && materialBiologico.amostragem.replace(/<\/?p>/g, '')}</td>
                                  <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: 'none' }}>{materialBiologico.descricao && materialBiologico.descricao.replace(/<\/?p>/g, '')}</td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </>
                      )}
                    </SuiBox>
                  </Grid>
                </TabPanel01>
                <TabPanel01 value={value01} index={3}>
                  <Grid item xs={12} lg={12}>
                    <SuiBox p={1} lineHeight={1}>
                      {/* <SuiTypography variant="h6" fontWeight="medium" style={{ marginBottom: '10px' }}>
                        Observações do(s) material(ais) biológico(s)
                      </SuiTypography> */}
                      {Array.isArray(historico) && historico.length > 0 ? (
                        <table style={{ borderSpacing: '0 10px', width: '100%' }}>
                          <thead>
                            <tr>
                              <th style={{ padding: '10px', fontSize: '13px', width: '5%', textAlign: 'left' }}>ID</th>
                              <th style={{ padding: '10px', fontSize: '13px', width: '7%', textAlign: 'left' }}>Material</th>
                              <th style={{ padding: '10px', fontSize: '13px', width: '7%', textAlign: 'left' }}>Data</th>
                              <th style={{ padding: '10px', fontSize: '13px', width: '85%', textAlign: 'left' }}>Observações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {historico.map((item, index) => (
                              <tr key={item.id}>
                                <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: index !== historico.length - 1 ? '1px solid #ccc' : 'none' }}>{item.id_item}</td>
                                <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: index !== historico.length - 1 ? '1px solid #ccc' : 'none' }}>{item.material_biologico && item.material_biologico.replace(/<\/?p>/g, '')}</td>
                                <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: index !== historico.length - 1 ? '1px solid #ccc' : 'none' }}>{dateEnBr(item.data_operacao)}</td>
                                <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: index !== historico.length - 1 ? '1px solid #ccc' : 'none' }}>{item.observacao !== null ? item.observacao : ' Nenhuma observação registrada '}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <>
                          {historico && (
                            <table style={{ borderSpacing: '0 10px', width: '100%' }}>
                              <thead>
                                <tr>
                                  <th style={{ padding: '10px', fontSize: '13px', width: '5%', textAlign: 'left' }}>ID</th>
                                  <th style={{ padding: '10px', fontSize: '13px', width: '7%', textAlign: 'left' }}>Material</th>
                                  <th style={{ padding: '10px', fontSize: '13px', width: '7%', textAlign: 'left' }}>Data</th>
                                  <th style={{ padding: '10px', fontSize: '13px', width: '85%', textAlign: 'left' }}>Observações</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: 'none' }}>{historico.id_item}</td>
                                  <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: 'none' }}>{historico.material_biologico && historico.material_biologico.replace(/<\/?p>/g, '')}</td>
                                  <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: 'none' }}>{dateEnBr(historico.data_operacao)}</td>
                                  <td style={{ padding: '10px', fontSize: '13px', textAlign: 'left', borderBottom: 'none' }}>{historico.observacao !== null ? historico.observacao : ' Nenhuma observação registrada '}</td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </>
                      )}
                    </SuiBox>
                  </Grid>
                </TabPanel01>
              </SuiBox>

            </Card>
          </Grid>

          {/* <Grid item xs={12} lg={12}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Laudo parcial ditado
                </SuiTypography>
            
                <SuiTypography variant="overline" color="info" textGradient>
                  {transcript}
                </SuiTypography>
              </SuiBox>
              <SuiBox p={1} lineHeight={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>
                    <SuiButton variant="gradient" color="info" onClick={handleListing}>
                      <BsRecordCircle size="24px" />
                    </SuiButton>
                    <SuiButton variant="gradient" color="info" onClick={stopHandle}>
                      <BsStopCircle size="24px" />
                    </SuiButton>
                    <SuiButton variant="gradient" color="info" onClick={resetTranscript}>
                      <BsEraserFill size="24px" />
                    </SuiButton>
                  </Grid>
                </Grid>
              </SuiBox>
            </Card>
          </Grid> */}


          <Grid item xs={12} lg={12}>
            <Card>
              <SuiBox pt={3} px={3}>
                <Tabs 
                value={value02} 
                onChange={handleChanges02}
                sx={{
                  '.MuiTabs-indicator': {
                    backgroundImage: `linear-gradient(to right, ${sessionStorage.getItem('theme') === 'info' ? '#21C1FD' : '#85E32E'}, ${sessionStorage.getItem('theme') === 'info' ? '#21C1FD' : '#85E32E'})`,
                    boxShadow: '0 0 2px rgba(0,0,0,0.5)',
                    marginRight: '6px', 
                  },
                  '.MuiTab-root': {
                    color: 'rgba(0, 0, 0, 0.87)',
                    '&:not(.Mui-selected)': {
                      backgroundColor: '#f4f4f4',
                      boxShadow: '0 0 2px rgba(0,0,0,0.5)',
                      marginLeft: '6px', 
                      marginRight: '6px', 
                    },
                  },
                  '.MuiTab-root.Mui-selected': {
                    fontWeight: 'bold',
                    marginLeft: '6px', 
                    marginRight: '6px', 
                    boxShadow: '0 0 5px rgba(0,0,0,0.3)', 

                  }
                }}
                >
                  {diagnosticoDescritivoStatus && (
                    <Tab label="Diagnóstico ditado" />
                  )}
                  {dadosClinicosLaudoStatus && (
                    <Tab label="Dados clínicos" />
                  )}
                  {dadosTecnicosLaudoStatus && (
                    <Tab label="Dados técnicos" />
                  )}
                  {macroscopiaLaudoStatus && (
                    <Tab label="Macroscopia" />
                  )}
                  {microscopiaLaudoStatus && (
                    <Tab label="Microscopia" />
                  )}
                  {diagHistoLaudoStatus && (
                    <Tab label="Diagnóstico anatomopatológico" />
                  )}
                  {conclusaoStatus && (
                    <Tab label="Conclusão" />
                  )}
                  {diagNotasLaudoStatus && (
                    <Tab label="Notas" />
                  )}
                  {painelLaudoStatus && (
                    <Tab label="Painel" />
                  )}
                  {imagemLaudoStatus && (
                    <Tab label="Imagem" />
                  )}
                </Tabs>
                {diagnosticoDescritivoStatus && (
                  <TabPanel02 value={value02} index={diagnosticoDescritivoIndex}>
                    {diagnosticoDescritivoStatus && (
                      <SuiBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <SuiBox mb={1} ml={0.5} mt={6} lineHeight={0} display="inline-block">
                          <SuiTypography component="label" variant="caption" fontWeight="bold">
                            Diagnóstico ditado
                          </SuiTypography>
                        </SuiBox>
                        <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                          <SuiTypography
                            component="label"
                            variant="caption"
                            fontWeight="regular"
                            textColor="text"
                          >
                            Você pode escrever o diagnóstico aqui
                          </SuiTypography>
                        </SuiBox>
                        <SuiEditor
                          readOnly
                          value={diagnosticoDescritivo}
                          onChange={handleChange}
                        />
                      </SuiBox>

                    )}

                  </TabPanel02>
                )}


                {dadosClinicosLaudoStatus && (
                  <TabPanel02 value={value02} index={dadosClinicosLaudoIndex}>
                    {dadosClinicosLaudoStatus && (
                      <SuiBox mt={2}>
                        <SuiBox mb={1} ml={0.5} mt={1} lineHeight={0} display="inline-block">
                          <SuiTypography component="label" variant="caption" fontWeight="bold">
                            Laudo dados clínicos
                          </SuiTypography>
                        </SuiBox>
                        <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                          <SuiTypography
                            component="label"
                            variant="caption"
                            fontWeight="regular"
                            textColor="text"
                          >
                            Você pode escrever o diagnóstico aqui
                          </SuiTypography>
                        </SuiBox>
                        <SuiEditor
                          readOnly
                          value={dadosClinicosLaudo || "Nenhum modelo selecionado"}
                          onChange={handleChangeLaudoDadosClinicos}
                        />
                      </SuiBox>
                    )}
                  </TabPanel02>
                )}
                {dadosTecnicosLaudoStatus && (<TabPanel02 value={value02} index={dadosTecnicosLaudoIndex}>
                  {dadosTecnicosLaudoStatus && (
                    <SuiBox mt={2}>
                      <SuiBox mb={1} ml={0.5} mt={1} lineHeight={0} display="inline-block">
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Laudo dados técnicos
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="regular"
                          textColor="text"
                        >
                          Você pode escrever o diagnóstico aqui
                        </SuiTypography>
                      </SuiBox>
                      <SuiEditor
                        readOnly
                        value={dadosTecnicosLaudo || "Nenhum modelo selecionado"}
                        onChange={handleChangeLaudoDadosTecnicos}
                      />
                    </SuiBox>

                  )}
                </TabPanel02>
                )}



                {macroscopiaLaudoStatus && (<TabPanel02 value={value02} index={macroscopiaLaudoIndex}>
                  {macroscopiaLaudoStatus && (
                    <SuiBox mt={2}>
                      <SuiBox mb={1} ml={0.5} mt={1} lineHeight={0} display="inline-block">
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Laudo macroscopia
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="regular"
                          textColor="text"
                        >
                          Você pode escrever o diagnóstico aqui
                        </SuiTypography>
                      </SuiBox>
                      <SuiEditor
                        readOnly
                        value={macroscopiaLaudo || "Nenhum modelo selecionado"}
                        onChange={handleChangeLaudoMacroscopia}
                      />
                    </SuiBox>

                  )}


                </TabPanel02>
                )}


                {microscopiaLaudoStatus && (<TabPanel02 value={value02} index={microscopiaLaudoIndex}>
                  {microscopiaLaudoStatus && (
                    <SuiBox mt={1}>
                      <SuiBox mb={1} ml={0.5} mt={1} lineHeight={0} display="inline-block">
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Laudo microscopia
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="regular"
                          textColor="text"
                        >
                          Você pode escrever o diagnóstico aqui
                        </SuiTypography>
                      </SuiBox>
                      <SuiEditor
                        readOnly
                        value={microscopiaLaudo || "Nenhum modelo selecionado"}
                        onChange={handleChangeLaudoMicroscopia}
                      />
                    </SuiBox>

                  )}

                </TabPanel02>
                )}


                {diagHistoLaudoStatus && (<TabPanel02 value={value02} index={diagHistoLaudoIndex}>
                  {diagHistoLaudoStatus && (
                    <SuiBox mt={2}>
                      <SuiBox mb={1} ml={0.5} mt={1} lineHeight={0} display="inline-block">
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Laudo diagnóstico anatomopatológico
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="regular"
                          textColor="text"
                        >
                          Você pode escrever o diagnóstico aqui
                        </SuiTypography>
                      </SuiBox>
                      <SuiEditor
                        readOnly
                        value={diagHistoLaudo || "Nenhum modelo selecionado"}
                        onChange={handleChangeLaudoDiagHisto}
                      />
                    </SuiBox>
                  )}
                </TabPanel02>
                )}

                {conclusaoStatus && (<TabPanel02 value={value02} index={conclusaoIndex}>
                  {conclusaoStatus && (
                    <SuiBox mt={2}>
                      <SuiBox mb={1} ml={0.5} mt={1} lineHeight={0} display="inline-block">
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Laudo conclusão
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                        <SuiTypography
                          component="label"
                          variant="caption"
                          fontWeight="regular"
                          textColor="text"
                        >
                          Você pode escrever o diagnóstico aqui
                        </SuiTypography>
                      </SuiBox>
                      <SuiEditor
                        readOnly
                        value={conclusaoLaudo || "Nenhum modelo selecionado"}
                        onChange={handleChangeLaudoConclusao}
                      />
                    </SuiBox>
                  )}
                </TabPanel02>
                )}

                {diagNotasLaudoStatus && (
                  <TabPanel02 value={value02} index={diagNotasLaudoIndex}>
                    {diagNotasLaudoStatus && (
                      <SuiBox mt={2}>
                        <SuiBox mb={1} ml={0.5} mt={1} lineHeight={0} display="inline-block">
                          <SuiTypography component="label" variant="caption" fontWeight="bold">
                            Laudo notas
                          </SuiTypography>
                        </SuiBox>
                        <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                          <SuiTypography
                            component="label"
                            variant="caption"
                            fontWeight="regular"
                            textColor="text"
                          >
                            Você pode escrever o diagnóstico aqui
                          </SuiTypography>
                        </SuiBox>
                        <SuiEditor
                          readOnly
                          value={diagNotasLaudo || "Nenhum modelo selecionado"}
                          onChange={handleChangeLaudoDiagNotas}
                        />
                      </SuiBox>

                    )}
                  </TabPanel02>
                )}


                {painelLaudoStatus && (<TabPanel02 value={value02} index={painelLaudoIndex}>
                  {painelLaudoStatus && (
                    // <Painel options={{ addRemoveLinks: true }} idEntradaExame={Number(idEntradaExame)} />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', fontWeight: 'bold' }}>
                      Indisponível no modo visualização de laudo
                    </div>

                  )}

                </TabPanel02>
                )}


                {imagemLaudoStatus && (<TabPanel02 value={value02} index={imagemLaudoIndex}>
                  {imagemLaudoStatus && (
                    // <Grid item xs={12} lg={12}>
                    //   <SuiBox pt={1} px={1} >
                    //     <SuiBox>
                    //       <SuiBox
                    //         display="flex"
                    //         flexDirection="column"
                    //         justifyContent="flex-end"
                    //         height="10%"
                    //       >
                    //         <SuiBox mb={1} ml={2.5} mt={1} lineHeight={0} display="inline-block">
                    //           <SuiTypography component="label" variant="caption" fontWeight="bold">
                    //             Enviar imagem(ns)
                    //           </SuiTypography>
                    //         </SuiBox>

                    //         <SuiDropzone options={{ addRemoveLinks: true }} idEntradaExame={Number(idEntradaExame)} />
                    //       </SuiBox>
                    //     </SuiBox>
                    //   </SuiBox>
                    // </Grid>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', fontWeight: 'bold' }}>
                      Indisponível no modo visualização de laudo
                    </div>

                  )}
                </TabPanel02>
                )}
              </SuiBox>
              <SuiBox display="flex" justifyContent="flex-end" mt={7} mb={3} mr={3}>
                <SuiButton
                  variant="gradient"
                  buttonColor="info"
                  onClick={() => {
                    props.history.goBack();
                  }}
                >
                  Voltar
                </SuiButton>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(VisualizarLaudo);