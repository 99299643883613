import React, { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiEditor from "components/SuiEditor";
import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";

import axios from "axios";

// Soft UI Dashboard PRO React example components


import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Redirect, useHistory, useParams, withRouter } from "react-router-dom";
import swal from "@sweetalert/with-react";
import TipoSelect from "../../../../components/tipo-select";
import ProcedimentoSelect from "../../../../components/procedimento-select";
import ProdutoSelect from "../../../../components/produto-select";

function ItensMateriasKit(props) {
  const [itens, setItens] = useState([{id:0, id_material: 0, produto: undefined, quantidade: 0 }]);
  const [produtosSelecionados, setProdutosSelecionados] = useState([]);
  

  const getItens = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/produtos-kit?id_kit=${props.match.params.id}`, config)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          if (res.data.length > 0) {
            itens.length = 0;
            res.data.map((i, k) =>
              setItens((prevItens) => [
                ...prevItens,
                {
                  id:i.id,
                  id_material: i.id_material,
                  produto: i.nome_produto,
                  quantidade: i.quantidade,
                },
              ])
            );
          } else {
            console.log("Nada");
          }
        }
      })
      .catch(() => { });
  };


  const deleteItem = async (id) => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .delete(`${window.url}ws/produtos-kit/${id}`, config)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          swal("Agilie", "Item excluído com sucesso.", "success");
          getItens();
        }
      })
      .catch(() => { });
  };


  const excluir = async (id) => {
    const MySwal = withReactContent(Swal);
  
    MySwal.fire({
      title: "Excluir cadastro",
      text: `Tem certeza que deseja excluir?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, excluir!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem(id);
      }
    });
  };
  


  const addLine = () => {
    setItens([...itens, { id_material: 0, produto: undefined, quantidade: 0 }]);
  };

  const save = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .post(
        `${window.url}ws/produtos-kit`,
        {
          /* MANTER O IDSAAS  */
          idSaas: sessionStorage.getItem("idSaas"),
          id_kit: props.match.params.id,
          itens,
        },
        config
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          swal("Agilie", "Produto adicionado ao kit com sucesso.", "success");
          getItens();
        }
      })
      .catch(() => { });
  };

  // const updateLine = (i, value, field) => {
  //   const change = [...itens];
  //   change[i] = { ...itens[i], [field]: value };
  //   setItens(change);
  // };

  // const updateLine = (i, value, field) => {
  //   const change = [...itens];
  //   change[i] = { ...itens[i], [field]: value };
  //   setItens(change);

  //   if (field === "id_material") { // se o campo modificado for o id_material, atualiza o estado de produtosSelecionados
  //     const newProdutosSelecionados = [...produtosSelecionados];
  //     newProdutosSelecionados[i] = value;
  //     setProdutosSelecionados(newProdutosSelecionados);
  //   }
  // };

  const updateLine = (i, value, field) => {
    const change = [...itens];
    if (field === "quantidade" && value <= 0) {
      change[i] = { id_material: 0, produto: undefined, quantidade: 0 };
    } else {
      change[i] = { ...itens[i], [field]: value };
    }
    setItens(change);
  };
  


  useEffect(() => {
    getItens();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
         <Grid container spacing={3}>
          <Grid item xs={12} lg={11}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  Cadastro de produtos em Kits
                </SuiTypography>
                <Divider />
                {/* eslint-disable-next-line react/no-array-index-key */}
                {itens.map((i, k) => (
                  <Grid container spacing={3} key={k}>
                    <Grid item xs={12} md={9}>
                      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Produto
                        </SuiTypography>
                      </SuiBox>
                      {i.quantidade > 0 ? (
                        <ProdutoSelect
                          onChangeValue={(e) => {
                            updateLine(k, e.value, "id_material");
                          }}
                          value={{ value: i.id_material, label: i.produto }}
                        />
                      ) : (
                        <ProdutoSelect
                          onChangeValue={(e) => {
                            updateLine(k, e.value, "id_material");
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Quantidade
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox display="flex" alignItems="center">
                        <SuiInput
                          onChange={(e) => {
                            updateLine(k, e.target.value, "quantidade");
                          }}
                          value={i.quantidade || ""}
                        />
                        <SuiBox mr={1} />
                        <SuiButton
                          size="small"
                          buttonColor="error"
                          onClick={() => {
                            excluir(i.id);
                          }}
                        >
                          -
                        </SuiButton>
                      </SuiBox>
                    </Grid>

                  </Grid>
                ))}
                {/* TEXTO */}
                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  <SuiBox mr={1}>
                    <SuiButton
                      variant="gradient"
                      buttonColor="primary"
                      onClick={() => {
                        addLine(itens.length + 1);
                      }}
                    >
                      Nova linha
                    </SuiButton>
                  </SuiBox>
                  <SuiBox mr={1}>
                    <SuiButton
                      buttonColor="light"
                      onClick={() => {
                        props.history.goBack();
                      }}
                    >
                      cancelar
                    </SuiButton>
                  </SuiBox>
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    onClick={() => {
                      save(); // ao clicar no botão irá chamar a função POST no banco de dados
                    }}
                  >
                    Salvar
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>

               {/* <Grid item xs={12} lg={3}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Dicas
                </SuiTypography>
                <Divider />
              </SuiBox>
            </Card>
          </Grid> */}
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(ItensMateriasKit);
