import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
// react-router components
import {Route, Switch, useLocation} from "react-router-dom";

// jss components
import {create} from "jss";

// jss-rtl components
import rtl from "jss-rtl";

// @mui style components
import {jssPreset} from "@mui/styles";

// @mui material components
import {StyledEngineProvider, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

import "./custom.css";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";

// Soft UI Dashboard PRO React routes
import routes from "routes";

// Soft UI Dashboard PRO React contexts
import {useSoftUIController} from "context";

import {BarLoader} from "react-spinners";
import packageJson from "../package.json";
import Basic from "./layouts/authentication/sign-in/basic";
import deleteRoutes from "./deleteRoutes";
import intRoutes from "./intRoutes";
import editRoutes from "./editRoutes";

global.appVersion = packageJson.version;

function CustomRoute({isPrivate, ...rest}) {
    const {token, loading} = useSelector((state) => state.auth);
    const {pathname} = useLocation();

    if (loading) {
        return (
            <>
                <BarLoader color="#ccc"/>
            </>
        );
    }

    if (isPrivate && !token) {
        return <Basic/>;
    }

    return <Route {...rest} />;
}

export default function App() {
    const [controller, dispatch] = useSoftUIController();
    const {miniSidenav, direction, layout, openConfigurator} = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const {pathname} = useLocation();

    // JSS presets for the rtl
    const jss = create({
        plugins: [...jssPreset().plugins, rtl()],
    });

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            dispatch({
                type: "MINI_SIDENAV",
                value: false,
            });
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            dispatch({
                type: "MINI_SIDENAV",
                value: true,
            });
            setOnMouseEnter(false);
        }
    };

    // Change the openConfigurator state
    const handleConfiguratorOpen = () => {
        dispatch({
            type: "OPEN_CONFIGURATOR",
            value: !openConfigurator,
        });
    };

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return (
                    <CustomRoute
                        isPrivate
                        exact
                        path={route.route}
                        component={route.component}
                        key={route.key}
                    />
                );
            }

            return null;
        });

    const configsButton = (
        <SuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.5rem"
            height="3.5rem"
            backgroundColor="white"
            boxShadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            customClass="cursor-pointer"
            onClick={handleConfiguratorOpen}
        >
            <Icon className=" text-dark" fontSize="default">
                settings
            </Icon>
        </SuiBox>
    );

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                {layout === "dashboard" && (
                    <>
                        <Sidenav
                            routes={routes}
                            onMouseEnter={handleOnMouseEnter}
                            onMouseLeave={handleOnMouseLeave}
                        />
                        <Configurator/>
                        {configsButton}
                    </>
                )}
                {layout === "vr" && <Configurator/>}
                <Switch>
                    <Route path="/authentication/sign-in" component={Basic}/>
                    {getRoutes(routes)}
                    {getRoutes(deleteRoutes)}
                    {getRoutes(intRoutes)}
                    {getRoutes(editRoutes)}
                    <Route path="/" component={Basic}/>
                </Switch>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
