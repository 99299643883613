import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import swal from "@sweetalert/with-react";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiEditor from "components/SuiEditor";
import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Redirect, withRouter } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { BounceLoader } from "react-spinners";
import TabelaSelect from "../../../../components/tabela-select";
import ProcedimentoSelect from "../../../../components/procedimento-select";
import ConvenioSelect from "../../../../components/convenio-select";
import EntradaExameSelect from "../../../../components/entradaExame-select";
import StatusSelect from "../../../../components/status-select";
import FilialSelect from "../../../../components/filial-select";
import ContaBancariaSelect from "../../../../components/contabancaria-select";

function EditarValorProcedimento(props) {
  const [idTabela, setIdTabela] = useState();
  const [tabela, setTabela] = useState();
  const [idProcedimento, setIdProcedimento] = useState();
  const [procedimento, setProcedimento] = useState();
  const [idConvenio, setIdConvenio] = useState();
  const [convenio, setConvenio] = useState();
  const [idContaBancaria, setIdContaBancaria] = useState();
  const [contaBancaria, setContaBancaria] = useState();
  const [valorParticular, setValorParticular] = useState();
  const [valorConvenio, setValorConvenio] = useState();
  const [idFilialCobranca, setIdFilialCobranca] = useState();
  const [filialCobranca, setFilialCobranca] = useState();
  const [idFilial, setIdFilial] = useState();
  const [filial, setFilial] = useState();
  const [status, setStatus] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const save = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .put(
        `${window.url}ws/valorProcedimento/${props.match.params.id}`,
        {
          idSaas: sessionStorage.getItem("idSaas"),
          id_tabela: idTabela,
          id_procedimento: idProcedimento,
          id_convenio: idConvenio,
          id_conta_bancaria: idContaBancaria,
          valor_particular: valorParticular,
          valor_convenio: valorConvenio,
          id_filial_cobranca: idFilialCobranca,
          id_filial: idFilial,
          status: status.value,
        },
        config
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          swal("Agilie", "Valor procedimento atualizado com sucesso.", "success");
          props.history.push("/auxiliares/valorProcedimento");
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/valorProcedimento/${props.match.params.id}`, config)
      .then((res) => {
        const valorProcedimentoObj = res.data.valorProcedimento;
        setIdTabela(valorProcedimentoObj.id_tabela);
        setTabela(valorProcedimentoObj.nome_tabela);
        setIdProcedimento(valorProcedimentoObj.id_procedimento);
        setProcedimento(valorProcedimentoObj.nome_procedimento);
        setIdConvenio(valorProcedimentoObj.id_convenio);
        setConvenio(valorProcedimentoObj.nome_convenio);
        setValorParticular(valorProcedimentoObj.valor_particular);
        setValorParticular(valorProcedimentoObj.valor_particular);
        setValorConvenio(valorProcedimentoObj.valor_convenio);
        setIdContaBancaria(valorProcedimentoObj.id_conta_bancaria);
        setContaBancaria(valorProcedimentoObj.banco);
        setStatus({
          value: valorProcedimentoObj.status,
          label: valorProcedimentoObj.status === 1 ? "Ativo" : "Inativo",
        });
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  useEffect(() => {
    get();
  }, []);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
         <Grid container spacing={3}>
          <Grid item xs={12} lg={11}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Editar Valor procedimento
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  Os dados alterados não geram efeito sobre entrada de exames anteriores
                </SuiTypography>
                <Divider />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Tabela
                      </SuiTypography>
                    </SuiBox>
                    <TabelaSelect
                      value={{ value: setIdTabela, label: tabela }}
                      onChangeValue={(e) => {
                        setIdTabela(e.value);
                        setTabela(e.label);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Procedimento
                      </SuiTypography>
                    </SuiBox>
                    <ProcedimentoSelect
                      value={{ value: setIdProcedimento, label: procedimento }}
                      onChangeValue={(e) => {
                        setIdProcedimento(e.value);
                        setProcedimento(e.label);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Convênio
                      </SuiTypography>
                    </SuiBox>
                    <ConvenioSelect
                      value={{ value: setIdConvenio, label: convenio }}
                      onChangeValue={(e) => {
                        setIdConvenio(e.value);
                        setConvenio(e.label);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Valor particular
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={valorParticular || ""}
                      onChange={(e) => {
                        setValorParticular(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Valor convênio
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={valorConvenio || ""}
                      onChange={(e) => {
                        setValorConvenio(e.target.value);
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Filial execução
                      </SuiTypography>
                    </SuiBox>
                    <FilialSelect
                     value={{ value: idFilialCobranca, label: filialCobranca }}
                     onChangeValue={(e) => {
                      setIdFilialCobranca(e.value);
                      idFilialCobranca(e.label);
                     }}
                      
                    />
                  </Grid> */}
                  {/* <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Filial faturamento
                      </SuiTypography>
                    </SuiBox>
                    <FilialSelect
                      value={{ value: idFilial, label: filial }}
                      onChangeValue={(e) => {
                        setIdFilial(e.value);
                        idFilial(e.label);
                      }}
                    />
                  </Grid> */}
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Conta bancária faturamento
                      </SuiTypography>
                    </SuiBox>
                    <ContaBancariaSelect
                     value={{ value: idContaBancaria, label: contaBancaria }}
                     onChangeValue={(e) => {
                      setIdContaBancaria(e.value);
                      setContaBancaria(e.label);
                     }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Status
                      </SuiTypography>
                    </SuiBox>
                    <StatusSelect
                      value={status}
                      onChangeValue={(e) => {
                        if (e.value === 0) {
                          setStatus({ value: e.value, label: "Inativo" });
                        } else if (e.value === 1) {
                          setStatus({ value: e.value, label: "Ativo" });
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  <SuiBox mr={1}>
                    <SuiButton
                      buttonColor="light"
                      onClick={() => {
                        props.history.goBack();
                      }}
                    >
                      cancelar
                    </SuiButton>
                  </SuiBox>
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    onClick={() => {
                      save(); // ao clicar no botão irá chamar a função POST no banco de dados
                    }}
                  >
                    Salvar
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
               {/* <Grid item xs={12} lg={3}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Dicas
                </SuiTypography>
                <Divider />
              </SuiBox>
            </Card>
          </Grid> */}
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(EditarValorProcedimento);
