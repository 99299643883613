import { useEffect, useState } from "react";
import { toast } from "react-toastify";

// @mui material components
import Card from "@mui/material/Card";
import { v4 as uuidv4 } from "uuid";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import axios from "axios";
import DataTable from "examples/Tables/DataTable";

import { useHistory, withRouter, useParams } from "react-router-dom";

// Soft UI Dashboard PRO React example components
import Tooltip from "@mui/material/Tooltip";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BounceLoader } from "react-spinners";
import SuiInput from "components/SuiInput";
import { ImLab, IoEyeOutline, GiStethoscope, } from "react-icons/all";
import SuiButton from "../../../../components/SuiButton";
import BoxStatus from "./components/BoxStatus";
import { dateEnBr, statusBase, fixando, descalcificando } from "../../../../utils";
import Paginacao from "../../../../components/paginacao";


function EntradaExamePreparo(props) {
  const [entradaExame, setEntradaExame] = useState([]);
  const { id } = useParams();
  const [identificador, setIdentificador] = useState(id);
  const [isLoading, setIsLoading] = useState(true);
  const [links, setLinks] = useState([]);
  const [page, setPage] = useState("1");
 const [searchTerm, setSearchTerm] = useState(id !== undefined ? id : "");
  const [pesquisa, setPesquisa] = useState([]);
  const [atualizaLista, setAtualizaLista] = useState(1);


  const history = useHistory();

  const handleSearch = (searchPage = 1) => {

    axios
      .get(`${window.url}ws/entradaExame/buscar/entradaExame`, {
        params: {
          buscar: searchTerm,
          page: searchPage,
          status: 99,
        },
      })
      .then((response) => {
        if (searchTerm !== "") {
          if (response.data.data.length === 0) {
            toast.info("Nenhum item encontrado!");
          } else {
            setPesquisa(response);
            setLinks(response.data.links);
            setPage(searchPage);
            if (response.data.current_page === 1) {
              setPage('1');
            }
          }
        } else {
          setPesquisa([]);
          setPage('1');
          setAtualizaLista(atualizaLista + 1);

        }
      })
      .catch(() => {
        toast.error("Ops! Houve um erro na pesquisa!");
      });
  };

  const handlePageChange = (newPage) => {
    if (searchTerm !== "") {
      handleSearch(newPage);
    } else {
      setPage(newPage);
    }
  };

  useEffect(async () => {
    if (identificador !== undefined) {
      setSearchTerm(identificador)
      handleSearch();
    }
  }, [identificador]);


  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    setIsLoading(true);

    await axios
      .get(`${window.url}ws/entradaExame/99/list?page=${page}`, config)
      .then((res) => {
        setEntradaExame([]);
        const dados = pesquisa.data && pesquisa.data.data.length > 0 ? pesquisa.data.data : res.data.data;
        // eslint-disable-next-line array-callback-return
        dados.map((item) => {
          // eslint-disable-next-line no-shadow
          setEntradaExame((entradaExame) => [
            ...entradaExame,
            /* VARIÁVEIS DO BANCO DE DADOS */
            {
              id: item.id,
              identificador: item.identificador,
              data_entrada: item.data_entrada,
              razao_social: item.razao_social,
              nome_convenio: item.nome_convenio,
              nome: item.nome,
              data_nascimento: item.data_nascimento,
              previsao_entrega: item.previsao_entrega,
              data_laudo: item.data_laudo,
              data_liberado: item.data_liberado,
              prioridade: item.prioridade,
              deve_guia: item.deve_guia,
              status: item.status,
              status_procedimento: item.status_procedimento,
              carteirinha: item.carteirinha,

              /* FUNÇÃO DE EDIÇÃO */
              functions:
                item.status === 99 ? (
                  "Aguardando retorno"
                ) : (
                  <SuiBox ml={-1.325}>
                    <SuiButton
                      variant="gradient"
                      color="info"
                      onClick={() => {
                        history.push(`/clinico/enviar/laminacao/${item.id}/${uuidv4()}`);
                      }}
                    >
                      <ImLab size="18px" />
                    </SuiButton>
                  </SuiBox>
                ),
              visualizar: (
                <SuiBox ml={-1.325}>
                  <Tooltip title="Procedimento" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor={item.status_procedimento !== 0 ? "info" : "error"}
                      onClick={() => {
                        history.push(
                          `/auxiliares/entradaExame/procedimento/${item.id}/${item.uuid}`
                        );
                      }}
                      style={{ marginLeft: '3px' }}
                    >
                      <GiStethoscope style={item.status_procedimento !== 0 ? {} : { color: 'white' }} />
                    </SuiButton>
                  </Tooltip>
                  <Tooltip title="Visualizar material biológico" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={() => {
                        history.push(
                          `/auxiliares/entradaExame/vizualizarMacroscopia/${item.id}/${uuidv4()}`
                        );
                      }}
                      style={{ marginLeft: '3px' }}
                    >
                      <IoEyeOutline />
                    </SuiButton>
                  </Tooltip>
                </SuiBox>
              ),
            },
          ]);
        });
        setIsLoading(false);
        if (pesquisa.length === 0 && identificador === undefined) {
          setLinks(res.data.links);
        }
      })
      .catch(() => { });
  };

  const table = {
    /* EDITAR CAMPOS */
    columns: [
      // { Header: "#", accessor: "id", width: "2%" },
      { Header: "Exame Nº", accessor: "identificador", width: "10%" },
      { Header: "Paciente", accessor: "nome", width: "10%" },
      {
        Header: "Carteirinha",
        accessor: "carteirinha",
        Cell: ({ value }) => value || "Não informado",
        width: "10%",
      },
      {
        Header: "Nascimento",
        accessor: "data_nascimento",
        Cell: ({ value }) => value ? dateEnBr(value) : "Não informado",
        width: "15%",
      },
      
      { Header: "Convênio", accessor: "nome_convenio", width: "10%" },
      {
        Header: "Entrada",
        accessor: "data_entrada",
        Cell: ({ value }) => value ? dateEnBr(value) : "Não informado",
        width: "10%",
      },
      {
        Header: "Previsão",
        accessor: "previsao_entrega",
        Cell: ({ value }) => value ? dateEnBr(value) : "Não informado",
        width: "10%",
      },
      
      { Header: "Status", accessor: "functions", width: "5%" },
      { Header: "Opções", accessor: "visualizar", width: "1%" },
    ],

    rows: entradaExame,
  };

  useEffect(() => {
    get();
  }, [page, atualizaLista]);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <SuiBox p={3} lineHeight={1}>
          <SuiTypography variant="h5" fontWeight="medium">
            Entrada exame (histotécnico)
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            Lista de materiais biológicos (histotécnico)
          </SuiTypography>
        </SuiBox>
        <SuiBox p={3.5} mb={1} width="20rem" ml="auto">
          {entradaExame.length > 0 && (
            <SuiInput
              placeholder="Busca & Enter"
              value={searchTerm}
              onChange={(event) => {
                setSearchTerm(event.target.value);
                if (event.target.value === "") {
                  setPesquisa([]);
                  setPage('1');
                  setAtualizaLista(atualizaLista + 1);
                }
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSearch();
                }
              }}
            />
          )}
        </SuiBox>
        {/* eslint-disable-next-line no-nested-ternary */}
        {entradaExame.length > 0 ? (
          <>
            <DataTable table={table} />
            <Paginacao links={links} page={page} setPage={handlePageChange} />
          </>
        ) : (
          <SuiBox p={3} lineHeight={1}>
            <small>Nenhum registro encontrado.</small>
          </SuiBox>
        )}
        <BoxStatus />
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(EntradaExamePreparo);
