import React, { Component, Fragment, useEffect, useState } from "react";
import Select, { components } from "react-select";
import axios from "axios";
import { withRouter } from "react-router-dom";
import "../../globals";
import SuiSelect from "../SuiSelect";

function ContaBancariaSelect(props) {
  const [selectedOption, setSelectedOption] = useState();
  const [statusOptions, setStatusOptions] = useState([]);
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .get(`${window.url}ws/contasBancarias/select/full`, config)
      .then((res) => {
        if (res.status === 200) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < res.data.length; i++) {
            statusOptions.push({
              value: res.data[i].id,
              label: `${res.data[i].banco} - ${res.data[i].agencia} ${res.data[i].n_conta}`,
            });
          }
          setStatusOptions(statusOptions);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.status);
      })
      .then(() => {});
  }, []);

  // eslint-disable-next-line no-shadow
  const NoOptionsMessage = (props) => (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">Nenhum registro para a busca</span>
    </components.NoOptionsMessage>
  );

  const { value, onChangeValue } = props;

  return (
    <div>
      <SuiSelect
        placeholder="Selecione"
        components={{ NoOptionsMessage }}
        value={value}
        onChange={onChangeValue}
        options={statusOptions}
        className="form-control"
      />
    </div>
  );
}

export default withRouter(ContaBancariaSelect);
