import React, { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import Tooltip from "@mui/material/Tooltip";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { withRouter } from "react-router-dom";
import SuiButton from "../../../../../components/SuiButton";
import SuiInput from "../../../../../components/SuiInput";

function ImpressaoEtiquetas(props) {
  const [prefix, setPrefix] = useState("BX");
  const [numeroInicial, setNumeroInicial] = useState();
  const [numeroFinal, setNumeroFinal] = useState();

  const print = () => {
    const url = `${window.url}ws/PDF/etiqueta/${prefix}/${sessionStorage.getItem(
      "idFilial"
    )}/${numeroInicial}/${numeroFinal}`;
    window.open(url, "_blank", "toolbar=no,resizable=no,top=10,left=10,width=400,height=400");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
         <Grid container spacing={3}>
          <Grid item xs={12} lg={11}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Etiquetas
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  Impressão de etiquetas em lote
                </SuiTypography>
                <Divider />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={2} lg={2} sl={2}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Prefixo
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setPrefix(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} lg={3} sl={3}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Número inicial
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setNumeroInicial(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} lg={3} sl={3}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Número final
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      onChange={(e) => {
                        setNumeroFinal(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    onClick={() => {
                      print();
                    }}
                  >
                    Imprimir
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
               {/* <Grid item xs={12} lg={3}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Dicas
                </SuiTypography>
                <Divider />
              </SuiBox>
            </Card>
          </Grid> */}
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(ImpressaoEtiquetas);
