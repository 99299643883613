import React, { useEffect, useState } from "react";
import {toast} from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import swal from "@sweetalert/with-react";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import SuiEditor from "components/SuiEditor";
import SuiDropzone from "components/SuiDropzone";
import SuiButton from "components/SuiButton";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Redirect, withRouter } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { BounceLoader } from "react-spinners";

function EditarOrigemExame(props) {
  const [nomeOrigem, setNomeOrigem] = useState();
  const [tipoDocumento, setTipoDocumento] = useState();
  const [nDocumento, setNDocumento] = useState();
  const [telefone, setTelefone] = useState();
  const [codigoAcesso, setCodigoAcesso] = useState();
  const [senha, setSenha] = useState();
  const [endereco, setEndereco] = useState();
  const [uf, setUf] = useState();
  const [cidade, setCidade] = useState();
  const [cep, setCep] = useState();
  const [observacoes, setObservacoes] = useState();
  const [receberEmail, setReceberEmail] = useState(false);
  const [email, setEmail] = useState();
  const handleSetReceberEmail = () => setReceberEmail(!receberEmail);
  const [isLoading, setIsLoading] = useState(true);

  const save = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .put(
        `${window.url}ws/origemExame/${props.match.params.id}`,
        {
          idSaas: sessionStorage.getItem("idSaas"),
          nome_origem: nomeOrigem,
          tipo_documento: tipoDocumento,
          n_documento: nDocumento,
          telefone,
          codigo_acesso: codigoAcesso,
          senha,
          endereco,
          uf,
          cidade,
          cep,
          observacoes,
          recebe_email: receberEmail,
          email,
        },
        config
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          swal("Agilie", "Origem Exame atualizado com sucesso.", "success");
          props.history.push("/auxiliares/origemExame");
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/origemExame/${props.match.params.id}`, config)
      .then((res) => {
        const origemExameObj = res.data.origemExame;
        setNomeOrigem(origemExameObj.nome_origem);
        setTipoDocumento(origemExameObj.tipo_documento);
        setNDocumento(origemExameObj.n_documento);
        setTelefone(origemExameObj.telefone);
        setCodigoAcesso(origemExameObj.codigo_acesso);
        setSenha(origemExameObj.senha);
        setEndereco(origemExameObj.endereco);
        setUf(origemExameObj.uf);
        setCidade(origemExameObj.cidade);
        setCep(origemExameObj.cep);
        setObservacoes(origemExameObj.observacoes);
        setReceberEmail(origemExameObj.recebe_email);
        setEmail(origemExameObj.email);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const falha = error.response.data.message;
          toast.error(falha);
          props.history.goBack();
        } else if (error.response.status === 500) {
          const falha = error.response.data.message;
          toast.error(falha);
          swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
        } else {
          const falha = error.response.data.message;
          toast.error(falha);
          const mensagem = error.response.data.errors;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < mensagem.length; i++) {
            const msg = error.response.data.errors[i];
            toast.error(msg);
          }
        }
      });
  };

  useEffect(() => {
    get();
  }, []);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={3} mb={4}>
         <Grid container spacing={3}>
          <Grid item xs={12} lg={11}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Editar Origem Exame
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                  Os dados alterados não geram efeito sobre entrada de exames anteriores
                </SuiTypography>
                <Divider />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Nome Origem exame
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={nomeOrigem || ""}
                      onChange={(e) => {
                        setNomeOrigem(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Tipo de documento
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={tipoDocumento || ""}
                      onChange={(e) => {
                        setTipoDocumento(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Número do documento
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={nDocumento || ""}
                      onChange={(e) => {
                        setNDocumento(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Telefone
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={telefone || ""}
                      onChange={(e) => {
                        setTelefone(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Código de acesso
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={codigoAcesso || ""}
                      onChange={(e) => {
                        setCodigoAcesso(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Senha
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={senha || ""}
                      onChange={(e) => {
                        setSenha(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Endereço
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={endereco || ""}
                      onChange={(e) => {
                        setEndereco(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        CEP
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={cep || ""}
                      onChange={(e) => {
                        setCep(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Cidade
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={cidade || ""}
                      onChange={(e) => {
                        setCidade(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        UF
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={uf || ""}
                      onChange={(e) => {
                        setUf(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Email
                      </SuiTypography>
                    </SuiBox>
                    <SuiInput
                      value={email || ""}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SuiBox mb={1} ml={0.5} lineHeight={4} display="inline-block">
                      <Checkbox checked={receberEmail} onChange={handleSetReceberEmail} />
                      <SuiTypography
                        variant="button"
                        fontWeight="bold"
                        onClick={handleSetReceberEmail}
                        customClass="cursor-pointer user-select-none"
                      >
                        &nbsp;&nbsp; Receber Email &nbsp;
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                </Grid>
                <SuiBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SuiBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Observação
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox mb={1.5} ml={0.5} mt={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="regular"
                      textColor="text"
                    >
                      Você pode escrever uma observação aqui
                    </SuiTypography>
                  </SuiBox>
                  <SuiEditor value={observacoes || ""} onChange={setObservacoes} />
                </SuiBox>
                {/* TEXTO */}
                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                  <SuiBox mr={1}>
                    <SuiButton
                      buttonColor="light"
                      onClick={() => {
                        props.history.goBack();
                      }}
                    >
                      cancelar
                    </SuiButton>
                  </SuiBox>
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    onClick={() => {
                      save(); // ao clicar no botão irá chamar a função POST no banco de dados
                    }}
                  >
                    Salvar
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
               {/* <Grid item xs={12} lg={3}>
            <Card className="overflow-visible">
              <SuiBox p={2} lineHeight={1}>
                <SuiTypography variant="h6" fontWeight="medium">
                  Dicas
                </SuiTypography>
                <Divider />
              </SuiBox>
            </Card>
          </Grid> */}
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(EditarOrigemExame);
