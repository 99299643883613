import React, { useEffect, useState } from "react";
import { components } from "react-select";
import axios from "axios";
import { withRouter } from "react-router-dom";
import "../../globals";
import { BarLoader } from "react-spinners";
import SuiSelect from "../SuiSelect";

function FilialSelect(props) {
  const [statusOptions, setStatusOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .get(`${window.url}ws/filial`, config)
      .then((res) => {
        if (res.status === 200) {
          // eslint-disable-next-line no-shadow
          res.data.map((i, k) =>
            // eslint-disable-next-line no-shadow
            setStatusOptions((statusOptions) => [
              ...statusOptions,
              { value: i.id, label: `${i.filial} - ${i.cnpj}` },
            ])
          );
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.status);
      });
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    getData();
    return () => controller.abort();
  }, []);

  // eslint-disable-next-line no-shadow
  const NoOptionsMessage = (props) => (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">Nenhum registro para a busca</span>
    </components.NoOptionsMessage>
  );

  const { value, onChangeValue } = props;

  return isLoading ? (
    <BarLoader color="#ccc" />
  ) : (
    <div>
      <SuiSelect
        placeholder="Selecione"
        components={{ NoOptionsMessage }}
        value={value}
        onChange={onChangeValue}
        options={statusOptions}
        className="form-control"
      />
    </div>
  );
}

export default withRouter(FilialSelect);
