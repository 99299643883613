import React from "react";
import { withRouter } from "react-router-dom";
import "../../globals";
import SoftPagination from "../SuiPagination";

function Paginacao(props) {
  const { links, page, setPage } = props;

  return (
    <div
      style={{
        margin: "auto",
        width: "100%",
        padding: "20px",
        display: "flex",
        justifyContent: "center", 
      }}
    >
      <SoftPagination  color={sessionStorage.getItem('theme')}>
        {links.map((i, k) => {
          return (
            i.label !== "Próximo" &&
            i.label !== "Anterior" && (
              <SoftPagination
                item
                onClick={(e) => {
                  setPage(i.label);
                }}
                key={k}
                active={i.label === page}
              >
                {i.label}
              </SoftPagination>
            )
          );
        })}
      </SoftPagination>
    </div>
  );
}

export default withRouter(Paginacao);





