/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const defaultLineChartData = {
  labels: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set"],
  datasets: [
    {
      label: "Contas a pagar",
      color: "info",
      data: [500.0, 100.0, 200.0, 190.0, 400.0, 350.0, 500.0, 450.0, 700.0],
    },
    {
      label: "Contas a receber",
      color: "dark",
      data: [1000.0, 3000.0, 4000.0, 12000.0, 15000.0, 2200.0, 2800.0, 2500.0, 2800.0],
    },
  ],
};

export default defaultLineChartData;
