import React, {useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {withRouter} from "react-router-dom";
import SuiDatePicker from "../../../../../components/SuiDatePicker";

function RelatorioHistotecnico(props) {
    const [dataInicial, setDataInicial] = useState(new Date().toLocaleString("pt-BR"));
    const [dataFinal, setDataFinal] = useState(new Date().toLocaleString("pt-BR"));

    const print = () => {
        const url = `${
            window.url
        }ws/PDF/relatorio/histotecnico?data_inicial=${dataInicial}&data_final=${dataFinal}&id_filial=${sessionStorage.getItem(
            "idFilial"
        )}`;
        window.open(url, "_blank", "toolbar=no,resizable=no,top=10,left=10,width=400,height=400");
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SuiBox mt={3} mb={4}>
                       <Grid container spacing={3}>
                    <Grid item xs={12} lg={11}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Histotécnico
                                </SuiTypography>
                                <SuiTypography variant="button" fontWeight="regular" textColor="text">
                                    Impressão de relatório para envio histotécnico
                                </SuiTypography>
                                <Divider/>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={4} lg={3} xl={3}>
                                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                                            Data de entrada inicial
                                        </SuiTypography>
                                        <SuiDatePicker
                                            onChangeValue={(evt) => {
                                                setDataInicial(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={3} xl={3}>
                                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                                            Data de entrada final
                                        </SuiTypography>
                                        <SuiDatePicker
                                            onChangeValue={(evt) => {
                                                setDataFinal(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                                    <SuiButton
                                        variant="gradient"
                                        buttonColor="info"
                                        onClick={() => {
                                            print();
                                        }}
                                    >
                                        Imprimir
                                    </SuiButton>
                                </SuiBox>
                            </SuiBox>
                        </Card>
                    </Grid>
                    {/* <Grid item xs={12} lg={3}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Dicas
                                </SuiTypography>
                                <Divider/>
                            </SuiBox>
                        </Card>
                    </Grid> */}
                </Grid>
            </SuiBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default withRouter(RelatorioHistotecnico);
