// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";
import axios from "axios";
import {useEffect, useState} from "react";
import MiniStatisticsCard from "../../../../../../examples/Cards/StatisticsCards/MiniStatisticsCard";

function BoxStatus({label, ...rest}) {
    const [totalRecepcao, setTotalRecepcao] = useState(0);
    const [totalRegistro, setTotalRegistro] = useState(0);
    const [totalMacroscopia, setTotalMacroscopia] = useState(0);
    const [totalPreparo, setTotalPreparo] = useState(0);
    const [totalPassagem, setTotalPassagem] = useState(0);
    const [totalDiagnostico, setTotalDiagnostico] = useState(0);
    const [totalDigitacao, setTotalDigitacao] = useState(0);
    const [totalCorrecao, setTotalCorrecao] = useState(0);
    const [totalFinalizados, setTotalFinalizados] = useState(0);

    const get = () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        axios
            .get(`${window.url}ws/entradaExame/totais/count`, config)
            .then((res) => {
                setTotalRecepcao(res.data.recepcao);
                setTotalRegistro(res.data.registro);
                setTotalMacroscopia(res.data.macroscopia);
                setTotalPreparo(res.data.preparo);
                setTotalPassagem(res.data.passagem);
                setTotalDiagnostico(res.data.diagnostico);
                setTotalDigitacao(res.data.digitacao);
                setTotalCorrecao(res.data.correcao);
                setTotalFinalizados(res.data.finalizados);
            })
            .catch(() => {
            });
    };

    useEffect(() => {
        get();
    }, []);

    return (
        <>
            <SuiBox p={1} lineHeight={1}>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={2}>
                        <SuiBox mb={0}>
                            <MiniStatisticsCard
                                title={{text: "Macroscopia"}}
                                count={totalMacroscopia}
                                icon={{color: sessionStorage.getItem('theme'), component: "paid"}}
                                link="/auxiliares/entradaExame/lista/macroscopia"
                            />
                        </SuiBox>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <SuiBox mb={0}>
                            <MiniStatisticsCard
                                title={{text: "Histotécnico", fontWeight: "bold"}}
                                count={totalPreparo}
                                icon={{color: sessionStorage.getItem('theme'), component: "paid"}}
                                link="/auxiliares/entradaExame/lista/preparo"
                            />
                        </SuiBox>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <SuiBox mb={0}>
                            <MiniStatisticsCard
                                title={{text: "Diagnóstico", fontWeight: "bold"}}
                                count={totalDiagnostico}
                                icon={{color: sessionStorage.getItem('theme'), component: "paid"}}
                                link="/auxiliares/entradaExame/lista/diagnostico"
                            />
                        </SuiBox>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <SuiBox mb={0}>
                            <MiniStatisticsCard
                                title={{text: "Digitação", fontWeight: "bold"}}
                                count={totalDigitacao}
                                icon={{color:sessionStorage.getItem('theme'), component: "paid"}}
                                link="/auxiliares/entradaExame/lista/digitacao"
                            />
                        </SuiBox>
                    </Grid>
                    <Grid item xs={6} sm={2.5}>
                        <SuiBox mb={0}>
                            <MiniStatisticsCard
                                title={{text: 'Correção\u00A0&\u00A0liberação', fontWeight: "bold"}}
                                count={totalCorrecao}
                                icon={{color: sessionStorage.getItem('theme'), component: "paid"}}
                                link="/auxiliares/entradaExame/lista/correcao"
                            />
                        </SuiBox>
                    </Grid>
                </Grid>
            </SuiBox>
        </>
    );
}

export default BoxStatus;
