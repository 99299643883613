import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import axios from "axios";
import DataTable from "examples/Tables/DataTable";

import { useHistory, withRouter } from "react-router-dom";

// Soft UI Dashboard PRO React example components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BounceLoader, ClipLoader } from "react-spinners";
import SuiButton from "../../../../components/SuiButton";

function Glosa() {
  const [almoxarifado, setAlmoxarifado] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .get(`${window.url}ws/glosa`, config)
      .then((res) => {
        // eslint-disable-next-line array-callback-return
        res.data.map((item) => {
          // eslint-disable-next-line no-shadow
          setAlmoxarifado((almoxarifado) => [
            ...almoxarifado,
            /* VARIÁVEIS DO BANCO DE DADOS */
            {
              id: item.id,
              id_fornecedor: item.id_fornecedor,
              id_produto: item.id_produto,
              data_recebimento: item.data_recebimento,
              data_pagamento: item.data_pagamento,
              quantidade: item.quantidade,
              valor_unitario: item.valor_unitario,

              /* FUNÇÃO DE EDIÇÃO */
              functions: (
                <SuiBox ml={-1.325}>
                  {/* <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      history.push(`/auxiliares/almoxarifado/editar/${item.id}/${item.uuid}`);
                    }}
                  >
                    <Icon>edit</Icon>
                  </SuiButton> */}
                  <Tooltip title="Editar" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={() => {
                        history.push(`/auxiliares/almoxarifado/editar/${item.id}/${item.uuid}`);
                      }}
                    >
                      <Icon>edit</Icon>
                    </SuiButton>
                  </Tooltip>

                </SuiBox>
              ),
            },
          ]);
        });
        setIsLoading(false);
      })
      .catch(() => { });
  };

  const table = {
    /* EDITAR CAMPOS */
    columns: [
      // { Header: "#", accessor: "id", width: "2%" },
      { Header: "Produto", accessor: "nome_produto", width: "15%" },
      { Header: "Data de recebimento", accessor: "data_recebimento", width: "15%" },
      { Header: "Data de pagamento", accessor: "data_pagamento", width: "15%" },
      { Header: "Opções", accessor: "functions", width: "1%" },
    ],

    rows: almoxarifado,
  };

  useEffect(() => {
    get();
  }, []);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <SuiBox p={3} lineHeight={1}>
          <SuiTypography variant="h5" fontWeight="medium">
            Glosa(s)
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            Lista de lançamentos automáticos de glosa
          </SuiTypography>
        </SuiBox>
        {/* eslint-disable-next-line no-nested-ternary */}
        {almoxarifado.length > 0 ? (
          <DataTable table={table} canSearch />
        ) : (
          <SuiBox p={3} lineHeight={1}>
            <small>Nenhum registro encontrado.</small>
          </SuiBox>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(Glosa);
