import { useEffect, useState } from "react";
import { toast } from "react-toastify";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import axios from "axios";
import DataTable from "examples/Tables/DataTable";

import { useHistory, withRouter } from "react-router-dom";

// Soft UI Dashboard PRO React example components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BounceLoader, ClipLoader } from "react-spinners";
import SuiInput from "components/SuiInput";
import SuiButton from "../../../../components/SuiButton";
import Paginacao from "../../../../components/paginacao";

function MaterialTecido() {
  const [materialTecido, setMaterialTecido] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [links, setLinks] = useState([]);
  const [page, setPage] = useState("1");
  const [searchTerm, setSearchTerm] = useState("");
  const [pesquisa, setPesquisa] = useState([]);
  const [atualizaLista, setAtualizaLista] = useState(1);


  const history = useHistory();


  const handleSearch = (searchPage = 1) => {

    axios
      .get(`${window.url}ws/materialTecido/buscar/materialTecido`, {
        params: {
          buscar: searchTerm,
          page: searchPage,
        },
      })
      .then((response) => {
        if (searchTerm !== "") {
          if (response.data.data.length === 0) {
            toast.info("Nenhum item encontrado!");
          } else {
            setPesquisa(response);
            setLinks(response.data.links);
            setPage(searchPage);
            if (response.data.current_page === 1) {
              setPage('1');
            }
          }
        } else {
          setPesquisa([]);
          setPage('1');
          setAtualizaLista(atualizaLista + 1);

        }
      })
      .catch(() => {
        toast.error("Ops! Houve um erro na pesquisa!");
      });
  };

  const handlePageChange = (newPage) => {
    if (searchTerm !== "") {
      handleSearch(newPage);
    } else {
      setPage(newPage);
    }
  };

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    setIsLoading(true);

    await axios
      .get(`${window.url}ws/materialTecido?page=${page}`, config)
      .then((res) => {
        setMaterialTecido([]);
        const dados = pesquisa.data && pesquisa.data.data.length > 0 ? pesquisa.data.data : res.data.data;
        // eslint-disable-next-line array-callback-return
        dados.map((item) => {
          // eslint-disable-next-line no-shadow
          setMaterialTecido((materialTecido) => [
            ...materialTecido,
            /* VARIÁVEIS DO BANCO DE DADOS */
            {
              id: item.id,
              nome_material: item.nome_material,

              /* FUNÇÃO DE EDIÇÃO */
              functions: (
                <SuiBox ml={-1.325}>
                  {/* <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      history.push(`/auxiliares/materialTecido/editar/${item.id}/${item.uuid}`);
                    }}
                  >
                    <Icon>edit</Icon>
                  </SuiButton> */}
                  <Tooltip title="Editar" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={() => {
                        history.push(`/auxiliares/materialTecido/editar/${item.id}/${item.uuid}`);
                      }}
                    >
                      <Icon>edit</Icon>
                    </SuiButton>
                  </Tooltip>

                </SuiBox>
              ),
            },
          ]);
        });
        setIsLoading(false);
        if (pesquisa.length === 0) {
          setLinks(res.data.links);
        }
      })
      .catch(() => { });
  };

  const table = {
    /* EDITAR CAMPOS */
    columns: [
      // { Header: "#", accessor: "id", width: "2%" },
      { Header: "Nome do material", accessor: "nome_material", width: "30%" },
      { Header: "Opções", accessor: "functions", width: "1%" },
    ],

    rows: materialTecido,
  };

  useEffect(() => {
    get();
  }, [page, atualizaLista]);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <SuiBox p={3} display="flex" justifyContent="space-between">
          <SuiBox>
            <SuiTypography variant="h5" fontWeight="medium">
              Material biológico
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" textColor="text">
              Lista de Material biológico
            </SuiTypography>
          </SuiBox>
          <SuiBox p={1}>
            <SuiButton
              variant="gradient"
              buttonColor="info"
              onClick={() => {
                history.push("/auxiliares/materialTecido/Cadastro");
              }}
            >
              Cadastrar
            </SuiButton>
          </SuiBox>
        </SuiBox>
        <SuiBox p={3.5} mb={1} width="20rem" ml="auto">
          {materialTecido.length > 0 && (
            <SuiInput
              placeholder="Busca & Enter"
              value={searchTerm}
              onChange={(event) => {
                setSearchTerm(event.target.value);
                if (event.target.value === "") {
                  setPesquisa([]);
                  setPage('1');
                  setAtualizaLista(atualizaLista + 1);
                }
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSearch();
                }
              }}
            />
          )}
        </SuiBox>
        {/* eslint-disable-next-line no-nested-ternary */}
        {materialTecido.length > 0 ? (
          <>
            <DataTable table={table} />
            <Paginacao links={links} page={page} setPage={handlePageChange} />
          </>
        ) : (
          <SuiBox p={3} lineHeight={1}>
            <small>Nenhum registro encontrado.</small>
          </SuiBox>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(MaterialTecido);
