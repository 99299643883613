import React, { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import axios from "axios";
import DataTable from "examples/Tables/DataTable";

import { useHistory, withRouter } from "react-router-dom";

// Soft UI Dashboard PRO React example components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BounceLoader, ClipLoader } from "react-spinners";
import Grid from "@mui/material/Grid";
import SuiButton from "../../../../components/SuiButton";
import { dateEnBr, principal } from "../../../../utils";
import Paginacao from "../../../../components/paginacao";

function ConvenioPaciente(props) {
  const [convenioPaciente, setConvenioPaciente] = useState([]);
  const [idPaciente, setIdPaciente] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [links, setLinks] = useState([]);
  const [page, setPage] = useState("1");

  const history = useHistory();

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    setIsLoading(true);

    await axios
      .get(`${window.url}ws/convenioPaciente/${props.match.params.id}/list?page=${page}`, config)
      .then((res) => {
        setConvenioPaciente([]);
        setIdPaciente(props.match.params.id);
        // eslint-disable-next-line array-callback-return
        res.data.data.map((item) => {
          // eslint-disable-next-line no-shadow
          setConvenioPaciente((convenioPaciente) => [
            ...convenioPaciente,
            /* VARIÁVEIS DO BANCO DE DADOS */
            {
              id: item.id,
              id_paciente: item.id_paciente,
              nome: item.nome,
              id_convenio: item.id_convenio,
              nome_convenio: item.nome_convenio,
              carteirinha: item.carteirinha,
              data_validade: dateEnBr(item.data_validade),
              principal: principal(item.principal),
              observacoes: item.observacoes,
              /* FUNÇÃO DE EDIÇÃO */
              functions: (
                <SuiBox ml={-1.325}>
                  {/* <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      history.push(`/auxiliares/convenioPaciente/editar/${item.id}/${item.uuid}`);
                    }}
                  >
                    <Icon>edit</Icon>
                  </SuiButton> */}
                  <Tooltip title="Editar" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={() => {
                        history.push(`/auxiliares/convenioPaciente/editar/${item.id}/${item.uuid}`);
                      }}
                    >
                      <Icon>edit</Icon>
                    </SuiButton>
                  </Tooltip>

                </SuiBox>
              ),
            },
          ]);
        });
        setIsLoading(false);
        setLinks(res.data.links);
      })
      .catch(() => { });
  };

  const table = {
    /* EDITAR CAMPOS */
    columns: [
      // { Header: "#", accessor: "id", width: "2%" },
      { Header: "Nome", accessor: "nome", width: "25%" },
      { Header: "Convênio", accessor: "nome_convenio", width: "15%" },
      { Header: "Carteirinha Nº", accessor: "carteirinha", width: "15%" },
      { Header: "Principal", accessor: "principal", width: "15%" },
      { Header: "Data de validade", accessor: "data_validade", width: "15%" },
      { Header: "Opções", accessor: "functions", width: "1%" },
    ],

    rows: convenioPaciente,
  };

  useEffect(() => {
    get();
  }, [page]);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <SuiBox p={3} lineHeight={1}>
          <SuiTypography variant="h5" fontWeight="medium">
            Convênio paciente
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            Lista de convênio paciente
          </SuiTypography>
        </SuiBox>
        <Grid item xs={12} md={6}>
          <SuiBox p={3} lineHeight={0}>
            <SuiButton
              ml={10}
              variant="gradient"
              color="info"
              onClick={() => {
                history.push(`/auxiliares/paciente/convenios/cadastro/${idPaciente}/${props.uuid}`);

              }}
            >
              <SuiTypography variant="h6" fontWeight="medium">
                Novo
              </SuiTypography>
            </SuiButton>
          </SuiBox>
        </Grid>
        {/* eslint-disable-next-line no-nested-ternary */}
        {convenioPaciente.length > 0 ? (
          <>
            <DataTable table={table} canSearch />
            <Paginacao links={links} page={page} setPage={setPage} />
          </>
        ) : (
          <SuiBox p={3} lineHeight={1}>
            <small>Nenhum registro encontrado.</small>
          </SuiBox>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(ConvenioPaciente);
