import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import axios from "axios";
import DataTable from "examples/Tables/DataTable";

import { useHistory, withRouter } from "react-router-dom";

// Soft UI Dashboard PRO React example components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BounceLoader, ClipLoader } from "react-spinners";
import SuiButton from "../../../../components/SuiButton";
import Paginacao from "../../../../components/paginacao";

function Produto() {
  const [produto, setProduto] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [links, setLinks] = useState([]);
  const [page, setPage] = useState("1");

  const history = useHistory();

  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    setIsLoading(true);

    await axios
      .get(`${window.url}ws/produto?page=${page}`, config)
      .then((res) => {
        setProduto([]);
        // eslint-disable-next-line array-callback-return
        res.data.data.map((item) => {
          // eslint-disable-next-line no-shadow
          setProduto((produto) => [
            ...produto,
            /* VARIÁVEIS DO BANCO DE DADOS */
            {
              id: item.id,
              nome_produto: item.nome_produto,
              codigo_barras: item.codigo_barras,
              codigo_produto: item.codigo_produto,
              /* FUNÇÃO DE EDIÇÃO */
              functions: (
                <SuiBox ml={-1.325}>
                  {/* <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      history.push(`/auxiliares/produto/editar/${item.id}/${item.uuid}`);
                    }}
                  >
                    <Icon>edit</Icon>
                  </SuiButton> */}
                  <Tooltip title="Editar" placement="top">
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      onClick={() => {
                        history.push(`/auxiliares/produto/editar/${item.id}/${item.uuid}`);
                      }}
                    >
                      <Icon>edit</Icon>
                    </SuiButton>
                  </Tooltip>

                </SuiBox>
              ),
            },
          ]);
        });
        setIsLoading(false);
        setLinks(res.data.links);
      })
      .catch(() => { });
  };

  const table = {
    /* EDITAR CAMPOS */
    columns: [
      // { Header: "#", accessor: "id", width: "2%" },
      { Header: "Código do produto", accessor: "codigo_produto", width: "15%" },
      { Header: "Nome do produto", accessor: "nome_produto", width: "80%" },
      { Header: "Opções", accessor: "functions", width: "1%" },
    ],

    rows: produto,
  };

  useEffect(() => {
    get();
  }, [page]);

  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <SuiBox p={3} lineHeight={1}>
          <SuiTypography variant="h5" fontWeight="medium">
            Produto
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            Lista de Produto
          </SuiTypography>
        </SuiBox>
        {/* eslint-disable-next-line no-nested-ternary */}
        {produto.length > 0 ? (
          <>
            <DataTable table={table} canSearch />
            <Paginacao links={links} page={page} setPage={setPage} />
          </>
        ) : (
          <SuiBox p={3} lineHeight={1}>
            <small>Nenhum registro encontrado.</small>
          </SuiBox>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(Produto);
