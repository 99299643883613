import { useEffect, useState } from "react";


export function useCarteirinha() {
  const [carteirinha, setCarteirinha] = useState(() => {
    const carteirinha = localStorage.getItem("carteirinha");
    return carteirinha;
  });

  useEffect(() => {
    if (carteirinha !== null) {
      localStorage.setItem("carteirinha", carteirinha);
    }

  }, [carteirinha]);

  return { carteirinha, setCarteirinha };
}


export function useIdCarteirinha() {
  const [idCarteirinha, setIdCarteirinha] = useState(() => {
    const idCarteirinha = localStorage.getItem("id_carteirinha");
    return idCarteirinha;
  });

  useEffect(() => {
    if (idCarteirinha !== null) {
      localStorage.setItem("id_carteirinha", idCarteirinha);
    }

  }, [idCarteirinha]);

  return { idCarteirinha, setIdCarteirinha };
}



// ---------------------------------------------------------
export function useIdentificador() {
  const [identificador, setIdentificador] = useState(() => {
    const identificador = localStorage.getItem("identificador");
    return identificador;
  });

  useEffect(() => {
    if (identificador !== null) {
      localStorage.setItem("identificador", identificador);
    }

  }, [identificador]);

  return { identificador, setIdentificador };
}

// ---------------------------------------------------------
export function useIdBancadaAnterior() {
  const [idBancadaAnterior, setIdBancadaAnterior] = useState(() => {
    const idBancadaAnterior = localStorage.getItem("id_bancada_anterior");
    return idBancadaAnterior;
  });

  useEffect(() => {
    if (idBancadaAnterior !== null) {
      localStorage.setItem("id_bancada_anterior", idBancadaAnterior);
    }

  }, [idBancadaAnterior]);

  return { idBancadaAnterior, setIdBancadaAnterior };
}

export function useBancadaAnterior() {
  const [bancadaAnterior, setBancadaAnterior] = useState(() => {
    const bancadaAnterior = localStorage.getItem("bancadaAnterior");

    return bancadaAnterior;
  });

  useEffect(() => {
    if (bancadaAnterior !== null) {
      localStorage.setItem("bancadaAnterior", bancadaAnterior);
    }

  }, [bancadaAnterior]);

  return { bancadaAnterior, setBancadaAnterior };
}

// ---------------------------------------------------------

export function useIdBancada() {
  const [idBancada, setIdBancada] = useState(() => {
    const idBancada = localStorage.getItem("id_bancada");
    return idBancada;
  });

  useEffect(() => {
    if (idBancada !== null) {
      localStorage.setItem("id_bancada", idBancada);
    }

  }, [idBancada]);

  return { idBancada, setIdBancada };
}

export function useBancada() {
  const [bancada, setBancada] = useState(() => {
    const bancada = localStorage.getItem("bancada");

    return bancada;
  });

  useEffect(() => {
    if (bancada !== null) {
      localStorage.setItem("bancada", bancada);
    }

  }, [bancada]);

  return { bancada, setBancada };
}

// ---------------------------------------------------------

export function useIdPaciente() {
  const [idPaciente, setIdPaciente] = useState(() => {
    const idPaciente = localStorage.getItem("id_paciente");
    return idPaciente;
  });

  useEffect(() => {
    if (idPaciente !== null) {
      localStorage.setItem("id_paciente", idPaciente);
    }

  }, [idPaciente]);

  return { idPaciente, setIdPaciente };
}

export function usePaciente() {
  const [paciente, setPaciente] = useState(() => {
    const paciente = localStorage.getItem("paciente");

    return paciente;
  });

  useEffect(() => {
    if (paciente !== null) {
      localStorage.setItem("paciente", paciente);
    }

  }, [paciente]);

  return { paciente, setPaciente };
}

// ---------------------------------------------------------

export function useIdInstituicao() {
  const [idInstituicao, setIdInstituicao] = useState(() => {
    const idInstituicao = localStorage.getItem("id_instituicao");
    return idInstituicao;
  });

  useEffect(() => {
    if (idInstituicao !== null) {
      localStorage.setItem("id_instituicao", idInstituicao);
    }
  }, [idInstituicao]);

  return { idInstituicao, setIdInstituicao };
}

export function useInstituicao() {
  const [instituicao, setInstituicao] = useState(() => {
    const instituicao = localStorage.getItem("instituicao");

    return instituicao;
  });

  useEffect(() => {
    if (instituicao !== null) {
      localStorage.setItem("instituicao", instituicao);
    }


  }, [instituicao]);

  return { instituicao, setInstituicao };
}

// ---------------------------------------------------------

export function useIdConvenio() {
  const [idConvenio, setIdConvenio] = useState(() => {
    const idConvenio = localStorage.getItem("id_convenio");
    return idConvenio;
  });

  useEffect(() => {
    if (idConvenio !== null) {
      localStorage.setItem("id_convenio", idConvenio);
    }

  }, [idConvenio]);

  return { idConvenio, setIdConvenio };
}

export function useConvenio() {
  const [convenio, setConvenio] = useState(() => {
    const convenio = localStorage.getItem("convenio");

    return convenio;
  });

  useEffect(() => {
    if (convenio !== null) {
      localStorage.setItem("convenio", convenio);
    }

  }, [convenio]);

  return { convenio, setConvenio };
}

// ---------------------------------------------------------

export function useIdProfissionalSaude() {
  const [idProfissionalSaude, setIdProfissionalSaude] = useState(() => {
    const idProfissionalSaude = localStorage.getItem("id_profissionalSaude");
    return idProfissionalSaude;
  });

  useEffect(() => {
    if (idProfissionalSaude !== null) {
      localStorage.setItem("id_profissionalSaude", idProfissionalSaude);
    }
    
  }, [idProfissionalSaude]);

  return { idProfissionalSaude, setIdProfissionalSaude };
}

export function useProfissionalSaude() {
  const [profissionalSaude, setProfissionalSaude] = useState(() => {
    const profissionalSaude = localStorage.getItem("profissionalSaude");

    return profissionalSaude;
  });

  useEffect(() => {
    if (profissionalSaude !== null) {
      localStorage.setItem("profissionalSaude", profissionalSaude);
    }

  }, [profissionalSaude]);

  return { profissionalSaude, setProfissionalSaude };
}

// ---------------------------------------------------------

export function useIdProfissionalSaudeAux() {
  const [idProfissionalSaudeAux, setIdProfissionalSaudeAux] = useState(() => {
    const idProfissionalSaudeAux = localStorage.getItem("id_profissionalSaude_aux");
    return idProfissionalSaudeAux;
  });

  useEffect(() => {
    if (idProfissionalSaudeAux !== null) {
      localStorage.setItem("id_profissionalSaude_aux", idProfissionalSaudeAux);
    }
    
  }, [idProfissionalSaudeAux]);

  return { idProfissionalSaudeAux, setIdProfissionalSaudeAux };
}

export function useProfissionalSaudeAux() {
  const [profissionalSaudeAux, setProfissionalSaudeAux] = useState(() => {
    const profissionalSaudeAux = localStorage.getItem("profissionalSaudeAux");

    return profissionalSaudeAux;
  });

  useEffect(() => {
    if (profissionalSaudeAux !== null) {
      localStorage.setItem("profissionalSaudeAux", profissionalSaudeAux);
    }

  }, [profissionalSaudeAux]);

  return { profissionalSaudeAux, setProfissionalSaudeAux };
}



export function useDeleteAll() {

  localStorage.removeItem("carteirinha");
  localStorage.removeItem("identificador");
  localStorage.removeItem("id_bancada_anterior");
  localStorage.removeItem("bancadaAnterior");
  localStorage.removeItem("id_bancada");
  localStorage.removeItem("id_carteirinha")
  localStorage.removeItem("bancada");
  localStorage.removeItem("id_paciente");
  localStorage.removeItem("paciente");
  localStorage.removeItem("id_instituicao");
  localStorage.removeItem("instituicao");
  localStorage.removeItem("id_convenio");
  localStorage.removeItem("convenio");
  localStorage.removeItem("id_profissionalSaude");
  localStorage.removeItem("profissionalSaude");
  localStorage.removeItem("id_profissionalSaude_aux");
  localStorage.removeItem("profissionalSaudeAux");
  localStorage.removeItem("goBackStatus");

  return (0);
}