import axios from "axios";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {getToken, removeToken, setFilial, setTheme, setToken} from "../../utils/HelperFunctions";

export const fetchUserData = createAsyncThunk(
    "auth/fetchUserData",
    async (_, {rejectWithValue}) => {
        try {
            const accessToken = getToken();
            const config = {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            const response = await axios.get(`${window.url}auth/user`, config);
            return {...response.data, accessToken};
        } catch (e) {
            removeToken();
            return rejectWithValue("");
        }
    }
);

export const login = createAsyncThunk(`auth/login`, async (payload) => {
    const response = await axios.post(`${window.url}auth/login`, payload);

    setToken(response.data.accessToken);
    setFilial(payload.idFilial);
    setTheme(response.data.theme);

    window.location.href = "/dashboards/home";
});

export const signOut = createAsyncThunk("auth/signOut", async () => {
    removeToken();
});