import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import axios from "axios";
import DataTable from "examples/Tables/DataTable";

import { useHistory, withRouter } from "react-router-dom";

// Soft UI Dashboard PRO React example components
import Icon from "@mui/material/Icon";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BounceLoader } from "react-spinners";
import Swal from "sweetalert2";
import swal from "@sweetalert/with-react";
import withReactContent from "sweetalert2-react-content";
import Grid from "@mui/material/Grid";
import SuiButton from "../../../../components/SuiButton";
import Paginacao from "../../../../components/paginacao";
import { fixando, descalcificando } from "../../../../utils";

function VizualizarMacroscopia(props) {
  const [materialBioEntradaExame, setMaterialBioEntradaExame] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [identificador, setIdentificador] = useState('');
  const [nomePaciente, setNomePaciente] = useState('');
  const [links, setLinks] = useState([]);
  const [page, setPage] = useState("1");
  const history = useHistory();



  const get = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    setIsLoading(true);

    await axios
      .get(`${window.url}ws/materiaisLaminacao/${props.match.params.id}/list?page=${page}`, config)
      .then((res) => {
        setIdentificador(res.data.data[0].identificador);
        setNomePaciente(res.data.data[0].nome);
        setMaterialBioEntradaExame([]);
        setIsLoading(false);
        // eslint-disable-next-line array-callback-return
        res.data.data.map((item) => {
          // eslint-disable-next-line no-shadow
          setMaterialBioEntradaExame((materialBioEntradaExame) => [
            ...materialBioEntradaExame,
            /* VARIÁVEIS DO BANCO DE DADOS */
            {
              id: item.id_material_biologico_ee,
              id_entrada_exame: item.id_entrada_exame,
              id_material_tecido: item.id_material_tecido,
              id_item: item.id_item,
              material_biologico: item.material_biologico,
              // nome_material: item.nome_material,
              id_coloracao: item.id_coloracao,
              coloracao: item.coloracao,
              codigo_coloracao: item.codigo_coloracao,
              numero_bi: item.numero_bi,
              fixando: fixando(item.fixando),
              descalcificando: descalcificando(item.descalcificando),
              amostragem: item.amostragem,
              descricao: item.descricao,
              identificador: item.identificador,
              /* FUNÇÃO DE EDIÇÃO */
              functions: (
                <SuiBox ml={-1.325}>
                  <SuiButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      history.push(`/auxiliares/materialBiologicoEntradaExame/editar/${item.id}/${item.uuid}`);
                    }}
                  >
                    <Icon>edit</Icon>
                  </SuiButton>
                </SuiBox>
              ),
            },
          ]);
        });
        setIsLoading(false);
        setLinks(res.data.links);
      })
      .catch(() => { });
  };


  const table = {
    /* EDITAR CAMPOS */
    columns: [
      // { Header: "id ", accessor: "id", width: "4%" },
      // { Header: "Exame", accessor: "id_entrada_exame", width: "4%" },
      // { Header: "Material", accessor: "material_biologico", width: "10%" },
      { Header: "id", accessor: "id_item", width: "3%" },
      {
        Header: "Material",
        accessor: "material_biologico",
        Cell: ({ row }) => <span dangerouslySetInnerHTML={{ __html: row.values.material_biologico }} />,
        width: "10%",
      },
      // { Header: "Coloracao", accessor: "coloracao", width: "10%" },
      {
        Header: "Coloracao",
        accessor: "coloracao",
        Cell: ({ row }) => {
          let { coloracao } = row.values;
          if (!coloracao) {
            coloracao = ''; 
          }
          return (
            <span 
              dangerouslySetInnerHTML={{
                __html: coloracao.length > 35 ? `${coloracao.slice(0, 35)}...` : coloracao
              }}
            />
          )
        },
        width: "10%", 
      },
      { Header: "Nºblocos", accessor: "numero_bi", width: "3%" },
      { Header: "Fixando", accessor: "fixando", width: "5%" },
      { Header: "Descalcificando", accessor: "descalcificando", width: "5%" },
      {
        Header: "Amostragem",
        accessor: "amostragem",
        Cell: ({ row }) => <span dangerouslySetInnerHTML={{ __html: row.values.amostragem }} />,
        width: "10%",
      },
      {
        Header: "Descrição",
        accessor: "descricao",
        Cell: ({ row }) => {
          let { descricao } = row.values;
          if (!descricao) {
            descricao = ''; 
          }
          return (
            <span 
              dangerouslySetInnerHTML={{
                __html: descricao.length > 50 ? `${descricao.slice(0, 50)}...` : descricao
              }}
            />
          )
        },
        width: "20%", 
      }
    ],

    rows: materialBioEntradaExame,
  };

  useEffect(() => {
    get();
  }, [page]);




  return isLoading ? (
    <DashboardLayout>
      <DashboardNavbar />
      <BounceLoader size={30} color="#ccc" />
    </DashboardLayout>
  ) : (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <SuiBox p={3} lineHeight={1}>
          <SuiTypography variant="h5" fontWeight="medium">
            Material biológico no histotécnico
          </SuiTypography>
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            Lista de materiais biológico
          </SuiTypography>
          <Grid item xs={12} md={12}>
            <SuiBox mb={0} ml={0.5} mt={1} lineHeight={0} display="inline-block">
              <SuiTypography variant="h6" fontWeight="medium">
                {identificador}
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid item xs={12} md={12}>
            <SuiBox mb={0} ml={0.5} mt={1} lineHeight={0} display="inline-block">
              <SuiTypography variant="h6" fontWeight="medium">
                {nomePaciente}
              </SuiTypography>
            </SuiBox>
          </Grid>
          <SuiBox
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            mt={1}
            lineHeight={1}
            width="100%"
            position="absolute"
            top="1rem"
            right="1rem"
          >
            <SuiButton
              variant="gradient"
              buttonColor="light"
              size="medium"
              onClick={() => {
                history.goBack();
              }}
            >
              <SuiTypography variant="h6" fontWeight="medium">
                Voltar
              </SuiTypography>
            </SuiButton>
          </SuiBox>
        </SuiBox>
        {/* eslint-disable-next-line no-nested-ternary */}
        {materialBioEntradaExame.length > 0 ? (
          <>
            <DataTable table={table} canSearch />
            <Paginacao links={links} page={page} setPage={setPage} />
          </>
        ) : (
          <SuiBox p={3} lineHeight={1}>
            <small>Nenhum registro encontrado.</small>
          </SuiBox>
        )}
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default withRouter(VizualizarMacroscopia);