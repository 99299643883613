import React, { useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useHistory, withRouter } from "react-router-dom";

// Sweet Alerts page components
import Template from "layouts/pages/sweet-alerts/components/Template";

function ExcluirTabelaProcedimento(props) {
  const history = useHistory();
  const excluir = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .delete(`${window.url}ws/tabelaProcedimento/${props.match.params.id}`, config)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          props.history.push("/auxiliares/tabelaProcedimento");
        }
      })
      .catch(() => {});
  };
  //   const get = async () => {
  //     const config = {
  //       headers: {
  //         Accept: "application/json",
  //         Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //       },
  //     };
  //   };
  //   useEffect(() => {
  //     get();
  //   }, []);

  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Tem certeza que deseja excluir?",
        text: "Depois de confirmar não poderá ser revertido!",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Não, cancelar!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          excluir();
          newSwal.fire("Excluído!", "Item excluído.", "success");
          history.push(`/auxiliares/tabelaProcedimento`); /* RETIRAR APÓS O .delete FUNCIONAR!  */
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          newSwal.fire("Cancelado!", "O item não foi excluído :)", "error");
          history.push(`/auxiliares/tabelaProcedimento`);
        }
      });
  };

  return <Template title="Atenção! Você esta prestes a excluir um item..." action={showAlert} />;
}

export default withRouter(ExcluirTabelaProcedimento);
