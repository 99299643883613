import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

// SmartHome dashboard components
import Cameras from "layouts/dashboards/smart-home/components/Cameras";
import axios from "axios";
import SuiTypography from "../../../components/SuiTypography";
import { useSoftUIController } from "../../../context";

function SmartHome() {
    const [periodo, setPeriodo] = useState();
    const [totalRecepcao, setTotalRecepcao] = useState(0);
    const [totalMacroscopia, setTotalMacroscopia] = useState(0);
    // const [totalLaminacao, setTotalLaminacao] = useState(0);
    const [totalPreparo, setTotalPreparo] = useState(0);
    const [totalLaudo, setTotalLaudo] = useState(0);
    const [controller, dispatch] = useSoftUIController();

    useEffect(() => {
        dispatch({ type: "SIDENAV_COLOR", value: sessionStorage.getItem('theme') });
    }, []);

    const get = () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        axios
            .get(`${window.url}ws/entradaExame/totais/count`, config)
            .then((res) => {
                setTotalRecepcao(res.data.recepcao);
                setTotalPreparo(res.data.preparo);
                setTotalLaudo(res.data.diagnostico);
                setTotalMacroscopia(res.data.macroscopia);
            })
            .catch(() => {
            });
    };

    useEffect(() => {
        get();
    }, []);

    useEffect(() => {
        const data = new Date();

        setPeriodo(`${data.getMonth().toString().padStart(2, "0")} / ${data.getFullYear()}`);
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox pt={3}>
                <SuiTypography variant="h3" fontWeight="regular">
                    Dashboard {periodo}
                </SuiTypography>
                <SuiBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} xl={7}>
                            <Cameras />
                        </Grid>
                        <Grid item xs={12} xl={5}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <DefaultCounterCard
                                        count={totalRecepcao || 0}
                                        title="exames"
                                        description="Na recepção"
                                        color={sessionStorage.getItem('theme')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DefaultCounterCard
                                        count={totalMacroscopia || 0}
                                        title="exames"
                                        description="Em macroscopia"
                                        color={sessionStorage.getItem('theme')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DefaultCounterCard
                                        count={totalPreparo || 0}
                                        title="exames"
                                        description="Em histotécnico"
                                        color={sessionStorage.getItem('theme')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DefaultCounterCard
                                        count={totalLaudo || 0}
                                        title="exames"
                                        description="Em diagnóstico"
                                        color={sessionStorage.getItem('theme')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SuiBox>
            </SuiBox>
            <Footer />
        </DashboardLayout>
    );
}

export default SmartHome;
