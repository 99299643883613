import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from 'moment';


// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Stack from '@mui/material/Stack';


// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiEditor from "components/SuiEditor";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { BiEditAlt, BiPlusCircle, BiSearch } from "react-icons/all";

import axios from "axios";

// Soft UI Dashboard PRO React example components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Link, useHistory, withRouter } from "react-router-dom";
import swal from "@sweetalert/with-react";
import PacienteSelect from "../../../../components/paciente-select";
import InstituicaoSelect from "../../../../components/instituicao-select";
import ConvenioSelect from "../../../../components/convenio-select";
// import ProcedimentoSelect from "../../../../components/procedimento-select";
import ProfissionalSelect from "../../../../components/profissional-select";
// import MaterialTecidoSelect from "../../../../components/materialTecido-select";
import CodigoEntradaExameSelect from "../../../../components/codigoEntradaExame-select";
import SuiDatePicker from "../../../../components/SuiDatePicker";
import {
    useCarteirinha,
    useBancada,
    useBancadaAnterior,
    useConvenio,
    useDeleteAll,
    useIdBancada,
    useIdCarteirinha,
    useIdBancadaAnterior,
    useIdConvenio,
    useIdentificador,
    useIdInstituicao,
    useIdPaciente,
    useIdProfissionalSaude,
    useIdProfissionalSaudeAux,
    useInstituicao,
    usePaciente,
    useProfissionalSaude,
    useProfissionalSaudeAux,
} from "./components/hooks/useLocalStorge";



const customCheckboxTheme = createTheme({
    components: {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    "& .MuiSvgIcon-root": {
                        stroke: "#000",
                        strokeWidth: 0.1,
                    },
                },
            },
        },
    },
});



function adicionarDiasUteis(dataOriginalStr, numDiasParaAdicionar) {
    let dataAtual = moment(dataOriginalStr, 'DD/MM/YYYY');

    while (numDiasParaAdicionar > 0) {
        dataAtual = dataAtual.add(1, 'days');
        if (dataAtual.day() !== 0 && dataAtual.day() !== 6) {
            numDiasParaAdicionar -= 1;
        }
    }
    return dataAtual;
}


function CadastroEntradaExames(props) {
    const data = new Date();

    const [idCodigoEntradaExame, setIdCodigoEntradaExame] = useState();
    const { carteirinha, setCarteirinha } = useCarteirinha('');
    const { identificador, setIdentificador } = useIdentificador();
    const { idBancadaAnterior, setIdBancadaAnterior } = useIdBancadaAnterior();
    const { bancadaAnterior, setBancadaAnterior } = useBancadaAnterior();
    const { idBancada, setIdBancada } = useIdBancada();
    const { bancada, setBancada } = useBancada();
    const { idPaciente, setIdPaciente } = useIdPaciente();
    const { paciente, setPaciente } = usePaciente();
    const { idInstituicao, setIdInstituicao } = useIdInstituicao();
    const { instituicao, setInstituicao } = useInstituicao();
    const { idConvenio, setIdConvenio } = useIdConvenio();
    const { convenio, setConvenio } = useConvenio();
    const { idProfissionalSaude, setIdProfissionalSaude } = useIdProfissionalSaude();
    const { profissionalSaude, setProfissionalSaude } = useProfissionalSaude();
    const { idProfissionalSaudeAux, setIdProfissionalSaudeAux } = useIdProfissionalSaudeAux();
    const { profissionalSaudeAux, setProfissionalSaudeAux } = useProfissionalSaudeAux();
    const [dataEntradaExame, setDataEntradaExame] = useState();
    const [previsaoEntrega, setPrevisaoEntrega] = useState();
    const [registroEnvio, setRegistroEnvio] = useState();
    const [prioridade, setPrioridade] = useState(false);
    const [dataColeta, setDataColeta] = useState();
    const [observacao, setObservacao] = useState();
    const [historicoEntradaEx, setHistoricoEntradaEx] = useState();
    const [carteirinhaEncontrada, setCarteirinhaEncontrada] = useState(false);
    const { idCarteirinha, setIdCarteirinha } = useIdCarteirinha();
    const [load, setLoad] = useState(true);
    const history = useHistory();


    const [deveGuia, setDeveGuia] = useState(false);
    const handleSetPrioridade = () => {
        setPrioridade(!prioridade);
    };
    const handleSetDeveGuia = () => setDeveGuia(!deveGuia);


    const save = async () => {

        // if (carteirinhaEncontrada === false) {
        //     toast.info('Paciente sem carteirinha!')
        //     return;
        // }
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .post(
                `${window.url}ws/entradaExame`,
                {
                    /* MANTER O IDSAAS  */
                    id_filial: sessionStorage.getItem("idFilial"),
                    id_paciente: idPaciente,
                    id_instituicao: idInstituicao,
                    id_convenio: idConvenio,
                    id_profissional: idProfissionalSaude,
                    id_profissional_aux: idProfissionalSaudeAux,
                    id_carteirinha: idCarteirinha,
                    data_entrada_exame: dataEntradaExame,
                    previsao_entrega: previsaoEntrega,
                    registro_envio: registroEnvio,
                    prioridade,
                    data_coleta: dataColeta,
                    observacao,
                    deve_guia: deveGuia,
                    // id_codigo_entrada_exame: idCodigoEntradaExame,
                    id_bancada: idBancada,
                    identificador,


                },
                config
            )
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    // const { identificador } = res.data;
                    swal("Agilie", `Entrada exame cadastrado com sucesso! O identificador é: ${identificador}`, "success");
                    props.history.push("/auxiliares/entradaExame");
                    useDeleteAll();
                }
            })
            .catch((error) => {

                if (error.response.status === 404) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    props.history.goBack();
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else if (error.response.status === 400) {
                    // const identificador = (error.response.data.generated_code);
                    swal("Agilie", `Esse identificador já existe! Procure pelo identificador: ${identificador}`, "error");
                    props.history.push("/auxiliares/entradaExame");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    const pacientePush = () => {
        localStorage.setItem("goBackStatus", "1");
        props.history.push("/auxiliares/paciente/cadastro");
    };

    const instituicaoPush = () => {
        localStorage.setItem("goBackStatus", "1");
        props.history.push("/auxiliares/instituicao/cadastro");
    };

    const convenioPush = () => {
        localStorage.setItem("goBackStatus", "1");
        props.history.push("/auxiliares/convenio/cadastro");
    };

    const profissionalSaudePush = () => {
        localStorage.setItem("goBackStatus", "1");
        props.history.push("/auxiliares/profissionalSaude/cadastro");
    };


    const gerarIdentificador = async (id) => {
        await axios
            .get(`${window.url}ws/entradaExame/identificador/${id}`)
            .then((res) => {
                setIdentificador(res.data);
            })
            .catch((error) => {
                console.log(error)
            });
        // setIsLoading(false);
    };

    useEffect(async () => {

        if (idBancada !== null && idBancada !== 'null') {

            const id = idBancada;

            if (localStorage.getItem("id_bancada_anterior") === 'null') {
                setIdBancadaAnterior(idBancada);
                await gerarIdentificador(id);
            } else if ((localStorage.getItem("id_bancada")) !== (localStorage.getItem("id_bancada_anterior"))) {
                setIdBancadaAnterior(idBancada);
                await gerarIdentificador(id);
            }
        }
    }, [idBancada]);


    useEffect(() => {
        const dataAtual = moment().format('DD/MM/YYYY');
        setDataEntradaExame(dataAtual);

        const previsaoEntregaData = adicionarDiasUteis(dataAtual, 10);
        setPrevisaoEntrega(previsaoEntregaData.format('DD/MM/YYYY'));
    }, []);



    const buscarCarteirinha = async (botao) => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .get(`${window.url}ws/convenioPaciente/carteirinha/${carteirinha}`, config)
            .then((res) => {
                const convenioPacienteObj = res.data.carteirinha;
                if (botao === true) {
                    setIdCarteirinha(convenioPacienteObj.id);
                    setIdPaciente(convenioPacienteObj.id_paciente);
                    setPaciente(convenioPacienteObj.nome);
                    setIdConvenio(convenioPacienteObj.id_convenio);
                    setConvenio(convenioPacienteObj.nome_convenio);
                    toast.success("Carteirinha encontrada!")
                    setCarteirinhaEncontrada(true);

                } else {
                    setCarteirinhaEncontrada(true);
                }


            })
            .catch((error) => {
                if (error.response.status === 404) {
                    // const falha = error.response.data.message;
                    // toast.error(falha);
                    if (botao === true) {
                        toast.info("Paciente sem carteirinha!")
                        setCarteirinhaEncontrada(false);
                        setIdCarteirinha(null);
                        setIdPaciente(null);
                        setPaciente('');
                    } else {
                        setCarteirinhaEncontrada(false);

                    }

                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    const buscarPacienteCarteirinha = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .get(`${window.url}ws/convenioPaciente/pacienteCarteirinha/${idPaciente}`, config)
            .then((res) => {
                const pacienteCarteirinhaObj = res.data.pacienteCarteirinha;
                setIdCarteirinha(pacienteCarteirinhaObj.id)
                setCarteirinha(pacienteCarteirinhaObj.carteirinha);
                setIdConvenio(pacienteCarteirinhaObj.id_convenio);
                setConvenio(pacienteCarteirinhaObj.nome_convenio);
                setCarteirinhaEncontrada(true);
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    setCarteirinhaEncontrada(false);
                    toast.info("Paciente sem carteirinha!")
                    setCarteirinha('');
                    setIdCarteirinha('');
                    setIdConvenio('');
                    setConvenio('');
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };


    const historicoEntradaExame = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };

        await axios
            .get(`${window.url}ws/entradaExame/historicoEntradaExame/${idPaciente}`, config)
            .then((res) => {
                const historicoEntradaExameObj = res.data.historicoEntradaExame;
                if (res.status === 200 || res.status === 201) {
                    if (historicoEntradaExameObj.length >= 1) {
                        toast.success("Exame(s) encontrado(s)");
                    }
                    setHistoricoEntradaEx(historicoEntradaExameObj);
                }



            })
            .catch((error) => {
                if (error.response.status === 404) {
                    // toast.error("Nenhum exame cadastrado para este paciente" );
                } else if (error.response.status === 500) {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    swal("Agilie", "Ops! Houve um erro, contate o densevolvedor!", "error");
                } else {
                    const falha = error.response.data.message;
                    toast.error(falha);
                    const mensagem = error.response.data.errors;
                    /* eslint-disable no-plusplus */
                    for (let i = 0; i < mensagem.length; i++) {
                        const msg = error.response.data.errors[i];
                        toast.error(msg);
                    }
                }
            });
    };

    useEffect(() => {
        if (idPaciente !== null) {
            historicoEntradaExame();
        }

    }, [idPaciente]);


    useEffect(() => {
        if (idPaciente !== null && localStorage.getItem("id_paciente") !== idPaciente) {
            buscarPacienteCarteirinha();
            setLoad(false);
        }
        // Load é para evitar que buscarPacienteCarteirinha(); seja chamado toda vez que atualiza a página
    }, [idPaciente, load]);

    useEffect(() => {
        if (carteirinha !== null) {
            buscarCarteirinha();
        }


    }, [carteirinha]);




    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SuiBox mt={3} mb={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={9} container alignItems="center">
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={12} md={3} lg={3}>
                                        <SuiTypography variant="h6" fontWeight="medium">
                                            Nova entrada de exames
                                        </SuiTypography>
                                        <SuiTypography variant="button" fontWeight="regular" textColor="text">
                                            Cadastro entrada de exames
                                        </SuiTypography>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={3}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Carteirinha Nº
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            value={carteirinha ?? ""}
                                            onChange={(e) => {
                                                setCarteirinha(e.target.value);
                                            }}

                                        />
                                    </Grid>
                                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
                                        <SuiBox mt={1} mb={-4} ml={1}>
                                            <Tooltip title="Buscar carteirinha" placement="top">
                                                <SuiButton
                                                    variant="gradient"
                                                    buttonColor="info"
                                                    onClick={() => {
                                                        if (carteirinha === null || carteirinha === '') {
                                                            swal("Agilie", "Digite o número da carteirinha!", "info");
                                                        } else {
                                                            const botao = true;
                                                            buscarCarteirinha(botao);
                                                        }
                                                    }}
                                                >
                                                    <BiSearch />
                                                </SuiButton>
                                            </Tooltip>
                                        </SuiBox>
                                    </Stack>

                                    <Grid item xs={12} md={2.7} lg={2.7}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Bancada
                                            </SuiTypography>
                                        </SuiBox>
                                        <CodigoEntradaExameSelect
                                            value={{
                                                value: idBancada,
                                                label: bancada || "Selecione"

                                            }}
                                            onChangeValue={(e) => {
                                                setIdBancada(e.value);
                                                setBancada(e.label);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2} lg={2}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Exame Nº
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiInput
                                            readOnly
                                            value={identificador ?? ""}
                                            placeholder={identificador === null || identificador === "null" || identificador === "" ? "Selecione a bancada" : "Selecione a bancada"}
                                        />
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Paciente
                                            </SuiTypography>
                                        </SuiBox>
                                        <PacienteSelect
                                            value={{
                                                value: idPaciente,
                                                label: paciente || "Selecione",

                                            }}
                                            onChangeValue={(e) => {
                                                setIdPaciente(e.value);
                                                setPaciente(e.label);
                                            }}
                                        />
                                        <SuiBox mt={1} mb={1}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={12}>
                                                    <Tooltip title="Cadastrar paciente" placement="top">
                                                        <SuiButton
                                                            variant="gradient"
                                                            buttonColor="info"
                                                            onClick={() => {
                                                                pacientePush();
                                                            }}
                                                        >
                                                            <BiPlusCircle />
                                                        </SuiButton>
                                                    </Tooltip>
                                                    <Tooltip title="Editar paciente" placement="top">
                                                        <SuiButton
                                                            variant="gradient"
                                                            buttonColor="info"
                                                            onClick={() => {
                                                                if (idPaciente === null || idPaciente === 'null') {
                                                                    swal("Agilie", "Selecione um paciente para editar", "info");

                                                                } else {
                                                                    props.history.push(
                                                                        `/auxiliares/paciente/editar/${idPaciente}/${props.uuid}`
                                                                    );
                                                                    localStorage.setItem("goBackStatus", "1");
                                                                }
                                                            }}
                                                            style={{ marginLeft: '3px' }}
                                                        >
                                                            <BiEditAlt />
                                                        </SuiButton>
                                                    </Tooltip>
                                                    <Tooltip title="Cadastrar carteirinha" placement="top">
                                                        <SuiButton
                                                            variant="gradient"
                                                            buttonColor="info"
                                                            onClick={() => {
                                                                if (idPaciente === null || idPaciente === 'null') {
                                                                    swal("Agilie", "Selecione um paciente para cadastrar a carteirinha", "info");

                                                                } else {
                                                                    props.history.push(
                                                                        `/auxiliares/paciente/convenios/cadastro/${idPaciente}/${props.uuid}`
                                                                    );
                                                                    localStorage.setItem("goBackStatus", "1");
                                                                }
                                                            }}
                                                            style={{ marginLeft: '3px' }}
                                                        >
                                                            <BiPlusCircle />
                                                        </SuiButton>
                                                    </Tooltip>

                                                </Grid>
                                            </Grid>
                                        </SuiBox>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Instituição
                                            </SuiTypography>
                                        </SuiBox>
                                        <InstituicaoSelect
                                            value={{
                                                value: idInstituicao,
                                                label: instituicao || "Selecione",

                                            }}
                                            onChangeValue={(e) => {
                                                setIdInstituicao(e.value);
                                                setInstituicao(e.label);
                                            }}
                                        />
                                        <SuiBox mt={1} mb={1}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={6}>
                                                    <Tooltip title="Cadastrar instituição" placement="top">
                                                        <SuiButton
                                                            variant="gradient"
                                                            buttonColor="info"
                                                            onClick={() => {
                                                                instituicaoPush();
                                                            }}
                                                        >
                                                            <BiPlusCircle />
                                                        </SuiButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </SuiBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Convênio
                                            </SuiTypography>
                                        </SuiBox>
                                        <ConvenioSelect
                                            value={{
                                                value: idConvenio,
                                                label: convenio || "Selecione",

                                            }}
                                            onChangeValue={(e) => {
                                                setIdConvenio(e.value);
                                                setConvenio(e.label);
                                            }}
                                        />
                                        <SuiBox mt={1} mb={1}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={6}>
                                                    <Tooltip title="Cadastrar convênio" placement="top">
                                                        <SuiButton
                                                            variant="gradient"
                                                            buttonColor="info"
                                                            onClick={() => {
                                                                convenioPush();
                                                            }}
                                                        >
                                                            <BiPlusCircle />
                                                        </SuiButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </SuiBox>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Médico e/ou profissional solicitante
                                            </SuiTypography>
                                        </SuiBox>
                                        <ProfissionalSelect
                                            value={{
                                                value: idProfissionalSaude,
                                                label: profissionalSaude || "Selecione"

                                            }}
                                            onChangeValue={(e) => {
                                                setIdProfissionalSaude(e.value);
                                                setProfissionalSaude(e.label);
                                            }}
                                        />
                                        <SuiBox mt={1} mb={1}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={6}>
                                                    <Tooltip title="Cadastrar médico" placement="top">
                                                        <SuiButton
                                                            variant="gradient"
                                                            buttonColor="info"
                                                            onClick={() => {
                                                                profissionalSaudePush();
                                                            }}
                                                        >
                                                            <BiPlusCircle />
                                                        </SuiButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </SuiBox>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Médico e/ou profissional auxiliar
                                            </SuiTypography>
                                        </SuiBox>
                                        <ProfissionalSelect
                                            value={{
                                                value: idProfissionalSaudeAux,
                                                label: profissionalSaudeAux || "Selecione"

                                            }}
                                            onChangeValue={(e) => {
                                                setIdProfissionalSaudeAux(e.value);
                                                setProfissionalSaudeAux(e.label);
                                            }}
                                        />
                                        <SuiBox mt={1} mb={1}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={6}>
                                                    <Tooltip title="Cadastrar médico" placement="top">
                                                        <SuiButton
                                                            variant="gradient"
                                                            buttonColor="info"
                                                            onClick={() => {
                                                                profissionalSaudePush();
                                                            }}
                                                        >
                                                            <BiPlusCircle />
                                                        </SuiButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </SuiBox>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Data de coleta
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            onChangeValue={(evt) => {
                                                setDataColeta(evt.toLocaleString("pt-BR"));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Data entrada do exame
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiDatePicker
                                            valueDefault={dataEntradaExame || ""}
                                            onChangeValue={(evt) => {
                                                const dataSelecionada = evt.toLocaleString("pt-BR");
                                                setDataEntradaExame(dataSelecionada);
                                                const previsaoEntregaData = adicionarDiasUteis(dataSelecionada, 10);
                                                setPrevisaoEntrega(previsaoEntregaData.format('DD/MM/YYYY'));
                                            }}
                                            input={{
                                                placeholder: "Selecione uma data",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <ThemeProvider theme={customCheckboxTheme}>
                                                <Checkbox
                                                    checked={prioridade}
                                                    onChange={() => {
                                                        handleSetPrioridade();
                                                    }}
                                                />
                                            </ThemeProvider>
                                            <SuiTypography
                                                variant="button"
                                                fontWeight="bold" /* regular */
                                                onClick={() => {
                                                    handleSetPrioridade();
                                                }}
                                                customClass="cursor-pointer user-select-none"
                                            >
                                                Prioridade
                                            </SuiTypography>
                                        </SuiBox>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <ThemeProvider theme={customCheckboxTheme}>
                                                <Checkbox checked={deveGuia} onChange={handleSetDeveGuia} />
                                            </ThemeProvider>
                                            <SuiTypography
                                                variant="button"
                                                fontWeight="bold" /* regular */
                                                onClick={handleSetDeveGuia}
                                                customClass="cursor-pointer user-select-none"
                                            >
                                                Deve guia
                                            </SuiTypography>
                                        </SuiBox>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <SuiBox mb={1} ml={0.5} display="inline-block">
                                            <SuiTypography component="label" variant="caption" fontWeight="bold">
                                                Observações
                                            </SuiTypography>
                                        </SuiBox>
                                        <SuiEditor value={observacao || ""} onChange={setObservacao} />
                                    </Grid>
                                </Grid>
                                <SuiBox display="flex" justifyContent="flex-end" mt={3}>
                                    <SuiBox mr={1}>
                                        <SuiButton
                                            buttonColor="light"
                                            onClick={() => {
                                                props.history.push("/auxiliares/entradaExame");
                                                useDeleteAll();
                                            }}
                                        >
                                            cancelar
                                        </SuiButton>
                                    </SuiBox>
                                    <SuiButton
                                        variant="gradient"
                                        buttonColor="info"
                                        onClick={() => {
                                            const botao = false;
                                            buscarCarteirinha(botao);
                                            save();
                                        }}
                                    >
                                        Salvar
                                    </SuiButton>
                                </SuiBox>
                            </SuiBox>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Card className="overflow-visible">
                            <SuiBox p={2} lineHeight={1}>
                                <SuiTypography variant="h6" fontWeight="medium">
                                    Histórico de exames
                                </SuiTypography>
                                <Divider />
                                <SuiBox pt={2}>
                                    {historicoEntradaEx && historicoEntradaEx.length > 0 ? (
                                        <Grid container spacing={1}>
                                            <Grid item xs={4}>
                                                <SuiTypography variant="subtitle2" fontWeight="bold" style={{ marginBottom: '6px' }} >
                                                    Exame Nº
                                                </SuiTypography>
                                                {historicoEntradaEx.map((exame, index) => (
                                                    <SuiTypography
                                                        key={index}
                                                        variant="caption"
                                                        display="block"
                                                        gutterBottom
                                                        color="primary"
                                                        style={{ marginBottom: '6px' }}
                                                    >
                                                        {exame.identificador}
                                                    </SuiTypography>
                                                ))}
                                            </Grid>
                                            <Grid item xs={4}>
                                                <SuiTypography variant="subtitle2" fontWeight="bold" style={{ marginBottom: '6px' }}>
                                                    Entrada
                                                </SuiTypography>
                                                {historicoEntradaEx.map((exame, index) => (
                                                    <SuiTypography
                                                        key={index}
                                                        variant="caption"
                                                        display="block"
                                                        gutterBottom
                                                        style={{ marginBottom: '6px' }}
                                                    >
                                                        {moment(exame.data_entrada).format('DD/MM/YYYY')}
                                                    </SuiTypography>
                                                ))}
                                            </Grid>
                                            <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <SuiTypography variant="subtitle2" fontWeight="bold" style={{ marginBottom: '6px' }}>
                                                    Editar
                                                </SuiTypography>
                                                {historicoEntradaEx.map((exame, index) => (
                                                    <Tooltip key={index} title="Editar exame" placement="top">
                                                        <Link to={`/auxiliares/entradaExame/editar/${exame.id}/${exame.uuid}`}>
                                                            <SuiTypography
                                                                variant="caption"
                                                                display="block"
                                                                gutterBottom
                                                                color="primary"
                                                                style={{ marginBottom: '6px' }}
                                                            >
                                                                {exame.identificador}
                                                            </SuiTypography>
                                                        </Link>
                                                    </Tooltip>
                                                ))}
                                            </Grid>

                                        </Grid>
                                    ) : (
                                        <SuiTypography variant="caption" display="block" gutterBottom>
                                            {historicoEntradaEx ? 'Nenhum exame encontrado.' : ''}
                                        </SuiTypography>
                                    )}
                                </SuiBox>
                            </SuiBox>
                        </Card>
                    </Grid>
                </Grid>
            </SuiBox >
            <Footer />
        </DashboardLayout >
    );
}

export default withRouter(CadastroEntradaExames);
