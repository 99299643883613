import ExcluirTabelaProcedimento from "./layouts/pages/auxiliares/tabelaProcedimento/excluir";
import ExcluirProcedimento from "./layouts/pages/auxiliares/procedimento/excluir";

const deleteRoutes = [
  {
    type: "collapse",
    name: "Delete",
    key: "delete",
    collapse: [
      {
        name: "Delete Tabela Procedimento",
        key: "delete-tabela-procedimento",
        route: "/auxiliares/tabelaProcedimento/excluir/:id/:uuid",
        component: ExcluirTabelaProcedimento,
      },
      {
        name: "Delete Procedimento",
        key: "delete-procedimento",
        route: "/auxiliares/procedimento/excluir/:id/:uuid",
        component: ExcluirProcedimento,
      },
    ],
  },
];

export default deleteRoutes;
